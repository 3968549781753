import { Button, Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
// Components
import NavLoginRegister from '../../components/navbar/NavLoginRegister'
// validation
import * as Yup from 'yup'
import { Formik } from 'formik'
import InputField from '../../components/common/forms/InputField'
// api
import { resetPassword } from '../../api/authApi'
import { useMutation } from '@tanstack/react-query'

export default function ResetPasswordChange () {
    const navigate = useNavigate()
    const toast = useToast()

    const pathname = useLocation().pathname
    const { user_id, token } = useParams()
    const { data, isLoading, mutate, error } = useMutation(['activate'], resetPassword, {
        onSuccess: (data) => {
            console.log(data)
            toast({ title: 'Has reseteado tu cuenta!', status: 'success' })
        },
        onError: (error) => {
            console.log(error)
        }
    })

    // vaaalidation for login
    const ResetSchema = Yup.object({
        password: Yup.string()
            .min(8, 'Contraseña demasiado corta - mínimo 8 caracteres')
            .matches(/[a-zA-Z]/, 'Solo puede contener letras del abecedario')
            .required('Campo obligatorio'),
        password2: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
            .required('Campo obligatorio')
    })
    return (
        <Flex w="100%" height="100vh" direction="column" bg="lightgrey">
            <NavLoginRegister path={pathname} />
            <Flex justify="center" align="center" w="100%">
                <Flex py="5%" w={['80%', '75%', '400px', '400px']} direction="column" align="center" gap="5">
                    <Flex>
                        <Heading size="lg">another</Heading>
                        <Heading size="lg" color={'blue'}>
                            tool
                        </Heading>
                    </Flex>
                    <Flex
                        bg="white"
                        w="100%"
                        rounded="xl"
                        direction="column"
                        align="center"
                        gap="6"
                        py="12%"
                        boxShadow="lg"
                    >
                        {data
                            ? (
                                <>
                                    <Heading size="md"> Perfecto! </Heading>
                                    <Text>Ya puedes iniciar sesión</Text>
                                    <Button mt="8" variant="primary-s" size="md" onClick={() => navigate('/login')}>
                                    Iniciar sesión
                                    </Button>
                                </>
                            )
                            : (
                                <>
                                    <Heading size="md"> Restablecer contraseña</Heading>
                                    <Formik
                                        initialValues={{
                                            password: '',
                                            password2: ''
                                        }}
                                        validationSchema={ResetSchema}
                                        onSubmit={(values) => {
                                            mutate({
                                                user_id,
                                                token,
                                                password: values.password
                                            })
                                        }}
                                    >
                                        {(formik) => (
                                            <Flex
                                                direction="column"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        formik.handleSubmit()
                                                    }
                                                }}
                                                as="form"
                                                w="80%"
                                                justify="space-around"
                                                align="center"
                                                gap="3"
                                            >
                                                <InputField placeholder="Contraseña" name="password" type="password" />
                                                <Flex direction={'column'} align="start">
                                                    <Text color="gray" fontSize="xs" fontWeight="hairline">
                                                    Debe tener al menos 8 caracteres
                                                    </Text>
                                                    <Text color="gray" fontSize="xs" fontWeight="hairline">
                                                    No puede ser similar a tu otra información personal
                                                    </Text>
                                                    <Text color="gray" fontSize="xs" fontWeight="hairline">
                                                    No puede ser enteramente numérica
                                                    </Text>
                                                </Flex>
                                                <InputField
                                                    placeholder="Repite Contraseña"
                                                    name="password2"
                                                    type="password"
                                                />
                                                {error && console.log(error)}
                                                <Button
                                                    mt="8"
                                                    variant="primary-s"
                                                    size="md"
                                                    onClick={formik.handleSubmit}
                                                    isLoading={isLoading}
                                                    isDisabled={
                                                        (JSON.stringify(formik.errors) !== '{}') |
                                                    (JSON.stringify(formik.touched) === '{}')
                                                    }
                                                >
                                                Cambiar
                                                </Button>
                                            </Flex>
                                        )}
                                    </Formik>
                                </>
                            )}
                    </Flex>

                    {/*
                        <Flex bg='white' w='100%' rounded='xl' direction='column' align='center'  gap='6' py='12%' boxShadow='lg'>
                            <Heading size='md'> Ya existe una sesión iniciada </Heading>
                            <Button mt='8' variant='primary-out-s' size='md'>
                                    Cerrar Sesión </Button>
                        </Flex>
                        */}

                    <Flex w="100%" justify="space-between">
                        <Text fontSize="xs" cursor="pointer" onClick={() => navigate('/register')}>
                            Registrarse
                        </Text>
                        <Text fontSize="xs" cursor="pointer">
                            He olvidado mi contraseña
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
