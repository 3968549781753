import { Badge } from '@chakra-ui/react'

export default function RecurrenceTagDisplay ({ recurrence, ...props }) {
    const color = recurrence === 'recurring' ? 'purple' : 'blue.500'
    const label = recurrence === 'recurring' ? 'Recurrente' : 'Ünico'

    return (
        <Badge bg={color} {...props} px='1em' rounded='lg' color='white'>
            {label}
        </Badge>
    )
}
