interface CalculateTotalAndTaxResult {
	total: number;
	taxes: number;
}

export const calculateTotalAndTax = (
	priceIncludesTax: boolean,
	selectedTax: "ten" | "twenty" | "no" | "exent",
	price: number,
	quantity = 1
): CalculateTotalAndTaxResult => {
	const taxMultiplier = selectedTax === "ten" ? 0.1 : selectedTax === "twenty" ? 0.21 : 0;
	const subtotal = price * quantity;
	let taxes: number;
	let total: number;

	if (priceIncludesTax) {
		taxes = subtotal * (taxMultiplier / (1 + taxMultiplier));
		total = subtotal;
	} else {
		taxes = subtotal * taxMultiplier;
		total = subtotal + taxes;
	}

	return { total, taxes };
};
