import { PieChart, Pie, Label, Cell, ResponsiveContainer } from "recharts";
import { Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work

export default function ServicesPieChart({ data }) {
	moment.locale("es");
	const COLORS = ["#42C74F99", "#0258FF99", "#F2B174"];
	const isEmpty = data?.pie?.length < 1;
	const renderLabel = () => (
		<text x={200} y={200} textAnchor='middle' dominantBaseline='middle'>
			<tspan x={"50%"} y={"40%"} fontSize='24' fontWeight='bold'>
				{data?.total}
			</tspan>
			<tspan x={"50%"} y={"60%"} fontSize='14'>
				Servicios
			</tspan>
		</text>
	);

	if (data) {
		return (
			<Flex direction='column' align='center' w='100%' h='100%' bg='white' py='1em' rounded='xl'>
				<Text fontSize='20px' fontWeight='bold' alignSelf='start' mb='1em' px='2em'>
					Resumen servicios
				</Text>
				{isEmpty ? (
					<Flex h='100%' align='center'>
						<Text align='center'>No has registrado órdenes con servicios para este periodo</Text>
					</Flex>
				) : (
					<ResponsiveContainer width='100%' height='50%'>
						<PieChart>
							<Pie
								data={data?.pie}
								dataKey='total'
								label
								cx='50%'
								cy='50%'
								innerRadius={"65%"}
								outerRadius={"80%"}
								fill='#8884d8'
								paddingAngle={4}
							>
								{data?.pie.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={entry.color || COLORS[index % COLORS.length]} />
								))}
								<Label content={renderLabel} />
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				)}
				<Flex
					direction='column'
					gap='0.3em'
					alignSelf='start'
					mt='2em'
					px='2em'
					w='100%'
					maxH='40%'
					overflowY='scroll'
					sx={{
						marginLeft: "5px",
						"&::-webkit-scrollbar": { width: "5px", height: "8px" },
						"&::-webkit-scrollbar-track": {
							backgroundColor: "#f5f5f5"
						},
						"&::-webkit-scrollbar-thumb": {
							backgroundColor: "#cfcfcf"
						}
					}}
				>
					{data?.pie.map((entry, index) => (
						<Flex key={index} align='center' justify='space-between' w='100%'>
							<Flex align='center' gap='0.5em' w='90%'>
								<Flex bg={entry.color || "grey"} w='15px' h='15px' rounded='full' />
								<Text
									fontSize='16px'
									pr='2rem'
									overflow='hidden'
									whiteSpace='nowrap'
									textOverflow='ellipsis'
									maxW='100%'
								>
									{entry.label || "Sin servicio"}
								</Text>
							</Flex>
							<Text fontWeight='bold' justifySelf='end'>
								{entry.total}
							</Text>
						</Flex>
					))}
				</Flex>
			</Flex>
		);
	}
}
