import { Flex, Text, Button, Image, useMediaQuery } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
// icons
import { FaWhatsapp } from "react-icons/fa";
// img
import whatsappPreview from "../../../img/communication/whatsappPreview.png";
// comps
import AnothertoolColored from "../../common/AnothertoolColored";
import CheckoutButton from "../../payments/CheckoutButton";

export default function NoPackageView() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const navigate = useNavigate();
	if (!isMobile) {
		return (
			<Flex bg='white' align='center' rounded='xl' justify='center' px='4em' py='2em' gap='2em' mt='2em'>
				<Flex direction='column' maxW='400px'>
					<FaWhatsapp color='#42C74F' size='32px' />
					<AnothertoolColored fontSize='30px' fontWeight='bold' />
					<Text fontWeight='bold' fontSize='22px'>
						WhatsApp
					</Text>
					<Text fontWeight='bold' my='1.5em'>
						¡Se acabó recordar las citas a tus clientes de forma manual!
					</Text>
					<Text>
						Agiliza la comunicación, gracias a la automatización del envío de mensajes a tu base de datos a
						través de la integración de WhatsApp en nuestro software.
						<br /> <br />
						Envía recordatorios automáticos con la opción de confirmar y cancelar para reagendar tus citas y
						organizarte la jornada con tiempo.
					</Text>
					<Flex
						boxShadow={"0px 0px 20px 2px rgba(0, 0, 0, 0.10)"}
						direction='column'
						gap='0.5em'
						p='2em'
						rounded='xl'
						mt='1.5em'
					>
						<Text fontWeight='bold'>Paquete Recordatorios WhatsApp</Text>
						<Text>Actualiza a plan PRO</Text>
						<Button w='150px' mt='1em' variant='primary' onClick={() => navigate("/planes")}>
							Lo quiero
						</Button>
						{/*
                        <CheckoutButton
                            subscription='whatsapp'
                            label='Lo quiero'
                            w='150px'
                            mt='1em'
                        />
                        */}
					</Flex>
				</Flex>
				<Image minW='200px' objectFit='contain' src={whatsappPreview} />
			</Flex>
		);
	}
	return (
		<Flex direction='column' minH='100dvh' w='100%' px='1em' pt='1em' align='center'>
			<Flex align='center' gap='0.5em'>
				<FaWhatsapp color='#42C74F' size='32px' />
				<AnothertoolColored fontSize='30px' fontWeight='bold' />
			</Flex>
			<Text fontWeight='bold' fontSize='22px' mb='1em'>
				WhatsApp
			</Text>
			<Flex>
				<Text fontWeight='bold' my='1.5em' align='center'>
					¡Se acabó recordar las citas a tus clientes de forma manual!
				</Text>
				<Image w='170px' src={whatsappPreview} />
			</Flex>
			<Flex
				boxShadow={"0px 0px 20px 2px rgba(0, 0, 0, 0.10)"}
				direction='column'
				gap='0.5em'
				p='2em'
				rounded='xl'
				mt='1.5em'
			>
				<Text fontWeight='bold'>Paquete Beta Tester</Text>
				<Text>60 conversaciones con templates de mensajes a elegir</Text>
				<Flex align='center' gap='0.2em'>
					<Text fontWeight='bold' color='grey' fontSize='18px' textDecor={"line-through"}>
						15€
					</Text>
					<Text fontWeight='bold' color='blue.500' fontSize='20px'>
						{" "}
						12€
					</Text>
				</Flex>
				<CheckoutButton subscription='whatsapp' label='Lo quiero' w='150px' mt='1em' />
			</Flex>

			<Text align='center' mt='1em'>
				Agiliza la comunicación, gracias a la automatización del envío de mensajes a tu base de datos a través
				de la integración de WhatsApp en nuestro software.
				<br /> <br />
				Envía recordatorios automáticos con la opción de confirmar y cancelar para reagendar tus citas y
				organizarte la jornada con tiempo.
			</Text>
		</Flex>
	);
}
