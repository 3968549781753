import { useMemo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Flex, Text } from "@chakra-ui/react";
import Select from "react-select";
import { useField } from "formik";
//types
import type React from "react";
import type { ActionMeta, SingleValue } from "react-select";
import type { Car } from "../../../../types/car";
//icons
import { LuGhost } from "react-icons/lu";
//api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getAllCars } from "../../../../api/carsApi";

type CarSelectFilterFieldProps = {
	label: string | React.ReactNode;
	icon?: React.ReactNode;
	required?: boolean;
	isloading?: boolean;
	placeholder?: string;
	error?: string;
	name: string;
};

interface FormattedOption {
	label: React.ReactNode;
	value: number;
	brandModel: string;
	plate?: string;
	clientName?: string;
}

export default function CarSelectFilterField({
	label,
	icon,
	required,
	isloading,
	placeholder,
	error,
	...props
}: CarSelectFilterFieldProps) {
	const { data: cars, isLoading } = useAuthFetch(["cars"], getAllCars) as {
		data: {
			results: Car[];
		};
		isLoading: boolean;
	};

	const [field, meta, helpers] = useField(props);

	const formatData: FormattedOption[] = useMemo(() => {
		return cars?.results.map((car: Car) => ({
			label: (
				<Flex align='center' gap='0.5rem'>
					<Text>
						{car?.brand_display} {car?.model} ({car?.plate || car?.client?.name})
					</Text>
					{car?.client?.is_ghost && <LuGhost size='18px' color='gray' />}
				</Flex>
			),
			value: car?.id,
			brandModel: `${car?.brand} ${car?.model}`,
			clientName: car?.client?.name,
			plate: car?.plate
		}));
	}, [cars]);

	const customFilter = (
		option: { data: FormattedOption; label: string; value: string | number },
		inputValue: string
	): boolean => {
		const searchQuery = inputValue.toLowerCase();
		if (!searchQuery) {
			return true; // Mostrar todas las opciones si no hay un filtro aplicado
		}
		const { plate, brandModel, clientName } = option.data;

		const plateMatch = plate ? plate.toLowerCase().includes(searchQuery) : false;
		const brandMatch = brandModel ? brandModel.toLowerCase().includes(searchQuery) : false;
		const clientNameMatch = clientName ? clientName.toLowerCase().includes(searchQuery) : false;

		return plateMatch || brandMatch || clientNameMatch;
	};

	const handleChange = (option: SingleValue<FormattedOption>, action: ActionMeta<FormattedOption>) => {
		if (action.action === "clear" || !option) {
			helpers.setValue("");
		} else {
			helpers.setValue(option.value);
		}
	};

	return (
		<FormControl isInvalid={(meta.error && meta.touched) || !!error} isRequired={required} w='100%'>
			<Flex gap='0.5em' align='start'>
				{icon}
				<FormLabel color='black'>{label}</FormLabel>
			</Flex>
			<Select
				isLoading={isLoading}
				placeholder={placeholder || "Escribe para buscar"}
				noOptionsMessage={() => `No hay opciones de ${label}`}
				options={formatData}
				name={field.name}
				onBlur={field.onBlur}
				value={formatData?.find((option) => option.value === field.value) || null}
				onChange={handleChange}
				filterOption={customFilter}
				isClearable={true}
			/>
			{error && <FormErrorMessage>{error} </FormErrorMessage>}
			{meta.touched && meta.error && <FormErrorMessage>{meta.error} </FormErrorMessage>}
		</FormControl>
	);
}
