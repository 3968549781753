import { createApi, getAll, getAllPag, getOptions, createUpdate, deleteItem } from "./baseApi";

const servicesApi = createApi("services");

export const getAllServices = async (payload) => {
	return getAll(servicesApi, payload);
};
export const getAllServicesPag = async (payload) => {
	return getAllPag(servicesApi, payload);
};
export const createUpdateService = async (payload) => {
	return createUpdate(servicesApi, payload);
};
export const deleteService = async (payload) => {
	return deleteItem(servicesApi, payload);
};
export const getServiceOptions = async (payload) => {
	return getOptions(servicesApi, payload);
};

//Price and durations
const pricesApi = createApi("prices");

export const getAllPrices = async (payload) => {
	return getAll(pricesApi, payload);
};
