import { Flex, Button } from "@chakra-ui/react";

export default function ReservationButton({ step, setStep, formik, isloading, hour, error }) {
	const handleClick = () => {
		if (step === 0) {
			setStep(1);
		} else if (step === 1) {
			formik.handleSubmit();
		}
	};
	const disableSubmit = () => {
		if (step === 0 && !hour) return true;
		if (step === 1 && !formik.dirty) return true;
		else return false;
	};

	return (
		<Flex w='100%' justify='space-between' px='1em' py='1em' gap='1em'>
			<Button onClick={() => setStep(0)} isDisabled={step === 0}>
				Atrás
			</Button>

			<Button
				w={step === 1 && "250px"}
				onClick={handleClick}
				isDisabled={disableSubmit()}
				variant='primary'
				isLoading={isloading}
			>
				{step === 0 ? "Siguiente" : "Reservar"}
			</Button>
		</Flex>
	);
}
