import { Flex, Text } from "@chakra-ui/react";
import type { BusinessHour } from "../../../../../types/business";

export const formatSchedule = (businessHour: BusinessHour) => {
	if (businessHour.morning_closing_time || businessHour.afternoon_opening_time) {
		return (
			<Flex direction='column' gap='0.2rem'>
				<Text w='100px'>
					{businessHour.morning_opening_time?.replace(/:\d{2}$/, "")} -{" "}
					{businessHour.morning_closing_time?.replace(/:\d{2}$/, "")}
				</Text>
				<Text w='100px'>
					{businessHour.afternoon_opening_time?.replace(/:\d{2}$/, "")} -{" "}
					{businessHour.afternoon_closing_time?.replace(/:\d{2}$/, "")}
				</Text>
			</Flex>
		);
	}
	return (
		<Text w='100px'>
			{businessHour.morning_opening_time?.replace(/:\d{2}$/, "")} -{" "}
			{businessHour.afternoon_closing_time?.replace(/:\d{2}$/, "")}
		</Text>
	);
};
