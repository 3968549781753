import { Flex, Switch, Text } from "@chakra-ui/react";
import moment from "moment";
// forms
import InputField from "../../../common/forms/InputField";
// icons
import { LuGhost } from "react-icons/lu";

export default function OrderDates({ formik, isMobile, order, error, absences }) {
	return (
		<Flex direction='column' gap='1.5em'>
			<Flex direction={isMobile && "column"} gap='20px'>
				<InputField
					label='Fecha entrada'
					name='date_in'
					type='datetime-local'
					maxW='220px'
					error={error?.response?.data?.date_in}
				/>
				<InputField
					label='Fecha salida'
					name='date_out'
					type='datetime-local'
					maxW='220px'
					error={error?.response?.data?.date_out}
				/>
			</Flex>
			{order && (
				<Flex gap='0.5em' align='center'>
					<LuGhost size='20px' color='gray' />
					<Text color='gray'>Ausencia cliente</Text>
					<Switch
						colorScheme='brand'
						isChecked={formik?.values?.absence}
						onChange={() => formik.setFieldValue("absence", !formik?.values?.absence)}
					/>
				</Flex>
			)}
			{absences && (
				<Flex direction='column' gap='0.5em'>
					{absences?.map((absence) => (
						<Text fontSize='14px' color='gray' key={absence.id}>
							Cliente no se presenta el {moment(absence.date).format("l H:M")}
						</Text>
					))}
				</Flex>
			)}
		</Flex>
	);
}
