import { AreaChart, Area, ResponsiveContainer } from "recharts";

export default function IncomeChart({ data }) {
	return (
		<ResponsiveContainer width='100%' height='100%'>
			<AreaChart data={data}>
				<defs>
					<linearGradient id='greenGradient' x1='0' y1='0' x2='0' y2='1'>
						<stop offset='5%' stopColor='#42C74F33' stopOpacity={0.8} />
						<stop offset='90%' stopColor='#42C74F33' stopOpacity={0.1} />
					</linearGradient>
				</defs>
				<Area
					type='monotone'
					dot={false}
					fillOpacity={1}
					dataKey='income'
					stroke='#42C74F'
					fill='url(#greenGradient)'
					strokeWidth={4}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
}
