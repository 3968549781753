import { BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar } from "recharts";
import { Flex, Text, useMediaQuery } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
//comps
import DetailPopover from "./DetailPopover";

const RED = "#F44E4E";
const GREEN = "#42C74F";
export default function RevenueChart({ data }) {
	moment.locale("es");
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const getData = () => {
		if (data && data?.chart?.length > 0) {
			return data.chart.map((item) => ({
				date: moment(item.date).format("D MMM"),
				Ingresos: item.income,
				Gastos: item.expenses
			}));
		}
	};

	const CustomBar = (props) => {
		const { fill, ...rest } = props;
		return <rect {...rest} rx={5} ry={5} fill={fill} />;
	};
	const parsed = getData();
	if (data && !isMobile) {
		return (
			<Flex direction='column' align='center' w='100%' h='100%' bg='white' py='1em' rounded='xl'>
				<Flex w='100%' justify='space-between' align='center' gap='0.5em' mb='1em' px='2em' flexwrap='wrap'>
					<Text fontSize='20px' fontWeight='bold'>
						Beneficios
					</Text>
					<Flex align='center' gap='1em'>
						<Text color='gray'>Total Bruto:</Text>
						<Text fontSize='26px' color={data?.total > 0 ? GREEN : data?.total < 0 && RED} fontWeight='600'>
							{data?.total}€
						</Text>
						<DetailPopover net={data?.net} taxes={data?.taxes} />
					</Flex>
				</Flex>
				<ResponsiveContainer width='100%' minHeight={200} maxHeight={"100%"}>
					<BarChart data={parsed}>
						<XAxis dataKey='date' />
						<YAxis />
						<Tooltip />
						<Bar dataKey='Ingresos' fill={GREEN} barSize={20} shape={<CustomBar />} />
						<Bar dataKey='Gastos' fill={RED} barSize={20} shape={<CustomBar />} />
					</BarChart>
				</ResponsiveContainer>
			</Flex>
		);
	}
	if (data && isMobile) {
		return (
			<Flex direction='column' align='center' w='100%' h='100%' bg='white' py='1em' rounded='xl'>
				<Flex w='100%' justify='space-between' align='center' gap='0.5em' mb='1em' px='2em' flexwrap='wrap'>
					<Text fontWeight='bold'>Beneficio bruto</Text>
					<Text fontSize='20px' color={data?.total > 0 ? GREEN : data?.total < 0 && RED} fontWeight='600'>
						{data?.total}€
					</Text>
					<DetailPopover net={data?.net} taxes={data?.taxes} />
				</Flex>
				<ResponsiveContainer width='100%' minHeight={200} maxHeight={"100%"}>
					<BarChart data={parsed}>
						<XAxis dataKey='date' />
						<YAxis />
						<Tooltip />
						<Bar dataKey='Ingresos' fill={GREEN} barSize={20} shape={<CustomBar />} />
						<Bar dataKey='Gastos' fill={RED} barSize={20} shape={<CustomBar />} />
					</BarChart>
				</ResponsiveContainer>
			</Flex>
		);
	}
}
