import { Flex, Text, Skeleton, useMediaQuery } from "@chakra-ui/react";
// icons
import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";

type Period = "month" | "year";

interface ComparisonPeriodProps {
	lastPeriod: number;
	period: Period;
	isReverse?: boolean;
	isFinancial?: boolean;
	isLoading?: boolean;
	maxW?: string;
}

export default function ComparisonPeriodDisplay({
	lastPeriod,
	period,
	isFinancial,
	isReverse,
	isLoading,
	maxW
}: ComparisonPeriodProps) {
	const [isLittle] = useMediaQuery("(max-width: 1080px)");
	const RED = "#F44E4E";
	const GREEN = "#42C74F";

	// Tipar periodMappings correctamente
	const periodMappings: Record<Period, string> = {
		month: "el último mes",
		year: "el último año"
	};
	let changeText = "";
	let changeColor = "";
	let changeIcon = <BiTrendingUp />;

	if (lastPeriod > 0) {
		changeText = `+${Math.floor(lastPeriod)} ${isFinancial ? "€" : ""}`;
		changeColor = isReverse ? RED : GREEN;
		changeIcon = <BiTrendingUp color={isReverse ? RED : GREEN} />;
	} else if (lastPeriod < 0) {
		changeText = `${Math.floor(lastPeriod)} ${isFinancial ? "€" : ""}`;
		changeColor = isReverse ? GREEN : RED;
		changeIcon = <BiTrendingDown color={isReverse ? GREEN : RED} />;
	} else {
		changeText = "Igual";
		changeColor = "grey";
	}
	const periodDisplay = periodMappings[period] || "";

	if (!isLoading) {
		return (
			<>
				{!isLittle ? (
					<Flex align='center' gap='0.5rem' justify='end' maxW={maxW} flexWrap='wrap'>
						{changeIcon}
						<Text color={changeColor}>{changeText}</Text>
						<Text fontSize='10px' color='gray'>
							vs {periodDisplay}
						</Text>
					</Flex>
				) : (
					<Flex direction='column'>
						<Flex align='center' gap='0.5em'>
							{changeIcon}
							<Text color={changeColor}>{changeText}</Text>
						</Flex>
						<Text fontSize='10px' color='gray'>
							vs {periodDisplay}
						</Text>
					</Flex>
				)}
			</>
		);
	}
	return (
		<Flex align='center' gap='0.5em'>
			{changeIcon}
			<Skeleton h='20px' w='150px' rounded='xl' />
			<Text fontSize='10px' color='gray'>
				vs {periodDisplay}
			</Text>
		</Flex>
	);
}
