import { useState } from "react";
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";

export const CustomMarker = ({ position, icon, label }) => {
	const [infowindowOpen, setInfowindowOpen] = useState(false);
	const [markerRef, marker] = useAdvancedMarkerRef();

	return (
		<>
			<AdvancedMarker ref={markerRef} onClick={() => setInfowindowOpen(!infowindowOpen)} position={position}>
				{icon && icon}
			</AdvancedMarker>
			{infowindowOpen && (
				<InfoWindow anchor={marker} maxWidth={220} onCloseClick={() => setInfowindowOpen(false)}>
					{label}
				</InfoWindow>
			)}
		</>
	);
};
