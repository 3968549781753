import { useMemo, useState } from "react";
import { useDisclosure, Flex, IconButton, Tr, Td } from "@chakra-ui/react";
import moment from "moment";
// comps
import QuoteModal from "../forms/QuoteModal";
import PopoverDelete from "../../../common/PopoverDelete";
import ResponsiveTable from "../../../common/Tables/ResponsiveTable";
import SearchInput from "../../../common/Tables/SearchInput";
import QuoteStatusBadge from "../QuoteStatusBadge";
// icons
import { AiOutlineEdit, AiOutlineFilePdf } from "react-icons/ai";
// img
import emptyInvoices from "../../../../img/invoicing/emptyInvoices.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getAllQuotesPag, deleteQuote } from "../../../../api/quotesApi";

export default function QuotesTablePag() {
	const [quote, setQuote] = useState();
	const [searchQuery, setSearchQuery] = useState("");
	const { isOpen: isOpenForm, onOpen: onOpenForm, onClose: onCloseForm } = useDisclosure();

	const [page, setPage] = useState(1);
	const { data, isLoading } = useAuthFetch(
		["quotes", page, searchQuery],
		getAllQuotesPag,
		page,
		null,
		true,
		searchQuery
	);

	const handleEditQuote = (quote) => {
		setQuote(quote);
		onOpenForm();
	};
	const handleOpenQuotePDF = (quote) => {
		const url = `/presupuestos/${quote.uuid}`;
		window.open(url, "_blank");
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((quote) => (
				<Tr key={quote.id}>
					<Td>{quote.quote_number}</Td>
					<Td>{quote.client?.name}</Td>
					<Td> {moment(quote.date).format("L")} </Td>
					<Td>
						{quote.taxes} {quote.taxes > 0 && `(${quote.tax_display})`}
					</Td>
					<Td>
						{quote.total} {quote.discount > 0 && `(-${quote.discount}%)`}
					</Td>
					<Td>
						<QuoteStatusBadge status={quote.status} />
					</Td>
					<Td>
						<Flex>
							<IconButton
								icon={<AiOutlineEdit size='20px' color='#0258FF' />}
								bg='transparent'
								onClick={() => handleEditQuote(quote)}
							/>
							<PopoverDelete
								deleteKey={["deleteQuote"]}
								deleteFn={deleteQuote}
								slug={quote?.id}
								refetchKey={["quotes"]}
							/>
							<IconButton
								icon={<AiOutlineFilePdf size='20px' color='black' />}
								bg='transparent'
								onClick={() => handleOpenQuotePDF(quote)}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data, onOpenForm, isOpenForm]
	);
	const isEmpty = data?.count < 1;
	return (
		<>
			<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
			<ResponsiveTable
				paginated
				searchQuery={searchQuery}
				data={data}
				tableData={tableData}
				isEmpty={isEmpty}
				onOpen={() => {
					setQuote();
					onOpenForm();
				}}
				onClose={onCloseForm}
				page={page}
				setPage={setPage}
				isLoading={isLoading}
				item={{
					label: "Presupuesto",
					headers: [
						{ key: "quote_number", label: "Nº Presupuesto", roundedLeft: "lg" },
						{ key: "client", label: "Cliente" },
						{ key: "date", label: "Creado" },
						{ key: "taxes", label: "IVA(€)" },
						{ key: "total", label: "Total(€)" },
						{ key: "status", label: "Estado" }
					],
					formItem: quote,
					isOpen: isOpenForm
				}}
				FormComponent={QuoteModal}
				emptyImage={emptyInvoices}
			/>
		</>
	);
}
