import { useState, useMemo } from 'react'
import { useDisclosure, Flex, IconButton, Tr, Td } from '@chakra-ui/react'
// comps
import ResponsiveTable from '../../../common/Tables/ResponsiveTable'
import PopoverDelete from '../../../common/PopoverDelete'
import StockForm from '../forms/StockForm'
// icons
import { AiOutlineEdit } from 'react-icons/ai'
// img
import emptyStock from '../../../../img/stock/emptyStock.png'
// api
import { getAllStockPag, deleteStock } from '../../../../api/stockApi'
import useAuthFetch from '../../../../myHooks/useAuthFetch'

export default function StockTable () {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [page, setPage] = useState(1)
    const [stock, setStock] = useState()

    const { data, isLoading } = useAuthFetch(['stock', page], getAllStockPag, page)

    const tableData = useMemo(
        () =>
            data?.results?.map((stock) => (
                <Tr key={stock.id}>
                    <Td>{stock.name}</Td>
                    <Td>{stock.description || '-- --'}</Td>
                    <Td>{stock.reference || '-- --'}</Td>
                    <Td>
                        {stock.available_quantity} ({stock.unit_of_measurement})
                    </Td>
                    <Td>
                        {stock.min_quantity} ({stock.unit_of_measurement})
                    </Td>
                    <Td> {stock.supplier?.name || '-- --'} </Td>
                    <Td>
                        <Flex gap="1em">
                            <IconButton
                                icon={<AiOutlineEdit size="20px" color="#0258FF" />}
                                bg="transparent"
                                onClick={() => {
                                    setStock(stock)
                                    onOpen()
                                }}
                            />
                            <PopoverDelete
                                deleteKey={['deleteStock']}
                                deleteFn={deleteStock}
                                slug={stock?.id}
                                refetchKey={['stock']}
                            />
                        </Flex>
                    </Td>
                </Tr>
            )),
        [data, onOpen]
    )
    const isEmpty = data?.count < 1
    return (
        <ResponsiveTable
            paginated
            data={data}
            tableData={tableData}
            isEmpty={isEmpty}
            onOpen={() => {
                setStock()
                onOpen()
            }}
            onClose={onClose}
            page={page}
            setPage={setPage}
            isLoading={isLoading}
            item={{
                label: 'Stock',
                headers: [
                    { key: 'name', label: 'Nombre', roundedLeft: 'lg' },
                    { key: 'desc', label: 'Descripción' },
                    { key: 'ref', label: 'Ref.' },
                    { key: 'available', label: 'Disponible' },
                    { key: 'min', label: 'Min' },
                    { key: 'provider', label: 'Proveedor' }
                ],
                formItem: stock,
                isOpen
            }}
            FormComponent={StockForm}
            emptyImage={emptyStock}
        />
    )
}
