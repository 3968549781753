import { Flex, Box, Text } from "@chakra-ui/react";
// comps
import OrderRequestCard from "../card/OrderRequestCard";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getActiveOrderRequests } from "../../../../api/businessesApi";

export default function OrderRequestCardList() {
	const { data } = useAuthFetch(["activeOrderRequests"], getActiveOrderRequests);
	const isEmpty = data?.count < 1;
	if (data) {
		return (
			<Flex
				direction='column'
				h='100%'
				w='100%'
				gap='1rem'
				pr='0.5em'
				sx={{
					flexGrow: 1,
					overflowY: "auto",
					maxH: "95vh",
					"&::-webkit-scrollbar": { width: "5px" },
					"&::-webkit-scrollbar-track": {
						backgroundColor: "#f5f5f5"
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: "#cfcfcf"
					}
				}}
			>
				<Flex w='100%'>
					<Text fontSize='20px' fontWeight='bold'>
						Solicitudes de trabajo
					</Text>
					<Flex
						bg={isEmpty ? "grey" : "blue.500"}
						rounded='full'
						boxSize='15px'
						justify='center'
						align='center'
					>
						<Text fontSize='8px' color='white'>
							{data?.count}
						</Text>
					</Flex>
				</Flex>
				{!isEmpty ? (
					data.results?.map((request) => <OrderRequestCard key={request.id} request={request} />)
				) : (
					<Flex w='100%' h='100%' bg='white' rounded='lg' justify='center' align='center' gap='2em'>
						<Text maxW='70%' align='center'>
							No tienes ninguna solicitud de trabajo nueva.
						</Text>
					</Flex>
				)}
			</Flex>
		);
	}
	return <Text>No hay solicitudes de trabajo pendientes</Text>;
}
