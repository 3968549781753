import { Tooltip, Flex } from '@chakra-ui/react'
// icons
import { AiOutlineInfoCircle } from 'react-icons/ai'

export default function InfoTooltip ({ label, desc }) {
    return (
        <Tooltip
            shouldWrapChildren={true}
            label={desc}
            aria-label="A tooltip"
            fontsize="14px"
            fontWeight="hairline"
            rounded="lg"
            bg="lightgray"
            color="darkblue"
        >
            <Flex align="center" gap="0.5em">
                {label}
                <AiOutlineInfoCircle />
            </Flex>
        </Tooltip>
    )
}
