import { useState, useEffect } from "react";
import {
	useDisclosure,
	Button,
	Flex,
	Divider,
	useMediaQuery,
	Text,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
	ModalCloseButton
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Lottie from "lottie-react";
import confetti from "../confetti.json";
// forms
import { Formik } from "formik";
import * as Yup from "yup";
import { PHONE_REG_EXP } from "../../../../common/consts";
// comps
import SelectedServicesDisplay from "./SelectedServicesDisplay";
import TimeStep from "./TimeStep";
import ClientDataStep from "./ClientDataStep";
import ReservationButton from "./ReservationButton";
// api
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { getAvailableHours, getAvailableDays, createUpdateOrderRequest } from "../../../../../api/businessesApi";

export default function ReservationModalForm({ services, totalPrice, isDisabled, resetCart }) {
	const [day, setDay] = useState();
	const [hour, setHour] = useState();
	const [month, setMonth] = useState();
	const [year, setYear] = useState();
	const [error, setError] = useState("");
	const [dateIn, setDateIn] = useState();
	const [step, setStep] = useState(0);
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const { workshop } = useParams();

	const serviceIds = services?.map((service) => service.id).join(",");
	const filter = `?workshopId=${workshop}&day=${day}&month=${month}&year=${year}&services=${serviceIds}`;

	const { data: hours, isLoading: lHours } = useAuthFetch(
		["availableHours", day, month, year, workshop, services],
		getAvailableHours,
		null,
		filter,
		Boolean(isOpen && year && month && day)
	);

	const {
		data: days,
		isLoading: lDays,
		error: daysError
	} = useAuthFetch(
		["availableDays", month, year, workshop],
		getAvailableDays,
		null,
		filter,
		Boolean(isOpen && year && month)
	);
	const initialValues = {
		client_name: "",
		client_email: "",
		client_phone: "",
		client_address: "",
		client_nif_cif: "",
		has_signed_consent: false,
		brand: "",
		model: "",
		observations: ""
	};
	const validationSchema = Yup.object({
		brand: Yup.string("Debes seleccionar una opción").required("La marca es obligatoria"),
		model: Yup.string(),
		observations: Yup.string(),
		client_nif_cif: Yup.string().length(9, "No tiene el formato adecuado"),
		client_name: Yup.string().required("El nombre es obligatorio"),
		client_email: Yup.string().email("Formato no válido").required("El email es obligatorio"),
		client_phone: Yup.string()
			.matches(PHONE_REG_EXP, "Teléfono no válido")
			.min(9, "Debe tener al menos 9 dígitos")
			.max(12, "Demasiado largo")
			.required("Es obligatorio un teléfono de contacto")
	});

	useEffect(() => setError(""), [day, hour]);

	useEffect(() => {
		if (!year) {
			setYear(moment().year());
		}
		if (!month) {
			setMonth(moment().month());
		}
	}, []);

	useEffect(
		() =>
			setDateIn(
				moment({
					year,
					month,
					day,
					hour: hour?.split(":")[0],
					minute: hour?.split(":")[1]
				})
			),
		[day, hour]
	);

	const handleClose = () => {
		setHour();
		onClose();
		resetCart();
	};
	const {
		isLoading: lm,
		mutate,
		error: mutateError,
		data: requestResponse
	} = useAuthMutation({
		mutationFn: createUpdateOrderRequest,
		onSuccess: () => {
			toast({ title: "Solicitud enviada con exito!", status: "success" });
		},
		onError: () => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});

	const handleSubmit = (values) => {
		const payload = {
			data: values
		};
		if (!day) {
			setError("Debes elegir un día");
			return;
		}
		if (!hour) {
			setError("Debes seleccionar un horario");
		}
		payload.data.client_email = payload.data.client_email?.toLowerCase();
		const parsedServices = services.map((service) => service.id);
		payload.data.services = parsedServices;
		payload.data.date_in = dateIn.format("YYYY-MM-DDTHH:mm");
		mutate(payload);
	};

	return (
		<>
			<Button variant='primary' isDisabled={isDisabled} onClick={onOpen}>
				Reservar
			</Button>
			<Modal isOpen={isOpen} onClose={handleClose} size={isMobile ? "full" : "5xl"}>
				<ModalOverlay />
				{!requestResponse ? (
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => handleSubmit(values)}
					>
						{(formik) => (
							<ModalContent maxH={!isMobile && "100vh"} overflowY={isMobile && "scroll"}>
								<ModalCloseButton />
								<ModalBody pt='3em' align='center' px='4em'>
									{step === 0 && !isMobile && (
										<Flex w='100%' justify='space-between' gap='2rem'>
											<TimeStep
												lDays={!!lDays && !!isOpen && !!year && !!month}
												lHours={!!lHours && !!isOpen && !!year && !!month && !!day}
												daysError={daysError}
												days={days}
												day={day}
												setDay={setDay}
												month={month}
												setMonth={setMonth}
												hour={hour}
												hours={hours}
												setHour={setHour}
											/>
											<Divider orientation='vertical' h='600px' />
											{services.length && services.length > 0 && (
												<SelectedServicesDisplay
													services={services}
													totalPrice={totalPrice}
													dateIn={dateIn}
												/>
											)}
										</Flex>
									)}
									{step === 0 && isMobile && (
										<Flex direction='column'>
											{services.length && services.length > 0 && (
												<SelectedServicesDisplay services={services} dateIn={dateIn} />
											)}
											<TimeStep
												lDays={!!lDays && !!isOpen && !!year && !!month}
												lHours={!!lHours && !!isOpen && !!year && !!month && !!day}
												daysError={daysError}
												days={days}
												day={day}
												setDay={setDay}
												setMonth={setMonth}
												hour={hour}
												hours={hours}
												setHour={setHour}
											/>
										</Flex>
									)}
									{step === 1 && !isMobile && (
										<Flex w='100%' gap='2rem'>
											<ClientDataStep mutateError={mutateError} formik={formik} />
											<Divider orientation='vertical' h='600px' />
											{services.length && services.length > 0 && (
												<SelectedServicesDisplay
													services={services}
													totalPrice={totalPrice}
													dateIn={dateIn}
												/>
											)}
										</Flex>
									)}
									{step === 1 && isMobile && (
										<ClientDataStep mutateError={mutateError} formik={formik} />
									)}
								</ModalBody>
								<ModalFooter>
									{error && (
										<Text color='red' mr='2em'>
											{error}
										</Text>
									)}
									<ReservationButton
										step={step}
										setStep={setStep}
										formik={formik}
										isloading={lm}
										hour={hour}
										error={error}
									/>
								</ModalFooter>
							</ModalContent>
						)}
					</Formik>
				) : (
					<ModalContent>
						<ModalCloseButton />
						<ModalBody py='2em' align='center' px='4em' minH='400px'>
							<Lottie animationData={confetti} loop={true} style={{ width: "250px" }} />
							<Text fontWeight='bold' fontSize='18px' maxW='600px'>
								¡Genial! Hemos solicitado una cita para tu {requestResponse.brand.replace("_", " ")}{" "}
								{requestResponse.model}
								<br /> el próximo {moment(requestResponse.date_in).format("LL HH:mm")}
							</Text>
							<Text my='1em' maxW='500px'>
								Hemos notificado al centro con los datos de tu reserva. Recibirás un correo en un plazo
								máximo de 24 horas.
							</Text>
							<Text fontWeight='bold'>¡Disfruta de tu detallado!</Text>
							<Button variant='primary' onClick={handleClose} mt='2em'>
								Lo tengo
							</Button>
						</ModalBody>
					</ModalContent>
				)}
			</Modal>
		</>
	);
}
