import { Flex, useMediaQuery } from "@chakra-ui/react";
// comps
import UsersTable from "../../components/admin/users/UsersTable";
import UsersTableMobile from "../../components/admin/users/UsersTableMobile";

export default function UserManagement() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<>
				<Flex bg='white' rounded='xl' m p='1em' mt='20px' justifySelf='center' alignSelf='center'>
					<UsersTable />
				</Flex>
			</>
		);
	}
	return (
		<Flex direction='column' p='2em'>
			<UsersTableMobile />
		</Flex>
	);
}
