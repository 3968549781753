import { Fragment } from "react";
import { Flex, Text, Grid, GridItem } from "@chakra-ui/react";
// fs
import { formatDecimals } from "../../../common/functions";
import { calculateTotalAndTax } from "../fns";
// icons
import { BiWrench } from "react-icons/bi";

export default function UsedStock({ stock_used, tax, priceIncludesTax }) {
	const calculateTotals = (stockItem) => {
		const taxMultiplier = tax === "ten" ? 0.1 : tax === "twenty" ? 0.21 : 0;

		let price = stockItem.price;
		// Ajustar subtotal si incluye impuestos
		if (priceIncludesTax) {
			price /= 1 + taxMultiplier;
		}
		const impositiveBase = price * stockItem.quantity;
		const { total, taxes } = calculateTotalAndTax(false, tax, impositiveBase);
		return { total, taxes, price };
	};

	if (stock_used && stock_used?.length > 0) {
		return (
			<Flex direction='column' mt='0.5em'>
				<Flex align='center' gap='10px'>
					<BiWrench size='20px' color='black' />
					<Text fontSize='20px' fontWeight='bold'>
						Recambios
					</Text>
				</Flex>
				<Grid
					templateColumns='repeat(6, 1fr)'
					gap={4}
					mt='0.5em'
					bg='#F7F7F7'
					px='1em'
					py='2em'
					rounded='xl'
					border='1px solid #D9D9D9'
				>
					<GridItem rowSpan={1} colSpan={2}>
						<Text>Item</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text>Cantidad</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text>Precio</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text>IVA</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text>Total</Text>
					</GridItem>
					{stock_used.map((stockItem, index) => {
						const { total, taxes, price } = calculateTotals(stockItem);
						return (
							<Fragment key={index}>
								<GridItem rowSpan={1} colSpan={2}>
									<Text>{stockItem.name}</Text>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									<Text>{stockItem.quantity}</Text>
								</GridItem>
								<Text fontSize='15px'>
									{formatDecimals(stockItem.price)}€/{stockItem.unit}
								</Text>
								<GridItem rowSpan={1} colSpan={1}>
									<Text>{formatDecimals(taxes)}</Text>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									<Text>{formatDecimals(total)}</Text>
								</GridItem>
							</Fragment>
						);
					})}
				</Grid>
			</Flex>
		);
	}
	return (
		<Flex direction='column' mt='1em'>
			<Flex align='center' gap='10px'>
				<BiWrench size='20px' color='black' />
				<Text fontSize='20px' fontWeight='bold'>
					Recambios
				</Text>
			</Flex>
			<Flex w='100%' mt='0.5em' bg='#F7F7F7' p='1em' rounded='xl' border='1px solid #D9D9D9'>
				<Text mt='15px'>
					No se ha utilizado recambio en ésta orden de trabajo, añádelo desde la propia orden
				</Text>
			</Flex>
		</Flex>
	);
}
