import { Fragment } from "react";
import { Flex, Text, Grid, GridItem } from "@chakra-ui/react";
// fs
import { formatDecimals } from "../../../common/functions";
import { calculateTotalAndTax } from "../fns";
// icons
import { BiWrench } from "react-icons/bi";

export default function MultiUsedStock({ stock_used, tax, priceIncludesTax }) {
	const calculateTotals = (stockItem) => {
		const taxMultiplier = tax === "ten" ? 0.1 : tax === "twenty" ? 0.21 : 0;

		let price = stockItem.price;
		// Ajustar subtotal si incluye impuestos
		if (priceIncludesTax) {
			price /= 1 + taxMultiplier;
		}
		const impositiveBase = price * stockItem.quantity;
		const { total, taxes } = calculateTotalAndTax(false, tax, impositiveBase);
		return { total, taxes, price };
	};

	return (
		<>
			<Flex align='center' gap='10px' mt='1em'>
				<BiWrench size='20px' color='black' />
				<Text fontSize='20px' fontWeight='bold'>
					Recambios
				</Text>
			</Flex>
			<hr />
			{stock_used?.length > 0 ? (
				<Grid templateColumns='repeat(6, 1fr)' gap={4} mt='0.5em' rounded='xl'>
					<GridItem rowSpan={1} colSpan={2}>
						<Text fontWeight='semibold'>Item</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text fontWeight='semibold'>Cantidad</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text fontWeight='semibold'>Precio</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text>IVA</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text fontWeight='semibold'>Total</Text>
					</GridItem>

					{stock_used?.map((stock) => {
						const { total, taxes, price } = calculateTotals(stock);

						return (
							<Fragment key={stock.id}>
								<GridItem rowSpan={1} colSpan={2}>
									<Text fontSize='15px'> {stock.name} </Text>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									<Text fontSize='15px'>
										{formatDecimals(stock.quantity)}
										{stock.unit}
									</Text>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									<Text fontSize='15px'>
										{formatDecimals(stock.price)}€/{stock.unit}
									</Text>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									<Text>{formatDecimals(taxes)}</Text>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									<Text>{formatDecimals(total)}</Text>
								</GridItem>
							</Fragment>
						);
					})}
				</Grid>
			) : (
				<Text mt='1em'>
					No se han encontrado recambios para esta orden. <br />
					<span style={{ fontWeight: "bold" }}>Añade un recambio</span> editando la orden desde la tabla de
					datos.
				</Text>
			)}
		</>
	);
}
