import { useState, useEffect } from "react";
import { Text, Button, GridItem, useMediaQuery, Flex, IconButton } from "@chakra-ui/react";
// fs
import { formatDecimals } from "../../../common/functions";
import { calculateTotalAndTax } from "../fns";
// forms
import InputField from "../../../common/forms/InputField";
// icons
import { AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai";

export default function InvoiceItemRow({ formik, index, arrayHelpers }) {
	const [display, setDisplay] = useState(true);
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const priceIncludesTax = formik.values.price_includes_tax;
	const selectedTax = formik.values.tax;

	useEffect(() => {
		const { price, quantity } = formik.values.items[index];

		const { total, taxes } = calculateTotalAndTax(priceIncludesTax, selectedTax, price, quantity);
		formik.setFieldValue(`items.${index}.total`, formatDecimals(total));
		formik.setFieldValue(`items.${index}.taxes`, formatDecimals(taxes));
	}, [priceIncludesTax, formik.values.items, index, selectedTax]);

	const handleDisplay = () => {
		setDisplay((prevDisplay) => !prevDisplay);
	};

	if (!isMobile) {
		return (
			<>
				<GridItem rowSpan={1} colSpan={6}>
					<InputField name={`items.${index}.concept`} type='text' placeholder='Servicio' bg='white' />
				</GridItem>
				<GridItem rowSpan={1} colSpan={2}>
					<InputField name={`items.${index}.quantity`} type='number' bg='white' />
				</GridItem>
				<GridItem rowSpan={1} colSpan={2}>
					<InputField name={`items.${index}.price`} type='number' placeholder='x€' bg='white' />
				</GridItem>
				<GridItem rowSpan={1} colSpan={2}>
					<Text mt='15px' fontSize='16px' fontWeight='bold' align='end'>
						{formik.values.items[index].taxes}€
					</Text>
				</GridItem>
				<GridItem rowSpan={1} colSpan={2}>
					<Text mt='15px' fontSize='16px' fontWeight='bold' align='end'>
						{formik.values.items[index].total}€
					</Text>
				</GridItem>
				<GridItem rowSpan={1} colSpan={1}>
					<Button
						mt='10px'
						variant='danger'
						size='xs'
						onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
					>
						-
					</Button>
				</GridItem>
			</>
		);
	}
	return (
		<Flex bg='#F7F7F7' direction='column' px='1em' py='2em' rounded='xl' border='1px solid #D9D9D9' gap='1em'>
			<Flex gap='1em'>
				<InputField
					name={`items.${index}.concept`}
					type='text'
					placeholder='Servicio'
					bg='white'
					label='Item'
				/>
				<IconButton
					bg='transparent'
					icon={display ? <AiOutlineUpCircle size='25px' /> : <AiOutlineDownCircle size='25px' />}
					onClick={handleDisplay}
				/>
			</Flex>
			{display && (
				<>
					<Flex align='center' w='100%' justify='space-between' gap='1em'>
						<InputField
							name={`items.${index}.price`}
							type='number'
							placeholder='x€'
							bg='white'
							label='Precio'
						/>
					</Flex>
					<Flex align='center' w='100%' justify='space-between' gap='2em'>
						<InputField name={`items.${index}.quantity`} type='number' bg='white' label='Qty' maxW='60%' />
						<Text mt='15px' fontSize='14px' fontWeight='bold' align='end'>
							{formik.values.items[index].total}€
						</Text>
						<Button
							mt='10px'
							variant='danger'
							size='xs'
							onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
						>
							-
						</Button>
					</Flex>
				</>
			)}
		</Flex>
	);
}
