import { Fragment, useMemo } from "react";
import {
	Flex,
	Image,
	IconButton,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useMediaQuery,
	useDisclosure
} from "@chakra-ui/react";
// comps
import NavItem from "./NavItem";
// img
import logo from "../../img/brand/at_white.png";
// icons
import { AiOutlineDashboard } from "react-icons/ai";
import {
	MdOutlineAdminPanelSettings,
	MdOutlineInventory2,
	MdOutlinePendingActions,
	MdOutlineNotificationsActive
} from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import { LuFile } from "react-icons/lu";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaChartLine } from "react-icons/fa6";
import { PiUserListBold } from "react-icons/pi";
import { BsShop, BsStripe } from "react-icons/bs";
// auth
import { useAuthUser } from "react-auth-kit";
//types
import type { UserPlan } from "../../types/plan";

interface NavbarProps {
	userplan: UserPlan;
}

export default function Navbar({ userplan }: NavbarProps) {
	const auth = useAuthUser();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const isStaff = auth()?.is_staff;

	const planExpired = userplan?.has_expired && !isStaff && userplan?.plan?.name !== "BETA";
	const disabledStyle = useMemo(
		() => ({
			cursor: planExpired ? "not-allowed" : "auto",
			filter: planExpired ? "grayscale(70%)" : "none"
		}),
		[planExpired]
	);
	const liteMenuItems = [
		{ icon: AiOutlineDashboard, title: "Panel", slash: "/dashboard" },
		{ icon: PiUserListBold, title: "Datos", slash: "/datos" },
		{ icon: BsShop, title: "Negocio", slash: "/negocio" }
	];
	const liteWhasAppMenuItems = [
		{ icon: AiOutlineDashboard, title: "Panel", slash: "/dashboard" },
		{ icon: PiUserListBold, title: "Datos", slash: "/datos" },
		{ icon: BsShop, title: "Negocio", slash: "/negocio" },
		{ icon: MdOutlineNotificationsActive, title: "Msgs", slash: "/comunicacion" }
	];
	const allMenuItems = [
		{ icon: AiOutlineDashboard, title: "Panel", slash: "/dashboard" },
		{ icon: LuFile, title: "Ordenes", slash: "/ordenes" },
		{ icon: PiUserListBold, title: "Datos", slash: "/datos" },
		{ icon: TbFileInvoice, title: "Facturas", slash: "/facturas" },
		{ icon: MdOutlineInventory2, title: "Stock", slash: "/stock" },
		{ icon: BsShop, title: "Negocio", slash: "/negocio" },
		{ icon: MdOutlineNotificationsActive, title: "Msgs", slash: "/comunicacion" },
		{ icon: FaChartLine, title: "Métricas", slash: "/estadisticas" }
	];
	const adminMenuItems = [
		{ icon: MdOutlinePendingActions, title: "Actions", slash: "/admin/user-actions" },
		{ icon: MdOutlineAdminPanelSettings, title: "Users", slash: "/admin/users-management" },
		{ icon: BsStripe, title: "Stripe", slash: "/admin/stripe" },
		{ icon: MdOutlineAdminPanelSettings, title: "Mapa", slash: "/admin/businesses-map" }
	];
	const getMenuItems = () => {
		if (isStaff) return adminMenuItems;
		if (userplan?.plan?.name === "LITE") return liteMenuItems;
		if (userplan?.plan?.name === "LITE_WHATSAPP") return liteWhasAppMenuItems;
		return allMenuItems;
	};
	if (!isMobile) {
		return (
			<Flex
				w='100px'
				direction='column'
				bg='darkblue'
				minH='100vh'
				h='100%'
				{...disabledStyle}
				position='sticky'
				top='0'
			>
				<Image src={logo} boxSize='30px' alignSelf='center' my='2em' />
				<Flex direction='column' gap='1rem'>
					{getMenuItems()?.map((item) => (
						<Fragment key={item.title}>
							<NavItem icon={item.icon} title={item.title} slash={item.slash} />
						</Fragment>
					))}
				</Flex>
			</Flex>
		);
	}
	return (
		<>
			<IconButton
				aria-label='menu_button'
				bg='transparent'
				color='darkblue'
				icon={<RxHamburgerMenu size='25px' />}
				onClick={onOpen}
			/>
			<Drawer variant='mobile' isOpen={isOpen} placement='left' onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent bg='darkblue'>
					<DrawerCloseButton />
					<Flex direction='column' gap='1rem' p='0'>
						{getMenuItems()?.map((item) => (
							<Fragment key={item.title}>
								<NavItem icon={item.icon} title={item.title} slash={item.slash} />
							</Fragment>
						))}
					</Flex>
				</DrawerContent>
			</Drawer>
		</>
	);
}
