import { Flex, Text } from '@chakra-ui/react'
// icons
import { CiCamera } from 'react-icons/ci'
// comps
import DetailForm from '../forms/DetailForm'

export default function Detail ({
    savedInspection,
    photo,
    view,
    circleCoords,
    setCircleCoords,
    selectedDetail,
    setSelectedDetail,
    zone,
    order,
    isBefore
}) {
    if (circleCoords.x === 0 && circleCoords.y === 0) {
        return (
            <Flex
                direction="column"
                bg="white"
                rounded="xl"
                h="100%"
                w="100%"
                p="1em"
                align="center"
                pt="4em"
                gap="2em"
            >
                <CiCamera color="#0258FF" size="75px" />
                <Text align="center" maxW="300px">
                    Una vez hayas hecho una foto a la vista del coche seleccionada. <br />
                    Haz clic en cualquier parte del coche para añadir fotos y comentarios.
                </Text>
            </Flex>
        )
    } else {
        return (
            <DetailForm
                selectedDetail={selectedDetail}
                savedInspection={savedInspection}
                order={order}
                zone={zone}
                isBefore={isBefore}
                circleCoords={circleCoords}
                setCircleCoords={setCircleCoords}
                setSelectedDetail={setSelectedDetail}
            />
        )
    }
}
