import { Button, Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { useNavigate, useLocation } from 'react-router-dom'
// Components
import NavLoginRegister from '../../components/navbar/NavLoginRegister'
// validation
import * as Yup from 'yup'
import { Formik } from 'formik'
import InputField from '../../components/common/forms/InputField'
// api
import { getResetLink } from '../../api/authApi'
import { useMutation } from '@tanstack/react-query'

export default function ResetPassword () {
    const navigate = useNavigate()
    const toast = useToast()

    const pathname = useLocation().pathname

    const { isLoading, mutate, error } = useMutation(['getResetLink'], getResetLink, {
        onSuccess: () => {
            toast({ title: 'Mail enviado!', status: 'success' })
        },
        onError: (error) => {
            toast({
                title: error.message,
                description: error.response?.data.message,
                status: 'error'
            })
        }
    })

    // vaaalidation for login
    const ResetSchema = Yup.object({
        email: Yup.string().email('Formato de email inválido').required('Campo obligatorio')
    })
    return (
        <Flex w="100%" height="100vh" direction="column" bg="lightgrey">
            <NavLoginRegister path={pathname} />
            <Flex justify="center" align="center" w="100%">
                <Flex py="5%" w={['80%', '75%', '400px', '400px']} direction="column" align="center" gap="5">
                    <Flex>
                        <Heading size="lg">another</Heading>
                        <Heading size="lg" color={'blue'}>
                            tool
                        </Heading>
                    </Flex>
                    <Flex
                        bg="white"
                        w="100%"
                        rounded="xl"
                        direction="column"
                        align="center"
                        gap="6"
                        py="12%"
                        boxShadow="lg"
                    >
                        <Heading size="md"> ¿Has olvidado tu contraseña? </Heading>
                        <Text align="center">
                            No te preocupes, introduce tu email y te enviaremos un email para que la restablezcas
                        </Text>
                        <Formik
                            initialValues={{
                                email: ''
                            }}
                            validationSchema={ResetSchema}
                            onSubmit={(values) => {
                                mutate(values)
                            }}
                        >
                            {(formik) => (
                                <Flex
                                    direction="column"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            formik.handleSubmit()
                                        }
                                    }}
                                    as="form"
                                    w="80%"
                                    justify="space-around"
                                    align="center"
                                    gap="3"
                                >
                                    <InputField name="email" placeholder="Email" />
                                    {error && <Text color="red">{error.response.data}</Text>}
                                    <Button
                                        mt="8"
                                        variant="primary-s"
                                        size="md"
                                        onClick={formik.handleSubmit}
                                        isLoading={isLoading}
                                        isDisabled={
                                            (JSON.stringify(formik.errors) !== '{}') |
                                            (JSON.stringify(formik.touched) === '{}')
                                        }
                                    >
                                        Enviar mail{' '}
                                    </Button>
                                </Flex>
                            )}
                        </Formik>
                    </Flex>

                    {/*
                        <Flex bg='white' w='100%' rounded='xl' direction='column' align='center'  gap='6' py='12%' boxShadow='lg'>
                            <Heading size='md'> Ya existe una sesión iniciada </Heading>
                            <Button mt='8' variant='primary-out-s' size='md'>
                                    Cerrar Sesión </Button>
                        </Flex>
                        */}

                    <Flex w="100%" justify="space-between">
                        <Text fontSize="xs" cursor="pointer" onClick={() => navigate('/register')}>
                            Registrarse
                        </Text>
                        <Text fontSize="xs" cursor="pointer">
                            He olvidado mi contraseña
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
