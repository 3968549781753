import { Flex, useMediaQuery } from "@chakra-ui/react"
//comps
import TopBar from "../components/pages/businesses/businessProfile/TopBar";
import Footer from "../components/pages/businesses/businessProfile/Footer";

export default function HubLayout({children}){
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const contentWidth = {base: "100%", md: "700px", lg: "800px", xl: "1080px", "2xl": "1280px"  }
    
    return(
        <Flex w='100%' minH='100dvh'  direction='column' bg='lightgrey' align='center' >
            <TopBar contentWidth={contentWidth} />
            {children}
            <Footer  contentWidth={contentWidth}/>
        </Flex>
    )
}