import { useState } from "react";
import { Flex, Text, Select, useMediaQuery } from "@chakra-ui/react";
import moment from "moment";
// comps
import FinancialStats from "../../components/pages/analytics/FinancialStats";
import OrderStats from "../../components/pages/analytics/OrderStats";
import PeriodSelector from "../../components/pages/analytics/PeriodSelector";
import WorkerStats from "../../components/pages/analytics/WorkerStats";
import LoadingOverlay from "../../components/common/loading/LoadingOverlay";
// api
import useAuthQuery from "../../myHooks/useAuthQuery";
import { getAllStats } from "../../api/statsApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function Analytics() {
	const [month, setMonth] = useState(moment().month() + 1);
	const [week, setWeek] = useState(moment().week());
	const [year, setYear] = useState(moment().year());
	const [period, setPeriod] = useState("month");
	const [stat, setStat] = useState("orders");
	const authHeader = useAuthHeader();
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const { data, isLoading } = useAuthQuery({
		queryKey: ["allStats", stat, period, year, week, month],
		queryFn: () =>
			getAllStats({
				token: authHeader(),
				slug: stat,
				year,
				week,
				month,
				period
			})
	});
	return (
		<>
			{!isMobile ? (
				<Flex w='100%' justify='space-between' h='6vh' px='4em' pt='1em'>
					<Flex gap='1em' align='center'>
						<Text fontSize='24px' fontWeight='semibold'>
							Estadísticas
						</Text>
						<Select value={stat} bg='white' color='#0258FF' onChange={(e) => setStat(e.target.value)}>
							<option value='orders'>Órdenes</option>
							<option value='financial'>Finanzas</option>
							<option value='workers'>Trabajadores</option>
						</Select>
					</Flex>
					<PeriodSelector
						period={period}
						setPeriod={setPeriod}
						month={month}
						setMonth={setMonth}
						week={week}
						setWeek={setWeek}
						year={year}
						setYear={setYear}
					/>
				</Flex>
			) : (
				<Flex direction='column' px='2em' pt='1em' gap='1em'>
					<Flex gap='1em' align='center'>
						<Text fontSize='24px' fontWeight='semibold'>
							Estadísticas
						</Text>
						<Select value={stat} bg='white' color='#0258FF' onChange={(e) => setStat(e.target.value)}>
							<option value='orders'>Órdenes</option>
							<option value='financial'>Finanzas</option>
						</Select>
					</Flex>
					<PeriodSelector
						period={period}
						setPeriod={setPeriod}
						month={month}
						setMonth={setMonth}
						week={week}
						setWeek={setWeek}
						year={year}
						setYear={setYear}
					/>
				</Flex>
			)}
			{stat === "orders" && <OrderStats data={data} period={period} />}
			{stat === "financial" && <FinancialStats data={data} period={period} isLoading={isLoading} />}
			{stat === "workers" && <WorkerStats data={data} period={period} isloading={isLoading} />}
			<LoadingOverlay isLoading={isLoading} />
		</>
	);
}
