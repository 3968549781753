import { Flex, Text, useMediaQuery } from "@chakra-ui/react";
//types
import type { PriceDuration } from "../../../../../types/service";
//fns
import { parseDuration, formatDecimals } from "../../../../common/functions";

interface PriceDurationRangeDisplayProps {
	prices: PriceDuration[];
}

export default function PriceDurationRangeDisplay({ prices }: PriceDurationRangeDisplayProps) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const getPriceDisplay = (priceDuration: PriceDuration) => {
		if (priceDuration.price_type === "discount" && priceDuration.discounted_price) {
			return (
				<Flex align='center'>
					<Text as='s' color='gray.500'>
						{`${formatDecimals(priceDuration.price)}€`}
					</Text>
					<Text ml='0.5rem' color='black'>
						{`${formatDecimals(priceDuration.discounted_price)}€`}
					</Text>
				</Flex>
			);
		}
		if (priceDuration.price_type === "from") {
			return `Desde ${formatDecimals(priceDuration.price)}€`;
		}
		return `${formatDecimals(priceDuration.price)}€`;
	};

	let priceDisplay: React.ReactNode = "-- --";
	let durationDisplay = "-- --";

	if (prices.length === 1) {
		const priceDuration = prices[0];
		const duration = priceDuration.duration;
		priceDisplay = getPriceDisplay(priceDuration);
		durationDisplay = `${parseDuration(duration)}`;
	} else if (prices.length > 1) {
		const minPrice = Math.min(...prices.map((priceDuration) => priceDuration.price));
		const minDuration = Math.min(...prices.map((priceDuration) => priceDuration.duration));
		const maxDuration = Math.max(...prices.map((priceDuration) => priceDuration.duration));
		priceDisplay = `desde ${formatDecimals(minPrice)}€`;
		durationDisplay = `de ${parseDuration(minDuration)} a ${parseDuration(maxDuration)}`;
	}

	return (
		<Flex
			fontSize={isMobile ? "14px" : "16px"}
			color='black'
			align='center'
			justify='end'
			gap='0.5rem'
			flexWrap='wrap'
		>
			<Text fontWeight='semibold'>{priceDisplay}</Text>
			<Text textAlign='end'>{durationDisplay}</Text>
		</Flex>
	);
}
