import { useParams } from "react-router-dom";
//comps
import InvoicePDF from "./invoice/InvoicePDF";
import MultiInvoicePDF from "./invoice/MultiInvoicePDF";
//api
import { getInvoiceDetail } from "../../../../api/invoicesApi";
import { useQuery } from "@tanstack/react-query";
import { Flex } from "@chakra-ui/react";

export default function InvoicePDFPage() {
	const { uuid } = useParams();

	const { data, isLoading } = useQuery({
		queryKey: ["invoiceDetail", uuid],
		queryFn: () => getInvoiceDetail(uuid)
	});
	const invoice = data?.invoice;
	const invoiceSettings = data?.invoice_settings;
	const businessProfile = data?.business_profile;
	return (
		<Flex w='100%' h='100vh' minH='100dvh'>
			{isLoading ? (
				"Cargando..."
			) : (
				<>
					{!invoice.is_multi ? (
						<InvoicePDF
							invoice={invoice}
							invoiceSettings={invoiceSettings}
							businessProfile={businessProfile}
						/>
					) : (
						<MultiInvoicePDF
							invoice={invoice}
							invoiceSettings={invoiceSettings}
							businessProfile={businessProfile}
						/>
					)}
				</>
			)}
		</Flex>
	);
}
