import { useEffect, useState } from "react";
import { Flex, Text, Input, Button, IconButton, useToast, Image } from "@chakra-ui/react";
// comps
import ImageUploader from "../ImageUploader";
// fs
// icons
import { MdDeleteOutline } from "react-icons/md";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateDetailImage, deleteDetailImage } from "../../../../api/detailsApi";

export default function DetailForm({
	savedInspection,
	order,
	isBefore,
	zone,
	circleCoords,
	selectedDetail,
	setSelectedDetail
}) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { x, y } = circleCoords;
	const [detailPhoto, setDetailPhoto] = useState(null);
	const [name, setName] = useState("");

	const { isLoading, mutate } = useAuthMutation({
		mutationFn: createUpdateDetailImage,
		onSuccess: () => {
			toast({ title: "Imagen detalle añadida!", status: "success" });
			QueryClient.invalidateQueries(["inspection", order, isBefore, zone]);
			QueryClient.refetchQueries(["inspection", order, isBefore, zone], { force: true });
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const { isLoading: ld, mutate: remove } = useAuthMutation({
		mutationFn: deleteDetailImage,
		onSuccess: () => {
			toast({ title: "Imagen eliminada!", status: "success" });
			setSelectedDetail();
			QueryClient.invalidateQueries(["inspection", order, isBefore, zone]);
			QueryClient.refetchQueries(["inspection", order, isBefore, zone], { force: true });
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const disableSumbit = () => {
		if (!detailPhoto || (!x === 0 && y === 0)) {
			return true;
		}
		return false;
	};
	const deleteImage = () => {
		const payload = {
			slug: selectedDetail.id,
			token: authHeader()
		};
		remove(payload);
	};

	const submit = () => {
		const data = {
			name,
			x_coord: circleCoords.x,
			y_coord: circleCoords.y,
			image: detailPhoto.file,
			inspection: savedInspection.id
		};
		const payload = {
			data,
			token: authHeader()
		};
		mutate(payload);
	};

	useEffect(() => {
		if (selectedDetail) {
			setDetailPhoto(selectedDetail?.image || null);
			setName(selectedDetail?.name || "");
		} else {
			setDetailPhoto(null);
			setName("");
		}
	}, [selectedDetail]);

	return (
		<Flex direction='column' gap='1em' h='100%' w='100%' bg='white' rounded='xl' p='1em'>
			<Text fontWeight='bold' fontSize='16px'>
				{!selectedDetail ? "Añadir Detalle" : `Editar Detalle #${selectedDetail.id}`}
			</Text>
			<Text>Nombre de zona</Text>
			<Input
				value={name}
				onChange={(e) => {
					setName(e.target.value);
				}}
				placeholder='Ej. Llanta delantera izquierda'
			/>
			{detailPhoto ? (
				<Image src={detailPhoto.url} rounded='lg' alignSelf='center' />
			) : (
				<Flex
					direction='column'
					align='center'
					justify='center'
					gap='2em'
					bg='lightgrey'
					mt='2em'
					minH='50vh'
					rounded='xl'
					w='100%'
				>
					<Text align='center' maxW='150px'>
						Haz una fotografía del coche o añade una desde tu galería.
					</Text>
					<ImageUploader isDetail setPhoto={setDetailPhoto} />
				</Flex>
			)}
			<Flex gap='1em' align='center' justify='end'>
				{selectedDetail && (
					<IconButton isLoading={ld} onClick={deleteImage} variant='danger' icon={<MdDeleteOutline />} />
				)}
				<Button
					variant='primary'
					position='absolute'
					bottom='2em'
					right='2em'
					isDisabled={disableSumbit()}
					onClick={submit}
					isLoading={isLoading}
				>
					Guardar
				</Button>
			</Flex>
		</Flex>
	);
}
