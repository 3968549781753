import { Tag, TagLabel } from "@chakra-ui/react";

export default function LogTypeBadge({ type }) {
	let color = "";
	let bg = "";
	let label = "";
	if (type === "app") {
		color = "blue";
		bg = "blue.100";
		label = "App";
	}
	if (type === "email") {
		color = "red";
		bg = "red.100";
		label = "Email";
	}
	if (type === "stripe") {
		color = "purple";
		bg = "purple.100";
		label = "Stripe";
	}
	if (type === "whatsapp") {
		color = "green";
		bg = "green.100";
		label = "WhatsApp";
	}
	return (
		<Tag bg={bg} rounded='xl' p='0.5em'>
			<TagLabel color={color}>{label}</TagLabel>
		</Tag>
	);
}
