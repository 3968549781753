import { Text, Highlight } from '@chakra-ui/react'

export default function AnothertoolColored ({ ...props }) {
    return (
        <>
            <Text {...props}>
                <Highlight query={'another'} styles={{ color: 'darkblue' }} >
                another
                </Highlight>
                <Highlight query={'tool'} styles={{ color: 'blue.500' }} >
                tool
                </Highlight>
            </Text>
        </>
    )
}
