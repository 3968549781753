import { Flex, Image, Text, Input, IconButton, useMediaQuery } from "@chakra-ui/react";
// icons
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";

export default function BannerImageUploader({ formik, bannerImage, preview, setPreview, error }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const handleSelectImage = (e) => {
		const file = e?.target?.files[0];
		const photoUrl = URL?.createObjectURL(file);
		setPreview(photoUrl);
		formik.setFieldValue("banner_image", file);
	};
	const handleCancelImage = () => {
		setPreview();
		formik.setFieldValue("banner_image", null);
		formik.resetForm();
	};
	const imageHeight = isMobile ? "80px" : "100px";
	const imageWidth = isMobile ? "80px" : "100px";
	if (bannerImage) {
		return (
			<>
				<Flex w='170px'>
					<Image
						src={preview || bannerImage}
						h={imageHeight}
						w={imageWidth}
						rounded='full'
						objectFit={"cover"}
					/>
					{bannerImage && !preview && (
						<>
							<IconButton
								cursor={"pointer"}
								alignSelf='end'
								ml='-23px'
								size='xs'
								icon={<AiOutlineEdit size='15px' />}
								variant='primary'
								as='label'
								htmlFor='banner_image'
								aria-label='Subir archivo'
							/>
							<Input
								z-index={100}
								id='banner_image'
								name='banner_image'
								type='file'
								accept='image/png, image/jpeg'
								onChange={handleSelectImage}
								style={{ display: "none" }}
							/>
						</>
					)}
					{preview && (
						<IconButton
							cursor={"pointer"}
							alignSelf='start'
							onClick={handleCancelImage}
							ml='-23px'
							size='xs'
							icon={<MdOutlineCancel size='15px' />}
							variant='danger'
						/>
					)}
				</Flex>
				{error && <Text color='red'>{error}</Text>}
			</>
		);
	}
	return (
		<Flex border='1px dashed grey' justify='center' bg='lightgrey' rounded='full' w={imageWidth} h={imageHeight}>
			<Image src={preview} objectFit={"cover"} h={imageHeight} w={imageWidth} />
			<IconButton
				cursor={"pointer"}
				ml='-23px'
				size='xs'
				icon={<AiOutlineEdit size='15px' />}
				variant='primary'
				as='label'
				htmlFor='banner_image'
				aria-label='Subir archivo'
			/>
			<Input
				z-index={100}
				id='banner_image'
				name='banner_image'
				type='file'
				accept='image/png, image/jpeg'
				onChange={handleSelectImage}
				style={{ display: "none" }}
			/>
		</Flex>
	);
}
