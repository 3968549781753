import { useState } from 'react'
import { Flex, Button, Text } from '@chakra-ui/react'
// stripe
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

const StripeForm = () => {
    const stripe = useStripe()
    const elements = useElements()
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault()
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }
        const { error } = await stripe.confirmPayment({
            // `Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: 'http://localhost:3000/paymentstatus'
            }
        })

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message)
            setLoading(false)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    }

    return (
        <Flex direction="column">
            {!stripe && <Text> Cargando.... </Text>}
            <PaymentElement />
            <Flex py="2em" justify="end" gap="2em" align="center">
                {errorMessage && (
                    <Text fontSize="14px" color="red">
                        {errorMessage}
                    </Text>
                )}

                <Button variant="primary" isDisabled={!stripe} onClick={handleSubmit} isLoading={loading}>
                    Pagar
                </Button>
            </Flex>
        </Flex>
    )
}

export default StripeForm
