import { useState } from "react";
import { Flex, Select, Tabs, TabList, TabPanels, Tab, TabPanel, useMediaQuery } from "@chakra-ui/react";
// comps
import InvoicesTableMobile from "../../components/pages/invoicing/tables/InvoicesTableMobile";
import InvoicesTablePag from "../../components/pages/invoicing/tables/InvoicesTablePag";
import QuotesTablePag from "../../components/pages/invoicing/tables/QuotesTablePag";
import QuotesTableMobile from "../../components/pages/invoicing/tables/QuotesTableMobile";
import InvoiceSettingsForm from "../../components/pages/invoicing/InvoiceSettingsForm";
import DepositReceiptsTablePag from "../../components/pages/depositReceipt/tables/DepositReceiptsTablePag";
import DepositReceiptsTableMobile from "../../components/pages/depositReceipt/tables/DepositReceiptsTableMobile";
import HelperModal from "../../components/tutorial/HelperModal";

export default function Invoices() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [tableSelect, setTableSelect] = useState("invoices");

	if (!isMobile) {
		return (
			<>
				<Flex justify='center' align='top' py='30px' w='100%'>
					<Tabs
						variant='brand'
						rounded='xl'
						bg='white'
						px='20px'
						pt='30px'
						isLazy={true}
						minH='60vh'
						width={{
							base: "100%",
							md: "700px",
							lg: "800px",
							xl: "1080px",
							"2xl": "1300px"
						}}
					>
						<TabList ml='15px'>
							<Tab>Facturas</Tab>
							<Tab>Presupuestos</Tab>
							<Tab>Resguardos</Tab>
							<Tab>Ajustes</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<InvoicesTablePag />
							</TabPanel>
							<TabPanel>
								<QuotesTablePag />
							</TabPanel>
							<TabPanel>
								<DepositReceiptsTablePag />
							</TabPanel>
							<TabPanel>
								<InvoiceSettingsForm />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Flex>
				<HelperModal />
			</>
		);
	}
	return (
		<Flex w='100%' direction='column' px='1em' pt='0.5em'>
			<Select
				value={tableSelect}
				onChange={(e) => setTableSelect(e.target.value)}
				color='black'
				fontWeight='bold'
				maxW='200px'
				fontSize='20px'
				border='none'
				h='5dvh'
			>
				<option value='invoices' style={{ fontSize: "16px" }}>
					Facturas
				</option>
				<option value='quotes' style={{ fontSize: "16px" }}>
					Presupuestos
				</option>
				<option value='receipts' style={{ fontSize: "16px" }}>
					Resguardos
				</option>
			</Select>
			{tableSelect === "invoices" && <InvoicesTableMobile />}
			{tableSelect === "quotes" && <QuotesTableMobile />}
			{tableSelect === "receipts" && <DepositReceiptsTableMobile />}
		</Flex>
	);
}
