import { useEffect, useMemo, useState } from "react";
import {
	useDisclosure,
	useToast,
	Select,
	Flex,
	Text,
	IconButton,
	Button,
	Tr,
	Td,
	Thead,
	Th,
	Checkbox
} from "@chakra-ui/react";
import moment from "moment";
// comps
import InvoiceStatusBadge from "../InvoiceStatusBadge";
import InvoiceModal from "../forms/InvoiceModal";
import MultiInvoicePDFModal from "../pdf/MultiInvoicePDFModal";
import InvoiceTicketPDFModal from "../pdf/InvoiceTicketPDFModal";
import PopoverDelete from "../../../common/PopoverDelete";
import PopoverBulkDelete from "../../../common/Tables/PopoverBulkDelete";
import ResponsiveTable from "../../../common/Tables/ResponsiveTable";
import SearchInput from "../../../common/Tables/SearchInput";
import BooleanFilter from "../../../common/Tables/BooleanFilter";
// icons
import { AiOutlineEdit } from "react-icons/ai";
import { IoTicketOutline } from "react-icons/io5";
import { AiOutlineFilePdf } from "react-icons/ai";
import { MdDownload } from "react-icons/md";
// img
import emptyInvoices from "../../../../img/invoicing/emptyInvoices.png";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import {
	deleteInvoice,
	getAllInvoicesPag,
	downloadMultipleInvoices,
	bulkDeleteInvoices
} from "../../../../api/invoicesApi";
import { useAuthHeader } from "react-auth-kit";

export default function InvoicesTablePag() {
	const authHeader = useAuthHeader();
	const toast = useToast();

	const [invoice, setInvoice] = useState();
	const [quarter, setQuarter] = useState(undefined);
	const [status, setStatus] = useState(undefined);
	const [searchQuery, setSearchQuery] = useState("");
	const [booleanFilter, setBooleanFilter] = useState("");
	const [page, setPage] = useState(1);
	const [selectedInvoices, setSelectedInvoices] = useState([]);

	const { isOpen: isOpenForm, onOpen: onOpenForm, onClose: onCloseForm } = useDisclosure();
	const { isOpen: isOpenTicketPDF, onOpen: onOpenTicketPDF, onClose: onCloseTicketPDF } = useDisclosure();
	const { isOpen: isOpenMultiPDF, onOpen: onOpenMultiPDF, onClose: onCloseMultiPDF } = useDisclosure();

	const filter = `${quarter ? `&quarter=${quarter}` : ""}${status ? `&status=${status}` : ""}${searchQuery ? `&search=${searchQuery}` : ""}${booleanFilter}`;

	const availableFilters = [
		{ label: "Con IVA", value: "taxes" },
		{ label: "Sin IVA", value: "no_taxes" },
		{ label: "Descuento", value: "discount" },
		{ label: "Pago Efectivo", value: "cash" }
	];

	const { data, isLoading } = useAuthFetch(
		["invoices", page, searchQuery, filter],
		getAllInvoicesPag,
		page,
		filter,
		true,
		searchQuery
	);
	const { isLoading: lm, mutate } = useAuthMutation({
		mutationFn: downloadMultipleInvoices,
		onSuccess: () => {
			setSelectedInvoices([]);
			toast({ title: "Facturas descargadas!", status: "success" });
		},
		onError: () => {
			toast({
				title: "¡Ups, ha habido un error!",
				status: "error"
			});
		}
	});

	useEffect(() => {
		setPage(1);
		setSelectedInvoices([]);
	}, [searchQuery, filter]);

	const resetFilters = () => {
		setBooleanFilter("");
		setSearchQuery("");
		setQuarter("");
		setStatus("");
	};

	const handleOpenTicketPDF = (invoice) => {
		setInvoice(invoice);
		onOpenTicketPDF();
	};
	const handleEditInvoice = (invoice) => {
		setInvoice(invoice);
		onOpenForm();
	};
	const handleOpenInvoice = (invoice) => {
		const url = `/facturas/${invoice.uuid}`;
		//const backent_url = `http://localhost:8000/api/invoicing/pdf/view/${invoice.id}`;
		window.open(url, "_blank");
	};
	const handleDownloadInvoice = (invoice) => {
		const baseUrl = process.env.REACT_APP_API_URL;
		if (!baseUrl) {
			console.error("Base URL is not defined");
			return;
		}
		const download_url = `${baseUrl}invoicing/pdf/download/${invoice.id}`;
		window.open(download_url, "_blank");
	};
	const handleDownloadMultipleInvoice = () => {
		const payload = { data: { invoice_ids: selectedInvoices }, token: authHeader() };
		mutate(payload);
	};
	const handleSelectInvoice = (id) => {
		setSelectedInvoices((prevSelected) =>
			prevSelected.includes(id) ? prevSelected.filter((invoiceId) => invoiceId !== id) : [...prevSelected, id]
		);
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((invoice) => (
				<Tr key={invoice.id}>
					<Td>
						<Checkbox
							isChecked={selectedInvoices.includes(invoice.id)}
							onChange={() => handleSelectInvoice(invoice.id)}
						/>
					</Td>
					<Td>{invoice.invoice_number}</Td>
					<Td>{invoice.client?.name}</Td>
					<Td> {moment(invoice.date).format("L")} </Td>
					<Td> {invoice.expiring_date ? moment(invoice.expiring_date).format("L") : "Sin Fecha"}</Td>
					<Td>
						{invoice.taxes} {invoice.taxes > 0 && `(${invoice.tax_display})`}
					</Td>
					<Td>
						{invoice.total} {invoice.discount > 0 && `(-${invoice.discount}%)`}{" "}
					</Td>
					<Td>
						<InvoiceStatusBadge status={invoice.status} />
					</Td>
					<Td>
						<Flex align='center' justify='end'>
							{!invoice.is_multi && (
								<IconButton
									isDisabled={invoice.is_multi}
									icon={<AiOutlineEdit size='20px' color='#0258FF' />}
									bg='transparent'
									onClick={() => handleEditInvoice(invoice)}
								/>
							)}
							<IconButton
								icon={<AiOutlineFilePdf size='20px' color='black' />}
								bg='transparent'
								onClick={() => handleOpenInvoice(invoice)}
							/>
							{/* 
								<IconButton
									icon={<MdDownload size='20px' color='black' />}
									bg='transparent'
									onClick={() => handleDownloadInvoice(invoice)}
								/>
							*/}
							{!invoice.is_multi && (
								<IconButton
									icon={<IoTicketOutline size='20px' color='black' />}
									bg='transparent'
									onClick={() => handleOpenTicketPDF(invoice)}
								/>
							)}
							<PopoverDelete
								deleteKey={["deleteInvoice"]}
								deleteFn={deleteInvoice}
								slug={invoice?.id}
								refetchKey={["invoices"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data, selectedInvoices]
	);
	const tableHeader = (
		<Thead>
			<Tr bg='lightgrey'>
				<Th py='1.5em' roundedLeft='lg'>
					<Checkbox
						isChecked={selectedInvoices.length > 0 && selectedInvoices.length >= data?.all_ids?.length}
						isIndeterminate={selectedInvoices.length > 0 && selectedInvoices.length < data?.all_ids?.length}
						onChange={(e) => {
							if (e.target.checked) {
								setSelectedInvoices(data?.all_ids);
							} else {
								setSelectedInvoices([]);
							}
						}}
					/>
				</Th>
				<Th py='1.5em'>Nº Factura</Th>
				<Th py='1.5em'>Cliente</Th>
				<Th py='1.5em'>Creada</Th>
				<Th py='1.5em'>Expira</Th>
				<Th py='1.5em'>IVA(€)</Th>
				<Th py='1.5em'>Total(€)</Th>
				<Th py='1.5em'>Estado</Th>
				<Th py='1.5em' roundedRight='lg'>
					<Button
						variant='primary'
						size='sm'
						onClick={() => {
							setInvoice();
							onOpenForm();
						}}
					>
						+ Factura
					</Button>
				</Th>
			</Tr>
		</Thead>
	);

	const isEmpty = data?.count < 1;
	const hasFilters = quarter || status || booleanFilter || searchQuery;
	const hasSelectedItems = selectedInvoices?.length > 1;
	return (
		<>
			<Flex justify='end' gap='1rem' align='center' w='100%' flexWrap='wrap'>
				{hasFilters && (
					<Button variant='white' size='xs' onClick={resetFilters}>
						Borrar filtros
					</Button>
				)}
				<Select
					maxW='180px'
					value={quarter}
					placeholder='Trimestre'
					onChange={(e) => setQuarter(e.target.value)}
				>
					<option value={1}>1 Trimestre</option>
					<option value={2}>2 Trimestre</option>
					<option value={3}>3 Trimestre</option>
					<option value={4}>4 Trimestre</option>
				</Select>
				<Select maxW='180px' placeholder='Estado' value={status} onChange={(e) => setStatus(e.target.value)}>
					<option value='pending'>Pendiente</option>
					<option value='payed'>Pagada</option>
					<option value='expired'>Caducada</option>
				</Select>
				<BooleanFilter availableFilters={availableFilters} setFilter={setBooleanFilter} />
				<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
			</Flex>
			{hasSelectedItems && (
				<Flex gap='1rem' align='center' justify='end' w='100%' flexWrap='wrap' mt='1rem'>
					<Text>{selectedInvoices?.length} Facturas seleccionadas</Text>
					<Button
						variant='primary'
						size='sm'
						leftIcon={<MdDownload size='20px' color='white' />}
						isLoading={lm}
						onClick={handleDownloadMultipleInvoice}
					>
						Descargar
					</Button>
					<PopoverBulkDelete
						deleteFn={bulkDeleteInvoices}
						selectedItems={selectedInvoices}
						setSelectedFn={setSelectedInvoices}
						refetchKey={["invoices"]}
					/>
				</Flex>
			)}
			<ResponsiveTable
				paginated
				searchQuery={searchQuery}
				data={data}
				tableData={tableData}
				isEmpty={isEmpty}
				onOpen={() => {
					setInvoice();
					onOpenForm();
				}}
				onClose={onCloseForm}
				page={page}
				setPage={setPage}
				isLoading={isLoading}
				tableHeader={tableHeader}
				item={{
					label: "Factura",
					headers: [
						{ key: "checkbox", roundedLeft: "lg" },
						{ key: "invoice_number", label: "Nº Factura" },
						{ key: "client", label: "Cliente" },
						{ key: "date", label: "Creada" },
						{ key: "expiring_date", label: "Expira" },
						{ key: "taxes", label: "IVA(€)" },
						{ key: "total", label: "Total(€)" },
						{ key: "status", label: "Estado" }
					],
					formItem: invoice,
					isOpen: isOpenForm
				}}
				FormComponent={InvoiceModal}
				emptyImage={emptyInvoices}
			/>
			<InvoiceTicketPDFModal invoice={invoice} onClose={onCloseTicketPDF} isOpen={isOpenTicketPDF} />

			<MultiInvoicePDFModal invoice={invoice} onClose={onCloseMultiPDF} isOpen={isOpenMultiPDF} />
		</>
	);
}
