import { FormControl, FormErrorMessage, Select, FormLabel } from '@chakra-ui/react'
import { Field, useField } from 'formik'
// Factorizacion del input de texto en los formularios
export default function SelectFieldWithFilter ({ label, ...props }) {
    // hook de formik para obtener el field data y los errors y touched de cada field
    const [field, meta] = useField(props)
    return (
        <FormControl isInvalid={meta.error && meta.touched}>
            <FormLabel> {label} </FormLabel>
            <Field
                as={Select}
                placeholder="Selecciona uno"
                {...field} // aqui van los props de onblur, on change
                {...props} // props que pasamos como placeholder etc
            >
                {props.choices?.map((choice) => (
                    <option key={choice.id || choice.value} value={choice.id || choice.value}>
                        {' '}
                        {choice.plate || choice.name || choice.display_name || choice.label}{' '}
                    </option>
                ))}
                {props.choices?.length === 0 && <option>No hay opciones disponibles</option>}
            </Field>
            {props.error && <FormErrorMessage>{props.error} </FormErrorMessage>}
            {meta.touched && meta.error && <FormErrorMessage>{meta.error} </FormErrorMessage>}
        </FormControl>
    )
}
