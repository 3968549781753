import { useEffect, useState } from "react";
import {
	useToast,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton
} from "@chakra-ui/react";
// comps
import MultiInvoiceForm from "./MultiInvoiceForm";
//fns
import { calculateTotalAndTax } from "../fns";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { getAllClients } from "../../../../api/clientsApi";
import { getInvoiceOptions, createUpdateInvoice } from "../../../../api/invoicesApi";
// auth
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { formatDecimals } from "../../../common/functions";

export default function MultiInvoiceModal({ orders, setSelectedItems, isOpen, onClose }) {
	const authHeader = useAuthHeader();
	const auth = useAuthUser();

	const QueryClient = useQueryClient();
	const toast = useToast();

	const [total, setTotal] = useState(0);
	const [taxes, setTaxes] = useState(0);
	const [impositiveBase, setImpositiveBse] = useState(0);
	const [subtotal, setSubtotal] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [withTaxes, setWithTaxes] = useState(true);

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateInvoice,
		onSuccess: () => {
			toast({ title: "MultiFactura creada!", status: "success" });
			QueryClient.invalidateQueries(["invoices"]);
			QueryClient.refetchQueries("invoices", { exact: false });
			onClose();
			setSelectedItems([]);
		},
		onError: () => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const { data: clients } = useAuthFetch(["clients"], getAllClients, null, null, !!isOpen);
	const { data: options } = useAuthFetch(["invoiceOptions"], getInvoiceOptions, null, null, !!isOpen);

	const createPayload = (values) => {
		return {
			data: values,
			token: authHeader()
		};
	};
	const submit = (values) => {
		const payload = createPayload(values);
		payload.data.total = total;
		payload.data.taxes = taxes;
		payload.data.is_multi = true;
		mutate(payload);
	};
	const calculateTotals = (values, orders) => {
		let subtotal = 0; // Es la base imponible previa al descuento

		const taxMultiplier = values?.tax === "ten" ? 0.1 : values?.tax === "twenty" ? 0.21 : 0;
		const priceIncludesTax = values?.price_includes_tax;

		for (const order of orders) {
			const hasServices = order.services?.length > 0;
			const hasStockUsed = order.stock_used?.length > 0;

			if (hasServices) {
				for (const service of order.services) {
					subtotal += formatDecimals(service.price);
				}
			}

			if (hasStockUsed) {
				const stock_used = order?.stock_used;
				const total_stock = stock_used.reduce((prev, curr) => prev + curr.total, 0);
				subtotal += total_stock;
			}
		}
		if (priceIncludesTax) {
			subtotal /= 1 + taxMultiplier;
		}
		// Calcular descuento
		const percentageDiscount = values.discount / 100; // Viene en %
		const totalDiscount = subtotal * percentageDiscount;
		// Calcular base imponible después del descuento
		const impositiveBase = subtotal - totalDiscount;
		// Calcular total y taxes usando la función auxiliar, nunca incluye impuestos, porque ya le paso la bse imponible
		const { total, taxes } = calculateTotalAndTax(false, values.tax, impositiveBase);

		// Sumar los impuestos del stock al total de impuestos
		const totalTaxes = taxes;

		// Actualizar los estados con los valores d
		setTotal(total.toFixed(2));
		setImpositiveBse(impositiveBase.toFixed(2));
		setSubtotal(subtotal.toFixed(2));
		setTaxes(totalTaxes.toFixed(2));
		setDiscount(totalDiscount.toFixed(2));
	};

	const closeModal = () => {
		setTotal(0);
		setDiscount(0);
		onClose();
	};

	const isFree = auth().plan === "FREE";
	return (
		<Modal isOpen={isOpen} onClose={closeModal} size='6xl'>
			<ModalOverlay />
			<ModalContent bg='white' h='80vh'>
				<ModalHeader>
					<Text>Crear Factura Múltiple</Text>
				</ModalHeader>
				<ModalCloseButton />

				<ModalBody>
					<MultiInvoiceForm
						submit={submit}
						clients={clients}
						options={options}
						isLoading={isLoading}
						withTaxes={withTaxes}
						setWithTaxes={setWithTaxes}
						total={total}
						setTotal={setTotal}
						subtotal={subtotal}
						taxes={taxes}
						impositiveBase={impositiveBase}
						discount={discount}
						setDiscount={setDiscount}
						orders={orders}
						calculateTotals={calculateTotals}
						isFree={isFree}
						error={error}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
