import { useState, useMemo } from "react";
import { Flex, Tr, Td, Badge, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
// comps
import ResponsiveTable from "../../../../common/Tables/ResponsiveTable";
import PopoverDelete from "../../../../common/PopoverDelete";
// img
import emptyCars from "../../../../../img/data/emptyCars.png";
// api
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { getAllOrderRequestsPag, deleteOrderRequest } from "../../../../../api/businessesApi";

export default function OrderRequestsTable() {
	const [page, setPage] = useState(1);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data, isLoading } = useAuthFetch(["orderRequests", page], getAllOrderRequestsPag, page);
	const tableData = useMemo(
		() =>
			data?.results?.map((request) => (
				<Tr key={request.id}>
					<Td> #{request.incremental_id} </Td>
					<Td> {request.client_name || "-- --"} </Td>
					<Td> {request.client_email || "-- --"} </Td>
					<Td> {request.client_phone || "-- --"} </Td>
					<Td> {request.brand_display || "-- --"} </Td>
					<Td> {request.model || "-- --"} </Td>
					<Td>
						{request.approved === true ? (
							<Badge colorScheme='green' rounded='xl'>
								Aprobada
							</Badge>
						) : request.approved === false ? (
							<Badge colorScheme='red' rounded='xl'>
								Rechazada
							</Badge>
						) : (
							<Badge colorScheme='orange' rounded='xl'>
								Pendiente
							</Badge>
						)}
					</Td>
					<Td maxW='200px' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
						{request.cancel_reason || "-- --"}
					</Td>
					<Td> {moment(request.expires_at).format("L")} </Td>
					<Td>
						<Flex gap='1em' key={request.id}>
							<PopoverDelete
								deleteKey={["deleteOrderRequest"]}
								deleteFn={deleteOrderRequest}
								slug={request?.id}
								refetchKey={["orderRequests"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data]
	);
	const isEmpty = data?.count < 1;
	return (
		<ResponsiveTable
			withoutLoading
			paginated
			page={page}
			setPage={setPage}
			withoutButton
			data={data}
			tableData={tableData}
			isEmpty={isEmpty}
			emptyImage={emptyCars}
			onClose={onClose}
			isLoading={isLoading}
			item={{
				label: "Solicitud de trabajo",
				headers: [
					{ key: "incremental_id", label: "", roundedLeft: "lg" },
					{ key: "client_name", label: "Nombre" },
					{ key: "client_email", label: "Email" },
					{ key: "client_phone", label: "Teléfono" },
					{ key: "brand", label: "Marca" },
					{ key: "model", label: "Modelo" },
					{ key: "approved", label: "Estado" },
					{ key: "cancel_reason", label: "Motivo" },
					{ key: "expires_at", label: "Caduca" }
				],
				isOpen
			}}
		/>
	);
}
