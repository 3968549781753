import { useState, useMemo } from "react";
import { Flex, Avatar, IconButton, Tr, Td, useDisclosure } from "@chakra-ui/react";
// comps
import WorkerForm from "./WorkerForm";
import ResponsiveTable from "../../../../common/Tables/ResponsiveTable";
import PopoverDelete from "../../../../common/PopoverDelete";
// icons
import { AiOutlineEdit } from "react-icons/ai";
// img
import emptyCars from "../../../../../img/data/emptyCars.png";
// api
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { getWorkers, deleteWorker } from "../../../../../api/businessesApi";

export default function WorkersTable() {
	const [worker, setWorker] = useState();
	const [page, setPage] = useState(1);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data, isLoading } = useAuthFetch(["workers", page], getWorkers, page);

	const tableData = useMemo(
		() =>
			data?.results?.map((worker) => (
				<Tr key={worker.id}>
					<Td>
						<Avatar name={worker.name} size='sm' bg={worker.color} />
					</Td>
					<Td> {worker.name} </Td>
					<Td> {worker.position || "-- --"} </Td>
					<Td> {worker.phone || "-- --"} </Td>
					<Td> {worker.email || "-- --"} </Td>
					<Td>
						<Flex bg={worker.color} boxSize='25px' rounded='md' />
					</Td>
					<Td>
						<Flex gap='1em' key={worker.id}>
							<IconButton
								icon={<AiOutlineEdit size='20px' color='blue' />}
								bg='transparent'
								onClick={() => {
									setWorker(worker);
									onOpen();
								}}
							/>
							<PopoverDelete
								deleteKey={["deleteWorker"]}
								deleteFn={deleteWorker}
								slug={worker?.id}
								refetchKey={["workers"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data]
	);
	const isEmpty = data?.count < 1;
	return (
		<ResponsiveTable
			withoutLoading
			data={data}
			tableData={tableData}
			isEmpty={isEmpty}
			emptyImage={emptyCars}
			onOpen={() => {
				setWorker();
				onOpen();
			}}
			onClose={onClose}
			isLoading={isLoading}
			item={{
				label: "Detailer",
				headers: [
					{ key: "avatar", label: "", roundedLeft: "lg" },
					{ key: "name", label: "Nombre" },
					{ key: "position", label: "Cargo" },
					{ key: "phone", label: "Teléfono" },
					{ key: "email", label: "Email" },
					{ key: "color", label: "Color" }
				],
				formItem: worker,
				isOpen
			}}
			FormComponent={WorkerForm}
		/>
	);
}
