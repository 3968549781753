import {
	Button,
	useToast,
	Flex,
	IconButton,
	VStack,
	Text,
	Switch,
	DrawerBody,
	DrawerFooter,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton
} from "@chakra-ui/react";
import moment from "moment";
//comps
import InfoTooltip from "../../../common/InfoTooltip";
//fns
import calculateTaxAndTotal from "../functions";
import { formatDecimals } from "../../../common/functions";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../../common/forms/InputField";
import SelectFieldFilter from "../../../common/forms/SelectFieldFilter";
import SelectField from "../../../common/forms/SelectField";

// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdatePurchase, getAllStock, getPurchaseOptions, getAllSuppliers } from "../../../../api/stockApi";

export default function SingleExpenseForm({ onClose, isOpen, item: purchase }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data: items } = useAuthFetch(["allItems"], getAllStock);
	const { data: suppliers } = useAuthFetch(["allSuppliers"], getAllSuppliers);
	const { data: options } = useAuthFetch(["purchaseOptions"], getPurchaseOptions);
	const taxChoices = options?.actions?.POST?.tax?.choices;

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdatePurchase,
		onSuccess: () => {
			toast({ title: "Creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["allItems"]);
			QueryClient.refetchQueries("allItems", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const initialValues = {
		concept: purchase ? purchase.concept : "",
		item: purchase ? purchase?.item?.id : "",
		quantity: purchase ? formatDecimals(purchase.quantity) : "",
		price: purchase ? purchase.price : "",
		tax: purchase ? purchase.tax : "twenty",
		price_includes_tax: purchase ? purchase.price_includes_tax : false,
		purchased_at: purchase ? purchase.purchased_at : moment().format("YYYY-MM-DD"),
		supplier: purchase ? purchase.supplier?.id : ""
	};
	const validateConceptAndItem = (value, context) => {
		const { item, concept } = context.parent;
		if (!item && !concept) {
			return context.createError({ message: "Debe proporcionar un concepto o seleccionar un item" });
		}
		return true;
	};

	const validationSchema = Yup.object({
		concept: Yup.string().test(
			"concept-or-item",
			"Debe proporcionar un concepto o seleccionar un item",
			function (value) {
				return validateConceptAndItem(value, this);
			}
		),
		item: Yup.string().test(
			"concept-or-item",
			"Debe proporcionar un concepto o seleccionar un item",
			function (value) {
				return validateConceptAndItem(value, this);
			}
		),
		quantity: Yup.number().required("Cantidad es obligatoria"),
		price: Yup.number().required("Precio es obligatorio").min(0, "El precio debe ser positivo"),
		purchased_at: Yup.date("El formato de la fecha no es correcto").required("La fecha de compra es obligatoria")
	});
	const submit = (values) => {
		let payload = {};
		if (purchase) {
			payload = {
				data: values,
				slug: purchase.id,
				token: authHeader()
			};
		} else {
			payload = {
				data: values,
				token: authHeader()
			};
		}
		mutate(payload);
	};
	const findUnits = (formikSelected) => {
		if (formikSelected) {
			const item = items?.results?.find((item) => item.id === formikSelected);
			return `(${item?.unit_of_measurement})`;
		}
		return "";
	};
	const findItemLabel = (itemId) => {
		const item = items?.results?.find((item) => item.id === itemId);
		return item?.name;
	};
	const findItemSupplier = (itemId) => {
		const item = items?.results?.find((item) => item.id === itemId);
		return item?.supplier?.name;
	};

	return (
		<Drawer isOpen={isOpen} placement='right' size='md' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>{purchase ? "Editar" : "Añadir"} compra</DrawerHeader>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => submit(values)}
				>
					{(formik) => {
						const { taxes, total } = calculateTaxAndTotal(formik.values);
						return (
							<>
								<DrawerBody>
									<VStack as='form' gap='1em'>
										<SelectFieldFilter
											required
											label='Item'
											options={items?.results}
											name='item'
											error={error?.response?.data?.item}
											placeholder={!formik.values.item && "Selecciona un item"}
										/>

										<Text>
											Si esta compra no pertenece a un item de tu inventario, añade un concepto
										</Text>
										<InputField
											isDisabled={formik.values.item}
											label='Concepto'
											name='concept'
											placeholder={formik.values.item && findItemLabel(formik.values.item)}
											required
											error={error?.response?.data?.quantity}
										/>
										<InputField
											label={`Cantidad ${findUnits(formik.values?.item)}`}
											name='quantity'
											required
											error={error?.response?.data?.quantity}
										/>
										<InputField
											label={
												<InfoTooltip
													label={
														<Flex align='center' gap='0.5em'>
															<Text>Precio unidad (€)</Text>
															<Switch
																colorScheme='brand'
																isChecked={formik.values.price_includes_tax}
																value={formik.values.price_includes_tax}
																onChange={() =>
																	formik.setFieldValue(
																		"price_includes_tax",
																		!formik.values.price_includes_tax
																	)
																}
															/>
														</Flex>
													}
													desc={"Activa la casilla si los precios ya incluyen IVA"}
												/>
											}
											name='price'
											required
											error={error?.response?.data?.price}
										/>
										<SelectField label='IVA' name='tax' choices={taxChoices} bg='white' />

										<InputField
											label='Fecha de compra'
											name='purchased_at'
											type='date'
											required
											error={error?.response?.data?.purchased_at}
										/>
										<SelectFieldFilter
											label='Proveedor'
											isDisabled={formik.values.item}
											placeholder={formik.values.item && findItemSupplier(formik.values.item)}
											options={suppliers?.results}
											name='supplier'
											error={error?.response?.data?.supplier}
										/>
									</VStack>
								</DrawerBody>
								<DrawerFooter>
									<Flex w='100%' align='center' gap='1rem'>
										<Text fontSize='18px' fontWeight='bold'>
											IVA: {taxes}€
										</Text>
										<Text fontSize='18px' fontWeight='bold'>
											Total: {total}€
										</Text>
									</Flex>
									<Flex justify='right' columnGap='3' mt='3'>
										<Button variant='white' onClick={onClose}>
											Cancelar
										</Button>
										<Button
											variant='primary'
											isDisabled={!formik.dirty}
											onClick={formik.handleSubmit}
											isLoading={isLoading}
										>
											Guardar
										</Button>
									</Flex>
								</DrawerFooter>
							</>
						);
					}}
				</Formik>
			</DrawerContent>
		</Drawer>
	);
}
