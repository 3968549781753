import { useState } from 'react'
import { Text, Flex, Image, Button } from '@chakra-ui/react'
// comps
import TutorialPagination from '../TutorialPagination'
// img
import step1 from '../../../img/tutorial/stock/Tutorial_Stock_1.png'
import step2 from '../../../img/tutorial/stock/Tutorial_Stock_2.png'
import step3 from '../../../img/tutorial/stock/Tutorial_Stock_3.png'
import step4 from '../../../img/tutorial/stock/Tutorial_Stock_4.png'
import andres from '../../../img/tutorial/Andres.png'

export default function StockTutorial ({ onClose }) {
    const [step, setStep] = useState(0)

    const slides = [
        {
            title: (
                <Flex flexWrap="wrap" justify="center">
                    <Text>¡Bienvenido a la sección de</Text>
                    <Text color="blue" fontWeight="bold" mx="0.2em">
                        Stock!
                    </Text>
                    <Text align="center">En la pestaña Inventario podrás añadir tu stock de productos.</Text>
                </Flex>
            ),
            image: step1
        },
        {
            title: (
                <Text align="center">
                    Introduce los campos de tu Stock, puedes elegir <span style={{ color: 'blue' }}>unidades</span> de
                    medida
                </Text>
            ),
            image: step2
        },
        {
            title: (
                <Text align="center">
                    Añade las compras que realizas, para que queden registradas, se añadan a tu inventario y se muestren
                    en las estadísticas del panel <span style={{ color: 'blue' }}>Ajustes</span>
                </Text>
            ),
            image: step3
        },
        {
            title: (
                <Text align="center">
                    Mantén cerca a tus <span style={{ color: 'blue' }}>proveedores </span>
                    de confianza y añádelos también a tu base.
                </Text>
            ),
            image: step4
        },
        {
            title: <Text>¡Empieza a componer tu inventario ahora!</Text>,
            image: andres
        }
    ]
    const slide = slides[step]
    return (
        <>
            <Flex direction="column" gap="1em" justify="center" align="center" p="1em" h="550px">
                {(step === 0 || step === slides.length - 1) && <Image src={andres} />}
                {slide.title}
                {step !== slides.length - 1 && <Image maxH="350px" src={slide.image} />}
                {step === slides.length - 1 && (
                    <Button onClick={onClose} variant="primary" mt="2em">
                        Terminar Guía
                    </Button>
                )}
            </Flex>
            <TutorialPagination step={step} slides={slides} setStep={setStep} />
        </>
    )
}
