import { useState, useMemo, useEffect } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Button,
    useDisclosure
} from '@chakra-ui/react'
// stripe
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// icons
import { BsArrowUpCircle } from 'react-icons/bs'
// api
import { createPayment } from '../../api/paymentsApi'
import { useMutation } from '@tanstack/react-query'
// comps
import StripeForm from './StripeForm'
import ProTag from '../common/ProTag'
import FeatureItem from '../pages/settings/plan/FeatureItem'
// auth
import { useAuthHeader } from 'react-auth-kit'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
export default function StripeModalButton ({ type, ...props }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [clientSecret, setClientSecret] = useState('')

    const authHeader = useAuthHeader()
    // create a payment intent, after that we can open the modal
    const { isLoading, mutate } = useMutation(['createPayment'], createPayment, {
        onSuccess: (data) => {
            setClientSecret(data.clientSecret)
            onOpen()
        },
        onError: (error) => {
            console.log(error)
        }
    })
    const stripeForm = useMemo(() => {
        const options = {
            clientSecret
        }
        return (
            <Elements stripe={stripePromise} options={options}>
                <StripeForm />
            </Elements>
        )
    }, [clientSecret])

    useEffect(() => {
        if (clientSecret) {
            onOpen()
        }
    }, [clientSecret, onOpen])

    const createPaymentIntent = () => {
        mutate({ token: authHeader() })
    }
    return (
        <>
            {type === 'green' && (
                <Button
                    my="1em"
                    w="100%"
                    size="md"
                    colorScheme="green"
                    isLoading={isLoading}
                    leftIcon={<BsArrowUpCircle color="white" size="20px" />}
                    alignSelf="center"
                    // isDisabled={true}
                    onClick={createPaymentIntent}
                >
                    Actualizar
                </Button>
            )}
            {type === 'blue' && <ProTag onClick={createPaymentIntent} {...props} />}
            {type === 'blueBig' && (
                <Button onClick={createPaymentIntent} {...props} variant="primary" rounded="full">
                    HAZTE PRO
                </Button>
            )}
            <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex w="100%" justify="space-evenly" py="2em">
                            {/* Information */}
                            <Flex direction="column" gap="1em" borderRight="1px solid #D9D7D7" pr="1em" maxW="50%">
                                <Text fontSize="18px" fontWeight="600" align="center">
                                    Accede a todas las funciones de Anothertool PRO
                                </Text>
                                <Text align="center" color="grey" fontSize="12px">
                                    Creemos que nuestras herramientas nuevas sin límites te van a encantar. Aprovecha
                                    Anotthertool al máximo y lleva tu taller al siguiente nivel.
                                </Text>
                                <FeatureItem text={'Órdenes de trabajo ILIMITADAS'} color="blue" />
                                <FeatureItem text={'Facturación ILIMITADA'} color="blue" />
                                <FeatureItem text={'Gestión de stock'} color="blue" />
                                <FeatureItem text={'Exportación historial del vehiculo'} color="blue" />
                                <FeatureItem text={'Estadísticas avanzadas'} color="blue" />
                                <FeatureItem text={'Gestión de coches en cola'} color="blue" />
                                <FeatureItem text={'Envío de presupuestos por mail'} color="blue" />
                                <Flex alignSelf="end" gap="0.5em">
                                    <Text fontSize="40px" fontWeight="600">
                                        25 €
                                    </Text>
                                    <Text fontSize="12px">/ mes</Text>
                                </Flex>
                            </Flex>
                            {stripeForm}
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
