import { createApi, getAll, getAllPag, createUpdate, deleteItem } from './baseApi'

const detailsApi = createApi('details')

export const createUpdateDetailImage = async (payload) => {
    let response = {}
    const headers = {
        Authorization: payload.token,
        'Content-Type': 'multipart/form-data'
    }
    if (payload.slug) {
        response = await detailsApi.put(`/${payload.slug}/`, payload.data, {
            headers
        })
    } else {
        response = await detailsApi.post('/', payload.data, {
            headers
        })
    }
    return response.data
}

export const deleteDetailImage = async (payload) => {
    return deleteItem(detailsApi, payload)
}
