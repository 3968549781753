import { Flex, Text } from "@chakra-ui/react";
// comps
import ComparisonPeriodDisplay from "../ComparisonPeriodDisplay";
import IncomeChart from "./IncomeChart";
//icons
import { BsBag } from "react-icons/bs";

export default function IncomeBox({ income, period, isLoading }) {
	return (
		<Flex direction='column' align='center' w='100%' h='100%' bg='white' px='2em' py='1em' rounded='xl'>
			<Flex w='100%' justify='space-between' mb='1rem'>
				<Flex gap='0.2rem' h='32px' align='center'>
					<BsBag size='18px' />
					<Text fontSize='22px' fontWeight='bold'>
						Ingresos
					</Text>
				</Flex>
				<Flex direction='column' align='end'>
					<Flex align='center' gap='1rem'>
						<Text color='gray'>TOTAL:</Text>
						<Text fontSize='26px' color='darkblue' fontWeight='600'>
							{income?.now}€
						</Text>
					</Flex>
					<ComparisonPeriodDisplay
						lastPeriod={income?.last_period}
						period={period}
						isFinancial
						isLoading={isLoading}
					/>
					<Flex align='center' gap='0.5rem' mt='0.4rem'>
						<Text color='gray' fontSize='14px'>
							Neto:
						</Text>
						<Text color='darkblue' fontWeight='semibold'>
							{income?.net}€
						</Text>
					</Flex>
					<Flex align='center' gap='0.5rem'>
						<Text color='gray' fontSize='14px'>
							IVA:
						</Text>
						<Text color='darkblue' fontWeight='semibold'>
							{income?.taxes}€
						</Text>
					</Flex>
				</Flex>
			</Flex>
			<IncomeChart data={income?.chart} />
		</Flex>
	);
}
