import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

import type { AxiosError } from "axios";

const useAuthQuery = <TData = unknown>(
	options: UseQueryOptions<TData, AxiosError>
): UseQueryResult<TData, AxiosError> => {
	const query = useQuery<TData, AxiosError>(options);
	const signOut = useSignOut();
	const navigate = useNavigate();

	if (query?.error?.response?.status === 401) {
		signOut();
		navigate("/login");
	}

	return query;
};

export default useAuthQuery;
