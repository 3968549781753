import { Text, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { styles } from "../PDFStyle";

export default function QuotePDFHeader({ quote, businessProfile, invoiceSettings }) {
	return (
		<>
			{/* Header */}
			<View style={styles.documentHeader}>
				<View
					style={{
						flexDirection: "column",
						gap: "10px",
						alignItems: "center"
					}}
				>
					{businessProfile?.banner_image && (
						<Image
							style={{ maxWidth: "120px", maxHeight: "120px", objectFit: "cover", borderRadius: "5px" }}
							cache={false}
							crossorigin='anonymous'
							src={businessProfile.banner_image}
						/>
					)}
					<Text style={{ fontSize: "12px" }}>{businessProfile?.name || "Nombre de tu centro"}</Text>
				</View>
				<View style={{ alignItems: "flex-end", gap: "5px" }}>
					<Text style={{ fontSize: "16px", marginBottom: "5px" }}>PRESUPUESTO</Text>
					<Text style={{ fontSize: "12px" }}>Nº Presupuesto: {quote?.quote_number}</Text>
					<Text style={{ color: "#737A87", fontSize: "10px" }}>Fecha: {moment(quote?.date).format("L")}</Text>
				</View>
			</View>

			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					width: "100%",
					marginTop: "5px",
					borderTop: "1px solid #F4F4F9",
					paddingTop: "10px"
				}}
			>
				<View style={{ flexDirection: "column", gap: "5px", fontSize: "10px", maxWidth: "45%" }}>
					<Text style={{ fontSize: "12px", color: "blue", marginBottom: "3px" }}>DE:</Text>
					<Text style={{ fontSize: "12px", fontWeight: "medium" }}>
						{invoiceSettings?.name || "Nombre de tu centro"}
					</Text>
					<Text>{businessProfile?.email || "Email de tu centro"}</Text>
					<Text>{businessProfile?.phone || "Teléfono del centro"}</Text>
				</View>
				<View
					style={{
						flexDirection: "column",
						gap: "5px",
						alignItems: "flex-end",
						fontSize: "10px",
						maxWidth: "45%"
					}}
				>
					<Text style={{ fontSize: "12px", color: "blue", marginBottom: "3px" }}>PARA:</Text>
					<Text style={{ fontSize: "12px", fontWeight: "medium" }}>{quote?.client?.name}</Text>
					{quote?.client?.address && <Text style={{ textAlign: "right" }}>{quote.client.address}</Text>}
					{quote?.client?.nif_cif && <Text>{quote.client.nif_cif} </Text>}
					{quote?.client?.email && <Text>{quote.client.email} </Text>}
					{quote?.client?.phone && <Text>{quote.client.phone?.split("+34")[1] || quote.client.phone}</Text>}
				</View>
			</View>
		</>
	);
}
