import { useEffect, useState } from 'react'
import { Button, Flex, Text, Heading, useToast, IconButton, InputGroup, InputRightElement, useMediaQuery } from '@chakra-ui/react'
import { useNavigate, useLocation } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

// Components
import NavLoginRegister from '../../components/navbar/NavLoginRegister'
// icons
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
// validation
import * as Yup from 'yup'
import { Formik } from 'formik'
import InputField from '../../components/common/forms/InputField'
// api
import { login, googleLogin } from '../../api/authApi'
import { useMutation } from '@tanstack/react-query'
// auth
import { useSignIn, useIsAuthenticated } from 'react-auth-kit'
import { userSignIn } from '../../utils'
// SocialAuth
import { GoogleLogin } from '@react-oauth/google'

export default function Login () {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const toast = useToast()
    const signIn = useSignIn()
    const isAuthenticated = useIsAuthenticated()
    const pathname = useLocation().pathname
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const [isMobile, isTablet, isDesktop] = useMediaQuery([
        '(max-width: 450px)',
        '(min-width: 768px) and (max-width: 1023px)',
        '(min-width: 1024px)'
    ])
    useEffect(() => {
        if (isAuthenticated()) {
            navigate('/dashboard')
        }
    })

    const { isLoading, mutate, error } = useMutation(['login'], login, {
        onSuccess: (token) => userSignIn(token, toast, signIn, navigate),
        onError: () => {
            toast({
                title: '!Ups, Ha habido un error!',
                status: 'error'
            })
        }
    })
    const { mutate: gLogin, isLoading: gLoading, error: gError } = useMutation(['googleLogin'], googleLogin, {
        onSuccess: (token) => {
            userSignIn(token, toast, signIn, navigate)
        },
        onError: () => {
            toast({
                title: '!Ups, Ha habido un error!',
                status: 'error'
            })
        }
    })

    const onGSuccess = async (res) => {
        const { email, name, picture } = jwt_decode(res.credential)
        const device = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'
        gLogin({
            email,
            name,
            picture,
            device
        })
    }
    const handleLogin = (values) => {
        const payload = values
        const device = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'
        payload.device = device
        payload.email = payload.email.toLowerCase()
        mutate(payload)
    }

    const LoginSchema = Yup.object({
        password: Yup.string().required('Es obligatoria'),
        email: Yup.string().email('Formato de email inválido').required('Campo obligatorio')
    })

    return (
        <Flex w="100%" h="100vh" direction="column" bg="lightgrey">
            <NavLoginRegister path={pathname} />

            <Flex justify="center" align="center" w="100%">
                <Flex
                    py={!isMobile ? '4em' : '0.5em'}
                    w={!isMobile ? '550px' : '90%'}
                    direction="column"
                    align="center"
                    gap="5"
                >
                    <Flex
                        bg="white"
                        w="100%"
                        mt={isMobile && '1.5em'}
                        rounded="xl"
                        direction="column"
                        align="center"
                        gap="6"
                        py="12%"
                        boxShadow="lg"
                    >
                        {!isMobile
                            ? (
                                <Flex mb="1em">
                                    <Heading fontSize="24px"> Inicia sesión en</Heading>
                                    <Heading fontSize="24px" ml="0.4em">
                                    another
                                    </Heading>
                                    <Heading ml="1px" fontSize="24px" color="blue">
                                    tool
                                    </Heading>
                                </Flex>
                            )
                            : (
                                <Heading fontSize="22px"> Iniciar sesión</Heading>
                            )}

                        <GoogleLogin
                            clientId={clientId}
                            text="signin"
                            onSuccess={onGSuccess}
                            // onFailure={console.log('Fallo en googleLogin plugin from home')}
                            onScriptLoadFailure={() => console.log('Cargando')}
                            width={!isMobile ? '600px' : '100%'}
                            theme="outline"
                            size="large"
                            logo_alignment="left"
                            context="signin"
                            type="standard"
                            isSignedIn={true}
                        />

                        <Text fontSize="14px" fontWeight="400" alignSelf="center" color="#6b7280">
                            o inicia sesión con
                        </Text>

                        <Formik
                            initialValues={{
                                email: '',
                                password: ''
                            }}
                            validationSchema={LoginSchema}
                            onSubmit={(values) => handleLogin(values)}
                        >
                            {(formik) => (
                                <Flex
                                    direction="column"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            formik.handleSubmit()
                                        }
                                    }}
                                    as="form"
                                    w="80%"
                                    justify="space-around"
                                    align="center"
                                    gap="3"
                                >
                                    <InputField
                                        name="email"
                                        placeholder="Introduce tu correo electrónico"
                                        label="Email"
                                    />
                                    <InputGroup>
                                        <InputField
                                            type={show ? 'text' : 'password'}
                                            name="password"
                                            placeholder="Contraseña"
                                            label="Contraseña"
                                            error={error?.response?.data?.detail}
                                        />
                                        <InputRightElement mt="2em">
                                            <IconButton
                                                bg="transparent"
                                                color="blackAlpha.600"
                                                onClick={() => setShow(!show)}
                                                icon={
                                                    show
                                                        ? (
                                                            <AiFillEyeInvisible size="25px" />
                                                        )
                                                        : (
                                                            <AiFillEye size="25px" />
                                                        )
                                                }
                                            />
                                        </InputRightElement>
                                    </InputGroup>
                                    {(error || gError) &&
                                        <Text color='red' mt='1em' fontSize='18px'>
                                            {error?.response?.data?.message || gError?.response?.data?.message}
                                        </Text>
                                    }
                                    <Button
                                        mt="8"
                                        variant="primary"
                                        size="md"
                                        w="100%"
                                        onClick={formik.handleSubmit}
                                        isLoading={isLoading || gLoading}
                                        isDisabled={!formik.dirty}
                                    >
                                        Iniciar Sesión
                                    </Button>
                                    {error &&
                                        <Text color='red' >
                                            {error?.response?.data?.error}
                                        </Text>
                                    }
                                </Flex>
                            )}
                        </Formik>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
