import { Box, Badge, Tooltip } from "@chakra-ui/react";
//types
import type { PriceDuration } from "../../../../types/service";

interface OrderCardServiceTagsProps {
	services: PriceDuration[];
}

export default function OrderCardServiceTags({ services }: OrderCardServiceTagsProps) {
	if (services) {
		return (
			<Box gap='10px' maxW='100%' overflowWrap='break-word' overflow='hidden'>
				{services.map((service) => (
					<Tooltip
						key={service.id}
						label={service.name}
						bg={service.color}
						color='black'
						rounded='lg'
						hasArrow
					>
						<Badge
							bg={service.color}
							maxW='220px'
							cursor={"pointer"}
							overflow='hidden'
							whiteSpace='nowrap'
							textOverflow='ellipsis'
							color='black.900'
							px='5px'
							rounded='full'
							mb='10px'
						>
							{service.name} ({service.size})
						</Badge>
					</Tooltip>
				))}
			</Box>
		);
	}
	return <></>;
}
