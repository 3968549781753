import { useEffect } from "react";
import { Flex, Grid, GridItem, useToast, useDisclosure, useMediaQuery } from "@chakra-ui/react";
// comps
import PendingInvoicesStatBox from "../../components/pages/analytics/incomeExpenses/PendingInvoicesStatBox";
import ComparisonStatBox from "../../components/pages/analytics/ComparisonStatBox";
import HelperModal from "../../components/tutorial/HelperModal";
import Onboarding from "../../components/tutorial/onboarding/Onboarding";
import DirectPaymentModal from "../Payments/DirectPaymentModal";
import MyCalendar from "../../components/pages/dashboard/calendar/MyCalendar";
import OrderRequestCardList from "../../components/pages/orders/lists/OrderRequestCardList";
import LatestOrderCardList from "../../components/pages/orders/lists/LatestOrderCardList";
// icons
import { TbFileInvoice } from "react-icons/tb";
import { FaEuroSign } from "react-icons/fa";
import { BsSticky } from "react-icons/bs";
// api
import useAuthFetch from "../../myHooks/useAuthFetch";
import { updateOrderStatus } from "../../api/ordersApi";
import { getDashboardStats } from "../../api/statsApi";
import useAuthQuery from "../../myHooks/useAuthQuery";
import { useQueryClient } from "@tanstack/react-query";
// auth
import { useAuthHeader, useAuthUser } from "react-auth-kit";

export default function Dashboard() {
	const authHeader = useAuthHeader();
	const authUser = useAuthUser();
	const QueryClient = useQueryClient();
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const { data } = useAuthFetch(["dashboardStats"], getDashboardStats);

	useAuthQuery({
		queryKey: ["updateOrderStatus"],
		queryFn: () => updateOrderStatus(authHeader()),
		onSuccess: (data) => {
			if (data.started) {
				toast({ title: data.started, status: "success" });
				QueryClient.invalidateQueries(["startedorders"]);
				QueryClient.refetchQueries("startedorders", { force: true });
			}
			if (data.completed) {
				QueryClient.invalidateQueries(["completedorders"]);
				QueryClient.refetchQueries("completedorders", { force: true });
				toast({ title: data.completed, status: "success" });
			}
			if (data.waiting) {
				QueryClient.invalidateQueries(["waitingorders"]);
				QueryClient.refetchQueries("waitingorders", { force: true });
				toast({ title: data.waiting, status: "success" });
			}
		}
	});
	useEffect(() => {
		if (!authUser()?.has_seen_tutorial) {
			onOpen();
		}
		//onOpen();
	}, []);
	const period = "month";
	if (!isMobile) {
		return (
			<>
				<Grid p='1em' w='100%' h='92vh' templateRows='repeat(5, 1fr)' templateColumns='repeat(4, 1fr)' gap={6}>
					<GridItem rowSpan={5} colSpan={1}>
						<OrderRequestCardList />
					</GridItem>
					<GridItem rowSpan={5} colSpan={1}>
						<LatestOrderCardList />
					</GridItem>
					<GridItem rowSpan={5} colSpan={2}>
						<MyCalendar />
					</GridItem>
					{/*
                    <GridItem colSpan={1}>
                        <ComparisonStatBox
                            title="Ordenes"
                            icon={<BsSticky size="25px" />}
                            data={data?.orders}
                            period={period}
                        />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <ComparisonStatBox
                            title="Ingresos"
                            icon={<FaEuroSign size="25px" />}
                            data={data?.income}
                            period={period}
                            isFinancial
                        />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <PendingInvoicesStatBox
                            title="Pendiente de pago"
                            icon={<TbFileInvoice size="25px" />}
                            data={data?.pending}
                            period={period}
                        />
                    </GridItem>
                    */}
				</Grid>
				<HelperModal />
				<Onboarding isOpen={isOpen} onClose={onClose} />
			</>
		);
	}
	return (
		<>
			<Flex direction='column' gap='2em' px='1em' py='2em'>
				<OrderRequestCardList />
				<MyCalendar />
				<LatestOrderCardList />
				<ComparisonStatBox
					title='Ordenes'
					icon={<BsSticky size='25px' />}
					data={data?.orders}
					period={period}
				/>
				<ComparisonStatBox
					title='Ingresos'
					icon={<FaEuroSign size='25px' />}
					data={data?.income}
					period={period}
					isFinancial
				/>
				<PendingInvoicesStatBox
					title='Pendiente de pago'
					icon={<TbFileInvoice size='25px' />}
					data={data?.pending}
					period={period}
				/>
			</Flex>
			<Onboarding isOpen={isOpen} onClose={onClose} />
		</>
	);
}
