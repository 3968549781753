import { useState, useRef } from "react";
import {
	useDisclosure,
	useMediaQuery,
	useToast,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	Flex,
	Text,
	Input,
	Checkbox
} from "@chakra-ui/react";
import moment from "moment";
import SignatureCanvas from "react-signature-canvas";
//api
import { useQueryClient } from "@tanstack/react-query";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { createDepositReceiptSignature } from "../../../../api/depositReceiptApi";

export default function DepositReceiptSignatureModal({ depositReceipt }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const { isOpen, onOpen, onClose } = useDisclosure();
	const QueryClient = useQueryClient();
	const toast = useToast();
	const [name, setName] = useState();
	const [readTerms, setReadTerms] = useState(false);
	const [signedAt, setSignedAt] = useState(moment().format("YYYY-MM-DDTHH:mm"));

	const [signatureData, setSignatureData] = useState(null); // Store signature data
	const canvasRef = useRef(null); // Reference to the canvas element

	const handleSignatureComplete = () => {
		console.log("calling this function");
		// Get the signature image data as a PNG base64 string
		const signatureImage = canvasRef.current.toDataURL("image/png");
		setSignatureData(signatureImage);
	};

	/* a function that uses the canvas ref to clear the canvas
    via a method given by react-signature-canvas */
	const clear = () => {
		setSignatureData(null);
		canvasRef.current.clear();
	};

	/* a function that uses the canvas ref to trim the canvas
    from white spaces via a method given by react-signature-canvas
    then saves it in our state */
	const save = () => {
		setSignatureData(canvasRef.current.getTrimmedCanvas().toDataURL("image/png"));
	};

	const disableSubmit = () => {
		if (!signatureData || !name || !signedAt || !readTerms) return true;
		return false;
	};

	const { isLoading, mutate } = useAuthMutation({
		mutationFn: createDepositReceiptSignature,
		onSuccess: () => {
			toast({ title: "Deposito firmado con exito!", status: "success" });
			QueryClient.invalidateQueries(["depositReceipt", depositReceipt.id]);
			QueryClient.refetchQueries(["depositReceipt", depositReceipt.id], { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
			console.log(error);
		}
	});

	const dataURLtoFile = (dataurl, filename) => {
		const [mimePart, dataPart] = dataurl.split(",");
		const mime = mimePart.match(/:(.*?);/)[1];
		const bstr = atob(dataPart);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	};

	const handleSubmit = () => {
		const signatureFile = dataURLtoFile(signatureData, "signature.png");

		const payload = {
			name: name,
			signed_at: signedAt,
			signature_image: signatureFile,
			deposit_receipt: depositReceipt.id
		};
		mutate(payload);
	};
	if (!isMobile) {
		return (
			<>
				<Button variant='primary' justifySelf='center' onClick={onOpen} alignSelf='center' w='100%' mt='3rem'>
					FIRMAR
				</Button>
				<Modal isOpen={isOpen} onClose={onClose} size='xl'>
					<ModalOverlay />
					<ModalContent bg='white'>
						<ModalHeader>Firmar resguardo</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Flex mt='1rem' w='380px' p='0.2rem' h='200px' borderRadius='8px' border='1px solid black'>
								<SignatureCanvas
									ref={canvasRef}
									penColor='black'
									backgroundColor='white'
									canvasProps={{
										width: "350px",
										height: "200px",
										className: "sigCanvas"
									}}
									onSignatureComplete={handleSignatureComplete}
								/>
								<Flex direction='column' ml='4em' gap='1em'>
									<Button onClick={save} variant='white' isDisabled={signatureData}>
										Guardar
									</Button>
									<Button onClick={clear} variant='danger' isDisabled={!signatureData}>
										Borrar
									</Button>
								</Flex>
							</Flex>
							<Flex direction='column'>
								<Flex direction='column' mt='2em' gap='0.5em'>
									<Text fontWeight='bold'>Nombre Completo</Text>
									<Input onChange={(e) => setName(e.target.value)} />
								</Flex>
								<Flex direction='column' mt='1em' gap='0.5em'>
									<Text fontWeight='bold'>Fecha y Hora</Text>
									<Input
										type='datetime-local'
										value={signedAt}
										onChange={(e) => setSignedAt(e.target.value)}
									/>
								</Flex>
								<Checkbox
									mt='2em'
									color='black'
									value={readTerms}
									onChange={() => setReadTerms(!readTerms)}
								>
									Confirmo que los datos son correctos y estoy conforme.
								</Checkbox>
							</Flex>
						</ModalBody>
						<ModalFooter justifyContent='center'>
							<Button
								variant='primary'
								isLoading={isLoading}
								onClick={handleSubmit}
								isDisabled={disableSubmit()}
							>
								FIRMAR
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</>
		);
	}
	return (
		<>
			<Button variant='primary' justifySelf='center' onClick={onOpen} alignSelf='center' w='100%' mt='3rem'>
				FIRMAR
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size='full'>
				<ModalOverlay />
				<ModalContent bg='white'>
					<ModalHeader>Firmar resguardo</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex direction='column' mt='1rem' p='0.2rem' borderRadius='8px' border='1px solid black'>
							<SignatureCanvas
								ref={canvasRef}
								penColor='black'
								backgroundColor='white'
								canvasProps={{
									width: "100%",
									height: "100px",
									className: "sigCanvas"
								}}
								onSignatureComplete={handleSignatureComplete}
							/>
						</Flex>
						<Flex gap='1rem' mt='1.5rem' justify='end'>
							<Button onClick={clear} variant='danger' isDisabled={!signatureData}>
								Borrar
							</Button>
							<Button onClick={save} variant='white' isDisabled={signatureData}>
								Guardar firma
							</Button>
						</Flex>
						<Flex direction='column'>
							<Flex direction='column' mt='2em' gap='0.5em'>
								<Text fontWeight='bold'>Nombre Completo</Text>
								<Input onChange={(e) => setName(e.target.value)} />
							</Flex>
							<Flex direction='column' mt='1em' gap='0.5em'>
								<Text fontWeight='bold'>Fecha y Hora</Text>
								<Input
									type='datetime-local'
									value={signedAt}
									onChange={(e) => setSignedAt(e.target.value)}
								/>
							</Flex>
							<Checkbox
								mt='2em'
								color='black'
								value={readTerms}
								onChange={() => setReadTerms(!readTerms)}
							>
								Confirmo que los datos son correctos y estoy conforme.
							</Checkbox>
						</Flex>
					</ModalBody>
					<ModalFooter justifyContent='center'>
						<Button
							variant='primary'
							isLoading={isLoading}
							onClick={handleSubmit}
							isDisabled={disableSubmit()}
						>
							FIRMAR
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
