import {
	Button,
	useToast,
	Flex,
	Text,
	Drawer,
	DrawerHeader,
	DrawerBody,
	DrawerOverlay,
	DrawerContent,
	DrawerFooter,
	DrawerCloseButton
} from "@chakra-ui/react";
// icons
import { PiChatTeardropTextBold } from "react-icons/pi";
import { MdOutlineWebAsset } from "react-icons/md";
// comps
import PriceDurationItems from "./PriceDurationItems";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../../common/forms/InputField";
import ColorSelect from "./ColorSelect";
import TextareaField from "../../../common/forms/TextareaField";
import SelectField from "../../../common/forms/SelectField";
import SwitchField from "../../../common/forms/SwitchField";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateService, getServiceOptions } from "../../../../api/servicesApi";

export default function ServiceFormMobile({ onClose, isOpen, item: service }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data } = useAuthFetch(["serviceOptions"], getServiceOptions);
	const serviceCategoryOptions = data?.actions?.POST?.category?.choices;
	const servicePriorityOptions = data?.actions?.POST?.priority?.choices;

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateService,
		onSuccess: () => {
			toast({ title: "Servicio creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["services"]);
			QueryClient.refetchQueries("services", { force: true });
			onClose();
		},
		onError: () => {
			toast({
				title: "Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const initialValues = {
		name: service?.name || "",
		color: service?.color || "grey",
		description: service?.description || "",
		what_includes: service?.what_includes || "",
		category: service?.category || "",
		priority: service?.priority || "",
		is_featured: service?.is_featured || false,
		is_visible: service?.is_visible || false,
		more_information_url: service?.more_information_url || "",
		prices:
			service?.prices?.length > 0
				? service.prices
				: [{ price: 0, duration: 0, size: "M", price_type: "fixed", discounted_price: undefined }]
	};
	const validationSchema = Yup.object({
		name: Yup.string().required("El nombre es obligatorio"),
		color: Yup.string(),
		more_information_url: Yup.string().url("El formato de la URL no es válido"),
		prices: Yup.array()
			.required("El servicio debe tener al menos un precio")
			.of(
				Yup.object().shape({
					price: Yup.number().required("Precio obligatorio"),
					discounted_price: Yup.number(),
					duration: Yup.number().required("Introduce precio"),
					size: Yup.string(),
					price_type: Yup.string()
				})
			)
	});
	const submit = (values) => {
		const payload = service
			? { data: { ...values }, slug: service.id, token: authHeader() }
			: { data: { ...values }, token: authHeader() };

		mutate(payload);
	};

	return (
		<>
			<Drawer isOpen={isOpen} onClose={onClose} size='full'>
				<DrawerOverlay />
				<DrawerContent bg='white' maxH='95dvh'>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => submit(values)}
					>
						{(formik) => (
							//console.log(formik.values),
							<>
								<DrawerHeader>
									<Text> {service ? "Editar" : "Crear"} Servicio </Text>
								</DrawerHeader>
								<DrawerCloseButton />
								<DrawerBody>
									<Flex direction='column' fontSize='14px' gap='1rem'>
										<InputField
											label='Nombre'
											name='name'
											required
											placeholder='Ej: Limpieza integral'
											error={error?.response?.data?.name}
										/>
										<ColorSelect formik={formik} />
										<SelectField
											label='Categoría'
											name='category'
											placeholder='Elige un categoría'
											choices={serviceCategoryOptions}
										/>
										<SwitchField name='is_featured' label='Destacado' />
										<SwitchField name='is_visible' label='Visible en perfil' />
										<SelectField
											label='Prioridad'
											name='priority'
											placeholder='Prioridad en orden'
											choices={servicePriorityOptions}
										/>
										<TextareaField
											label='Descripción'
											icon={<PiChatTeardropTextBold size='20px' color='black' />}
											name='description'
										/>
										<TextareaField
											label='¿Qué Incluye?'
											icon={<PiChatTeardropTextBold size='20px' color='black' />}
											name='what_includes'
										/>

										<InputField
											placeholder='https://'
											name='more_information_url'
											label='URL Más Información'
											icon={<MdOutlineWebAsset size='20px' color='black' />}
										/>
									</Flex>
									<PriceDurationItems formik={formik} />
								</DrawerBody>
								<DrawerFooter>
									<Flex justify='right' columnGap='3' mt='3'>
										<Button variant='white' onClick={onClose}>
											Cancelar
										</Button>
										<Button
											variant='primary'
											isDisabled={!formik.dirty}
											onClick={formik.handleSubmit}
											isLoading={isLoading}
										>
											Guardar
										</Button>
									</Flex>
								</DrawerFooter>
							</>
						)}
					</Formik>
				</DrawerContent>
			</Drawer>
		</>
	);
}
