import { useState } from "react";
import {
	useMediaQuery,
	useToast,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Drawer,
	DrawerHeader,
	DrawerBody,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton
} from "@chakra-ui/react";

// comps
import InvoiceForm from "./InvoiceForm";
import InvoiceFormMobile from "./InvoiceFormMobile";
//fns
import { calculateTotalAndTax } from "../fns";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { getAllClients } from "../../../../api/clientsApi";
import { getInvoiceOptions, createUpdateInvoice } from "../../../../api/invoicesApi";
// auth
import { useAuthHeader, useAuthUser } from "react-auth-kit";

export default function InvoiceModal({ item: invoice, order, isOpen, onClose }) {
	const authHeader = useAuthHeader();
	const auth = useAuthUser();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const QueryClient = useQueryClient();
	const toast = useToast();

	const [total, setTotal] = useState(0);
	const [taxes, setTaxes] = useState(0);
	const [impositiveBase, setImpositiveBse] = useState(0);
	const [subtotal, setSubtotal] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [withTaxes, setWithTaxes] = useState(true);
	const [payedInCash, setPayedInCash] = useState(invoice ? invoice.payed_in_cash : false);
	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateInvoice,
		onSuccess: () => {
			toast({ title: "Factura actualizada!", status: "success" });
			QueryClient.invalidateQueries(["invoices"]);
			QueryClient.refetchQueries("invoices", { force: true });
			onClose();
		},
		onError: () => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const { data: clients } = useAuthFetch(["clients"], getAllClients, { enabled: !!isOpen });
	const { data: options } = useAuthFetch(["invoiceOptions"], getInvoiceOptions, { enabled: !!isOpen });
	const createPayload = (values) => {
		return {
			data: values,
			...(invoice ? { slug: invoice.id } : {}),
			token: authHeader()
		};
	};
	const submit = (values) => {
		const payload = createPayload(values);
		payload.data.total = total;
		payload.data.taxes = taxes;
		payload.data.payed_in_cash = payedInCash;
		mutate(payload);
	};
	const calculateTotals = (values, order) => {
		const items = values.items || [];
		let subtotal = 0; // Es la base imponible previa al descuento
		const taxMultiplier = values.tax === "ten" ? 0.1 : values.tax === "twenty" ? 0.21 : 0;
		const priceIncludesTax = values.price_includes_tax;
		const hasStockUsed = order?.stock_used?.length > 0 || invoice?.stock_used?.length > 0;
		// Calcular subtotal
		for (const item of items) {
			subtotal += item.price * item.quantity;
		}
		if (hasStockUsed || values.quote?.stock_used?.length > 0) {
			const stock_used = invoice?.stock_used || order?.stock_used || values.quote.stock_used;
			const total_stock = stock_used.reduce((prev, curr) => prev + curr.total, 0);
			subtotal += total_stock;
		}
		// Ajustar subtotal si incluye impuestos
		if (priceIncludesTax) {
			subtotal /= 1 + taxMultiplier;
		}

		// Calcular descuento
		const percentageDiscount = values.discount / 100; // Viene en %
		const totalDiscount = subtotal * percentageDiscount;
		// Calcular base imponible después del descuento
		const impositiveBase = subtotal - totalDiscount;
		// Calcular total y taxes usando la función auxiliar, nunca incluye impuestos, porque ya le paso la bse imponible
		const { total, taxes } = calculateTotalAndTax(false, values.tax, impositiveBase);

		// Actualizar los estados con los valores calculados
		setTotal(total.toFixed(2));
		setImpositiveBse(impositiveBase.toFixed(2));
		setSubtotal(subtotal.toFixed(2));
		setTaxes(taxes.toFixed(2));
		setDiscount(totalDiscount.toFixed(2));
	};
	const isFree = auth()?.plan === "FREE";

	if (!isMobile) {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size='6xl'>
				<ModalOverlay />
				<ModalContent bg='white' maxH='95dvh'>
					<ModalHeader>
						<Text>{invoice ? "Editar" : "Crear"} Factura</Text>
					</ModalHeader>
					<ModalCloseButton />

					<ModalBody>
						<InvoiceForm
							submit={submit}
							clients={clients}
							invoice={invoice}
							options={options}
							isLoading={isLoading}
							order={order}
							payedInCash={payedInCash}
							setPayedInCash={setPayedInCash}
							calculateTotals={calculateTotals}
							total={total}
							subtotal={subtotal}
							taxes={taxes}
							impositiveBase={impositiveBase}
							discount={discount}
							isFree={isFree}
							error={error}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		);
	}
	return (
		<Drawer size='full' isOpen={isOpen} placement='right' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>{invoice ? "Editar" : "Crear"} Factura</DrawerHeader>
				<DrawerCloseButton />
				<DrawerBody>
					<InvoiceFormMobile
						submit={submit}
						clients={clients}
						invoice={invoice}
						options={options}
						isLoading={isLoading}
						order={order}
						payedInCash={payedInCash}
						setPayedInCash={setPayedInCash}
						withTaxes={withTaxes}
						setWithTaxes={setWithTaxes}
						calculateTotals={calculateTotals}
						total={total}
						subtotal={subtotal}
						taxes={taxes}
						discount={discount}
						isFree={isFree}
						error={error}
					/>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
}
