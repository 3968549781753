import {
	IconButton,
	useDisclosure,
	Text,
	Flex,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	PopoverAnchor
} from "@chakra-ui/react";
//icons
import { IoEyeOutline } from "react-icons/io5";

interface DetailPopover {
	net: number;
	taxes: number;
}

export default function DetailPopover({ net, taxes }: DetailPopover) {
	const { onOpen, onClose, isOpen } = useDisclosure();

	return (
		<Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement='right-start' closeOnBlur={false}>
			<PopoverTrigger>
				<IconButton
					bg='transparent'
					aria-label='detail-view'
					onClick={onOpen}
					icon={<IoEyeOutline color='blue' />}
				/>
			</PopoverTrigger>
			<PopoverContent w='200px'>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader color='darkblue'>Beneficios</PopoverHeader>
				<PopoverBody>
					<Flex justify='space-between' w='100%'>
						<Text>Total neto:</Text>
						<Text color='darkblue' fontWeight='semibold'>
							{net}€
						</Text>
					</Flex>
					<Flex justify='space-between' w='100%'>
						<Text>IVA:</Text>
						<Text color='darkblue' fontWeight='semibold'>
							{taxes}€
						</Text>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
}
