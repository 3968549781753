import { useParams } from "react-router-dom";
import { Flex, Button, useMediaQuery } from "@chakra-ui/react";
//types
import type { BusinessServiceCategory } from "../../../../../types/business";
//api
import { getAvailableServiceCategories } from "../../../../../api/businessesApi";
import { useQuery } from "@tanstack/react-query";

interface BusinessServiceFilterProps {
	filter: string;
	setFilter: (filter: string) => void;
}

export default function BusinessServiceFilter({ filter, setFilter }: BusinessServiceFilterProps) {
	const { workshop } = useParams();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const { data } = useQuery<BusinessServiceCategory[]>({
		queryKey: ["availableCategories", workshop],
		queryFn: () => getAvailableServiceCategories({ slug: workshop })
	});
	if (!isMobile) {
		return (
			<Flex minW='50%' maxW='60%' gap='0.5rem' flexWrap='wrap'>
				{data?.map((category: BusinessServiceCategory) => (
					<Button
						key={category.value}
						fontSize={!isMobile ? "12px" : "10px"}
						rounded='xl'
						py='0em'
						px='0.5rem'
						variant={filter === category.value ? "primary" : "white"}
						onClick={() => setFilter(category.value)}
					>
						{category.display} ({category.count})
					</Button>
				))}
			</Flex>
		);
	}
	return (
		<Flex w='100%' gap='0.5rem' flexWrap='wrap'>
			{data?.map((category: BusinessServiceCategory) => (
				<Button
					key={category.value}
					fontSize={!isMobile ? "12px" : "10px"}
					rounded='xl'
					py='0em'
					px='0.5rem'
					variant={filter === category.value ? "primary" : "white"}
					onClick={() => setFilter(category.value)}
				>
					{category.display} ({category.count})
				</Button>
			))}
		</Flex>
	);
}
