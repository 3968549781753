import { useState, useMemo } from "react";
import { useDisclosure, Flex, IconButton, Tr, Td } from "@chakra-ui/react";
// comps
import ResponsiveTable from "../../../common/Tables/ResponsiveTable";
import PopoverDelete from "../../../common/PopoverDelete";
import SearchInput from "../../../common/Tables/SearchInput";
// icons
import { AiOutlineEye } from "react-icons/ai";
// img
import emptyCars from "../../../../img/data/emptyCars.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getAllDepositReceiptsPag, deleteDepositReceipt } from "../../../../api/depositReceiptApi";

export default function DepositReceiptsTablePag() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [receipt, setReceipt] = useState();
	const [searchQuery, setSearchQuery] = useState("");

	const { data, isLoading } = useAuthFetch(
		["depositReceipts", page, searchQuery],
		getAllDepositReceiptsPag,
		page,
		null,
		true,
		searchQuery
	);
	const getHasSignature = (receipt) => {
		if (receipt.signature) {
			return `Firmado por ${receipt.signature.name}`;
		}
		return "No firmado";
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((receipt) => (
				<Tr key={receipt.id}>
					<Td>RD{receipt.incremental_id}</Td>
					<Td> {receipt.client_name} </Td>
					<Td> {getHasSignature(receipt)} </Td>
					<Td>
						<Flex gap='1em' key={receipt.id}>
							<IconButton
								bg='transparent'
								icon={<AiOutlineEye size='20px' color='blue' />}
								onClick={() => window.open(`/resguardo-deposito/${receipt.id}`, "_blank")}
							/>
							<PopoverDelete
								deleteKey={["deleteDepositReceipt"]}
								deleteFn={deleteDepositReceipt}
								slug={receipt?.id}
								refetchKey={["depositReceipts"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data, onOpen]
	);

	const isEmpty = data?.count < 1;
	return (
		<>
			<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
			<ResponsiveTable
				paginated
				withoutButton
				searchQuery={searchQuery}
				data={data}
				tableData={tableData}
				isEmpty={isEmpty}
				onOpen={() => {
					setReceipt();
					onOpen();
				}}
				onClose={onClose}
				page={page}
				setPage={setPage}
				isLoading={isLoading}
				item={{
					label: "Resguardo Depósito",
					headers: [
						{ key: "incremental_id", label: "ID", roundedLeft: "lg" },
						{ key: "client_name", label: "Cliente" },
						{ key: "signature", label: "Firma" }
					],
					isOpen
				}}
				emptyImage={emptyCars}
			/>
		</>
	);
}
