import { useQuery } from "@tanstack/react-query";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
// auth
import { useAuthHeader } from "react-auth-kit";

const useAuthFetch = (queryKey, queryFn, page, filter, enabled, searchQuery, ...props) => {
	const authHeader = useAuthHeader();
	const signOut = useSignOut();
	const navigate = useNavigate();

	return useQuery({
		queryKey,
		queryFn: () => queryFn({ token: authHeader(), page, filter, searchQuery }),
		enabled,
		...props,
		onError: (error) => {
			if (error?.response?.status === 401) {
				signOut();
				navigate("/login");
			}
		}
	});
};

export default useAuthFetch;
