import { Flex, Text, Image, Grid, Skeleton, GridItem, useMediaQuery } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import moment from "moment";
//comps
import LoadingOverlay from "../../components/common/loading/LoadingOverlay";
import DepositReceiptSignatureModal from "../../components/pages/depositReceipt/form/DepositReceiptSignatureModal";
//fns
import { parseDuration } from "../../components/common/functions";
//icons
import { BsShop } from "react-icons/bs";
import { BiCar } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { MdCleaningServices } from "react-icons/md";
//types
import type { PriceDuration } from "../../types/service";
import type { DepositReceiptLive, DepositReceiptObservation } from "../../types/depositReceipt";
// img
import carParts from "../../img/depositReceipt/carParts.png";
// api
import { getDepositReceipt } from "../../api/depositReceiptApi";
import { useQuery } from "@tanstack/react-query";

export default function DepositReceipt() {
	const { receiptId } = useParams();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const { data, isLoading } = useQuery<DepositReceiptLive>({
		queryKey: ["depositReceipt", receiptId],
		queryFn: () => getDepositReceipt({ slug: receiptId })
	});
	const depositReceipt = data?.deposit_receipt;
	const order = data?.order;
	const client = data?.client;
	const business = data?.business;
	const invoiceSettings = data?.invoice_settings;
	const hasObservations: boolean = (depositReceipt && depositReceipt.observations.length > 0) || false;
	const hasServices: boolean = (order && order?.services?.length > 0) || false;
	const hasSignature = depositReceipt?.signature;
	const hasBusinessSignature = invoiceSettings?.signature;
	const contentWidth = { base: "100%", md: "700px", lg: "800px", xl: "1080px", "2xl": "1280px" };
	const gridItemMaxH = "250px";

	if (!isMobile) {
		return (
			<>
				<Flex minH='100dvh' w='100%' bg='#F4F4F9' justify='center' py='3rem'>
					<Grid width={contentWidth} maxH='100dvh' templateColumns='repeat(3, 1fr)' gap={4}>
						<GridItem colSpan={1} rowSpan={1} bg='white' rounded='xl' p='1rem' maxH={gridItemMaxH}>
							<Flex direction='column'>
								<Flex align='end' gap='0.5rem' mb='0.5rem'>
									<BsShop color='#0258FF' size='25px' />
									<Text fontWeight='bold'>Taller</Text>
								</Flex>
								{isLoading ? (
									<Skeleton height='20px' />
								) : (
									business && (
										<>
											<Text> {business.name} </Text>
											<Text> {business.address?.formatted_address} </Text>
											<Text> {business.phone} </Text>
											<Text> {business.email} </Text>
										</>
									)
								)}
								{isLoading ? (
									<Skeleton height='20px' />
								) : (
									invoiceSettings?.CIF && <Text> {invoiceSettings.CIF} </Text>
								)}
							</Flex>
						</GridItem>

						<GridItem colSpan={1} rowSpan={1} bg='white' rounded='xl' p='1rem' maxH={gridItemMaxH}>
							<Flex direction='column'>
								<Flex align='end' gap='0.5rem' mb='0.5rem'>
									<FiUser color='#0258FF' size='25px' />
									<Text fontWeight='bold'>Cliente</Text>
								</Flex>
								{isLoading ? (
									<>
										<Skeleton height='20px' />
										<Skeleton height='20px' />
										<Skeleton height='20px' />
										<Skeleton height='20px' />
									</>
								) : (
									client && (
										<>
											<Text> {client.name} </Text>
											{client.email && <Text> {client.email} </Text>}
											{client.phone && <Text> {client.phone} </Text>}
											{client.nif_cif && <Text> {client.nif_cif} </Text>}
										</>
									)
								)}
							</Flex>
						</GridItem>

						<GridItem colSpan={1} rowSpan={1} bg='white' rounded='xl' p='1rem' maxH={gridItemMaxH}>
							<Flex direction='column'>
								<Flex align='end' gap='0.5rem' mb='0.5rem'>
									<BiCar color='#0258FF' size='25px' />
									<Text fontWeight='bold'>Vehículo</Text>
								</Flex>
								{isLoading ? (
									<>
										<Skeleton height='20px' />
										<Skeleton height='20px' />
										<Skeleton height='20px' />
									</>
								) : (
									<>
										<Text>
											{order?.car.brand_display} {order?.car?.plate && `(${order.car.plate})`}
										</Text>
										<Text>Kilómetros: {depositReceipt?.kms} kms</Text>
										<Text>Combustible: {depositReceipt?.fuel_status_display}</Text>
									</>
								)}
							</Flex>
						</GridItem>

						<GridItem colSpan={1} rowSpan={1} bg='white' rounded='xl' p='1rem' maxH={gridItemMaxH}>
							<Flex direction='column'>
								<Text fontWeight='bold'>Nº Resguardo</Text>
								{isLoading ? (
									<Skeleton height='20px' />
								) : (
									<Text>RD{depositReceipt?.incremental_id.toString().padStart(5, "0")}</Text>
								)}
								<Text fontWeight='bold' mt='0.5rem'>
									Fecha de recepción
								</Text>
								{isLoading ? (
									<Skeleton height='20px' />
								) : (
									<Text>{order?.date_in ? moment(order.date_in).format("L") : "-- --"}</Text>
								)}
								{order?.date_out && (
									<>
										<Text fontWeight='bold' mt='0.5rem'>
											Fecha de entrega prevista
										</Text>
										{isLoading ? (
											<Skeleton height='20px' />
										) : (
											<Text>
												{order?.date_out ? moment(order.date_out).format("L") : "-- --"}
											</Text>
										)}
									</>
								)}
							</Flex>
						</GridItem>
						{hasServices && (
							<GridItem colSpan={2} bg='white' rounded='xl' p='1rem'>
								<Flex w='100%' direction='column' gap='1rem'>
									<Flex align='end' gap='0.5rem' mb='0.5rem'>
										<MdCleaningServices color='#0258FF' size='25px' />
										<Text fontWeight='bold'>Servicios a realizar</Text>
									</Flex>
									{isLoading ? (
										<>
											<Skeleton height='20px' />
											<Skeleton height='20px' />
											<Skeleton height='20px' />
										</>
									) : (
										order?.services.map((service: PriceDuration) => (
											<Flex key={service.id} gap='1em'>
												<Text>{service.name}</Text>
												<Text>{service.price}</Text>
												<Text>{parseDuration(service.duration, "long")}</Text>
											</Flex>
										))
									)}
								</Flex>
							</GridItem>
						)}

						{hasObservations && (
							<GridItem colSpan={3}>
								<Flex w='100%' bg='white' rounded='xl' p='1em' justify='space-between'>
									<Flex direction='column' w='65%'>
										<Text fontWeight='bold' mb='0.7rem'>
											Observaciones
										</Text>
										{isLoading ? (
											<>
												<Skeleton height='20px' />
												<Skeleton height='20px' />
												<Skeleton height='20px' />
											</>
										) : (
											depositReceipt?.observations.map(
												(observation: DepositReceiptObservation) => (
													<Flex key={observation.id} gap='0.2rem' mt='0.3rem'>
														<Text>{observation.zone_display}:</Text>
														<Text>{observation.description}</Text>
													</Flex>
												)
											)
										)}
									</Flex>
									<Image w='250px' src={carParts} objectFit='contain' />
								</Flex>
							</GridItem>
						)}

						<GridItem colSpan={1} maxH={"300px"} bg='white' rounded='xl' p='1rem'>
							<Flex direction='column'>
								<Text fontWeight='bold'>Firma taller</Text>
								{isLoading ? (
									<>
										<Skeleton height='20px' />
										<Skeleton height='20px' />
									</>
								) : (
									hasBusinessSignature && (
										<>
											<Image src={invoiceSettings?.signature} />
											<Text>Firmado por {business?.name}</Text>
										</>
									)
								)}
							</Flex>
						</GridItem>

						<GridItem colSpan={1} maxH={"300px"} bg='white' rounded='xl' p='1rem'>
							<Flex direction='column' h='100%'>
								<Text fontWeight='bold'>Firma cliente</Text>
								{isLoading ? (
									<>
										<Skeleton height='20px' />
										<Skeleton height='20px' />
									</>
								) : hasSignature ? (
									<>
										<Image src={depositReceipt.signature.signature_image} />
										<Text>
											Firmado por {depositReceipt.signature.name} el{" "}
											{moment(depositReceipt.signature.signed_at).format("L HH:MM")}
										</Text>
									</>
								) : (
									<DepositReceiptSignatureModal depositReceipt={depositReceipt} />
								)}
							</Flex>
						</GridItem>
					</Grid>
				</Flex>
				<LoadingOverlay isLoading={isLoading} />
			</>
		);
	}
	return (
		<>
			<Flex minH='100dvh' w='100%' bg='#F4F4F9' direction='column' gap='1rem' px='2rem' py='3rem'>
				<Flex direction='column' bg='white' p='1rem' rounded='lg'>
					<Flex align='end' gap='0.5rem' mb='0.5rem'>
						<BsShop color='#0258FF' size='25px' />
						<Text fontWeight='bold'>Taller</Text>
					</Flex>
					{isLoading ? (
						<Skeleton height='20px' />
					) : (
						business && (
							<>
								<Text> {business.name} </Text>
								<Text> {business.address?.formatted_address} </Text>
								<Text> {business.phone} </Text>
								<Text> {business.email} </Text>
							</>
						)
					)}
					{isLoading ? (
						<Skeleton height='20px' />
					) : (
						invoiceSettings?.CIF && <Text> {invoiceSettings.CIF} </Text>
					)}
				</Flex>
				{/* CLIENTE */}
				<Flex direction='column' bg='white' p='1rem' rounded='lg'>
					<Flex align='end' gap='0.5rem' mb='0.5rem'>
						<FiUser color='#0258FF' size='25px' />
						<Text fontWeight='bold'>Cliente</Text>
					</Flex>
					{isLoading ? (
						<>
							<Skeleton height='20px' />
							<Skeleton height='20px' />
							<Skeleton height='20px' />
							<Skeleton height='20px' />
						</>
					) : (
						client && (
							<>
								<Text> {client.name} </Text>
								{client.email && <Text> {client.email} </Text>}
								{client.phone && <Text> {client.phone} </Text>}
								{client.nif_cif && <Text> {client.nif_cif} </Text>}
							</>
						)
					)}
				</Flex>
				{/* VEHICULO */}
				<Flex direction='column' bg='white' p='1rem' rounded='lg'>
					<Flex align='end' gap='0.5rem' mb='0.5rem'>
						<BiCar color='#0258FF' size='25px' />
						<Text fontWeight='bold'>Vehículo</Text>
					</Flex>
					{isLoading ? (
						<>
							<Skeleton height='20px' />
							<Skeleton height='20px' />
							<Skeleton height='20px' />
						</>
					) : (
						<>
							<Text>
								{order?.car.brand_display} {order?.car?.plate && `(${order.car.plate})`}
							</Text>
							<Text>Kilómetros: {depositReceipt?.kms} kms</Text>
							<Text>Combustible: {depositReceipt?.fuel_status_display}</Text>
						</>
					)}
				</Flex>
				{/* RESGUARDO */}
				<Flex direction='column' bg='white' rounded='xl' p='1rem'>
					<Text fontWeight='bold'>Nº Resguardo</Text>
					{isLoading ? (
						<Skeleton height='20px' />
					) : (
						<Text mt='0.2rem'>RD{depositReceipt?.incremental_id.toString().padStart(5, "0")}</Text>
					)}
					<Text fontWeight='bold' mt='0.5rem'>
						Fecha de recepción
					</Text>
					{isLoading ? (
						<Skeleton height='20px' />
					) : (
						<Text mt='0.2rem'>{order?.date_in ? moment(order.date_in).format("L") : "-- --"}</Text>
					)}
					{order?.date_out && (
						<>
							<Text fontWeight='bold' mt='0.5rem'>
								Fecha de entrega prevista
							</Text>
							{isLoading ? (
								<Skeleton height='20px' />
							) : (
								<Text>{order?.date_out ? moment(order.date_out).format("L") : "-- --"}</Text>
							)}
						</>
					)}
				</Flex>
				{hasServices && (
					<Flex w='100%' direction='column' gap='1rem' bg='white' rounded='xl' p='1rem'>
						<Flex align='end' gap='0.5rem' mb='0.5rem'>
							<MdCleaningServices color='#0258FF' size='25px' />
							<Text fontWeight='bold'>Servicios a realizar</Text>
						</Flex>
						{isLoading ? (
							<>
								<Skeleton height='20px' />
								<Skeleton height='20px' />
								<Skeleton height='20px' />
							</>
						) : (
							order?.services.map((service: PriceDuration) => (
								<Flex key={service.id} gap='1em'>
									<Text>{service.name}</Text>
									<Text>{service.price}</Text>
									<Text>{parseDuration(service.duration, "long")}</Text>
								</Flex>
							))
						)}
					</Flex>
				)}
				{hasObservations && (
					<Flex direction='column' w='100%' bg='white' rounded='xl' p='1rem'>
						<Text fontWeight='bold' mb='0.7rem'>
							Observaciones
						</Text>
						{isLoading ? (
							<>
								<Skeleton height='20px' />
								<Skeleton height='20px' />
								<Skeleton height='20px' />
							</>
						) : (
							depositReceipt?.observations.map((observation: DepositReceiptObservation) => (
								<Flex key={observation.id} gap='0.2rem' mt='0.3rem'>
									<Text fontWeight='bold'>{observation.zone_display}:</Text>
									<Text>{observation.description}</Text>
								</Flex>
							))
						)}
					</Flex>
				)}
				<Flex direction='column' bg='white' rounded='xl' p='1rem'>
					<Text fontWeight='bold'>Firma taller</Text>
					{isLoading ? (
						<>
							<Skeleton height='20px' />
							<Skeleton height='20px' />
						</>
					) : (
						hasBusinessSignature && (
							<>
								<Image src={invoiceSettings?.signature} />
								<Text>Firmado por {business?.name}</Text>
							</>
						)
					)}
				</Flex>

				<Flex direction='column' bg='white' rounded='xl' p='1rem'>
					<Text fontWeight='bold'>Firma cliente</Text>
					{isLoading ? (
						<>
							<Skeleton height='20px' />
							<Skeleton height='20px' />
						</>
					) : hasSignature ? (
						<>
							<Image src={depositReceipt.signature.signature_image} />
							<Text>
								Firmado por {depositReceipt.signature.name} el{" "}
								{moment(depositReceipt.signature.signed_at).format("L HH:MM")}
							</Text>
						</>
					) : (
						<DepositReceiptSignatureModal depositReceipt={depositReceipt} />
					)}
				</Flex>
			</Flex>
			<LoadingOverlay isLoading={isLoading} />
		</>
	);
}
