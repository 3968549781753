import {
	useMediaQuery,
	useToast,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	Flex,
	Text,
	Image
} from "@chakra-ui/react";

import moment from "moment";
// icons
import { TfiDashboard } from "react-icons/tfi";
// img
import carParts from "../../../../img/depositReceipt/carParts.png";
// comps
import InputField from "../../../common/forms/InputField";
import DepositObservations from "./DepositObservations";
import DepositReceiptFuelStatus from "./DepositReceiptFuelStatus";
// forms and validation
import { Formik } from "formik";
import * as Yup from "yup";
// auth
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { createUpdateDepositReceipt } from "../../../../api/depositReceiptApi";
import { useAuthHeader } from "react-auth-kit";

export default function DepositReceiptModal({ order, isOpen, onClose }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const authHeader = useAuthHeader();
	const toast = useToast();
	const hasServices = order?.services?.length > 0;
	const initialValues = {
		kms: order?.car?.kms || "",
		fuel_status: "half",
		observations: [
			{
				zone: "",
				description: ""
			}
		]
	};
	const validationSchema = Yup.object({
		kms: Yup.number("Debe ser un número sin decimales").required("Debes introducir el kilometraje"),
		fuel_status: Yup.string().required("Debes introducir el estado del depósito"),
		observations: Yup.array().of(
			Yup.object().shape({
				zone: Yup.string().required("Elige una zona"),
				description: Yup.string().required("Debes añadir una descripción")
			})
		)
	});
	const { isLoading, mutate } = useAuthMutation({
		mutationFn: createUpdateDepositReceipt,
		onSuccess: (response) => {
			toast({ title: "Recibo creado!", status: "success" });
			onClose();
			window.open(`/resguardo-deposito/${response.id}`, "_blank");
		},
		onError: () => {
			toast({
				title: "Ups! Ha habido un Error",
				description: "Error haciendo la petición",
				status: "error"
			});
		}
	});
	const handleSubmit = (values) => {
		const payload = {
			data: {
				order: order.id,
				...values
			},
			token: authHeader()
		};
		mutate(payload);
	};
	if (!isMobile) {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size='6xl'>
				<ModalOverlay />
				<ModalContent bg='white'>
					<ModalHeader>
						<Text>Crear resguardo de depósito</Text>
					</ModalHeader>
					<ModalCloseButton />
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => handleSubmit(values)}
					>
						{(formik) => (
							<>
								<ModalBody>
									<Flex w='100%' justify='space-between'>
										<Flex direction='column' justifySelf='start' w='70%'>
											<Flex gap='2em' w='100%'>
												<Flex>
													<InputField
														label='Kilometraje'
														name='kms'
														icon={<TfiDashboard color='black' size='20px' />}
													/>
												</Flex>
												<DepositReceiptFuelStatus
													error={formik.errors.fuel_status}
													formik={formik}
												/>
											</Flex>
											<Flex gap='1em' mt='1em' w='100%'>
												<Image w='250px' src={carParts} objectFit={"contain"} />
												<DepositObservations formik={formik} />
											</Flex>
										</Flex>
										<Flex direction='column'>
											<Text fontWeight='bold'>Cliente</Text>
											<Text>{order?.client?.name}</Text>
											{hasServices && (
												<>
													<Text fontWeight='bold' mt='1em'>
														Servicios a realizar
													</Text>
													<Flex direction='column'>
														{order?.services?.map((service) => (
															<Flex key={service.id} gap='1em'>
																<Text>{service.name} </Text>
																<Text>{service.price} </Text>
																<Text>{service.duration} </Text>
															</Flex>
														))}
													</Flex>
												</>
											)}
											<Text mt='1em' fontWeight='bold'>
												Coche
											</Text>
											<Text>
												{order?.car?.brand_display} - {order?.car?.model} {order?.car?.plate}
											</Text>
											<Text mt='1em' fontWeight='bold'>
												Fecha entrada
											</Text>
											<Text>{order?.date_in ? moment(order?.date_in).format("L") : "-- --"}</Text>
											<Text mt='1em' fontWeight='bold'>
												Fecha salida
											</Text>
											<Text>
												{order?.date_out ? moment(order?.date_in).format("L") : "-- --"}
											</Text>
										</Flex>
									</Flex>
								</ModalBody>
								<ModalFooter>
									<Button variant='primary' isLoading={isLoading} onClick={formik.handleSubmit}>
										Guardar
									</Button>
								</ModalFooter>
							</>
						)}
					</Formik>
				</ModalContent>
			</Modal>
		);
	}
	return (
		<Modal isOpen={isOpen} onClose={onClose} size='full'>
			<ModalOverlay />
			<ModalContent bg='white'>
				<ModalHeader>
					<Text>Crear resguardo de depósito</Text>
				</ModalHeader>
				<ModalCloseButton />
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => handleSubmit(values)}
				>
					{(formik) => (
						<>
							<ModalBody>
								<Flex direction='column'>
									<Flex gap='2em' w='100%'>
										<Flex>
											<InputField
												label='Kilometraje'
												name='kms'
												icon={<TfiDashboard color='black' size='20px' />}
											/>
										</Flex>
										<DepositReceiptFuelStatus error={formik.errors.fuel_status} formik={formik} />
									</Flex>
									<DepositObservations formik={formik} />
									<Image
										alignSelf='center'
										w='250px'
										src={carParts}
										objectFit={"contain"}
										transform='rotate(90deg)'
									/>
									{hasServices && (
										<>
											<Text fontWeight='bold' mt='1em'>
												Servicios a realizar
											</Text>
											<Flex direction='column'>
												{order?.services?.map((service) => (
													<Flex key={service.id} gap='1em'>
														<Text>{service.name} </Text>
														<Text>{service.price} </Text>
														<Text>{service.duration} </Text>
													</Flex>
												))}
											</Flex>
										</>
									)}
									<Flex mt='1.5rem' w='100' justify='space-between'>
										<Flex direction='column' gap='0.5rem'>
											<Text fontWeight='bold'>Cliente</Text>
											<Text>{order?.client?.name}</Text>
										</Flex>
										<Flex direction='column' gap='0.5rem'>
											<Text fontWeight='bold'>Coche</Text>
											<Text>
												{order?.car?.brand_display} - {order?.car?.model} {
													order?.car?.plate
												}{" "}
											</Text>
										</Flex>
									</Flex>
									<Flex mt='1.5rem' w='100' justify='space-between'>
										<Flex direction='column' gap='0.5rem'>
											<Text mt='1em' fontWeight='bold'>
												Fecha entrada
											</Text>
											<Text>{order?.date_in ? moment(order?.date_in).format("L") : "-- --"}</Text>
										</Flex>
										<Flex direction='column' gap='0.5rem'>
											<Text mt='1em' fontWeight='bold'>
												Fecha salida
											</Text>
											<Text>
												{order?.date_out ? moment(order?.date_in).format("L") : "-- --"}
											</Text>
										</Flex>
									</Flex>
								</Flex>
							</ModalBody>
							<ModalFooter>
								<Button variant='primary' isLoading={isLoading} onClick={formik.handleSubmit}>
									Guardar
								</Button>
							</ModalFooter>
						</>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
}
