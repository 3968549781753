import { PDFViewer, Page, Text, View, Document } from "@react-pdf/renderer";
import { styles } from "../PDFStyle";
// comps
import InvoicePDFHeader from "./InvoicePDFHeader";
import InvoicePDFResume from "./InvoicePDFResume";
// fs
import { formatDecimals } from "../../../../common/functions";

export default function InvoicePDF({ invoice, invoiceSettings, businessProfile }) {
	return (
		<PDFViewer width='100%' height='100%'>
			<Document title={`Factura ${invoice?.invoice_number}`}>
				<Page size='A4' style={styles.page}>
					<InvoicePDFHeader
						invoiceSettings={invoiceSettings}
						businessProfile={businessProfile}
						invoice={invoice}
					/>
					{/* Invoice description */}
					<View
						style={{
							borderTop: "1px solid #F4F4F9",
							borderBottom: "1px solid #F4F4F9",
							paddingHorizontal: "10",
							paddingVertical: "5px",
							marginTop: "30px",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							color: "#737A87",
							fontSize: "10px"
						}}
					>
						<View style={{ width: "40%" }}>
							<Text>ITEM</Text>
						</View>
						<View
							style={{
								width: "10%",
								textAlign: "right"
							}}
						>
							<Text>CANT.</Text>
						</View>
						<View
							style={{
								width: "15%",
								textAlign: "right"
							}}
						>
							<Text>PRECIO</Text>
						</View>
						<View
							style={{
								width: "20%",
								textAlign: "right"
							}}
						>
							<Text>IVA(€)</Text>
						</View>
						<View
							style={{
								width: "15%",
								textAlign: "right"
							}}
						>
							<Text>TOTAL(€)</Text>
						</View>
					</View>
					{invoice?.items?.map((item, index) => (
						<View style={[styles.invoiceRow, index % 2 !== 0 && styles.evenRow]} key={item.id}>
							<View style={{ width: "40%" }}>
								<Text>{item?.concept}</Text>
							</View>
							<View
								style={{
									width: "10%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.quantity)}</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.price)}</Text>
							</View>
							<View
								style={{
									width: "20%",
									textAlign: "right"
								}}
							>
								<Text>
									{formatDecimals(item.taxes)} {invoice.taxes > 0 && `(${invoice.tax_display})`}
								</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.total)}</Text>
							</View>
						</View>
					))}
					{invoice?.stock_used?.map((item, index) => (
						<View style={[styles.invoiceRow, index % 2 !== 0 && styles.evenRow]} key={item.id}>
							<View style={{ width: "40%" }}>
								<Text>{item?.name}</Text>
							</View>
							<View
								style={{
									width: "10%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.quantity)}</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.price)}</Text>
							</View>
							<View
								style={{
									width: "20%",
									textAlign: "right"
								}}
							>
								<Text>21%</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.total)}</Text>
							</View>
						</View>
					))}

					<InvoicePDFResume invoice={invoice} invoiceSettings={invoiceSettings} />
				</Page>
			</Document>
		</PDFViewer>
	);
}
