import { useState, useMemo } from "react";
import {
	TableContainer,
	useToast,
	Checkbox,
	Flex,
	Text,
	Button,
	Table,
	Box,
	Badge,
	Thead,
	Tr,
	Th,
	Tbody,
	Td
} from "@chakra-ui/react";
import moment from "moment/moment";
// comps
import LoadingSpinner from "../../common/loading/LoadingSpinner";
import PaginationMenu from "../../common/Tables/PaginationMenu";
import PopoverDelete from "../../common/PopoverDelete";
import LogTypeBadge from "./LogTypeBadge";
// api
import useAuthMutation from "../../../myHooks/useAuthMutation";
import useAuthQuery from "../../../myHooks/useAuthQuery";
import { useQueryClient } from "@tanstack/react-query";
import { getUserActions, deleteUserAction, bulkDeleteUserAction } from "../../../api/adminApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function UserActionsTable({ device }) {
	const [page, setPage] = useState(1);
	const toast = useToast();
	const QueryClient = useQueryClient();

	const [selectedItems, setSelectedItems] = useState([]);
	const authHeader = useAuthHeader();

	const { data, isLoading } = useAuthQuery({
		queryKey: ["actions", page],
		queryFn: () => getUserActions({ auth: authHeader(), page })
	});
	const handleSelectItem = (id) => {
		setSelectedItems((prevSelected) =>
			prevSelected.includes(id) ? prevSelected.filter((actionId) => actionId !== id) : [...prevSelected, id]
		);
	};
	const { isLoading: lm, mutate } = useAuthMutation({
		mutationFn: bulkDeleteUserAction,
		onSuccess: () => {
			setSelectedItems([]);
			toast({ title: "Acciones borradas!", status: "success" });
			QueryClient.invalidateQueries(["actions"]);
			QueryClient.refetchQueries("actions", { force: true });
		},
		onError: () => {
			toast({
				title: "¡Ups, ha habido un error!",
				status: "error"
			});
		}
	});
	const handleBulkDelete = () => {
		mutate({ data: { ids: selectedItems }, token: authHeader() });
	};

	const tableData = useMemo(
		() =>
			data?.results?.map((action) => (
				<Tr key={action.id}>
					<Td>
						<Checkbox
							isChecked={selectedItems.includes(action.id)}
							onChange={() => handleSelectItem(action.id)}
						/>
					</Td>
					<Td>{action.user_email.split("@")[0]}</Td>
					<Td> {action.action} </Td>
					<Td>
						<Badge colorScheme={(action.status >= "200") & (action.status < 400) ? "green" : "red"}>
							{action.status}
						</Badge>
					</Td>
					<Td>
						<LogTypeBadge type={action.type} />
					</Td>
					<Td>
						<Box
							maxH='100px' // Ajusta el valor según sea necesario
							maxW='250px'
							overflowY='auto'
						>
							{action.response}
						</Box>
					</Td>
					<Td> {moment(action.timestamp).format("lll")} </Td>
					<Td>
						<PopoverDelete
							deleteKey={["deleteUserAction"]}
							deleteFn={deleteUserAction}
							slug={action?.id}
							refetchKey={["actions"]}
						/>
					</Td>
				</Tr>
			)),
		[data, selectedItems]
	);
	return (
		<>
			{selectedItems.length > 0 && (
				<Flex w='100%' justify='end' gap='2rem' align='center'>
					<Text>{selectedItems.length} seleccionados</Text>
					<Button size='sm' variant='danger' onClick={handleBulkDelete} isLoading={lm}>
						Borrar
					</Button>
				</Flex>
			)}

			<TableContainer
				mt='1em'
				minH='35vh'
				width={{ base: "100%", md: "650px", lg: "850px", xl: "1200px", "2xl": "1350px" }}
			>
				<Table variant='unstyled' size='sm'>
					<Thead>
						<Tr bg='lightgrey' color='darkblue'>
							<Th roundedLeft='lg' py='1.5rem' />
							<Th>User</Th>
							<Th>Action</Th>
							<Th>Status</Th>
							<Th>Type</Th>
							<Th>Response</Th>
							<Th roundedRight='lg'>Timestamp</Th>
						</Tr>
					</Thead>
					<Tbody>{tableData}</Tbody>
					{isLoading && <LoadingSpinner />}
				</Table>
				<PaginationMenu
					total={data?.count}
					next={data?.next}
					prev={data?.previous}
					page={page}
					setPage={setPage}
					device={device}
				/>
			</TableContainer>
		</>
	);
}
