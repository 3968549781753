import React from 'react'
import { Flex, useMediaQuery } from '@chakra-ui/react'

export default function StepsDisplay ({ currentStep, maxSteps, ...props }) {
    const [isMobile] = useMediaQuery('(max-width: 768px)')

    const steps = []
    for (let step = 0; step <= maxSteps - 1; step++) {
        const stepStyle = {
            width: '17px',
            height: '17px',
            borderRadius: '50%',
            backgroundColor: !isMobile
                ? step === currentStep
                    ? 'white'
                    : step < currentStep
                        ? '#0258FF'
                        : 'lightgrey'
                : step === currentStep
                    ? 'transparent'
                    : step < currentStep
                        ? '#0258FF'
                        : 'white',
            border: step === currentStep ? '2px solid #0258FF' : 'none'
        }

        steps.push(<Flex key={step} style={stepStyle} />)
    }
    if (!isMobile) {
        return (
            <Flex {...props} gap="30px" mb='1em'>
                {steps}
            </Flex>
        )
    } else {
        return (
            <Flex {...props} gap="20px" w="100%" px="1em" justify="center">
                {steps}
            </Flex>
        )
    }
}
