import { Flex, IconButton, Box, Icon } from '@chakra-ui/react'
// icons
import { FaCarRear } from 'react-icons/fa6'
import { PiCar, PiCarProfile } from 'react-icons/pi'
import { FaCheckCircle } from 'react-icons/fa'

export default function CarViewSelect ({ view, setView, photo }) {
    const iconsData = [
        { view: 'right', icon: <PiCarProfile size="40px" /> },
        { view: 'left', icon: <PiCarProfile size="40px" style={{ transform: 'scaleX(-1)' }} /> },
        { view: 'back', icon: <FaCarRear size="32px" /> },
        { view: 'front', icon: <PiCar size="40px" /> }
    ]

    return (
        <Flex gap="1.5em" align="center">
            {iconsData.map(({ view: iconView, icon }) => (
                <Box key={iconView} position="relative" display="inline-block">
                    <IconButton
                        bg="transparent"
                        color={view === iconView ? 'blue.500' : 'darkblue'}
                        icon={icon}
                        onClick={() => setView(iconView)}
                    />
                    {photo[iconView] && (
                        <Box zIndex="100" position="absolute" top="0" right="0">
                            <FaCheckCircle color="#42C74F" />
                        </Box>
                    )}
                </Box>
            ))}
        </Flex>
    )
}
