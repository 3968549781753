import { useEffect } from "react";
import { Text, Flex, Button } from "@chakra-ui/react";
// comps
import DiscountField from "./DiscountField";
import UsedStock from "./UsedStock";
import InfoTooltip from "../../../common/InfoTooltip";
import InvoiceItems from "./InvoiceItems";
// fs
import { formatDecimals } from "../../../common/functions";
import {
	getQuoteInitialValues,
	getInvoiceInitialValues,
	getQuoteValidationSchema,
	getInvoiceValidationSchema
} from "./utils";
// forms and validation
import { Formik } from "formik";
import InputField from "../../../common/forms/InputField";
import SelectField from "../../../common/forms/SelectField";
import SelectFieldFilter from "../../../common/forms/SelectFieldFilter";

export default function InvoiceFormMobile(props) {
	const {
		submit,
		nextInvoiceID,
		nextQuoteID,
		clients,
		invoice,
		quote,
		options,
		isLoading,
		order,
		withTaxes,
		setWithTaxes,
		calculateTotals,
		total,
		taxes,
		subtotal,
		discount,
		isFree,
		error,
		isQuote
	} = props;

	const taxChoices = options?.actions?.POST?.items?.child?.children?.tax?.choices;
	const payMethodChoices = options?.actions?.POST?.pay_method?.choices;
	const invoiceStatusChoices = options?.actions?.POST?.status?.choices;
	const quoteStatusChoices = options?.actions?.POST?.status?.choices;

	const initialValues = isQuote
		? getQuoteInitialValues(quote, order, nextQuoteID, taxChoices)
		: getInvoiceInitialValues(invoice, order, nextInvoiceID, taxChoices);

	const validationSchema = isQuote ? getQuoteValidationSchema() : getInvoiceValidationSchema();

	useEffect(() => {
		calculateTotals(initialValues, order);
	}, []);

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => submit(values)}>
			{(formik) => (
				<Flex direction='column' maxH='100dvh'>
					<Flex overflowY='scroll' direction='column' gap='1em' pb='2em'>
						{!isQuote ? (
							<InputField
								name='invoice_number'
								error={error?.response?.data?.invoice_number}
								label={
									<InfoTooltip
										label={"Nº Factura"}
										desc={"Puedes configurarlo desde panel de ajustes"}
									/>
								}
							/>
						) : (
							<InputField
								name='quote_number'
								error={error?.response?.data?.quote_number}
								label={<InfoTooltip label={"Nº Presupuesto"} />}
							/>
						)}
						<InputField name='date' label='Fecha' type='date' error={error?.response?.data?.date} />
						{!isQuote && (
							<InputField
								name='expiring_date'
								label='Fecha de Expiración'
								type='date'
								error={error?.response?.data?.expiring_date}
							/>
						)}
						<SelectFieldFilter
							label='Cliente'
							options={clients?.results}
							name='client'
							error={error?.response?.data?.clients}
						/>
						{clients?.count === 0 && (
							<Flex mt='1em' align='center' gap='1em'>
								<Text fontSize='14px' color='red'>
									Aún no hay clientes, crea uno desde el panel de datos
								</Text>
							</Flex>
						)}
						{!isQuote ? (
							<>
								<SelectField label='Método de pago' name='pay_method' choices={payMethodChoices} />
								<SelectField label='Estado' name='status' choices={invoiceStatusChoices} />
							</>
						) : (
							<SelectField label='Estado' name='status' choices={quoteStatusChoices} />
						)}
						<InvoiceItems
							formik={formik}
							taxChoices={taxChoices}
							withTaxes={withTaxes}
							setWithTaxes={setWithTaxes}
							calculateTotals={calculateTotals}
							order={order}
						/>

						<UsedStock stock_used={order?.stock_used || invoice?.stock_used} />

						<DiscountField formik={formik} calculateTotals={calculateTotals} order={order} />
					</Flex>
					<Flex direction='column' gap='1em' px='1rem' pt='1rem' pb='2rem'>
						<Flex w='100%' justify='space-between'>
							<Text fontSize='20px'>Subtotal {formatDecimals(subtotal)}€</Text>
							<Text fontSize='20px'>IVA {formatDecimals(taxes)}€</Text>
						</Flex>
						<Flex w='100%' justify='space-between'>
							{Number(discount) > 0 && (
								<Text fontSize='20px'>Descuento -{formatDecimals(discount)}€</Text>
							)}
							<Text fontSize='20px' fontWeight='bold'>
								Total {formatDecimals(total)}€
							</Text>
						</Flex>
						<Button
							variant='primary'
							justifySelf='end'
							alignSelf='end'
							w='150px'
							isDisabled={
								JSON.stringify(formik.touched) === "{}" && JSON.stringify(formik.values) === "{}"
							}
							onClick={formik.handleSubmit}
							isLoading={isLoading}
						>
							Guardar
						</Button>
					</Flex>
				</Flex>
			)}
		</Formik>
	);
}
