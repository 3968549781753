import React, { useState } from "react";
import { useToast, Button, Flex, Text } from "@chakra-ui/react";
import { setKey, setLanguage, setRegion, fromAddress } from "react-geocode";
//forms
import InputField from "../../../../../common/forms/InputField";
import SelectFieldFilter from "../../../../../common/forms/SelectFieldFilter";
import { Formik } from "formik";
import * as Yup from "yup";
//api
import { useAuthHeader } from "react-auth-kit";
import useAuthMutation from "../../../../../../myHooks/useAuthMutation";
import useAuthFetch from "../../../../../../myHooks/useAuthFetch";
import { useQueryClient } from "@tanstack/react-query";
import { getBusinessAddressOptions, createUpdateBusinessAddress } from "../../../../../../api/businessesApi";

export default function AddressGeoLocationInput({ currentAddress, selectedPlace, setSelectedPlace, handleClose }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const [choices, setChoices] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");

	setLanguage("es");
	setRegion("es");
	setKey(process.env.REACT_APP_GEOCODING_API_KEY);

	const { data: businessAddressOptions } = useAuthFetch(["businessAddressOptions"], getBusinessAddressOptions);
	const autonomousCommunityChoices = businessAddressOptions?.actions?.POST?.autonomous_community?.choices;

	const { isLoading, mutate } = useAuthMutation({
		mutationFn: createUpdateBusinessAddress,
		onSuccess: () => {
			toast({ title: "Direccion actualizada!", status: "success" });
			handleClose();
			QueryClient.invalidateQueries(["businessProfile"]);
			QueryClient.refetchQueries("businessProfile", { force: true });
		},
		onError: () => {
			toast({
				title: "Ups! Ha habido un error",
				status: "error"
			});
		}
	});

	const initialValues = {
		latitude: currentAddress?.latitude || 0,
		longitude: currentAddress?.longitude || 0,
		formatted_address: currentAddress?.formatted_address || "",
		street: currentAddress?.street || "",
		number: currentAddress?.number || "",
		postal_code: currentAddress?.postal_code || "",
		autonomous_community: currentAddress?.autonomous_community || "",
		city: currentAddress?.city || "",
		country: currentAddress?.country || "ES"
	};
	const validationSchema = Yup.object({
		street: Yup.string().required("Campo obligatorio"),
		number: Yup.string().required("Campo obligatorio"),
		postal_code: Yup.string().required("Campo obligatorio"),
		autonomous_community: Yup.string().required("Campo obligatorio")
	});

	const parseAddress = (values) => {
		const address = `${values.street}, ${values.number}, ${values.postal_code}, ${values.autonomous_community}`;
		return address;
	};
	const handleSearch = async (values) => {
		setErrorMessage("");
		try {
			const results = await fromAddress(parseAddress(values));
			setChoices(results.results);
		} catch (error) {
			console.error("Error buscando", error);
			setErrorMessage("No se encuentra ninguna dirección para estos valores");
		}
	};
	const handleSelect = (formik, location) => {
		const latitude = location.geometry.location.lat;
		const longitude = location.geometry.location.lng;
		setSelectedPlace({
			lat: latitude,
			lng: longitude
		});
		formik.setFieldValue("formatted_address", location.formatted_address);
		formik.setFieldValue("latitude", latitude);
		formik.setFieldValue("longitude", longitude);
		setChoices([]);
	};
	const handleReset = () => {
		setChoices([]);
		setSelectedPlace();
	};
	const handleSubmit = (formik) => {
		const payload = {
			data: formik.values,
			token: authHeader(),
			slug: currentAddress.id
		};
		payload.data.latitude = Number(payload.data.latitude).toFixed(10);
		payload.data.longitude = Number(payload.data.longitude).toFixed(10);
		mutate(payload);
	};
	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			validationSchema={validationSchema}
			onSubmit={(values) => handleSearch(values)}
		>
			{(formik) => (
				<>
					<Flex direction='column' gap='0.5rem'>
						<Flex gap='1rem'>
							<InputField name='street' placeholder='Calle, Avenida...' />
							<InputField name='number' placeholder='Nro.' />
						</Flex>
						<Flex gap='0.5rem'>
							<InputField name='postal_code' placeholder='Codigo Postal' />
							<InputField name='city' placeholder='Ciudad' />
							<SelectFieldFilter
								options={autonomousCommunityChoices}
								name='autonomous_community'
								placeholder='Coumindad Autonoma'
							/>
						</Flex>
						{errorMessage && <Text color='red'>{errorMessage}</Text>}

						<Button
							variant='primary'
							isDisabled={!formik.dirty}
							onClick={formik.handleSubmit}
							my='1rem'
							w='200px'
							alignSelf='center'
						>
							Buscar
						</Button>
					</Flex>
					{choices && (
						<Flex direction='column' gap='1rem' zIndex={1000}>
							{choices.map((choice) => (
								<Button key={choice.id} variant='white' onClick={() => handleSelect(formik, choice)}>
									{choice.formatted_address}
								</Button>
							))}
						</Flex>
					)}
					{selectedPlace && (
						<Flex py='1rem' align='center' position='absolute' bottom='0' right='2rem' gap='1rem'>
							<Button variant='danger' onClick={() => handleReset(formik)}>
								Cancelar
							</Button>

							<Button
								isLoading={isLoading}
								variant='primary'
								isDisabled={!formik.dirty}
								onClick={() => handleSubmit(formik)}
							>
								Guardar
							</Button>
						</Flex>
					)}
				</>
			)}
		</Formik>
	);
}
