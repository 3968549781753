const calculateTaxAndTotal = (values) => {
	let taxMultiplier = 0;
	const price = Number.parseFloat(values.price) || 0;
	let taxes = 0;
	const quantity = Number(values.quantity) || 1;
	const totalPrice = price * quantity;
	let total = price * quantity;
	switch (values.tax) {
		case "twenty":
			taxMultiplier = 0.21;
			break;
		case "ten":
			taxMultiplier = 0.1;
			break;
		default:
			taxMultiplier = 0;
	}
	if (values.price_includes_tax) {
		taxes = totalPrice - totalPrice / (1 + taxMultiplier);
		total = totalPrice; // El precio ya incluye los impuestos
	} else {
		taxes = totalPrice * taxMultiplier;
		total = totalPrice + taxes; // Sumar los impuestos al precio
	}

	return {
		taxes: taxes.toFixed(2),
		total: total.toFixed(2)
	};
};

export default calculateTaxAndTotal;
