import { createApi, getAll, getAllPag, createUpdate, deleteItem, getOptions } from "./baseApi";

const salesApi = createApi("stock/sales");

export const getAllSales = async (payload) => {
	return getAll(salesApi, payload);
};

export const getAllSalesPag = async (payload) => {
	return getAllPag(salesApi, payload);
};

export const createUpdateSale = async (payload) => {
	return createUpdate(salesApi, payload);
};

export const deleteSale = async (payload) => {
	return deleteItem(salesApi, payload);
};

const suppliersApi = createApi("stock/suppliers");

export const getAllSuppliers = async (payload) => {
	return getAll(suppliersApi, payload);
};

export const getAllSuppliersPag = async (payload) => {
	return getAllPag(suppliersApi, payload);
};

export const createUpdateSupplier = async (payload) => {
	return createUpdate(suppliersApi, payload);
};

export const deleteSupplier = async (payload) => {
	return deleteItem(suppliersApi, payload);
};

export const getSupplierPurchases = async (payload) => {
	if (!payload.supplierId) {
		return [];
	}
	const response = await suppliersApi.get(`${payload.supplierId}/purchases`, {
		headers: { Authorization: payload.token }
	});
	return response.data || [];
};

const stockItemsApi = createApi("stock/items");

export const getAllStock = async (payload) => {
	return getAll(stockItemsApi, payload);
};

export const getAllStockPag = async (payload) => {
	return getAllPag(stockItemsApi, payload);
};

export const createUpdateStock = async (payload) => {
	return createUpdate(stockItemsApi, payload);
};

export const deleteStock = async (payload) => {
	return deleteItem(stockItemsApi, payload);
};

export const getStockOptions = async (payload) => {
	return getOptions(stockItemsApi, payload);
};

const purchasesApi = createApi("stock/purchases");

export const getAllPurchases = async (payload) => {
	return getAll(purchasesApi, payload);
};

export const getAllPurchasesPag = async (payload) => {
	return getAllPag(purchasesApi, payload);
};

export const createUpdatePurchase = async (payload) => {
	return createUpdate(purchasesApi, payload);
};

export const deletePurchase = async (payload) => {
	return deleteItem(purchasesApi, payload);
};

export const getPurchaseOptions = async (payload) => {
	return getOptions(purchasesApi, payload);
};

const expensesApi = createApi("stock/expenses");

export const getAllExpenses = async (payload) => {
	return getAll(expensesApi, payload);
};

export const getAllExpensesPag = async (payload) => {
	return getAllPag(expensesApi, payload);
};

export const createUpdateExpense = async (payload) => {
	return createUpdate(expensesApi, payload);
};

export const deleteExpense = async (payload) => {
	return deleteItem(expensesApi, payload);
};

export const getExpenseOptions = async (payload) => {
	return getOptions(expensesApi, payload);
};
