import { Tag, TagLabel } from "@chakra-ui/react";

export default function QuoteStatusBadge({ status }) {
	let color = "";
	let bg = "";
	let label = "";
	if (status === "pending" || status === "Pendiente") {
		color = "orange";
		bg = "orange.100";
		label = "Pendiente";
	}
	if (status === "approved" || status === "Aprobado") {
		color = "#42C74F";
		bg = "#42C74F1A";
		label = "Aprobado";
	}
	if (status === "declined" || status === "Rechazado") {
		color = "#F44E4E";
		bg = "#F44E4E1A";
		label = "Rechazado";
	}
	if (status === "expired" || status === "Caducado") {
		color = "#F44E4E";
		bg = "#F44E4E1A";
		label = "Caducado";
	}
	return (
		<Tag bg={bg} rounded='xl' p='0.5em'>
			<TagLabel color={color}>&#x2022; {label}</TagLabel>
		</Tag>
	);
}
