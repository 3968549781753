import { Flex, useMediaQuery } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
// Components
import NavLoginRegister from '../../components/navbar/NavLoginRegister';
import RegisterForm from '../../components/auth/RegisterForm';
// auth
import { useIsAuthenticated } from 'react-auth-kit';

export default function Register() {
	const navigate = useNavigate();
	const isAuthenticated = useIsAuthenticated();
	const [isMobile] = useMediaQuery('(max-width: 768px)');

	if (isAuthenticated()) {
		navigate('/dashboard');
	}

	return (
		<Flex w="100%" minH="100vh" direction="column" bg="lightgrey">
			<NavLoginRegister path={'/register'} />

			<Flex justify="center" align="center" w="100%">
				<Flex
					py={!isMobile ? '4em' : '0.5em'}
					w={!isMobile ? '550px' : '90%'}
					direction="column"
					align="center"
					gap="5"
				>
					{/*
                    <Flex><Heading size='lg' >another</Heading><Heading size='lg' color={'blue'}>tool</Heading></Flex>
                    */}
					<Flex
						bg="white"
						w="100%"
						mt={isMobile && '1.5em'}
						rounded="xl"
						direction="column"
						align="center"
						gap="3"
						p={!isMobile ? '4em' : '2em'}
						boxShadow="lg"
					>
						<RegisterForm />
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
}
