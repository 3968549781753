import { Fragment, useState } from "react";
import { Flex, Button, Text, IconButton, useDisclosure } from "@chakra-ui/react";
// comps
import BusinessHoursForm from "./BusinessHoursForm";
import PopoverDelete from "../../../../common/PopoverDelete";
//fns
import { formatSchedule } from "./helpers";
// icons
import { AiOutlineEdit } from "react-icons/ai";
// api
import { deleteBusinessHours } from "../../../../../api/businessesApi";

export default function BusinessHoursTable({ businessHours }) {
	const [businessHour, setBusinessHour] = useState();
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Flex bg='white' w='100%' h='100%' rounded='lg' p='1.5em' direction='column'>
				<Flex w='100%' justify='space-between' mb='0.5em' bg='lightgrey' rounded='xl' p='0.5em'>
					<Text fontSize='18px'>Horarios</Text>
					<Button variant='primary' size='sm' onClick={onOpen}>
						+ Horario
					</Button>
				</Flex>
				{businessHours && (
					<Flex
						direction='column'
						gap='0.5em'
						p='0.5em'
						maxH='80%'
						overflowY='scroll'
						pr='1em'
						sx={{
							"&::-webkit-scrollbar": { width: "5px" },
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#f5f5f5"
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#cfcfcf"
							}
						}}
					>
						{businessHours.map((businessHour) => (
							<Fragment key={businessHour.id}>
								<Flex w='100%' align='center' justify='space-between'>
									<Text>{businessHour.day_display}</Text>
									<Flex align='center' gap='0.5em'>
										{businessHour.is_closed ? <Text>Cerrado</Text> : formatSchedule(businessHour)}
										<IconButton
											icon={<AiOutlineEdit size='20px' color='#0258FF' />}
											bg='transparent'
											onClick={() => {
												setBusinessHour(businessHour);
												onOpen();
											}}
										/>
										<PopoverDelete
											deleteFn={deleteBusinessHours}
											deleteKey={["deleteBusinessHours"]}
											slug={businessHour?.id}
											refetchKey={["landingBusinessHours"]}
										/>
									</Flex>
								</Flex>
								<hr />
							</Fragment>
						))}
					</Flex>
				)}
			</Flex>
			<BusinessHoursForm businessHour={businessHour} isOpen={isOpen} onClose={onClose} />
		</>
	);
}
