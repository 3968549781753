import { Flex, Text, useMediaQuery } from "@chakra-ui/react";
//comps
import ReservationModalForm from "../form/ReservationModalForm";
//types
import type { PriceDuration } from "../../../../../types/service";

interface ServicesCartProps {
	selectedServices: PriceDuration[];
	totalPrice: number;
	resetCart: () => void;
}

export default function ServicesCart({ selectedServices, totalPrice, resetCart }: ServicesCartProps) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const servicesCount = selectedServices.length;
	const display = servicesCount > 0;

	if (display) {
		return (
			<>
				{!isMobile ? (
					<Flex
						w={"50%"}
						align='center'
						h={"100px"}
						alignSelf='center'
						px='2rem'
						py='1rem'
						position='fixed'
						bottom='20px' // Ajusta la distancia desde la parte inferior
						left='50%'
						transform='translateX(-50%)' // Para centrar el contenedor horizontalmente
						justify='space-between'
						bg='white'
						rounded='lg'
						boxShadow='xl' // Añade una sombra para mejor visibilidad
						zIndex={1000} // Asegúrate de que esté encima de otros elementos
						border='1px solid #001234'
					>
						<Text>
							{servicesCount} Servicios
							<span style={{ fontWeight: "bold" }}> {totalPrice.toFixed(2)} €</span>
						</Text>
						<ReservationModalForm
							services={selectedServices}
							totalPrice={totalPrice}
							isDisabled={false}
							resetCart={resetCart}
						/>
					</Flex>
				) : (
					<Flex
						w='100%'
						bg='white'
						justify='space-between'
						align='center'
						h='60px'
						alignSelf='center'
						px='2rem'
						py='1rem'
						position='fixed'
						bottom='0px'
						boxShadow='xl'
						zIndex={1000}
					>
						<Flex direction='column' fontSize='14px'>
							<Text>{servicesCount} Servicios</Text>
							<Text>
								<span style={{ fontWeight: "bold" }}> {totalPrice.toFixed(2)} €</span>
							</Text>
						</Flex>
						<ReservationModalForm
							services={selectedServices}
							isDisabled={false}
							resetCart={resetCart}
							totalPrice={totalPrice}
						/>
					</Flex>
				)}
			</>
		);
	}
	return null;
}
