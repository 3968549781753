import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work

export default function ExpensesPieChart({ data }) {
	moment.locale("es");

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text
				x={x}
				y={y}
				fill='black'
				fontWeight='bold'
				textAnchor={x > cx ? "start" : "end"}
				dominantBaseline='central'
			>
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		);
	};
	const getExpenseColor = (name) => {
		if (name === "Compras") return "#F27474";
		if (name === "Variables") return "#F9CA94";
		if (name === "Fijos") return "#F6E8D3";
	};
	if (data) {
		return (
			<ResponsiveContainer width='100%' height='100%'>
				<PieChart>
					<Pie
						data={data?.data}
						cx='25%'
						cy='35%'
						labelLine={false}
						label={renderCustomizedLabel}
						outerRadius='70%'
						innerRadius='50%'
						paddingAngle={5}
						fill='#8884d8'
						dataKey='value'
					>
						{data?.data?.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={getExpenseColor(entry.name)} />
						))}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		);
	}
}
