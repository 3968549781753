import { useNavigate } from "react-router-dom";
import { Flex, Button, Text, Image, useMediaQuery } from "@chakra-ui/react";

// images
import imgLogo from "../../img/logos/logo_navbar.png";

export default function NavLoginRegister({ path }) {
	const navigate = useNavigate();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<Flex w='100%' py='1.5em' align='center' gap='1rem' justify='center'>
				<Flex w='40%' justify='start'>
					<a href='https://another-tool.com'>
						<Image src={imgLogo} cursor='pointer' />
					</a>
				</Flex>

				<Flex w='40%' justify='end' gap='10' align='center'>
					{path === "/register" ? (
						<>
							<Text fontWeight='bold'>¿Ya tienes una cuenta?</Text>
							<Button variant='primary' size='sm' onClick={() => navigate("/login")}>
								Iniciar Sesión
							</Button>
						</>
					) : (
						<>
							<Text fontWeight='bold'>¿No tienes cuenta?</Text>
							<Button variant='primary' size='sm' onClick={() => navigate("/register")}>
								Regístrate
							</Button>
						</>
					)}
				</Flex>
			</Flex>
		);
	}
	return (
		<Flex w='100%' align='center' justify='center' direction='column'>
			<Flex py='1em'>
				<a href='https://another-tool.com'>
					<Image src={imgLogo} cursor='pointer' />
				</a>
			</Flex>

			<Flex w='100%' justify='space-evenly' align='center'>
				{path === "/register" ? (
					<>
						<Text fontWeight='bold'>¿Ya tienes una cuenta?</Text>
						<Button variant='primary' size='sm' onClick={() => navigate("/login")}>
							Iniciar Sesión
						</Button>
					</>
				) : (
					<>
						<Text fontWeight='bold'>¿No tienes cuenta?</Text>
						<Button variant='primary' size='sm' onClick={() => navigate("/register")}>
							Regístrate
						</Button>
					</>
				)}
			</Flex>
		</Flex>
	);
}
