import { useState, useMemo } from "react";
import {
	useDisclosure,
	Flex,
	Text,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from "@chakra-ui/react";
// comps
import WorkerForm from "./WorkerForm";
import PopoverDelete from "../../../../common/PopoverDelete";
import EditItemButton from "../../../../common/Tables/EditItemButton";
import TableMobile from "../../../../common/Tables/Mobile/TableMobile";
// img
import emptyCars from "../../../../../img/data/emptyCars.png";
// icons
import { LuUser } from "react-icons/lu";
// api
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { getWorkers, deleteWorker } from "../../../../../api/businessesApi";

export default function WorkersTableMobile() {
	const [worker, setWorker] = useState();
	const [page, setPage] = useState(1);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data, isLoading } = useAuthFetch(["workers", page], getWorkers, page);
	const openEdit = (item) => {
		setWorker(item);
		onOpen();
	};
	const openForm = () => {
		setWorker();
		onOpen();
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((worker) => (
				<AccordionItem key={worker.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Flex align='center' gap='1em'>
							<Flex bg={worker.color} boxSize='25px' rounded='md' />
							<Text>{worker.name} </Text>
						</Flex>

						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex direction='column' gap='1em' justify='space-between' fontSize='14px'>
							<Flex gap='0.5em' align='center'>
								<LuUser size='15px' color='black' />
								<Text>{worker.position || "-- --"}</Text>
							</Flex>
							<Flex gap='0.5em' align='center'>
								<LuUser size='15px' color='black' />
								<Text>{worker.phone || "-- --"}</Text>
							</Flex>
							<Flex gap='0.5em' align='center'>
								<LuUser size='15px' color='black' />
								<Text>{worker.email || "-- --"}</Text>
							</Flex>
							<Flex w='100%' justify='end' mt='-2em'>
								<EditItemButton editFn={openEdit} item={worker} />
								<PopoverDelete
									deleteKey={["deleteWorker"]}
									deleteFn={deleteWorker}
									slug={worker?.id}
									refetchKey={["workers"]}
								/>
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data]
	);

	return (
		<>
			<Flex mt='0.6rem' direction='column' w='100%'>
				<TableMobile
					data={data}
					tableData={tableData}
					openFormFn={openForm}
					isLoading={isLoading}
					emptyImg={emptyCars}
					label='Trabajador'
					page={page}
					setPage={setPage}
				/>
			</Flex>
			<WorkerForm worker={worker} isOpen={isOpen} onClose={onClose} />
		</>
	);
}
