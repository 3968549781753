import {
	Button,
	useToast,
	Flex,
	VStack,
	DrawerBody,
	DrawerFooter,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton
} from "@chakra-ui/react";

// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../../common/forms/InputField";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateSupplier } from "../../../../api/stockApi";

export default function SupplierForm({ onClose, isOpen, item: supplier }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateSupplier,
		onSuccess: () => {
			toast({ title: "Creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["suppliers"]);
			QueryClient.refetchQueries("suppliers", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const initialValues = {
		name: supplier ? supplier.name : "",
		address: supplier ? supplier.address : "",
		phone: supplier ? supplier.phone : "",
		email: supplier ? supplier.email : ""
	};
	const validationSchema = Yup.object({
		name: Yup.string().required("Nombre es obligatorio"),
		address: Yup.string(),
		phone: Yup.string().matches(phoneRegExp, "Teléfono no válido").min(9),
		email: Yup.string().email()
	});

	const submit = (values) => {
		let payload = {};
		if (supplier) {
			payload = {
				data: values,
				slug: supplier.id,
				token: authHeader()
			};
		} else {
			payload = {
				data: values,
				token: authHeader()
			};
		}
		mutate(payload);
	};
	return (
		<Drawer isOpen={isOpen} placement='right' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>{supplier ? "Editar" : "Crear"} Proveedor</DrawerHeader>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => submit(values)}
				>
					{(formik) => (
						<>
							<DrawerBody>
								<VStack as='form'>
									<InputField
										label='Nombre'
										name='name'
										required
										error={error?.response?.data?.name}
									/>
									<InputField
										label='Direccion'
										name='address'
										error={error?.response?.data?.address}
									/>
									<InputField label='Telefono' name='phone' error={error?.response?.data?.phone} />
									<InputField label='Email' name='email' error={error?.response?.data?.email} />
								</VStack>
							</DrawerBody>
							<DrawerFooter>
								<Flex justify='right' columnGap='3' mt='3'>
									<Button variant='white' onClick={onClose}>
										Cancelar
									</Button>
									<Button
										variant='primary'
										isDisabled={
											(JSON.stringify(formik.errors) !== "{}") |
											((JSON.stringify(formik.touched) === "{}") &
												(JSON.stringify(formik.values) === "{}"))
										}
										onClick={formik.handleSubmit}
										isLoading={isLoading}
									>
										Guardar
									</Button>
								</Flex>
							</DrawerFooter>
						</>
					)}
				</Formik>
			</DrawerContent>
		</Drawer>
	);
}
