import { useState } from "react";
import {
	useMediaQuery,
	useToast,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Drawer,
	DrawerHeader,
	DrawerBody,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton
} from "@chakra-ui/react";
// comps
import InvoiceForm from "./InvoiceForm";
import InvoiceFormMobile from "./InvoiceFormMobile";
//fns
import { calculateTotalAndTax } from "../fns";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { getAllClients } from "../../../../api/clientsApi";
import { getQuoteOptions, createUpdateQuote, getNextQuoteID } from "../../../../api/quotesApi";
// auth
import { useAuthHeader, useAuthUser } from "react-auth-kit";

export default function QuoteModal({ item: quote, order, isOpen, onClose }) {
	const authHeader = useAuthHeader();
	const auth = useAuthUser();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const QueryClient = useQueryClient();
	const toast = useToast();

	const [total, setTotal] = useState(0);
	const [taxes, setTaxes] = useState(0);
	const [impositiveBase, setImpositiveBse] = useState(0);
	const [subtotal, setSubtotal] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [withTaxes, setWithTaxes] = useState(true);
	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateQuote,
		onSuccess: () => {
			toast({ title: "Factura actualizada!", status: "success" });
			QueryClient.invalidateQueries(["quotes"]);
			QueryClient.refetchQueries("quotes", { force: true });
			onClose();
		},
		onError: () => {
			toast({
				title: "Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const { data: clients } = useAuthFetch(["clients"], getAllClients, { enabled: !!isOpen });
	const { data: nextQuoteID } = useAuthFetch(["quoteID"], getNextQuoteID, { enabled: !!isOpen });
	const { data: options } = useAuthFetch(["quoteOptions"], getQuoteOptions, { enabled: !!isOpen });

	const createPayload = (values) => {
		return {
			data: values,
			...(quote ? { slug: quote.id } : {}),
			token: authHeader()
		};
	};
	const submit = (values) => {
		const payload = createPayload(values);
		payload.data.total = total;
		payload.data.taxes = taxes;
		mutate(payload);
	};
	const calculateTotals = (values, order) => {
		const items = values.items || [];
		let subtotal = 0; // Es la base imponible previa al descuento
		const taxMultiplier = values.tax === "ten" ? 0.1 : values.tax === "twenty" ? 0.21 : 0;
		const priceIncludesTax = values.price_includes_tax;

		// Calcular subtotal
		for (const item of items) {
			subtotal += item.price * item.quantity;
		}
		// Ajustar subtotal si incluye impuestos
		if (priceIncludesTax) {
			subtotal /= 1 + taxMultiplier;
		}

		// Calcular impuestos y subtotal para stock usado
		let taxesStock = 0;
		if (order?.stock_used || values.quote?.stock_used?.length > 0) {
			const stock_used = order?.stock_used || values.quote.stock_used;
			const total_stock = stock_used.reduce((prev, curr) => prev + curr.total, 0);
			const taxes_stock = total_stock * 0.21;
			subtotal += total_stock - taxes_stock;
			taxesStock = taxes_stock;
		}

		// Calcular descuento
		const percentageDiscount = values.discount / 100; // Viene en %
		const totalDiscount = subtotal * percentageDiscount;
		// Calcular base imponible después del descuento
		const impositiveBase = subtotal - totalDiscount;
		// Calcular total y taxes usando la función auxiliar, nunca incluye impuestos, porque ya le paso la bse imponible
		const { total, taxes } = calculateTotalAndTax(false, values.tax, impositiveBase);

		// Sumar los impuestos del stock al total de impuestos
		const totalTaxes = taxes + taxesStock;

		// Actualizar los estados con los valores calculados
		setTotal(total.toFixed(2));
		setImpositiveBse(impositiveBase.toFixed(2));
		setSubtotal(subtotal.toFixed(2));
		setTaxes(totalTaxes.toFixed(2));
		setDiscount(totalDiscount.toFixed(2));
	};
	const isFree = auth().plan === "FREE";

	if (!isMobile) {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size='6xl'>
				<ModalOverlay />
				<ModalContent bg='white' h='80vh'>
					<ModalHeader>
						<Text>{quote ? "Editar" : "Crear"} Presupuesto</Text>
					</ModalHeader>
					<ModalCloseButton />

					<ModalBody>
						<InvoiceForm
							nextQuoteID={nextQuoteID}
							submit={submit}
							clients={clients}
							quote={quote}
							options={options}
							isLoading={isLoading}
							order={order}
							calculateTotals={calculateTotals}
							total={total}
							subtotal={subtotal}
							taxes={taxes}
							impositiveBase={impositiveBase}
							discount={discount}
							isFree={isFree}
							error={error}
							isQuote
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		);
	}
	return (
		<Drawer size='full' isOpen={isOpen} placement='right' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>{quote ? "Editar" : "Crear"} Presupuesto</DrawerHeader>
				<DrawerCloseButton />
				<DrawerBody>
					<InvoiceFormMobile
						nextQuoteID={nextQuoteID}
						submit={submit}
						clients={clients}
						quote={quote}
						options={options}
						isLoading={isLoading}
						order={order}
						withTaxes={withTaxes}
						setWithTaxes={setWithTaxes}
						calculateTotals={calculateTotals}
						total={total}
						subtotal={subtotal}
						taxes={taxes}
						discount={discount}
						isFree={isFree}
						error={error}
						isQuote
					/>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
}
