import { PDFViewer, Page, Text, View, Document } from "@react-pdf/renderer";
import { styles } from "../PDFStyle";
// comps
import QuotePDFHeader from "./QuotePDFHeader";
// fs
import { formatDecimals } from "../../../../common/functions";

export default function QuotePDF({ quote, invoiceSettings, businessProfile }) {
	const discount = quote?.discount > 0 ? (quote.discount / 100) * quote.subtotal : 0;
	return (
		<PDFViewer width='100%' height='100%'>
			<Document title={`Presupuesto  ${quote?.quote_number}`}>
				<Page size='A4' style={styles.page}>
					<QuotePDFHeader businessProfile={businessProfile} invoiceSettings={invoiceSettings} quote={quote} />
					{/* quote description */}
					<View
						style={{
							borderTop: "1px solid #F4F4F9",
							borderBottom: "1px solid #F4F4F9",
							paddingHorizontal: "10",
							paddingVertical: "5px",
							marginTop: "30px",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							color: "#737A87",
							fontSize: "10px"
						}}
					>
						<View style={{ width: "40%" }}>
							<Text>ITEM</Text>
						</View>
						<View
							style={{
								width: "10%",
								textAlign: "right"
							}}
						>
							<Text>CANT.</Text>
						</View>
						<View
							style={{
								width: "15%",
								textAlign: "right"
							}}
						>
							<Text>PRECIO</Text>
						</View>
						<View
							style={{
								width: "20%",
								textAlign: "right"
							}}
						>
							<Text>IVA(€)</Text>
						</View>
						<View
							style={{
								width: "15%",
								textAlign: "right"
							}}
						>
							<Text>TOTAL(€)</Text>
						</View>
					</View>
					{quote?.items?.map((item, index) => (
						<View style={[styles.invoiceRow, index % 2 !== 0 && styles.evenRow]} key={item.id}>
							<View style={{ width: "40%" }}>
								<Text>{item?.concept}</Text>
							</View>
							<View
								style={{
									width: "10%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.quantity)}</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.price)}</Text>
							</View>
							<View
								style={{
									width: "20%",
									textAlign: "right"
								}}
							>
								<Text>
									{formatDecimals(item.taxes)} {quote.taxes > 0 && `(${quote.tax_display})`}
								</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item?.total)}</Text>
							</View>
						</View>
					))}
					{quote?.stock_used?.map((item, index) => (
						<View style={[styles.quoteRow, index % 2 !== 0 && styles.evenRow]} key={item.id}>
							<View style={{ width: "40%" }}>
								<Text>{item?.name}</Text>
							</View>
							<View
								style={{
									width: "10%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.quantity)}</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.price)}</Text>
							</View>
							<View
								style={{
									width: "20%",
									textAlign: "right"
								}}
							>
								<Text>21%</Text>
							</View>
							<View
								style={{
									width: "15%",
									textAlign: "right"
								}}
							>
								<Text>{formatDecimals(item.total)}</Text>
							</View>
						</View>
					))}

					{/* Resumen */}
					<View
						style={{
							flexDirection: "row",
							justifyContent: "flex-end",
							marginTop: "50px"
						}}
					>
						<View
							style={{
								flexDirection: "column",
								gap: "10px",
								alignItems: "flex-end"
							}}
						>
							{quote?.discount > 0 && (
								<>
									<View style={styles.resumeRow}>
										<Text>Subtotal</Text>
										<Text>{formatDecimals(quote?.subtotal)}€</Text>
									</View>

									<View style={styles.resumeRow}>
										<Text>Descuento {quote?.discount}%</Text>
										<Text>{formatDecimals(discount)}€</Text>
									</View>
								</>
							)}
							<View style={styles.resumeRow}>
								<Text>Base Imponible</Text>
								<Text>{formatDecimals(quote?.subtotal - discount)}€</Text>
							</View>
							<View style={styles.resumeRow}>
								<Text>IVA</Text>
								<Text>{formatDecimals(quote?.taxes)}€</Text>
							</View>
							<View style={styles.resumeRow}>
								<Text>Total</Text>
								<Text style={{ fontWeight: "semibold" }}>{formatDecimals(quote?.total)}€</Text>
							</View>
						</View>
					</View>
				</Page>
			</Document>
		</PDFViewer>
	);
}
