import {
	ListItem,
	UnorderedList,
	Box,
	Flex,
	Text,
	Accordion,
	AccordionPanel,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	useMediaQuery
} from "@chakra-ui/react";
//icons

interface WhatIncludesSectionProps {
	whatIncludes: string | undefined;
}

export default function WhatIncludesSection({ whatIncludes }: WhatIncludesSectionProps) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const renderIncludesAsList = (what_includes: string | null) => {
		if (!what_includes) return;
		const items = what_includes.split("\n").filter((item) => item.trim() !== "");
		return (
			<UnorderedList>
				{items.map((item, index) => (
					<ListItem fontSize={isMobile ? "12px" : "16px"} key={index}>
						{item}
					</ListItem>
				))}
			</UnorderedList>
		);
	};

	if (!whatIncludes) return <></>;

	return (
		<Accordion allowToggle>
			<AccordionItem borderTop='none' borderBottom='none'>
				<AccordionButton px='0' rounded='lg'>
					<Box
						color='blue.500'
						fontSize={isMobile ? "14px" : "16px"}
						fontWeight='600'
						as='span'
						flex='1'
						textAlign='left'
					>
						¿Que Incluye?
					</Box>
					<AccordionIcon />
				</AccordionButton>

				<AccordionPanel pb={4}>{renderIncludesAsList(whatIncludes)}</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);
}
