import axios from 'axios'
import { createRefresh } from 'react-auth-kit'

const refreshApi = createRefresh({
    interval: 15, // Refreshs the token in every 19 minutes
    refreshApiCallback: async ({
        authToken,
        authTokenExpireAt,
        refreshToken,
        refreshTokenExpiresAt,
        authUserState
    }) => {
        try {
            // console.log('REFRESHING TOKEN')
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}user/refresh-token`,
                { refresh: refreshToken },
                {
                    headers: { Authorization: `Bearer ${authToken}` }
                }
            )
            return {
                isSuccess: true,
                newAuthToken: response.data.token
                // newAuthTokenExpireIn: 10,
                // newRefreshTokenExpiresIn: 1
            }
        } catch (error) {
            console.error('ERROR REFRESCANDO TOKEN', error)
            return {
                isSuccess: false
            }
        }
    }
})

export default refreshApi
