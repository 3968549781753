import * as Yup from "yup";
import moment from "moment";
//fns
import { formatDecimals } from "../../../common/functions";

const getInitialItems = (orders) => {
	let invoiceItems = [];
	for (const order of orders || []) {
		if (order?.services) {
			const parsedServiceItems = parseOrderServicesToInvoiceItems(order);
			invoiceItems = [...invoiceItems, ...parsedServiceItems];
		}
		if (order?.stock_used) {
			const parsedStockItems = parseOrderStockUsedToInvoiceItems(order);
			invoiceItems = [...invoiceItems, ...parsedStockItems];
		} else {
			invoiceItems.push({
				concept: "",
				price: "",
				quantity: "",
				order: order.id
			});
		}
	}
	return invoiceItems;
};

const parseOrderStockUsedToInvoiceItems = (order) => {
	const usedStock = order.stock_used;
	return usedStock.map((stock) => {
		return {
			concept: stock.name,
			price: formatDecimals(stock.total),
			quantity: formatDecimals(stock.quantity),
			order: order.id
		};
	});
};

const parseOrderServicesToInvoiceItems = (order) => {
	const services = order.services;
	return services.map((service) => {
		return {
			concept: service.name,
			price: formatDecimals(service.price),
			quantity: 1,
			order: order.id
		};
	});
};

export const getInvoiceInitialValues = (orders) => ({
	date: moment().format("YYYY-MM-DD"),
	expiring_date: moment().add(5, "days").format("YYYY-MM-DD"),
	client: orders[0]?.client?.id,
	invoice_number: "",
	status: "pending",
	pay_method: "card",
	price_includes_tax: true,
	total: 0,
	tax: "twenty",
	taxes: 0,
	series: "fact",
	discount: 0,
	items: getInitialItems(orders)
});

export const getInvoiceValidationSchema = () => {
	return Yup.object({
		invoice_number: Yup.string().required("Debes añadir numero de factura"),
		date: Yup.date().required(),
		expiring_date: Yup.date().when(
			"date",
			(date, schema) => date && schema.min(date, "No puede ser anterior a la fecha de la factura")
		),
		client: Yup.number().required("Debes seleccionar un cliente"),
		status: Yup.string(),
		discount: Yup.number("Debe ser un número"),
		items: Yup.array()
			.required("La factura debe contener al menos un item")
			.of(
				Yup.object().shape({
					concept: Yup.string().required("Concepto obligatorio"),
					price: Yup.number().required("Introduce precio"),
					quantity: Yup.number().min(1, "Minima cantidad es 1").required("Debes introducir cantidad"),
					order: Yup.number()
				})
			)
	});
};
