import { Flex, Text, List, ListItem, ListIcon } from "@chakra-ui/react";
// comps
import CheckoutButton from "./CheckoutButton";
// icons
import { AiOutlineCheck } from "react-icons/ai";

interface PlanCardProps {
	planName: "PRO" | "LITE";
	price: number;
	period: "monthly" | "biannual" | "annual";
	features: string[];
}

export default function PlanCard({ planName, price, period, features }: PlanCardProps) {
	return (
		<Flex
			rounded='lg'
			py='1.5em'
			px='2em'
			bg='white'
			direction='column'
			maxW='350px'
			h='100%'
			justify='space-between'
		>
			<Flex direction='column' gap='1rem'>
				<Flex gap='1rem' align='center'>
					<Text fontSize='24px' fontWeight='bold' color='darkblue'>
						Plan {planName}
					</Text>
				</Flex>
				<Flex gap='0.2rem' align='center'>
					<Text color='blue.500' fontWeight='bold' fontSize='32px'>
						{price}€
					</Text>
					<Text fontSize='12px'>/mes*</Text>
				</Flex>
				{planName === "PRO" && (
					<Text fontSize='16px' color='darkblue' fontWeight='bold'>
						Todo lo de Plan LITE mas:
					</Text>
				)}
				<List color='black' spacing={4}>
					{features?.map((feature, index) => (
						<ListItem key={index}>
							<ListIcon as={AiOutlineCheck} color='blue.500' />
							{feature}
						</ListItem>
					))}
				</List>
				<CheckoutButton label='Actualizar' variant='secondary' plan={planName} period={period} />
			</Flex>
			<Flex direction='column' gap='1rem'>
				<Text color='darkblue' mt='1rem'>
					Con <span style={{ fontWeight: "bold" }}>recordatorios automáticos</span> por WhatsApp
				</Text>
				<Flex gap='0.2rem' align='center'>
					<Text color='blue.500' fontWeight='bold' fontSize='32px'>
						{price + 6}€
					</Text>
					<Text fontSize='12px'>/mes*</Text>
				</Flex>
				<CheckoutButton label='Actualizar' variant='primary' plan={`${planName}_WHATSAPP`} period={period} />
			</Flex>
		</Flex>
	);
}
