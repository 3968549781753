import { Badge } from '@chakra-ui/react'

export default function ProTag ({ ...props }) {
    return (
        <Badge
            {...props}
            cursor="pointer"
            bg="blue.500"
            color="white"
            rounded="xl"
            align="center"
            fontWeight="400"
            w="50px"
            fontSize='10px'
            zIndex="1"
        >
            PRO
        </Badge>
    )
}
