import { useState } from "react";
import { Flex, Select, Tabs, TabList, TabPanels, Tab, TabPanel, useMediaQuery } from "@chakra-ui/react";
// comps
import SuppliersTable from "../../components/pages/stock/tables/SuppliersTable";
import SuppliersTableMobile from "../../components/pages/stock/tables/mobile/SuppliersTableMobile";
import StockTableMobile from "../../components/pages/stock/tables/mobile/StockTableMobile";
import StockTable from "../../components/pages/stock/tables/StockTable";
import PurchasesTable from "../../components/pages/stock/tables/PurchasesTable";
import PurchasesTableMobile from "../../components/pages/stock/tables/mobile/PurchasesTableMobile";
import SalesTable from "../../components/pages/stock/tables/SalesTable";
import ExpensesTable from "../../components/pages/stock/tables/ExpensesTable";
import ExpensesTableMobile from "../../components/pages/stock/tables/mobile/ExpensesTableMobile";
import HelperModal from "../../components/tutorial/HelperModal";

export default function Stock() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [tableSelect, setTableSelect] = useState("stock");

	if (!isMobile) {
		return (
			<>
				<Flex justify='center' align='top' py='30px' w='100%'>
					<Tabs
						variant='brand'
						rounded='xl'
						bg='white'
						px='20px'
						pt='30px'
						isLazy={true}
						minH='60vh'
						width={{ base: "100%", md: "700px", lg: "800px", xl: "1080px", "2xl": "1300px" }}
					>
						<TabList ml='15px'>
							<Tab>Inventario</Tab>
							<Tab>Compras</Tab>
							<Tab>Gastos fijos</Tab>
							<Tab>Proveedores</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<StockTable />
							</TabPanel>
							<TabPanel>
								<PurchasesTable />
							</TabPanel>
							<TabPanel>
								<ExpensesTable />
							</TabPanel>
							<TabPanel>
								<SuppliersTable />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Flex>
				<HelperModal />
			</>
		);
	}
	return (
		<Flex w='100%' direction='column' px='1em' pt='0.5em'>
			<Select
				value={tableSelect}
				onChange={(e) => setTableSelect(e.target.value)}
				color='black'
				fontWeight='bold'
				maxW='175px'
				fontSize='20px'
				border='none'
				h='5dvh'
			>
				<option value='stock' style={{ fontSize: "16px" }}>
					Inventario
				</option>
				<option value='purchases' style={{ fontSize: "16px" }}>
					Compras
				</option>
				<option value='expenses' style={{ fontSize: "16px" }}>
					Gastos
				</option>
				<option value='suppliers' style={{ fontSize: "16px" }}>
					Proveedores
				</option>
			</Select>
			{tableSelect === "stock" && <StockTableMobile />}
			{tableSelect === "purchases" && <PurchasesTableMobile />}
			{tableSelect === "expenses" && <ExpensesTableMobile />}
			{tableSelect === "suppliers" && <SuppliersTableMobile />}
		</Flex>
	);
}
