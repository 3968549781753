import { Text } from "@chakra-ui/react";
// img
import emptyWaiting from "../../../img/orders/emptyWaitingOrders.png";
import emptyPending from "../../../img/orders/emptyPendingOrders.png";
import emptyStarted from "../../../img/orders/emptyStartedOrders.png";
import emptyCompleted from "../../../img/orders/emptyCompletedOrders.png";

// Constantes
export const STATUS_COLORS = {
	pending: "gray",
	waiting: "blue",
	started: "orange",
	completed: "green"
};

export const STATUS_TITLES = {
	pending: "Pendientes",
	waiting: "En espera",
	started: "En curso",
	completed: "Completadas"
};

export const STATUS_EMPTY_MESSAGES = {
	pending: "No tienes nuevas órdenes para mostrar. Haz clic en + Orden para crear una nueva.",
	waiting:
		"Aquí se muestran las órdenes de trabajo que has aceptado y agendado, pero aún están en la cola para empezar a trabajar en ellas. Añade una fecha de entrada y salida a la órden, para que se muestre aquí.",
	started:
		"Aquí se mostrarán las órdenes de trabajo en las que estás trabajando. Una vez haya pasado la fecha de salida asignada, finalizarán automáticamente.",
	completed:
		"¡Enhorabuena! En esta estación se mostrarán las órdenes de trabajo que hayas completado. Fíjate en el color del precio para ver el estado de la factura."
};

// Funciones
export const getEmptyImage = (type) => {
	if (type === "pending") {
		return emptyPending;
	}
	if (type === "waiting") {
		return emptyWaiting;
	}
	if (type === "started") {
		return emptyStarted;
	}
	if (type === "completed") {
		return emptyCompleted;
	}
};

export const getEmptyText = (type) => {
	return <Text align='center'>{STATUS_EMPTY_MESSAGES[type]}</Text>;
};

export const getColor = (type) => {
	return STATUS_COLORS[type];
};

export const getTitleDisplay = (type) => {
	return STATUS_TITLES[type];
};

export const formatOrderIncrementalID = (incrementalID) => {
	const id = incrementalID >= 0 ? incrementalID : 1;
	return `#${id.toString().padStart(5, "0")}`;
};
