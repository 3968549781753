import { Badge } from "@chakra-ui/react";
//types
import type { Plan } from "../../types/plan";

interface UserPlanDisplayProps {
	plan: Plan["name"];
	size: "sm" | "md";
}

export default function UserPlanDisplay({ plan, size }: UserPlanDisplayProps) {
	const planColor = {
		FREE: {
			bg: "transparent",
			color: "blue.500",
			display: "Gratis"
		},
		TRIAL: {
			bg: "#FF7C02",
			color: "white",
			display: "Prueba Gratis"
		},
		PRO: {
			bg: "blue.500",
			color: "white",
			display: "PRO"
		},
		BETA: {
			bg: "#FF7C02",
			color: "white",
			display: "BETA TESTER"
		},
		MASTER: {
			bg: "pink.500",
			color: "white",
			display: "MASTER"
		},
		LITE: {
			bg: "pink.400",
			color: "white",
			display: "LITE"
		},
		LITE_WHATSAPP: {
			bg: "pink.400",
			color: "white",
			display: "LITE + WhatsApp"
		},
		PRO_WHATSAPP: {
			bg: "blue.500",
			color: "white",
			display: "PRO + WhatsApp"
		}
	};

	if (size === "md") {
		return (
			<Badge rounded='2xl' bg={planColor[plan]?.bg || "grey"} color='white' py='0.5em' px='1.5em'>
				{planColor[plan]?.display}
			</Badge>
		);
	}
	return (
		<Badge rounded='xl' px='0.5em' bg={planColor[plan]?.bg || "grey"} color={planColor[plan]?.color || "gray"}>
			{plan}
		</Badge>
	);
}
