import { useState } from "react";
import { Flex, Text, Select, useMediaQuery } from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
// comps
import PlanCard from "../../components/payments/PlanCard";
import StartingOfferCard from "./StartingOfferCard";

type Period = "monthly" | "biannual" | "annual";
interface PlanMeta {
	prices: {
		monthly: number;
		biannual: number;
		annual: number;
	};
	extraFeatures?: string[];
}

interface PlanSelectionProps {
	isDirectPayment?: boolean;
}

export default function PlanSelection({ isDirectPayment = false }: PlanSelectionProps) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [period, setPeriod] = useState<Period>("monthly");

	const commonFeatures = [
		"Gestión de clientes",
		"Órdenes de trabajo y agenda",
		"Historiales de vehículos",
		"Perfil de negocio con reservas online"
	];
	const litePlanMeta: PlanMeta = {
		prices: {
			monthly: 15,
			biannual: 13,
			annual: 11
		}
	};

	const proPlanMeta: PlanMeta = {
		extraFeatures: [
			"Panel de Órdenes de Trabajo",
			"Facturación y Presupuestos",
			"Stock de productos",
			"Resguardos de Depósito",
			"Estadísticas Avanzadas"
		],
		prices: {
			monthly: 29,
			biannual: 26,
			annual: 24
		}
	};
	const whatsappPlanMeta: PlanMeta = {
		extraFeatures: [
			"Facturación y Presupuestos",
			"Stock de productos",
			"Resguardos de Depósito",
			"Estadísticas Avanzadas",
			"WhatsApps Automáticos"
		],
		prices: {
			monthly: 35,
			biannual: 32,
			annual: 29
		}
	};
	if (!isMobile) {
		return (
			<Flex
				direction='column'
				align='center'
				width={{
					base: "100%",
					md: "700px",
					lg: "800px",
					xl: "1080px",
					"2xl": "1300px"
				}}
			>
				<Flex w='100%' justify='center' mt='1rem' mb='1rem' align='center' gap='1rem'>
					<Text>Periodo de facturación</Text>
					<Select
						value={period}
						bg='white'
						maxW='200px'
						onChange={(e) => setPeriod(e.target.value as Period)}
					>
						<option value='monthly'>Mensual</option>
						<option value='annual'>Anual</option>
					</Select>
				</Flex>
				<Grid p='2rem' templateColumns={isDirectPayment ? "repeat(2, 1fr)" : "repeat(3, 1fr)"} gap={6}>
					<GridItem>
						<PlanCard
							planName='LITE'
							price={litePlanMeta.prices[period]}
							period={period}
							features={commonFeatures}
						/>
					</GridItem>
					<GridItem>
						<PlanCard
							period={period}
							planName='PRO'
							price={proPlanMeta.prices[period]}
							features={proPlanMeta.extraFeatures || []}
						/>
					</GridItem>
					{!isDirectPayment && (
						<GridItem>
							<StartingOfferCard />
						</GridItem>
					)}
				</Grid>
				<Text color='black' fontSize='14px'>
					* IVA no incluido
				</Text>
			</Flex>
		);
	}
	return (
		<>
			<Flex w='100%' justify='center' mt='1rem'>
				<Select value={period} bg='white' maxW='200px' onChange={(e) => setPeriod(e.target.value as Period)}>
					<option value='monthly'>Mensual</option>
					<option value='annual'>Anual</option>
				</Select>
			</Flex>

			<Flex mt='1rem' pb='2rem' w='100%' direction='column' justify='center' align='center' gap='2rem'>
				<PlanCard
					planName='LITE'
					price={litePlanMeta.prices[period]}
					period={period}
					features={commonFeatures}
				/>
				<PlanCard
					period={period}
					planName='PRO'
					price={proPlanMeta.prices[period]}
					features={[...commonFeatures, ...(proPlanMeta.extraFeatures || [])]}
				/>
			</Flex>
		</>
	);
}
