import { Flex, Text, Checkbox, Divider, useMediaQuery } from "@chakra-ui/react";
import moment from "moment";
// comps
import InputField from "../../../../common/forms/InputField";
import SelectFieldFilter from "../../../../common/forms/SelectFieldFilter";
import TextareaField from "../../../../common/forms/TextareaField";
// icons
import { BiCar } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa6";
// api
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { getCarBrandChoices } from "../../../../../api/carsApi";

export default function ClientDataStep({ formik, mutateError }) {
	const { data: carChoices } = useAuthFetch(["carChoices"], getCarBrandChoices);
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<Flex
				direction='column'
				gap='2rem'
				minW='50%'
				maxH='70dvh'
				overflowY='scroll'
				pr='1rem'
				sx={{
					flexGrow: 1,
					"&::-webkit-scrollbar": { width: "5px" },
					"&::-webkit-scrollbar-track": {
						backgroundColor: "#f5f5f5"
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: "#cfcfcf"
					}
				}}
			>
				<Flex gap='1em' direction='column' mt='1em'>
					<Flex align='center' gap='0.5em'>
						<BiCar fontSize='20px' color='black' />
						<Text align='start' fontWeight='bold'>
							Tu coche
						</Text>
					</Flex>
					<Flex gap='1rem'>
						<SelectFieldFilter
							required
							label='Marca'
							options={carChoices}
							name='brand'
							error={mutateError?.response?.data?.brand}
						/>
						<InputField label='Modelo' name='model' error={mutateError?.response?.data?.model} />
					</Flex>
					<TextareaField name='observations' label='Información adicional para el centro' />
				</Flex>
				{isMobile && <Divider my={!isMobile && "0.5em"} />}
				<Flex direction='column' gap='1em'>
					<Flex align='center' gap='0.5em'>
						<FaRegUser fontSize='15px' color='black' />
						<Text align='start' fontWeight='bold'>
							Tú
						</Text>
					</Flex>
					<InputField
						label='Nombre '
						name='client_name'
						required
						error={mutateError?.response?.data?.client_name}
					/>
					<InputField
						label='Email '
						name='client_email'
						required
						error={mutateError?.response?.data?.client_email}
					/>
					<InputField
						label='Teléfono '
						name='client_phone'
						required
						error={mutateError?.response?.data?.client_phone}
					/>
					<InputField
						label='NIF/CIF '
						name='client_nif_cif'
						error={mutateError?.response?.data?.client_nif_cif}
					/>
					<TextareaField
						name='client_address'
						label='Dirección '
						error={mutateError?.response?.data?.client_address}
					/>
					<Flex>
						<Checkbox
							name='has_signed_consent'
							isChecked={formik.values.has_signed_consent}
							onChange={() =>
								formik.setFieldValue("has_signed_consent", !formik.values.has_signed_consent)
							}
						/>
						<Text align='start' ml='1rem'>
							Acepto que utilicen mi teléfono para enviarme notificaciones por whatsapp
						</Text>
					</Flex>
				</Flex>
			</Flex>
		);
	}
	return (
		<Flex align='start' mt='1.5rem' w='100%' justify='space-between' gap='2em' direction={isMobile && "column"}>
			<Flex gap='1em' direction='column' mt='1em'>
				<Flex align='center' gap='0.5em'>
					<BiCar fontSize='20px' color='black' />
					<Text align='start' fontWeight='bold'>
						Tu coche
					</Text>
				</Flex>

				<SelectFieldFilter
					required
					label='Marca'
					options={carChoices}
					name='brand'
					error={mutateError?.response?.data?.brand}
				/>
				<InputField label='Modelo' name='model' error={mutateError?.response?.data?.model} />
				<TextareaField name='observations' label='Información adicional para el centro' />
			</Flex>
			{isMobile && <Divider my={!isMobile && "0.5em"} />}
			<Flex direction='column' gap='1em'>
				<Flex align='center' gap='0.5em'>
					<FaRegUser fontSize='15px' color='black' />
					<Text align='start' fontWeight='bold'>
						Tú
					</Text>
				</Flex>
				<InputField
					label='Nombre '
					name='client_name'
					required
					error={mutateError?.response?.data?.client_name}
				/>
				<InputField
					label='Email '
					name='client_email'
					required
					error={mutateError?.response?.data?.client_email}
				/>
				<InputField
					label='Teléfono '
					name='client_phone'
					required
					error={mutateError?.response?.data?.client_phone}
				/>
				<InputField
					label='NIF/CIF '
					name='client_nif_cif'
					error={mutateError?.response?.data?.client_nif_cif}
				/>
				<TextareaField
					name='client_address'
					label='Dirección '
					error={mutateError?.response?.data?.client_address}
				/>
				<Flex align='center'>
					<Checkbox
						name='has_signed_consent'
						isChecked={formik.values.has_signed_consent}
						onChange={() => formik.setFieldValue("has_signed_consent", !formik.values.has_signed_consent)}
					/>
					<Text>Acepto que utilicen mi teléfono para enviarme notificaciones por whatsapp</Text>
				</Flex>
			</Flex>
		</Flex>
	);
}
