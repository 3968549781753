import { useState, useEffect } from "react";
import { Flex, Checkbox, Text } from "@chakra-ui/react";
// icon
import { PiGasPump } from "react-icons/pi";

export default function DepositReceiptFuelStatus({ formik, error }) {
	const [fuelStatus, setFuelStatus] = useState("half");
	useEffect(() => {
		formik.setFieldValue("fuel_status", fuelStatus);
	}, [fuelStatus]);

	return (
		<Flex direction='column' gap='0.5em'>
			<Flex align='center' gap='0.5em'>
				<PiGasPump color='black' size='20px' />
				<Text>Combustible</Text>
			</Flex>
			<Flex>
				<Checkbox onChange={() => setFuelStatus("reserve")} isChecked={fuelStatus === "reserve"} />
				<Text>R</Text>
				<Checkbox ml='0.5em' onChange={() => setFuelStatus("quarter")} isChecked={fuelStatus === "quarter"} />
				<Text>1/4</Text>
				<Checkbox ml='0.5em' onChange={() => setFuelStatus("half")} isChecked={fuelStatus === "half"} />
				<Text>1/2</Text>
				<Checkbox
					ml='0.5em'
					onChange={() => setFuelStatus("three_quarters")}
					isChecked={fuelStatus === "three_quarters"}
				/>
				<Text>3/4</Text>
				<Checkbox ml='0.5em' onChange={() => setFuelStatus("full")} isChecked={fuelStatus === "full"} />
				<Text>LL</Text>
			</Flex>
			{error && <Text color='red'>{error}</Text>}
		</Flex>
	);
}
