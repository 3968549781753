import { useEffect } from "react";
//comps
import InfoTooltip from "../../../common/InfoTooltip";
//forms
import SelectField from "../../../common/forms/SelectField";
import InputField from "../../../common/forms/InputField";
//api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getNextInvoiceID } from "../../../../api/invoicesApi";

export default function InvoiceNumber({ invoice, formik, choices, error }) {
	const filter = `?series=${formik.values.series}`;

	const { data: nextInvoiceID, isLoading } = useAuthFetch(
		["invoiceID", formik.values.series],
		getNextInvoiceID,
		null,
		filter
	);
	useEffect(() => {
		if (invoice && invoice.series === formik.values.series) {
			formik.setFieldValue("invoice_number", invoice?.invoice_number);
		} else {
			formik.setFieldValue("invoice_number", nextInvoiceID);
		}
	}, [nextInvoiceID]);

	return (
		<>
			<SelectField label='Serie de factura' name='series' choices={choices} />
			<InputField
				isLoading={isLoading}
				name='invoice_number'
				error={error?.response?.data?.invoice_number}
				label={<InfoTooltip label={"Nº"} desc={"Puedes configurarlo desde panel de ajustes"} />}
			/>
		</>
	);
}
