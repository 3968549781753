import axios from 'axios'

const plansApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}plans`
})

export const upgradePlan = async (payload) => {
    const response = await plansApi.post(
        '/upgrade',
        {},
        {
            headers: { Authorization: payload.token }
        }
    )
    return response.data
}
export const downgradePlan = async (payload) => {
    const response = await plansApi.post(
        '/downgrade',
        {},
        {
            headers: { Authorization: payload.token }
        }
    )
    return response.data
}

export const getUserPlan = async (payload) => {
    const response = await plansApi.get('/user', {
        headers: { Authorization: payload.token }
    })
    return response.data[0]
}

export const getExtraPlans = async (payload) => {
    const response = await plansApi.get('/extra', {
        headers: { Authorization: payload.token }
    })
    return response.data
}

export const getAllPlans = async (token) => {
    const response = await plansApi.get('/all', {
        headers: { Authorization: token }
    })
    return response.data
}

// Only for admin users
export const updateUserPlan = async (token) => {
    const response = await plansApi.get('/all', {
        headers: { Authorization: token }
    })
    return response.data
}

export const getPlanLimitations = async (payload) => {
    const response = await plansApi.get('/limitations', {
        headers: { Authorization: payload.token }
    })
    return response.data
}
