import { Box, Button, Flex } from '@chakra-ui/react'
// icons
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi'

export default function TutorialPagination ({ step, slides, setStep }) {
    const dots = slides.map((slide, index) => (
        <Box
            key={index}
            align="center"
            width="10px"
            height="10px"
            borderRadius="50%"
            background="gray.300"
            margin="0 5px"
            onClick={() => {
                setStep(index)
            }}
        >
            {index === step ? <Box width="15px" height="15px" borderRadius="50%" backgroundColor="blue" /> : null}
        </Box>
    ))
    return (
        <Flex justifyContent="space-between" alignItems="center">
            <Button
                variant="outlined"
                leftIcon={<BiLeftArrow />}
                onClick={() => {
                    setStep((step - 1 + slides.length) % slides.length)
                }}
            />
            <Flex justify="center">{dots}</Flex>
            <Button
                variant="outlined"
                rightIcon={<BiRightArrow />}
                onClick={() => {
                    setStep((step + 1) % slides.length)
                }}
            />
        </Flex>
    )
}
