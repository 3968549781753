import { Tooltip, Flex } from "@chakra-ui/react";
// icons
import { CiWarning } from "react-icons/ci";

interface WarningTooltip {
	label: string;
	desc: string;
}

export default function WarningTooltip({ label, desc }: WarningTooltip) {
	return (
		<Tooltip
			shouldWrapChildren={true}
			label={desc}
			aria-label='A tooltip'
			fontWeight='hairline'
			fontSize='14px'
			rounded='lg'
			bg='lightgray'
			color='darkblue'
		>
			<Flex align='center' gap='0.2rem'>
				<CiWarning color='#FF7C02' size='20px' />
				{label}
			</Flex>
		</Tooltip>
	);
}
