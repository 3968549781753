import { Flex, Text, Button } from '@chakra-ui/react'
import { useState } from 'react'
// comps
import TextareaField from '../../../common/forms/TextareaField'
// icons
import { PiChatTeardropTextBold } from 'react-icons/pi'

export default function ClientAddressField ({ error }) {
    const [display, setDisplay] = useState(false)

    return (
        <Flex direction="column">
            <Flex align="center">
                <PiChatTeardropTextBold size="20px" color="black" />
                <Text ml="0.5em" mr="1em">
                    Dirección
                </Text>
                <Button size="xs" variant={display ? 'grey' : 'primary'} onClick={() => setDisplay(!display)}>
                    {display ? '-' : '+'}
                </Button>
            </Flex>
            {display &&
                <TextareaField
                    name="address"
                    error={error}
                />}
        </Flex>
    )
}
