import { Flex, Text } from "@chakra-ui/react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
//icons
import { GoClock } from "react-icons/go";

export default function WorkerHoursChart({ data }) {
	const formatMinutesToHHMM = (minutes) => {
		const hours = Math.floor(minutes / 60);
		const mins = minutes % 60;
		return `${hours}h ${mins}m`;
	};

	const renderCustomizedLabel = ({ name, now }) => {
		return formatMinutesToHHMM(now);
	};

	if (data) {
		return (
			<Flex direction='column' align='center' w='100%' h='100%' bg='white' p='1.5rem' rounded='xl'>
				<Flex w='100%' align='center' gap='0.5rem' mb='2rem'>
					<GoClock size='24px' />
					<Text fontSize='24px' fontWeight='bold'>
						Horas trabajadas
					</Text>
				</Flex>
				<ResponsiveContainer width='100%' height='50%'>
					<PieChart>
						<Pie
							data={data}
							dataKey='now'
							label={renderCustomizedLabel}
							cx='50%'
							cy='50%'
							innerRadius={"45%"}
							outerRadius={"80%"}
							fill='#8884d8'
							paddingAngle={4}
						>
							{data.map((entry) => (
								<Cell key={`cell-${entry.name}`} fill={entry.color} />
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
				<Flex direction='column' gap='0.5rem' alignSelf='start'>
					{data.map((worker) => (
						<Flex key={worker.name} gap='1rem'>
							<Flex bg={worker.color} rounded='full' w='15px' h='15px' />
							<Text>{worker.name}</Text>
						</Flex>
					))}
				</Flex>
			</Flex>
		);
	}
	return <></>;
}
