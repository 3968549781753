import { Image, useDisclosure } from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./carouselCustom.css";
import { Carousel } from "react-responsive-carousel";
//comps
import CarouselModal from "./CarouselModal";

export default function BusinessImageCarousel({ images }) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const defaultImage =
		"https://www.shutterstock.com/image-photo/red-sportscars-wheels-covered-shampoo-600nw-2287564577.jpg";
	const hasImages = images?.length && images.length > 0;

	return (
		<>
			<Carousel onClickItem={onOpen} showThumbs={false} dynamicHeight={true} infiniteLoop={true}>
				{hasImages ? (
					images.map((image) => (
						<Image
							objectFit='cover'
							maxH='480px'
							cursor='pointer'
							key={image.id}
							rounded='lg'
							src={image.work_image}
						/>
					))
				) : (
					<Image rounded='lg' objectFit='cover' maxH='450px' src={defaultImage} />
				)}
			</Carousel>
			<CarouselModal isOpen={isOpen} onClose={onClose} images={images} />
		</>
	);
}
