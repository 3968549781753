import { Fragment, useEffect, useState } from "react";
import { Text, Flex, Button, Switch, Accordion } from "@chakra-ui/react";
// comps
import InfoTooltip from "../../../common/InfoTooltip";
import InvoiceNumber from "../forms/InvoiceNumber";
import MultiInvoiceItem from "./MultiInvoiceItem";
import MultiDiscountField from "./MultiDiscountField";
// fs
import { formatDecimals } from "../../../common/functions";
import { getInvoiceInitialValues, getInvoiceValidationSchema } from "./utils";
// forms and validation
import { Formik } from "formik";
import InputField from "../../../common/forms/InputField";
import SelectField from "../../../common/forms/SelectField";
import SelectFieldFilter from "../../../common/forms/SelectFieldFilter";

export default function MultiInvoiceForm(props) {
	const {
		submit,
		clients,
		calculateTotals,
		options,
		isLoading,
		orders,
		total,
		taxes,
		subtotal,
		impositiveBase,
		discount,
		error
	} = props;
	const [orderError, setOrderError] = useState(false);

	const taxChoices = options?.actions?.POST?.tax?.choices;
	const payMethodChoices = options?.actions?.POST?.pay_method?.choices;
	const invoiceStatusChoices = options?.actions?.POST?.status?.choices;
	const invoiceSeriesChoices = options?.actions?.POST?.series?.choices;

	const initialValues = getInvoiceInitialValues(orders);
	const validationSchema = getInvoiceValidationSchema();

	const handleSubmit = (values) => {
		submit(values);
	};
	useEffect(() => {
		const hasInvoice = orders.some((order) => order.invoice);
		setOrderError(hasInvoice);
	}, [orders]);

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			validationSchema={validationSchema}
			onSubmit={(values) => handleSubmit(values)}
		>
			{(formik) => (
				<Flex w='100%' gap='25px' h='100%'>
					<Flex
						direction='column'
						gap='1em'
						w='75%'
						maxH='70vh'
						overflowY='scroll'
						pr='1em'
						sx={{
							"&::-webkit-scrollbar": { width: "5px" },
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#f5f5f5"
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#cfcfcf"
							}
						}}
					>
						{/* Basic data section */}
						<Flex gap='1rem'>
							<InvoiceNumber formik={formik} error={error} choices={invoiceSeriesChoices} />
							<InputField name='date' label='Fecha' type='date' error={error?.response?.data?.date} />
							<InputField
								name='expiring_date'
								label='Fecha de Expiración'
								type='date'
								error={error?.response?.data?.expiring_date}
							/>
						</Flex>
						<Flex gap='1rem' align='end'>
							<Flex w='150px'>
								<SelectField name='tax' choices={taxChoices} bg='white' label='Impuestos' />
							</Flex>
							<Flex>
								<InfoTooltip
									label={
										<Flex align='center' gap='0.5em'>
											<Text>Precios con IVA</Text>
											<Switch
												colorScheme='brand'
												isChecked={formik.values.price_includes_tax}
												value={formik.values.price_includes_tax}
												onChange={() =>
													formik.setFieldValue(
														"price_includes_tax",
														!formik.values.price_includes_tax
													)
												}
											/>
										</Flex>
									}
									desc={"Activa la casilla si los precios ya incluyen IVA"}
								/>
							</Flex>
						</Flex>
						<Accordion defaultIndex={[0]} allowMultiple>
							{orders?.map((order) => (
								<Fragment key={order.id}>
									<MultiInvoiceItem
										order={order}
										priceIncludesTax={formik.values.price_includes_tax}
										tax={formik.values.tax}
									/>
								</Fragment>
							))}
						</Accordion>
					</Flex>
					<Flex direction='column' h='100%' w='25%' justify='space-between'>
						<Flex direction='column' gap='1em'>
							<SelectFieldFilter
								required
								label='Cliente'
								options={clients?.results}
								name='client'
								error={error?.response?.data?.clients}
							/>
							{clients?.count === 0 && (
								<Flex mt='1em' align='center' gap='1em'>
									<Text fontSize='14px' color='red'>
										Aún no hay clientes, crea uno desde el panel de datos
									</Text>
								</Flex>
							)}
							<SelectField label='Método de pago' name='pay_method' choices={payMethodChoices} />

							<SelectField label='Estado' name='status' choices={invoiceStatusChoices} />

							<MultiDiscountField formik={formik} calculateTotals={calculateTotals} orders={orders} />
						</Flex>
						<Flex direction='column' gap='2em'>
							<Flex w='100%' justify='space-between'>
								<Flex direction='column' gap='1em'>
									{Number(discount) > 0 && <Text fontSize='20px'>Subtotal</Text>}
									{Number(discount) > 0 && <Text fontSize='20px'>Descuento</Text>}
									<Text fontSize='20px'>Base Imponible</Text>
									<Text fontSize='20px'>IVA</Text>
									<Text fontSize='20px' fontWeight='bold'>
										Total
									</Text>
								</Flex>
								<Flex direction='column' gap='1em'>
									{Number(discount) > 0 && (
										<Text fontSize='20px' align='end'>
											{formatDecimals(subtotal)}€
										</Text>
									)}
									{Number(discount) > 0 && (
										<Text fontSize='20px' align='end'>
											-{formatDecimals(discount)}€
										</Text>
									)}
									<Text fontSize='20px' align='end'>
										{formatDecimals(impositiveBase)}€
									</Text>
									<Text fontSize='20px' align='end'>
										{formatDecimals(taxes)}€
									</Text>
									<Text fontSize='20px' fontWeight='bold' align='end'>
										{formatDecimals(total)}€
									</Text>
								</Flex>
							</Flex>
							{orderError && (
								<Text fontWeight='semibold' color='red.500'>
									Una o más órdenes ya tienen una factura asociada.
								</Text>
							)}
							<Button
								variant='primary'
								justifySelf='end'
								alignSelf='end'
								w='150px'
								isDisabled={!formik.dirty || !formik.isValid || orderError}
								onClick={formik.handleSubmit}
								isLoading={isLoading}
							>
								Guardar
							</Button>
						</Flex>
					</Flex>
				</Flex>
			)}
		</Formik>
	);
}
