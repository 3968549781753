import { useState, useMemo, useCallback } from "react";
import {
	useToast,
	useDisclosure,
	Flex,
	Text,
	Box,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from "@chakra-ui/react";
// comps
import TableMobile from "../../../common/Tables/Mobile/TableMobile";
import ClientForm from "./ClientForm";
import PopoverDelete from "../../../common/PopoverDelete";
import WhatsappConsentModal from "./WhatsappConsentModal";
import EditItemButton from "../../../common/Tables/EditItemButton";
// icons
import { LuFileSignature } from "react-icons/lu";
import { MdOutlinePhone, MdOutlineMailOutline } from "react-icons/md";
// img
import emptyClients from "../../../../img/data/emptyClients.png";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { useQueryClient } from "@tanstack/react-query";
import { getAllClientsPag, deleteClient } from "../../../../api/clientsApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function ClientsTableMobile() {
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [client, setClient] = useState();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data, isLoading } = useAuthFetch(["clients", page], getAllClientsPag, page);

	const { mutate, isLoading: ld } = useAuthMutation({
		mutationFn: deleteClient,
		onSuccess: () => {
			toast({ title: "Borrado con exito!", status: "success" });
			QueryClient.invalidateQueries(["clients"]);
			QueryClient.refetchQueries("clients", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const openEdit = (item) => {
		setClient(item);
		onOpen();
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((client) => (
				<AccordionItem key={client.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Box as='span' flex='1' textAlign='left' fontSize='14px' color='darkblue'>
							{client.name}
						</Box>
						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex
							w='100%'
							h='100%'
							direction='column'
							gap='1em'
							justify='space-between'
							fontSize='14px'
							p='0.5em'
						>
							<Flex gap='0.5em' align='center'>
								<MdOutlinePhone size='15px' color='black' />
								<Text>{client.phone || "-- --"}</Text>
							</Flex>
							<Flex gap='0.5em' align='center'>
								<MdOutlineMailOutline size='18px' color='black' />
								<Text>{client.email || "-- --"}</Text>
							</Flex>
							<Flex gap='0.5em' align='center'>
								<LuFileSignature size='18px' color='black' />
								<WhatsappConsentModal client={client} isFromTable size='sm' />
							</Flex>
							<Flex w='100%' justify='end' mt='-3em'>
								<EditItemButton editFn={openEdit} item={client} />
								<PopoverDelete
									deleteKey={["deleteClient"]}
									deleteFn={deleteClient}
									slug={client?.id}
									refetchKey={["clients"]}
								/>
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data, ld]
	);
	const openForm = () => {
		setClient();
		onOpen();
	};
	return (
		<>
			<TableMobile
				data={data}
				tableData={tableData}
				openFormFn={openForm}
				isLoading={isLoading}
				emptyImg={emptyClients}
				label='Órden'
				page={page}
				setPage={setPage}
			/>
			<ClientForm item={client} onClose={onClose} isOpen={isOpen} />
		</>
	);
}
