import { Spinner, Flex, Text } from '@chakra-ui/react'

export default function LoadingSpinner ({ ...props }) {
    return (
        <Flex direction="column" justify="center" align="center" gap="1em" {...props}>
            <Spinner color="#0258FF" emptyColor="gray.200" speed="0.7s" thickness="3px" size="lg" />
            <Text> Cargando... </Text>
        </Flex>
    )
}
