import { Button, useToast } from "@chakra-ui/react";
// fs
import dataURItoBlob from "../functions";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateInspection } from "../../../../api/inspectionsApi";

export default function SubmitInspection({ photo, order, state, savedInspection, isBefore, zone }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { isLoading, mutate } = useAuthMutation({
		mutationFn: createUpdateInspection,
		onSuccess: () => {
			toast({ title: "Inspección creada!", status: "success" });
			QueryClient.invalidateQueries(["inspection", order, isBefore, zone]);
			QueryClient.refetchQueries(["inspection", order, isBefore, zone], { force: true });
		},
		onError: (error) => {
			toast({
				title: "Error al crear la inspección",
				description: error.message || "Ha ocurrido un error inesperado",
				status: "error"
			});
		}
	});
	const disableSumbit = () => {
		if (!order) {
			return true;
		} else return false;
	};
	const parseImage = (side) => {
		if (photo[side] === undefined) {
			return undefined;
		}
		if (photo[side] === null) {
			return "delete";
		} else {
			return photo[side]?.file;
			/*
            if (typeof image === "string" && image.startsWith("http")) {
                return;
            } else {
                return new File([dataURItoBlob(image)], "image.jpg");
            }
            */
		}
	};
	const submit = () => {
		const values = {
			order,
			state,
			image_right_side: parseImage("right"),
			image_left_side: parseImage("left"),
			image_back: parseImage("back"),
			image_front: parseImage("front")
		};
		const payload = savedInspection
			? { data: values, slug: savedInspection.id, token: authHeader() }
			: { data: values, token: authHeader() };
		mutate(payload);
		// console.log(payload.data)
	};
	return (
		<Button variant='primary' isLoading={isLoading} isDisabled={disableSumbit()} onClick={submit}>
			Guardar
		</Button>
	);
}
