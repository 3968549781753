import { Fragment } from "react";
import { Flex, Avatar, useMediaQuery } from "@chakra-ui/react";
// comps
import UsersTable from "../../components/admin/users/UsersTable";
import UsersTableMobile from "../../components/admin/users/UsersTableMobile";
import { Map } from "@vis.gl/react-google-maps";
import { CustomMarker } from "../../components/pages/businesses/businessSettings/profile/address/CustomMarker";
//api
import { getAllBusinessesMap } from "../../api/businessesApi";
import { useQuery } from "@tanstack/react-query";

export default function BusinessesMapView() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const mapCenter = {
		lat: 40.4010219,
		lng: -3.6861754
	};

	const { data, isLoading } = useQuery({
		queryKey: ["businessesMap"],
		queryFn: () => getAllBusinessesMap()
	});

	if (!isMobile) {
		return (
			<>
				<Flex bg='white' rounded='xl' m p='1em' mt='20px' justifySelf='center' alignSelf='center'>
					<Map
						style={{ width: "800px", height: "500px" }}
						zoom={6}
						defaultCenter={mapCenter}
						mapId={"bf51a910020fa25a"}
						//disableDefaultUI={true}
					>
						{data?.map((business) => (
							<Fragment key={business.id}>
								<CustomMarker
									position={{
										lat: business.lat || 40.4010219,
										lng: business.long || -3.6861754
									}}
									icon={
										<Avatar
											src={business?.banner_image || ""}
											name={business?.name || "AT"}
											boxSize='40px'
										/>
									}
								/>
							</Fragment>
						))}
					</Map>
				</Flex>
			</>
		);
	}
	return (
		<Flex direction='column' p='2em'>
			<p>mobileee</p>
		</Flex>
	);
}
