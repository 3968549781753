import React from "react";
import {
    Input,
    Popover,
    PopoverTrigger,
    PopoverContent,
    IconButton,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    Button,
} from "@chakra-ui/react";
//icons
import { IoClose } from "react-icons/io5";

interface OrderRequestCancelPopoverProps  {
    isLoading: boolean;
    isDisabled?: boolean;
    cancelRequest: () => void;
    cancelReason: string;
    setCancelReason: (newValue:string) => void
}

const OrderRequestCancelPopover: React.FC<OrderRequestCancelPopoverProps> = 
    ({isLoading, isDisabled, cancelRequest, cancelReason, setCancelReason}) => {

    return(
        <Popover>
            <PopoverTrigger>
                <IconButton aria-label="cancel-order-request"
                    variant='danger' size='xs'
                    isLoading={isLoading}
                    isDisabled={isDisabled} 
                    icon={<IoClose size='20px' color='white' />} 
                />
            </PopoverTrigger>

            <PopoverContent p="0.5em">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                    Indica motivo de cancelación
                    <Input
                        type="text"
                        value={cancelReason}
                        onChange={(e)=>setCancelReason(e.target.value)}
                    />
                </PopoverBody>

                <Button
                    variant="danger"
                    isLoading={isLoading}
                    onClick={() => cancelRequest()}
                    size="sm"
                    mt="0.5em"
                    mx="1em"
                    w="75px"
                    alignSelf="end"
                >
                    Cancelar
                </Button>
            </PopoverContent>
        </Popover>
    )
}

export default OrderRequestCancelPopover;