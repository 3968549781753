import { Flex, Text, Link, useMediaQuery } from "@chakra-ui/react";
//icon
import { LuSparkle } from "react-icons/lu";
import { FiExternalLink } from "react-icons/fi";
//types
import type { PriceDuration, Service } from "../../../../../types/service";
//comps
import PriceDurationSelect from "./PriceDurationSelect";
import WhatIncludesSection from "./WhatIncludesSection";

interface ServiceCardProps {
	service: Service;
	handleToggleService: (service: PriceDuration | null) => void;
	selectedServices: PriceDuration[];
}

export default function ServiceCard({ service, handleToggleService, selectedServices }: ServiceCardProps) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	return (
		<Flex direction='column' bg='white' rounded='lg' px='1.5rem' py='2rem' w='100%'>
			{service.is_featured && (
				<Flex
					align='center'
					gap='0.2rem'
					bg='blue.500'
					w='95px'
					px='0.5rem'
					color='white'
					rounded='xl'
					fontSize='10px'
					mt='-25px'
					ml='-1rem'
					mb='10px'
				>
					<LuSparkle size='18px' />
					DESTACADO
				</Flex>
			)}
			<Flex justify='space-between' w='100%'>
				<Flex direction='column' maxW='60%' gap='0.5rem'>
					<Text fontWeight='bold' color='black' fontSize={isMobile ? "14px" : "18px"}>
						{service.name}
					</Text>
					{service.description && (
						<Text color='grey' fontSize={isMobile ? "12px" : "16px"}>
							{service.description}
						</Text>
					)}
					<WhatIncludesSection whatIncludes={service.what_includes} />
					{service.more_information_url && (
						<Link href={service.more_information_url} isExternal fontSize='14px' ml='0.5rem'>
							<Flex align='center' gap='0.2em'>
								+Info
								<FiExternalLink />
							</Flex>
						</Link>
					)}
				</Flex>
				<Flex direction='column' minW='20%' maxW='40%'>
					<PriceDurationSelect
						priceDurations={service.prices}
						selectedServices={selectedServices}
						handleToggleService={handleToggleService}
					/>
				</Flex>
			</Flex>
		</Flex>
	);
}
