import { Flex, Text, Skeleton, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";
// comps
import WorkerOrdersChart from "./workers/WorkerOrdersChart";
import WorkerRevenueChart from "./workers/WorkerRevenueChart";
import WorkerHoursChart from "./workers/WorkerHoursChart";

export default function WorkerStats({ data, isloading }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	if (data?.empty) {
		return (
			<GridItem rowSpan={2} colSpan={2} p='2rem' bg='white' rounded='lg'>
				<Text color='darkblue'>{data.empty}</Text>
			</GridItem>
		);
	}
	if (!isMobile) {
		return (
			<Grid
				pt='2em'
				pb='4em'
				px='4em'
				w='100%'
				h='86vh'
				templateRows='repeat(4, 1fr)'
				templateColumns='repeat(4, 1fr)'
				gap={6}
			>
				<GridItem rowSpan={2} colSpan={2}>
					{isloading ? (
						<Skeleton height='100%' w='100%' rounded='lg' />
					) : (
						<WorkerOrdersChart data={data?.orders} />
					)}
				</GridItem>
				<GridItem rowSpan={4} colSpan={2} />

				<GridItem colSpan={1} rowSpan={2}>
					{isloading ? (
						<Skeleton height='100%' w='100%' rounded='lg' />
					) : (
						<WorkerHoursChart data={data?.hours} />
					)}
				</GridItem>
				<GridItem colSpan={1} rowSpan={2}>
					{isloading ? (
						<Skeleton height='100%' w='100%' rounded='lg' />
					) : (
						<WorkerRevenueChart data={data?.revenue} />
					)}
				</GridItem>
			</Grid>
		);
	}
	return (
		<Flex direction='column' gap='1em' p='2em'>
			mobile
		</Flex>
	);
}
