import { PieChart, Pie, Label, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import { Flex, Text } from '@chakra-ui/react'
import moment from 'moment'
import 'moment/locale/es' // without this line it didn't work

export default function PayMethodPieChart ({ data }) {
    moment.locale('es')

    const renderLabel = () => (
        <text x={200} y={200} textAnchor="middle" dominantBaseline="middle">
            <tspan x={'50%'} y={'40%'} fontSize="24" fontWeight="bold">
                {data?.total}
            </tspan>
            <tspan x={'50%'} y={'60%'} fontSize="14">
                Facturas
            </tspan>
        </text>
    )

    const COLORS = ['#42C74F99', '#0258FF99', '#F2B174']
    if (data) {
        return (
            <Flex direction="column" align="center" w="100%" h="100%" bg="white" py="1em" rounded="xl">
                <Text fontSize="20px" fontWeight="bold" alignSelf="start" mb="1em" px="2em">
                    Métodos de pago
                </Text>
                {data?.total !== 0
                    ? (
                        <>
                            <ResponsiveContainer width="100%" maxHeight="50%">
                                <PieChart>
                                    <Pie
                                        data={data?.pie}
                                        dataKey="total"
                                        cx="50%"
                                        cy="50%"
                                        label
                                        innerRadius={'65%'}
                                        outerRadius={'90%'}
                                        fill="#8884d8"
                                        paddingAngle={2}
                                    >
                                        <Tooltip />
                                        {data?.pie.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                        <Label content={renderLabel} />
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                            <Flex direction="column" gap="0.3em" mt="2em" px="2em" w="100%">
                                {data?.pie.map((entry, index) => (
                                    <Flex key={index} align="center" w="100%" justify="space-between">
                                        <Flex align="center" gap="0.5em">
                                            <Flex bg={COLORS[index % COLORS.length]} w="15px" h="15px" rounded="full" />
                                            <Text
                                                fontSize="16px"
                                                maxW="220px"
                                                overflow="hidden"
                                                whiteSpace="nowrap"
                                                textOverflow="ellipsis"
                                            >
                                                {entry.label}
                                            </Text>
                                        </Flex>
                                        <Text fontWeight="bold" justifySelf="end">
                                            {entry.total}
                                        </Text>
                                    </Flex>
                                ))}
                            </Flex>
                        </>
                    )
                    : (
                        <Flex w="100%" h="100%" align="center" px="0.5em">
                            <Text align="center">No has registrado ninguna factura en este periodo.</Text>
                        </Flex>
                    )}
            </Flex>
        )
    }
}
