import { useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import moment from "moment";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getBusinessProfile } from "../../../../api/businessesApi";

export default function WhatsAppTemplatePreview({ template, order }) {
	const [templateData, setTemplateData] = useState({
		message: undefined,
		displayButtons: false
	});
	const { data } = useAuthFetch(["businessProfile"], getBusinessProfile);

	const clientName = order?.client?.name || "Ricardo";
	const orderCar = order?.car?.brand_display || "Opel Astra";
	const orderDate =
		moment(order?.date_in)?.format("dddd DD [de] MMMM [de] YYYY [a las] HH:mm") ||
		"jueves 21 de Junio de 2024 a las 11:30";
	const businessName = data?.name || "Anothertool Detal";
	const businessPhone = data?.phone || "622781871";
	const deposit_receipt_url = order?.receipt
		? `${process.env.REACT_APP_ENV_SETTINGS === "DEV" ? "http://localhost:3000" : "https://anothertool.es"}/resguardo-deposito/${order.receipt}`
		: "No tiene resguardo";
	const invoice_url = order?.invoice
		? `${process.env.REACT_APP_ENV_SETTINGS === "DEV" ? "http://localhost:3000" : "https://anothertool.es"}/facturas/${order.invoice.uuid}`
		: "No tiene factura";
	const quote_url = order?.quote
		? `${process.env.REACT_APP_ENV_SETTINGS === "DEV" ? "http://localhost:3000" : "https://anothertool.es"}/presupuestos/${order.quote.uuid}`
		: "No tiene presupuesto";

	const getTemplateData = () => {
		switch (template) {
			case "reminder":
				setTemplateData({
					message: (
						<Text>
							¡Hola {clientName} 👋! <br />
							Te recordamos que tienes una cita en {businessName} el {orderDate} .
							<br />
							¿Nos confirmas tu asistencia?
						</Text>
					),
					displayButtons: true
				});
				break;
			case "order_accepted":
				setTemplateData({
					message: (
						<Text>
							Hola {clientName}, buenas noticias 😀 <br />
							{businessName} ha aceptado la solicitud de trabajo para tu {orderCar} el {orderDate}.
							<br />
							Este mensaje ha sido enviado automáticamente, por favor no respondas. Si necesitas ponerte
							en contacto, escríbenos al {businessPhone}.
						</Text>
					),
					displayButtons: false
				});
				break;
			case "ready_to_pick":
				setTemplateData({
					message: (
						<Text>
							Hola {clientName}, buenas noticias 😀 <br />
							Tu {orderCar} ya está listo y esperando a que lo recojas. Puedes pasarte cuando quieras.
							<br />
							Este mensaje ha sido enviado automáticamente, por favor no respondas. Si necesitas ponerte
							en contacto, escríbenos al {businessPhone}.
						</Text>
					),
					displayButtons: false
				});
				break;
			case "deposit_receipt":
				setTemplateData({
					message: (
						<Text>
							Hola {clientName}, ya hemos inspeccionado tu {orderCar} <br />
							Aquí tienes el resguardo de depósito. En cuanto lo firmes, nos pondremos a trabajar con él{" "}
							<span style={{ color: "blue" }}>{deposit_receipt_url}</span>
							<br /> <br />
							Este mensaje ha sido enviado automáticamente, por favor no respondas. Si necesitas ponerte
							en contacto, escríbenos al {businessPhone}.
						</Text>
					),
					displayButtons: false
				});
				break;
			case "invoice":
				setTemplateData({
					message: (
						<Text>
							Hola {clientName}, ya tienes disponible la factura de tu {orderCar} <br />
							<span style={{ color: "blue" }}>{invoice_url}</span>
							<br /> <br />
							Este mensaje ha sido enviado automáticamente, por favor no respondas. Si necesitas ponerte
							en contacto, escríbenos al {businessPhone}.
						</Text>
					),
					displayButtons: false
				});
				break;
			case "quote":
				setTemplateData({
					message: (
						<Text>
							Hola {clientName}, ya tienes disponible el presupuesto de tu {orderCar} <br />
							<span style={{ color: "blue" }}>{quote_url}</span>
							<br /> <br />
							Este mensaje ha sido enviado automáticamente, por favor no respondas. Si necesitas ponerte
							en contacto, escríbenos al {businessPhone}.
						</Text>
					),
					displayButtons: false
				});
				break;
			default:
		}
	};
	useEffect(() => getTemplateData(), [template]);

	if (!template) {
		return (
			<Flex h='200px' w='100%' bg='lightgrey' rounded='lg' justify='center' align='center' mt='1em'>
				<Text maxW='60%' align='center'>
					Selecciona un template para previsualizar
				</Text>
			</Flex>
		);
	}
	return (
		<Flex bg='#F0F0F0' p='1em' rounded='lg' mt='1em' h='320px' align='center'>
			<Flex direction='column' gap='0.5em' maxW='320px'>
				<Flex direction='column' bg='white' p='1em' gap='0.2em' rounded='xl' fontSize='14px'>
					{templateData.message}
					<Text fontSize='12px' color='blue.500' align='end' mt='-15px'>
						11:45
					</Text>
				</Flex>
				{templateData.displayButtons && (
					<Flex justify='space-between'>
						<Flex bg='white' px='0.4em' py='0.5em' rounded='lg' color='blue.500'>
							ASISTIRÉ
						</Flex>
						<Flex bg='white' px='0.4em' py='0.5em' rounded='lg' color='blue.500'>
							NO ASISTIRÉ
						</Flex>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
}
