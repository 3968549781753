import { Flex, Text } from '@chakra-ui/react'

export default function PendingInvoicesStatBox ({ icon, title, data }) {
    const { total, invoices } = data || {}
    return (
        <Flex bg="white" rounded="lg" p="1.5em" direction="column" align="center" h="100%" justify="space-between">
            <Flex align="center" gap="0.5em" mb="0.5em">
                {icon}
                <Text fontWeight="bold" fontSize="20px">
                    {title}
                </Text>
            </Flex>
            <Text fontSize="40px" fontWeight="bold">
                {Math.floor(total)}€
            </Text>
            <Text fontSize="16px" color="gray">
                De {invoices} facturas
            </Text>
        </Flex>
    )
}
