import { useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useStripe } from "@stripe/react-stripe-js";
// icons
import { BiErrorAlt } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import { MdPendingActions } from "react-icons/md";
// api
import { useQueryClient } from "@tanstack/react-query";
import useAuthMutation from "../../myHooks/useAuthMutation";
import { upgradePlan } from "../../api/plansApi";
// auth
import { useAuthHeader, useAuthUser } from "react-auth-kit";

export default function PaymentStatus() {
	const authHeader = useAuthHeader();
	const auth = useAuthUser();
	const QueryClient = useQueryClient();
	const stripe = useStripe();

	const [message, setMessage] = useState(null);
	const [icon, setIcon] = useState(null);

	const { isLoading, mutate } = useAuthMutation({
		mutationFn: upgradePlan,
		onSuccess: () => {
			QueryClient.invalidateQueries(["userplan"]);
			QueryClient.refetchQueries("userplan", { force: true });
			auth().plan = "PRO";
		},
		onError: (error) => {
			console.log(error);
		}
	});

	useEffect(() => {
		if (!stripe) {
			return;
		}
		// Retrieve the "payment_intent_client_secret" query parameter appended to
		// your return_url by Stripe.js
		const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
		// Retrieve the PaymentIntent
		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			// Inspect the PaymentIntent `status` to indicate the status of the payment
			// to your customer.
			//
			// Some payment methods will [immediately succeed or fail][0] upon
			// confirmation, while others will first enter a `processing` state.
			//
			// [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
			switch (paymentIntent?.status) {
				case "succeeded":
					setMessage("Genial! Pago recibido.");
					setIcon(<AiOutlineCheck color='green' size='30px' />);
					mutate({ token: authHeader() });
					break;
				case "processing":
					setMessage("Procesando el pago. Te actualizaremos cuando esté completado.");
					setIcon(<MdPendingActions color='orange' size='30px' />);
					break;
				case "requires_payment_method":
					// Redirect your user back to your payment page to attempt collecting
					// payment again
					setMessage("El pago ha fallado. Intenta otro método de pago por favor.");
					setIcon(<BiErrorAlt color='red' size='30px' />);
					break;
				default:
					setMessage("Ups! Algo fue mal, intentalo de nuevo");
					setIcon(<BiErrorAlt color='red' size='30px' />);
					break;
			}
		});
	}, [stripe]);

	return (
		<>
			<Flex p='2em' bg='white' justify='center' rounded='xl' mt='2em' minW='400px' direction='column'>
				<Flex gap='0.5em' align='center'>
					{icon}
					<Text fontWeight='bold' fontSize='18px'>
						{message}
					</Text>
				</Flex>
				<Text mt='2em' maxW='350px'>
					Estamos encantados de ayudarte a optimizar tu taller. Ya puedes probar todas las nuevas
					características. Empieza a mejorar hoy!
				</Text>
				{isLoading && <Text>Actualizando el plan....</Text>}
			</Flex>
		</>
	);
}
