import {
	Button,
	useToast,
	Flex,
	VStack,
	Switch,
	Text,
	DrawerBody,
	DrawerFooter,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton
} from "@chakra-ui/react";
import moment from "moment";
//comps
import InfoTooltip from "../../../common/InfoTooltip";
//fns
import calculateTaxAndTotal from "../functions";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../../common/forms/InputField";
import SelectField from "../../../common/forms/SelectField";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateExpense, getExpenseOptions } from "../../../../api/stockApi";

export default function RecurringExpenseForm({ onClose, isOpen, item: expense }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data: options } = useAuthFetch(["expenseOptions"], getExpenseOptions, null, null, !!isOpen);
	//const recurrencePeriodOptions = options?.actions?.POST?.recurrence_period?.choices;
	const taxChoices = options?.actions?.POST?.tax?.choices;

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateExpense,
		onSuccess: () => {
			toast({ title: "Creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["recurringExpenses"]);
			QueryClient.refetchQueries("recurringExpenses", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const initialValues = {
		name: expense ? expense.name : "",
		description: expense ? expense.description : "",
		beneficiary: expense ? expense.beneficiary : "",
		interval: expense ? expense.interval : 1,
		recurrence_period: expense ? expense.recurrence_period : "month",
		price: expense ? expense.price : "",
		tax: expense ? expense.tax : "twenty",
		price_includes_tax: expense ? expense.price_includes_tax : false,
		recurrence: expense ? expense.recurrence : "single",
		purchased_at: expense ? expense.purchased_at : moment().format("YYYY-MM-DD")
	};
	const validationSchema = Yup.object({
		name: Yup.string().required("Nombre es obligatorio"),
		interval: Yup.number("Debe ser un número").required("Nombre es obligatorio"),
		price: Yup.number().required("Precio es obligatorio").min(0, "El precio debe ser positivo"),
		purchased_at: Yup.date("El formato de la fecha no es correcto").required("La fecha de compra es obligatoria")
	});
	const submit = (values) => {
		let payload = {};
		if (expense) {
			payload = {
				data: values,
				slug: expense.id,
				token: authHeader()
			};
		} else {
			payload = {
				data: values,
				token: authHeader()
			};
		}
		mutate(payload);
	};

	return (
		<Drawer isOpen={isOpen} placement='right' size='md' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>{expense ? "Editar" : "Añadir"} gasto fijo</DrawerHeader>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => submit(values)}
				>
					{(formik) => {
						const { taxes, total } = calculateTaxAndTotal(formik.values);

						return (
							<>
								<DrawerBody>
									<VStack as='form' gap='1em'>
										<InputField
											label='Nombre'
											name='name'
											placeholder='Ej: Nómina'
											required
											error={error?.response?.data?.name}
										/>
										<InputField
											label={
												<InfoTooltip
													label={
														<Flex align='center' gap='0.5em'>
															<Text>Precio</Text>
															<Switch
																colorScheme='brand'
																isChecked={formik.values.price_includes_tax}
																value={formik.values.price_includes_tax}
																onChange={() =>
																	formik.setFieldValue(
																		"price_includes_tax",
																		!formik.values.price_includes_tax
																	)
																}
															/>
														</Flex>
													}
													desc={"Activa la casilla si los precios ya incluyen IVA"}
												/>
											}
											name='price'
											required
											error={error?.response?.data?.price}
										/>
										<SelectField label='IVA' name='tax' choices={taxChoices} bg='white' />
										<InputField
											label='Primer pago'
											name='purchased_at'
											type='date'
											required
											error={error?.response?.data?.purchased_at}
										/>
										{/* 
									<Text alignSelf='start' fontWeight='500' mt='0.5rem' color='black'>
										Recurrencia de pago
									</Text>
									<Flex w='100%' justify='start' h='35px' align='center' gap='1rem'>
										<Text>Cada</Text>
										<InputField
											w='50px'
											name='interval'
											type='number'
											error={error?.response?.data?.interval}
										/>
										<SelectField
											name='recurrence_period'
											choices={recurrencePeriodOptions}
											error={error?.response?.data?.recurrence_period}
										/>
									</Flex>
									*/}
										<InputField
											label='Beneficiario'
											name='beneficiary'
											placeholder='Ej: Hacienda'
											error={error?.response?.data?.description}
										/>
										<InputField
											label='Descripción'
											name='description'
											error={error?.response?.data?.description}
										/>
									</VStack>
								</DrawerBody>
								<DrawerFooter>
									<Flex w='100%' align='center' gap='1rem'>
										<Text fontSize='18px' fontWeight='bold'>
											IVA: {taxes}€
										</Text>
										<Text fontSize='18px' fontWeight='bold'>
											Total: {total}€
										</Text>
									</Flex>
									<Flex justify='right' columnGap='3' mt='3'>
										<Button variant='white' onClick={onClose}>
											Cancelar
										</Button>
										<Button
											variant='primary'
											isDisabled={!formik.dirty}
											onClick={formik.handleSubmit}
											isLoading={isLoading}
										>
											Guardar
										</Button>
									</Flex>
								</DrawerFooter>
							</>
						);
					}}
				</Formik>
			</DrawerContent>
		</Drawer>
	);
}
