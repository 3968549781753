import { PDFViewer, Page, Text, View, Document } from "@react-pdf/renderer";
import { styles } from "../PDFStyle";
import moment from "moment/moment";
// comps
import InvoicePDFHeader from "./InvoicePDFHeader";
import InvoicePDFResume from "./InvoicePDFResume";
// fs
import { formatDecimals } from "../../../../common/functions";

export default function MultiInvoicePDF({ invoice, invoiceSettings, businessProfile }) {
	const littleColsWidth = "10%";
	const taxColWidth = "15%";
	const orderColWidth = "25%";
	const itemColWidth = "25%";

	const groupedItems = invoice?.items.reduce((acc, item) => {
		const orderId = item.order?.id;
		if (!acc[orderId]) {
			acc[orderId] = [];
		}
		acc[orderId].push(item);
		return acc;
	}, {});
	return (
		<PDFViewer width='100%' height='100%'>
			<Document title={`Factura ${invoice?.invoice_number}`}>
				<Page size='A4' style={styles.page}>
					<InvoicePDFHeader
						invoiceSettings={invoiceSettings}
						businessProfile={businessProfile}
						invoice={invoice}
					/>
					{/* Invoice description */}
					<View
						style={{
							borderTop: "1px solid #F4F4F9",
							borderBottom: "1px solid #F4F4F9",
							paddingHorizontal: "10",
							paddingVertical: "5px",
							marginTop: "30px",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							color: "#737A87",
							fontSize: "10px"
						}}
					>
						<View style={{ width: orderColWidth }}>
							<Text>Orden</Text>
						</View>
						<View style={{ width: itemColWidth }}>
							<Text>Servicios</Text>
						</View>
						<View
							style={{
								width: littleColsWidth,
								textAlign: "right"
							}}
						>
							<Text>Cant.</Text>
						</View>
						<View
							style={{
								width: littleColsWidth,
								textAlign: "right"
							}}
						>
							<Text>Precio</Text>
						</View>
						<View
							style={{
								width: taxColWidth,
								textAlign: "right"
							}}
						>
							<Text>IVA</Text>
						</View>
						<View
							style={{
								width: littleColsWidth,
								textAlign: "right"
							}}
						>
							<Text>Total</Text>
						</View>
					</View>
					{groupedItems &&
						Object.keys(groupedItems)?.map((orderId) => {
							const items = groupedItems[orderId];
							return (
								<View
									key={orderId}
									style={[
										styles.invoiceRow,
										{ borderBottomWidth: 2, borderColor: "#F7F7F7", paddingBottom: "10px" }
									]}
								>
									<View style={{ width: orderColWidth, flexDirection: "col", flexWrap: "wrap" }}>
										<Text style={{ width: "90%" }}>
											{items[0]?.order?.car?.brand_display} {items[0]?.order?.car?.model}
										</Text>
										<Text>{items[0]?.order?.car?.plate}</Text>
										<Text style={{ marginTop: "5px" }}>
											{moment(items[0]?.order?.date_in).format("l")}
										</Text>
									</View>

									<View style={{ width: itemColWidth }}>
										{items.map((item, index) => (
											<Text key={index} style={index !== 0 && { marginTop: "10px" }}>
												{item.concept}
											</Text>
										))}
									</View>
									<View style={{ width: littleColsWidth, textAlign: "right" }}>
										{items.map((item, index) => (
											<Text key={index} style={index !== 0 && { marginTop: "10px" }}>
												{formatDecimals(item.quantity)}
											</Text>
										))}
									</View>
									<View style={{ width: littleColsWidth, textAlign: "right" }}>
										{items.map((item, index) => (
											<Text key={index} style={index !== 0 && { marginTop: "10px" }}>
												{formatDecimals(item.price)}
											</Text>
										))}
									</View>
									<View style={{ width: taxColWidth, textAlign: "right" }}>
										{items.map((item, index) => (
											<Text
												key={index}
												style={[{ textAlign: "right" }, index !== 0 && { marginTop: "10px" }]}
											>
												{formatDecimals(item.taxes)}
												{invoice.taxes > 0 && `(${invoice.tax_display})`}
											</Text>
										))}
									</View>
									<View style={{ width: littleColsWidth, textAlign: "right" }}>
										{items.map((item, index) => (
											<Text key={index} style={index !== 0 && { marginTop: "10px" }}>
												{item.total}
											</Text>
										))}
									</View>
								</View>
							);
						})}

					<InvoicePDFResume invoice={invoice} invoiceSettings={invoiceSettings} />
				</Page>
			</Document>
		</PDFViewer>
	);
}
