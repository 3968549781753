import { useEffect, useState } from "react";
import { Flex, Text, Progress } from "@chakra-ui/react";

interface ProfileCompletedProgressBarProps {
	progress: number;
}

export default function ProfileCompletedProgressBar({ progress }: ProfileCompletedProgressBarProps) {
	const [color, setColor] = useState("red");
	useEffect(() => {
		if (progress <= 50) {
			setColor("red");
		}
		if (progress > 50 && progress < 90) {
			setColor("orange");
		}
		if (progress >= 90) {
			setColor("green");
		}
	}, [progress]);
	return (
		<Flex align='center'>
			<Progress bg='lightgray' value={progress} w='150px' colorScheme={color} h='5px' rounded='md' />
			<Text fontSize='12px' ml='0.5rem'>
				{progress}%
			</Text>
		</Flex>
	);
}
