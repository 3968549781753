import { Flex, Text } from "@chakra-ui/react";
//icons
import { TbFileInvoice } from "react-icons/tb";

export default function WorkerRevenueChart({ data }) {
	if (data) {
		return (
			<Flex direction='column' align='center' w='100%' h='100%' bg='white' p='1.5rem' rounded='xl'>
				<Flex w='100%' align='center' gap='0.5rem' mb='2rem'>
					<TbFileInvoice size='24px' />
					<Text fontSize='24px' fontWeight='bold'>
						Facturación
					</Text>
				</Flex>
				<Flex w='100%' gap='1rem' h='100%' justify='space-around'>
					{data.workers.map((worker) => (
						<Flex key={worker.name} direction='column' justify='end' align='center'>
							<Flex
								w='40px'
								bg={worker.color}
								rounded='lg'
								h={`${(worker.now / data.worker_max) * 100 + 1}%`}
								justifySelf='start'
							/>
							<Text fontSize='18px' fontWeight='semibold' textAlign='center'>
								{worker.name}
							</Text>

							<Text fontSize='18px' fontWeight='bold' color='darkblue' textAlign='center'>
								{worker.now}€
							</Text>
						</Flex>
					))}
				</Flex>
			</Flex>
		);
	}
	return <></>;
}
