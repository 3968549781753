import { Tag, TagLabel } from '@chakra-ui/react'

export default function OrderStatusView ({ status, ...props }) {
    const statusMap = {
        pending: { color: '#737A87', bg: '#F7F7F7', display: 'Pendiente' },
        waiting: { color: '#0258FF', bg: '#BEE3F8', display: 'En espera' },
        completed: { color: '#42C74F', bg: '#42C74F1A', display: 'Finalizada' },
        started: { color: '#FF7C02', bg: '#FEEBC8', display: 'En curso' }
    }

    const { color, bg, display } = statusMap[status] || {}

    return (
        <Tag bg={bg} rounded="xl" p="0.5em" maxW="100px" {...props} >
            <TagLabel color={color}>&#x2022; {display}</TagLabel>
        </Tag>
    )
}
