import { createApi, getAll, getAllPag, createUpdate, deleteItem } from "./baseApi";
import axios from "axios";

const depositReceiptApi = createApi("deposit-receipt");

export const getAllDepositReceiptsPag = async (payload) => {
	return getAllPag(depositReceiptApi, payload);
};
export const createUpdateDepositReceipt = async (payload) => {
	return createUpdate(depositReceiptApi, payload);
};
export const deleteDepositReceipt = async (payload) => {
	return deleteItem(depositReceiptApi, payload);
};

export const getDepositReceipt = async (payload) => {
	const slug = `${process.env.REACT_APP_API_URL}deposit-receipt-live-view?depositReceiptId=${payload.slug}`;
	const response = await axios.get(slug);
	return response.data;
};

const depositReceiptSignatureApi = createApi("deposit-receipt-signature");

export const createDepositReceiptSignature = async (payload) => {
	const response = await depositReceiptSignatureApi.post("/", payload, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});

	return response.data;
};
