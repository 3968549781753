import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	IconButton,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
	Button,
	useToast
} from "@chakra-ui/react";
//
import useAuthMutation from "../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
// icons
import { BsTrash } from "react-icons/bs";
//auth
import { useAuthHeader } from "react-auth-kit";

export default function PopoverBulkDelete({ deleteFn, selectedItems, setSelectedFn, refetchKey }) {
	const toast = useToast();
	const QueryClient = useQueryClient();
	const authHeader = useAuthHeader();

	const { isLoading, mutate } = useAuthMutation({
		mutationFn: deleteFn,
		onSuccess: () => {
			setSelectedFn([]);
			toast({ title: "Borrados!", status: "success" });
			QueryClient.invalidateQueries(refetchKey);
			QueryClient.refetchQueries(refetchKey, { force: true });
		},
		onError: () => {
			toast({
				title: "¡Ups, ha habido un error!",
				status: "error"
			});
		}
	});
	const handleBulkDelete = () => {
		mutate({ data: { ids: selectedItems }, token: authHeader() });
	};

	return (
		<Popover>
			<PopoverTrigger>
				<IconButton isLoading={isLoading} icon={<BsTrash size={"20px"} color='red' />} bg='transparent' />
			</PopoverTrigger>

			<PopoverContent p='0.5em'>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverBody>¿Seguro que quieres borrarlo?</PopoverBody>

				<Button
					variant='danger'
					isLoading={isLoading}
					onClick={() => handleBulkDelete()}
					size='sm'
					mt='0.5em'
					mx='1em'
					w='75px'
					alignSelf='end'
				>
					Borrar
				</Button>
			</PopoverContent>
		</Popover>
	);
}
