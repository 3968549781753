import { useState, useEffect } from 'react'
import { Flex, Text, Button, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
// icons
import { BiErrorAlt } from 'react-icons/bi'
import { AiOutlineCheck } from 'react-icons/ai'
import { MdPendingActions } from 'react-icons/md'
// image
import logo from '../../img/brand/at_blue.svg'
// comps
import CheckoutButton from '../../components/payments/CheckoutButton'
// api
import { useQueryClient } from '@tanstack/react-query'
import useAuthFetch from '../../myHooks/useAuthFetch'
import { getCheckoutSession } from '../../api/paymentsApi'

export default function CheckoutStatus () {
    const QueryClient = useQueryClient()
    const navigate = useNavigate()
    const [sessionId, setSessionId] = useState()
    const env_settings = process.env.REACT_APP_ENV_SETTINGS
    const { data: session, isLoading } = useAuthFetch(['checkoutSession', sessionId], getCheckoutSession, null, [sessionId, env_settings], !!sessionId)
    useEffect(() => {
        setSessionId(new URLSearchParams(window.location.search).get('session_id'))
    }, [])
    useEffect(() => {
        if (session?.payment_status === 'paid') {
            QueryClient.invalidateQueries(['userplan', 'extraplans'])
            QueryClient.refetchQueries(['userplan', 'extraplans'], { force: true })
        }
    }, [session])

    const PaymentStatusMessages = {
        pending: {
            title: 'Tu pago se está procesando',
            message: 'Mientras se termina el proceso sigue organizando tus próximas citas',
            button: <Button maxW='200px' variant='primary' onClick={() => navigate('/dashboard')} > Ir al panel </Button>,
            icon: <MdPendingActions color="orange" size="30px" />
        },
        paid: {
            title: 'Pago realizado correctamente',
            message: 'No pierdas ni un segundo más y empieza a establecer el vínculo perfecto con tus clientes',
            button: <Button maxW='200px' variant='primary' onClick={() => navigate('/comunicacion')} > Empezar </Button>,
            icon: <AiOutlineCheck color="green" size="30px" />
        },
        failed: {
            title: 'Vaya... parece que ha ocurrido un error',
            message: 'Algo no ha ido bien en el pago, vuelve a intentarlo para empezar a comunicarte con tus clientes',
            button: <CheckoutButton label='Volver al pago' />,
            icon: <BiErrorAlt color="red" size="30px" />
        },
        canceled: {
            title: 'Vaya... parece que ha ocurrido un error',
            message: 'Algo no ha ido bien en el pago, vuelve a intentarlo para empezar a comunicarte con tus clientes',
            button: <CheckoutButton label='Volver al pago' />,
            icon: <BiErrorAlt color="red" size="30px" />
        },
        expired: {
            title: 'Vaya... parece que ha ocurrido un error',
            message: 'Tu sesión ha expirado, vuelve a intentarlo para empezar a comunicarte con tus clientes',
            button: <CheckoutButton label='Volver al pago' />,
            icon: <BiErrorAlt color="red" size="30px" />
        },
        default: {
            title: 'Ups! Algo fue mal, intentalo de nuevo',
            message: 'Algo no ha ido bien en el pago, vuelve a intentarlo para empezar a comunicarte con tus clientes',
            icon: <BiErrorAlt color="red" size="30px" />,
            button: <CheckoutButton label='Volver al pago' />
        }
    }
    const renderMessage = (paymentStatus) => {
        const { title, message, icon, button } = PaymentStatusMessages[paymentStatus] || PaymentStatusMessages.default
        return (
            <Flex direction='column' maxW='400px' align='center' gap='1.5em'>
                <Flex gap="0.5em" align='center'>
                    <Image src={logo} boxSize="45px" alignSelf="center"/>
                    {icon}
                </Flex>
                <Text fontWeight="bold" align='center' fontSize="28px">
                    {title}
                </Text>
                <Text align='center' >
                    {message}
                </Text>
                {/*
                {button}
                */}
            </Flex>
        )
    }
    return (
        <Flex px="2em" py='4em' bg="white" justify="center" rounded="xl" mt="2em" minW="400px" direction="column">
            {!isLoading ? renderMessage(session?.payment_status) : <Text>Cargando...</Text>}
        </Flex>
    )
}
