import { useState, useMemo, useCallback } from "react";
import {
	Flex,
	Text,
	Box,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	useToast,
	Button
} from "@chakra-ui/react";
import moment from "moment";
// comps
import MessageStatusView from "./MessageStatusView";

import TableMobile from "../../common/Tables/Mobile/TableMobile";
//fns
import { formatOrderIncrementalID } from "../orders/helpers";
// img
import emptyStock from "../../../img/stock/emptyStock.png";
// api
import { useQueryClient } from "@tanstack/react-query";
import useAuthFetch from "../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../myHooks/useAuthMutation";
import { getAllProgrammedWhatsappPag, cancelProgrammedWhatsapp } from "../../../api/communicationApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function ProgrammedWhatsappTableMobile() {
	const [page, setPage] = useState(1);
	const QueryClient = useQueryClient();
	const authHeader = useAuthHeader();
	const toast = useToast();
	const { data, isLoading } = useAuthFetch(
		["programmedWhatsapps", page],
		getAllProgrammedWhatsappPag,
		page,
		null,
		true,
		null,
		{ refetchInterval: 10000 }
	);

	const { isLoading: loadingCancel, mutate } = useAuthMutation(
		["cancelProgrammedWhatsapps"],
		cancelProgrammedWhatsapp,
		{
			onSuccess: () => {
				toast({ title: "Cancelado con exito!", status: "success" });
				QueryClient.invalidateQueries(["programmedWhatsapps", "planlimits"]);
				QueryClient.refetchQueries(["programmedWhatsapps", "planlimits"], { force: true });
			},
			onError: () => {
				toast({
					title: "Ups! Ha habido un error",
					status: "error"
				});
			}
		}
	);
	const cancelWhatsapp = useCallback(
		(sid) => {
			const payload = {
				data: {
					id: sid
				},
				token: authHeader()
			};
			mutate(payload);
		},
		[mutate, authHeader]
	);
	const tableData = useMemo(
		() =>
			data?.results?.map((whatsapp) => (
				<AccordionItem key={whatsapp.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Box as='span' flex='1' textAlign='left' fontSize='14px' color='darkblue'>
							{whatsapp.client || "-- --"}
						</Box>
						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex
							w='100%'
							h='100%'
							direction='column'
							gap='1em'
							justify='space-between'
							fontSize='14px'
							p='0.5em'
						>
							<Flex gap='0.5em' align='center'>
								<Text>
									{whatsapp.order ? formatOrderIncrementalID(whatsapp.order_id) : "Eliminada"}
								</Text>
							</Flex>
							<Text>{whatsapp.type_display}</Text>
							<Text>{moment(whatsapp.send_at).format("lll")}</Text>

							<Text>
								<MessageStatusView status={whatsapp?.status} />
							</Text>
							<Flex w='100%' justify='end' mt='-3em'>
								<Button
									size='sm'
									colorScheme='red'
									isLoading={loadingCancel}
									isDisabled={whatsapp.status !== "scheduled"}
									onClick={() => cancelWhatsapp(whatsapp.sid)}
								>
									Cancelar
								</Button>
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data, loadingCancel]
	);

	return (
		<TableMobile
			withoutButton
			data={data}
			emptyImg={emptyStock}
			tableData={tableData}
			isLoading={isLoading}
			page={page}
			setPage={setPage}
		/>
	);
}
