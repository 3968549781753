import { useState } from "react";
import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
// comps
import OrderForm from "../forms/OrderForm";
import InvoiceModal from "../../invoicing/forms/InvoiceModal";
import QuoteModal from "../../invoicing/forms/QuoteModal";
import SendWhatsAppModal from "../../communication/form/SendWhatsAppModal";
import OrderCardDropDown from "./OrderCardDropdown";
import OrderCardServiceTags from "./OrderCardServiceTags";
import OrderReminderStatusView from "./OrderReminderStatusView";
import OrderWorkersView from "./OrderWorkersView";
import DepositReceiptModal from "../../depositReceipt/form/DepositReceiptModal";
import OrderCardProperties from "./OrderCardProperties";
import OrderCardLoading from "./OrderCardLoading";
//fns
import { formatOrderIncrementalID } from "../helpers";
// icons
import { BiCar } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
//types
import type { Order } from "../../../../types/order";
import type { Invoice } from "../../../../types/invoice";
import type { Quote } from "../../../../types/quote";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { deleteOrder } from "../../../../api/ordersApi";

interface OrderCardProps {
	order: Order;
	queryKey: string;
}

export default function OrderCard({ order, queryKey }: OrderCardProps) {
	const { isOpen: isOpenOrderForm, onOpen: onOpenOrderForm, onClose: onCloseOrderForm } = useDisclosure();
	const { isOpen: isOpenInvoiceModal, onOpen: onOpenInvoiceModal, onClose: onCloseInvoiceModal } = useDisclosure();
	const { isOpen: isOpenQuoteModal, onOpen: onOpenQuoteModal, onClose: onCloseQuoteModal } = useDisclosure();
	const { isOpen: isOpenDepositModal, onOpen: onOpenDepositModal, onClose: onCloseDepositModal } = useDisclosure();
	const { isOpen: isOpenWhatsAppModal, onOpen: onOpenWhatsAppModal, onClose: onCloseWhatsAppModal } = useDisclosure();

	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const toast = useToast();
	const [invoiceFromOrder, setInvoiceFromOrder] = useState<Invoice | undefined>(undefined);
	const [quoteFromOrder, setQuoteFromOrder] = useState<Quote | undefined>(undefined);
	const { mutate: remove, isLoading } = useAuthMutation({
		mutationFn: () => deleteOrder({ slug: order.id, token: authHeader() }),
		onSuccess: () => {
			toast({ title: "Borrado con exito!", status: "success" });
			QueryClient.invalidateQueries([queryKey]);
			QueryClient.refetchQueries([queryKey], { exact: true });
			onCloseOrderForm();
		},
		onError: () => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const handleOpenInvoice = () => {
		if (order.invoice) {
			setInvoiceFromOrder(order.invoice);
		}
		onOpenInvoiceModal();
	};
	const handleOpenQuote = () => {
		if (order.quote) {
			setQuoteFromOrder(order.quote);
		}
		onOpenQuoteModal();
	};
	if (isLoading) {
		return <OrderCardLoading />;
	}
	return (
		<>
			<Flex
				direction='column'
				maxW='100%'
				minW='250px'
				my='0.5em'
				boxShadow='2px 2px 2px 2px #F4F4F9'
				gap='1em'
				rounded='xl'
				justify='center'
				p='1em'
				bg='white'
			>
				<OrderReminderStatusView status={order.reminder_status} />
				<Flex maxW='100%' overflowWrap='break-word' justify='space-between' align='center'>
					<Flex align='center' gap='0.5em'>
						<Text fontWeight='bold'> Orden {formatOrderIncrementalID(order?.incremental_id)} </Text>
						<OrderWorkersView workers={order.workers} />
					</Flex>
					<OrderCardDropDown
						onOpenInvoiceModal={handleOpenInvoice}
						onOpenQuoteModal={handleOpenQuote}
						onOpenOrderForm={onOpenOrderForm}
						onOpenDepositModal={onOpenDepositModal}
						onOpenWhatsAppModal={onOpenWhatsAppModal}
						hasInvoice={!!order.invoice}
						hasQuote={!!order.quote}
						depositReceiptId={order.deposit_receipt?.id}
						remove={remove}
					/>
				</Flex>
				<Flex gap='0.5em' align='center' flexWrap='wrap'>
					<BiCar size='20px' color='grey' />
					<Text color='grey' fontWeight='bold'>
						{order.car.brand_display}
					</Text>
					<Text color='grey'>{order.car.model}</Text>
					<Text color='grey'>{order.car.plate}</Text>
				</Flex>
				<Flex gap='0.5em' align='center'>
					<FiUser size='20px' color='grey' />
					<Text color='grey' fontWeight='bold' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
						{order.client ? order.client.name : "-- --"}
					</Text>
				</Flex>
				<OrderCardServiceTags services={order.services} />
				<OrderCardProperties order={order} />
			</Flex>
			<OrderForm
				isOpen={isOpenOrderForm}
				item={order}
				onClose={onCloseOrderForm}
				dateIn={undefined}
				dateOut={undefined}
			/>
			<SendWhatsAppModal order={order} isOpen={isOpenWhatsAppModal} onClose={onCloseWhatsAppModal} />
			<DepositReceiptModal order={order} isOpen={isOpenDepositModal} onClose={onCloseDepositModal} />
			<InvoiceModal
				isOpen={isOpenInvoiceModal}
				item={invoiceFromOrder}
				order={order}
				onClose={onCloseInvoiceModal}
			/>
			<QuoteModal isOpen={isOpenQuoteModal} item={quoteFromOrder} order={order} onClose={onCloseQuoteModal} />
		</>
	);
}
