import { IconButton } from '@chakra-ui/react'
// icons
import { CiCamera } from 'react-icons/ci'

export default function ImageUploader ({ isDetail, setPhoto, view }) {
    const handleFileUpload = (e) => {
        const file = e.target.files[0]
        const photoUrl = URL.createObjectURL(file)
        const photo = {
            file,
            url: photoUrl
        }
        if (!isDetail) {
            setPhoto((prevPhoto) => ({
                ...prevPhoto,
                [view]: photo
            }))
        } else {
            setPhoto(photo)
        }
    }

    return (
        <>
            <IconButton
                as="label"
                htmlFor="file-upload"
                aria-label="Subir archivo"
                icon={<CiCamera strokeWidth="1px" size="25px" />}
                variant="primary"
                cursor="pointer"
            />
            <input
                z-index={100}
                id="file-upload"
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
            />
        </>
    )
}
