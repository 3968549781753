import { Text, Flex, Badge, List, ListItem, ListIcon } from "@chakra-ui/react";
// icons
import { AiOutlineCheck } from "react-icons/ai";

export default function StartingOfferCard() {
	return (
		<Flex rounded='lg' py='1.5em' px='2em' bg='white' direction='column' gap='1em' maxW='350px' h='100%'>
			<Flex>
				<Badge rounded='2xl' bg='#FF7C02' color='white' py='0.5em' px='1.5em'>
					OFERTA STARTER
				</Badge>
			</Flex>
			<Text fontWeight='semibold' color='black' mt='1rem'>
				SOLO con el plan anual...
			</Text>
			<Text fontWeight='bold' fontSize='24px' my='2rem' color='blue.500'>
				Te llevas GRATIS
			</Text>
			<List spacing={4} color='black'>
				<ListItem>
					<ListIcon as={AiOutlineCheck} color='blue.500' />
					<span style={{ fontWeight: "bold" }}>Diseño de tarjeta</span> de negocio con QR de tu página de
					reservas
				</ListItem>
				<ListItem>
					<ListIcon as={AiOutlineCheck} color='blue.500' />
					Revisión y auditoría de tu <span style={{ fontWeight: "bold" }}>página de reservas</span>
				</ListItem>
				<ListItem>
					<ListIcon as={AiOutlineCheck} color='blue.500' />
					Estrategia de <span style={{ fontWeight: "bold" }}>implementación </span>
					en RRSS
				</ListItem>
				<ListItem>
					<ListIcon as={AiOutlineCheck} color='blue.500' />
					Te <span style={{ fontWeight: "bold" }}>duplicamos </span> los WhatsApp automáticos
				</ListItem>
				<ListItem>
					<ListIcon as={AiOutlineCheck} color='blue.500' />
					<span style={{ fontWeight: "bold" }}>Importación </span> de tus clientes
				</ListItem>
			</List>
		</Flex>
	);
}
