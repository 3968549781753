import { createContext } from "react";
//types
import type { UserPlanLimitations } from "../../../types/plan";

interface PlanContextType {
	limits: UserPlanLimitations;
	setLimits: (limits: UserPlanLimitations) => void;
}

export const PlanContext = createContext<PlanContextType>({
	limits: {
		whatsapp_enabled: false,
		whatsapp_limit: 0,
		whatsapp_used: 0
	},
	setLimits: () => {}
});
