import { useState, useEffect } from "react";
import { Flex, Text, Switch, Select, useToast } from "@chakra-ui/react";
import InfoTooltip from "../../../../common/InfoTooltip";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import {
	getBusinessServiceSettings,
	createUpdateBussinessServiceSettings,
	getBusinessServiceSettingsOptions
} from "../../../../../api/businessesApi";
import { useQueryClient } from "@tanstack/react-query";
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../../myHooks/useAuthMutation";

export default function BusinessServiceSettingsForm() {
	const [sortBy, setSortBy] = useState();
	const [allowOvernight, setAllowOvernight] = useState();
	const [orderDesc, setOrderDesc] = useState(undefined);
	const toast = useToast();
	const QueryClient = useQueryClient();
	const authHeader = useAuthHeader();

	const { data: options } = useAuthFetch(["businessServiceSettingsOptions"], getBusinessServiceSettingsOptions);
	const sortByOptions = options?.actions?.POST?.sort_by?.choices;

	const { data, isLoading, isFetched } = useAuthFetch(["businessServiceSettings"], getBusinessServiceSettings);
	const {
		isLoading: lm,
		mutate,
		error
	} = useAuthMutation({
		mutationFn: createUpdateBussinessServiceSettings,
		onSuccess: () => {
			toast({ title: "Ajustes actualizados!", status: "success" });
			QueryClient.invalidateQueries(["businessServiceSettings"]);
			QueryClient.refetchQueries("businessServiceSettings", { force: true });
		},
		onError: () => {
			toast({
				title: "¿Ups, ha habido un error!",
				status: "error"
			});
		}
	});
	useEffect(() => {
		if (isFetched) {
			setSortBy(data?.sort_by);
			setOrderDesc(data?.order_desc);
			setAllowOvernight(data?.allow_overnight_services);
		}
	}, [isFetched]);
	useEffect(() => {
		if (data && sortBy && sortBy !== data?.sort_by) {
			submit();
		}
		if (data && orderDesc !== undefined && orderDesc !== data?.order_desc) {
			submit();
		}
		if (data && allowOvernight !== undefined && allowOvernight !== data?.allow_overnight_services) {
			submit();
		}
	}, [sortBy, orderDesc, allowOvernight]);
	const submit = () => {
		const payload = {
			data: {
				sort_by: sortBy,
				order_desc: orderDesc,
				allow_overnight_services: allowOvernight
			},
			slug: data.id,
			token: authHeader()
		};
		mutate(payload);
	};
	return (
		<>
			<Flex align='center' gap='0.5rem'>
				<Select
					maxW='200px'
					h='40px'
					placeholder='Ordenar por'
					value={sortBy}
					onChange={(e) => setSortBy(e.target.value)}
				>
					{sortByOptions?.map((option) => (
						<option value={option.value} key={option.display_name}>
							{option.display_name}
						</option>
					))}
				</Select>

				<Flex h='40px' align='center'>
					<Switch ml='1em' isChecked={orderDesc} onChange={() => setOrderDesc(!orderDesc)} />
					<Text ml='0.5rem'>{orderDesc ? "Descendente" : "Ascendente"}</Text>
				</Flex>
				<Flex ml='0.5rem' gap='0.5rem' align='center'>
					<Switch isChecked={allowOvernight} onChange={() => setAllowOvernight(!allowOvernight)} />
					<InfoTooltip
						label='¿Entregas al día siguiente?'
						desc={
							"Activa esta casilla si tus clientes pueden dejar el coche por la noche y recogerlo al día siguiente"
						}
					/>
				</Flex>
				{error?.response?.data?.allow_overnight_services && formik.values.allow_overnight_services && (
					<Text color='red'>{error.response.data.allow_overnight_services}</Text>
				)}
			</Flex>
		</>
	);
}
