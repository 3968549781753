import { Flex, Button, Switch, Text, useToast } from "@chakra-ui/react";
//comps
import InputField from "../../common/forms/InputField";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
// auth
import { useAuthHeader } from "react-auth-kit";
//api
import { useQueryClient } from "@tanstack/react-query";
import useAuthFetch from "../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../myHooks/useAuthMutation";
import { getCommunicationSettings, createUpdateCommunicationSettings } from "../../../api/communicationApi";

export default function CommunicationSettingsForm() {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data, isLoading } = useAuthFetch(["communicationSettings"], getCommunicationSettings);
	const {
		isLoading: lm,
		mutate,
		error
	} = useAuthMutation({
		mutationFn: createUpdateCommunicationSettings,
		onSuccess: () => {
			toast({ title: "Ajustes actualizados!", status: "success" });
			QueryClient.invalidateQueries(["communicationSettings"]);
			QueryClient.refetchQueries("communicationSettings", { force: true });
		},
		onError: () => {
			toast({
				title: "¡Ups, ha habido un error!",
				status: "error"
			});
		}
	});
	const initialValues = {
		auto_schedule: data?.auto_schedule || false,
		days_before: data?.days_before || 1
	};
	const validationSchema = Yup.object({
		auto_schedule: Yup.boolean(),
		days_before: Yup.number("Debe ser un número").min(0, "No puede ser negativo")
	});
	const submit = (values) => {
		const payload = { data: values, slug: data.id, token: authHeader() };
		mutate(payload);
		actions.setSubmitting(false);
	};
	return (
		<Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
			{(formik) => (
				<Flex direction='column' gap='1rem' mt='1rem '>
					<Flex align='end' gap='2rem'>
						<Flex align='center' gap='0.5em'>
							<Text>Recordatorios Automáticos</Text>
							<Switch
								name='auto_schedule'
								colorScheme='blue'
								isChecked={formik.values.auto_schedule}
								onChange={() => formik.setFieldValue("auto_schedule", !formik.values.auto_schedule)}
							/>
						</Flex>
						<InputField
							isDisabled={!formik.values.auto_schedule}
							maxW='75px'
							isLoading={isLoading}
							label='Días de antelación'
							name='days_before'
							error={error?.response?.data?.days_before}
						/>
					</Flex>

					<Button
						variant='primary'
						alignSelf='end'
						maxW='150px'
						isDisabled={!formik.dirty}
						onClick={() => formik.handleSubmit()}
						isLoading={lm}
					>
						Guardar
					</Button>
				</Flex>
			)}
		</Formik>
	);
}
