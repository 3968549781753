import { Flex, Text } from "@chakra-ui/react";
//coonst
import { FORM_COLOR_CHOICES } from "../../../common/consts";

export default function ColorSelect({ formik }) {
	return (
		<Flex direction='column'>
			<Flex align='center' gap='1rem' mb='1rem'>
				<Text>Color</Text>
				<Flex bg={formik.values.color} boxSize='25px' rounded='md' />
			</Flex>
			<Flex align='center' gap='0.5rem' justify='space-between' h='30px' maxW='300px' flexWrap='wrap'>
				{FORM_COLOR_CHOICES.map((color) => (
					<Flex
						bg={color}
						boxSize='25px'
						rounded='md'
						cursor='pointer'
						key={color}
						_hover={{ boxSize: "30px" }}
						onClick={() => formik.setFieldValue("color", color)}
					/>
				))}
			</Flex>
		</Flex>
	);
}
