import { Flex, Text } from "@chakra-ui/react";

export default function WorkerOrdersChart({ data }) {
	if (data) {
		return (
			<Flex direction='column' align='center' w='100%' h='100%' bg='white' p='1.5rem' rounded='xl'>
				<Flex w='100%' justify='space-between' align='center' gap='0.5em' mb='2rem'>
					<Text fontSize='24px' fontWeight='bold' color='darkblue'>
						Órdenes realizadas
					</Text>
					<Flex align='center' gap='1em'>
						<Text color='gray'>Total órdenes:</Text>
						<Text fontSize='28px' fontWeight='600'>
							{data?.total}
						</Text>
					</Flex>
				</Flex>
				<Flex direction='column' w='100%' gap='1em'>
					{data.workers.map((worker) => (
						<Flex key={worker.name} w='100%'>
							<Text fontSize='18px' fontWeight='semibold' color='black' w='10%'>
								{worker.name}
							</Text>
							<Flex
								h='32px'
								bg={worker.color}
								rounded='lg'
								w={`${(worker.now / data.worker_max) * 100 - 20}%`}
								justifySelf='start'
							/>

							<Text w='10%' fontSize='18px' ml='1rem' fontWeight='bold' color='darkblue'>
								{worker.now}
							</Text>
						</Flex>
					))}
				</Flex>
			</Flex>
		);
	}
	return <></>;
}
