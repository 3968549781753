import { Fragment } from "react";
import { Flex, Text, Button, Grid, GridItem } from "@chakra-ui/react";
// icons
import { PiChatTeardropTextBold } from "react-icons/pi";
// comps
import DepositObservationRow from "./DepositObservationRow";
// forms
import { FieldArray } from "formik";

export default function DepositObservations({ formik }) {
	return (
		<FieldArray
			name='observations'
			render={(arrayHelpers) => (
				<Flex direction='column' w='100%'>
					<Flex w='100%' justify='space-between' mt='2em'>
						<Flex align='center' gap='0.5em'>
							<PiChatTeardropTextBold size='20px' />
							<Text fontWeight='bold'>Observaciones</Text>
						</Flex>
						<Button
							variant='white'
							size='sm'
							onClick={() =>
								arrayHelpers.push({
									zone: "",
									description: ""
								})
							}
						>
							+ Zona
						</Button>
					</Flex>
					{formik.values.observations.length > 0 ? (
						<Grid templateColumns='repeat(7, 1fr)' gap={4} mt='0.5em'>
							<GridItem rowSpan={1} colSpan={2}>
								<Text>Zona</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={4}>
								<Text>Descripción</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={1}></GridItem>
							{formik.values.observations &&
								formik.values.observations.length > 0 &&
								formik.values.observations.map((item, index) => (
									<Fragment key={index}>
										<DepositObservationRow
											formik={formik}
											index={index}
											arrayHelpers={arrayHelpers}
										/>
									</Fragment>
								))}
						</Grid>
					) : (
						<Text mt='1rem'>Sin observaciones</Text>
					)}
				</Flex>
			)}
		/>
	);
}
