import { useState, useMemo } from "react";
import { useDisclosure, Flex, IconButton, Tr, Td } from "@chakra-ui/react";
import moment from "moment";
// comps
import ResponsiveTable from "../../../common/Tables/ResponsiveTable";
import PopoverDelete from "../../../common/PopoverDelete";
import ExpenseForm from "../forms/RecurringExpenseForm";
//fns
import { formatDecimals } from "../../../common/functions";
// icons
import { AiOutlineEdit } from "react-icons/ai";
// img
import emptyStock from "../../../../img/stock/emptyStock.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getAllExpenses, deleteExpense } from "../../../../api/stockApi";

export default function ExpensesTable() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [expense, setExpense] = useState();

	const { data, isLoading } = useAuthFetch(["recurringExpenses", page], getAllExpenses, page);
	const tableData = useMemo(
		() =>
			data?.results?.map((expense) => (
				<Tr key={expense.id}>
					<Td>{expense.name}</Td>
					<Td>{moment(expense.purchased_at).format("L")}</Td>
					{/* 
					<Td>{`Cada ${expense.interval} ${expense.recurrence_period_display}`}</Td>
					*/}
					<Td>{formatDecimals(expense.total)}</Td>
					<Td>{formatDecimals(expense.taxes)}</Td>
					<Td>{expense.description || "-- --"}</Td>
					<Td>{expense.beneficiary || "-- --"}</Td>
					<Td>
						<Flex gap='1em'>
							<IconButton
								icon={<AiOutlineEdit size='20px' color='#0258FF' />}
								bg='transparent'
								onClick={() => {
									setExpense(expense);
									onOpen();
								}}
							/>
							<PopoverDelete
								deleteKey={["deleteExpense"]}
								deleteFn={deleteExpense}
								slug={expense?.id}
								refetchKey={["expenses"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data, onOpen]
	);
	const isEmpty = data?.count < 1;
	return (
		<ResponsiveTable
			paginated
			data={data}
			tableData={tableData}
			isEmpty={isEmpty}
			onOpen={() => {
				setExpense();
				onOpen();
			}}
			onClose={onClose}
			page={page}
			setPage={setPage}
			isLoading={isLoading}
			item={{
				label: "Gasto",
				headers: [
					{ key: "name", label: "Nombre", roundedLeft: "lg" },
					{ key: "purchased_at", label: "Fecha" },
					{ key: "total", label: "Total(€)" },
					{ key: "taxes", label: "IVA(€)" },
					{ key: "description", label: "Descripción" },
					{ key: "beneficiary", label: "Beneficiario" }
				],
				formItem: expense,
				isOpen
			}}
			FormComponent={ExpenseForm}
			emptyImage={emptyStock}
		/>
	);
}
