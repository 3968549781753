import { Fragment } from "react";
import { Flex, Grid, GridItem, Text, useMediaQuery } from "@chakra-ui/react";
import moment from "moment";
//fns
import { formatDecimals } from "../../../common/functions";
import { calculateTotalAndTax } from "../fns";
// icons
import { MdCleaningServices } from "react-icons/md";

export default function MultiInvoiceServices({ services, priceIncludesTax, tax }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const parseDuration = (duration) => {
		const formattedMinutes = moment.duration(duration, "minutes");
		const days = formattedMinutes.days();
		const hours = formattedMinutes.hours();
		const minutes = formattedMinutes.minutes();
		const output = [];
		if (days > 0) {
			output.push(`${days}d`);
		}
		if (hours > 0) {
			output.push(`${hours}h`);
		}
		if (minutes > 0) {
			output.push(`${minutes}m`);
		}
		return output.join(" ");
	};

	const parseServicesToInvoiceItem = (services) => {
		return services.map((service) => {
			return {
				id: service.id,
				concept: service.name,
				price: formatDecimals(service.price),
				quantity: parseDuration(service.duration),
				total: service.price * 1
			};
		});
	};

	const calculateTotals = (service) => {
		const taxMultiplier = tax === "ten" ? 0.1 : tax === "twenty" ? 0.21 : 0;
		let price = service.price;
		// Ajustar subtotal si incluye impuestos
		if (priceIncludesTax) {
			price /= 1 + taxMultiplier;
		}
		const { total, taxes } = calculateTotalAndTax(false, tax, price);
		return { total, taxes, price };
	};

	return (
		<>
			<Flex align='center' gap='10px' mt='1em'>
				<MdCleaningServices size='20px' color='black' />
				<Text fontSize='18px' fontWeight='bold'>
					Servicios
				</Text>
			</Flex>
			<hr />
			{services.length > 0 ? (
				<Grid templateColumns='repeat(6, 1fr)' gap={4} mt='0.5em' mb='0.5em'>
					<GridItem rowSpan={1} colSpan={2}>
						<Text fontSize='17px' fontWeight='bold'>
							Concepto
						</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text fontSize='17px' fontWeight='bold'>
							Cantidad
						</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text fontSize='17px' fontWeight='bold'>
							Precio
						</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text fontSize='17px' fontWeight='bold'>
							IVA
						</Text>
					</GridItem>
					<GridItem rowSpan={1} colSpan={1}>
						<Text fontSize='17px' fontWeight='bold'>
							Total
						</Text>
					</GridItem>
					{parseServicesToInvoiceItem(services)?.map((item) => {
						const { total, taxes, price } = calculateTotals(item);
						return (
							<Fragment key={item.id}>
								<GridItem rowSpan={1} colSpan={2}>
									<Text fontSize='15px'> {item.concept} </Text>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									<Text fontSize='15px'>{item.quantity}</Text>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									<Text fontSize='15px'>{formatDecimals(price)}</Text>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									<Text fontSize='15px'>{formatDecimals(taxes)}€</Text>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									<Text fontSize='15px'> {formatDecimals(total)}€ </Text>
								</GridItem>
							</Fragment>
						);
					})}
				</Grid>
			) : (
				<Text mt='1em'>
					No se han encontrado servicios para esta orden. <br />
					<span style={{ fontWeight: "bold" }}>Añade un servicio</span> editando la orden desde la tabla de
					datos.
				</Text>
			)}
		</>
	);
}
