import { Flex, Text } from '@chakra-ui/react'
// icon
import { AiOutlineCheck } from 'react-icons/ai'

export default function FeatureItem ({ text, color, ...props }) {
    return (
        <Flex align="center">
            <AiOutlineCheck color={color || '#42C74F'} size="25px" />
            <Text fontSize="12px" ml="10px">
                {text}
            </Text>
        </Flex>
    )
}
