import { Image, Modal, ModalOverlay, ModalContent, ModalCloseButton } from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function CarouselModal({ isOpen, onClose, images }) {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size='3xl' maxH='90dvh'>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<Carousel showThumbs={false} dynamicHeight={true} infiniteLoop={true}>
					{images?.map((image) => (
						<Image key={image.id} rounded='lg' src={image.work_image} />
					))}
				</Carousel>
			</ModalContent>
		</Modal>
	);
}
