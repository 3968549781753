import { useState, useEffect } from "react";
import { Text, Skeleton } from "@chakra-ui/react";
import moment from "moment";
// comps
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./customDayPicker.css";
import es from "date-fns/locale/es"; // Import Spanish locale

export default function CalendarPicker({ unavailableDays, setDay, month, setMonth, error, isloading }) {
	const [selected, setSelected] = useState();
	const [monthView, setMonthView] = useState(() => {
		// Initialize with the current month or provided month
		return month !== undefined ? moment().month(month).toDate() : new Date();
	});

	useEffect(() => {
		if (month !== undefined) {
			setMonthView(moment().month(month).toDate());
		}
	}, [month]);

	const handleSelect = (date) => {
		setSelected(date);
		setDay(moment(date).date());
		setMonth(moment(date).month());
		setMonthView(moment(date).toDate()); // Update calendar month view
	};
	const disabledDays = unavailableDays?.length ? unavailableDays.map((day) => new Date(day)) : [];
	if (error) {
		return (
			<Text color='red' maxW='100%'>
				{error}
			</Text>
		);
	}
	const handleMonthChange = (newMonth) => {
		setMonthView(newMonth); // Allows the calendar to change the month view when navigating
	};

	/* 
	if (unavailableDays === undefined) {
		return <Skeleton w='400px' h='400px' rounded='lg' />;
	}
	*/
	return (
		<Skeleton isLoaded={!isloading} rounded='lg'>
			<DayPicker
				locale={es}
				mode='single'
				weekStartsOn={1}
				selected={selected}
				onSelect={handleSelect}
				lang='es-ES'
				month={monthView}
				onMonthChange={handleMonthChange} // Allow calendar navigation to change month
				disabled={[{ before: new Date() }, ...disabledDays]}
			/>
		</Skeleton>
	);
}
