import { useState } from 'react'
import { Text, Flex, Image, Button } from '@chakra-ui/react'
// comps
import TutorialPagination from '../TutorialPagination'
// img
import step1 from '../../../img/tutorial/data/Tutorial_Data_1.png'
import step2 from '../../../img/tutorial/data/Tutorial_Data_2.png'
import andres from '../../../img/tutorial/Andres.png'

export default function DataTutorial ({ onClose }) {
    const [step, setStep] = useState(0)

    const slides = [
        {
            title: (
                <Text align="center">
                    En la sección Datos quedarán guardados todas tus
                    <span style={{ color: 'blue' }}> Órdenes, Clientes y Coches</span>
                </Text>
            ),
            image: step1
        },
        {
            title: (
                <Text align="center">
                    Mantente siempre informado y abre el historial de los coches que entran en tu taller{' '}
                </Text>
            ),
            image: step2
        },
        {
            title: <Text align="center">Haz crecer tu negocio, controlando todos los datos de tu taller. </Text>,
            image: andres
        }
    ]
    const slide = slides[step]
    return (
        <>
            <Flex direction="column" gap="1em" justify="center" align="center" p="1em" h="550px">
                {(step === 0 || step === slides.length - 1) && <Image src={andres} />}
                {slide.title}
                {step !== slides.length - 1 && <Image maxH="350px" src={slide.image} />}
                {step === slides.length - 1 && (
                    <Button onClick={onClose} variant="primary" mt="2em">
                        Terminar Guía
                    </Button>
                )}
            </Flex>
            <TutorialPagination step={step} slides={slides} setStep={setStep} />
        </>
    )
}
