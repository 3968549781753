import { useMutation } from "@tanstack/react-query";
import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

import type { AxiosError } from "axios";

const useAuthMutation = <TData = unknown, TVariables = void, TError = AxiosError>(
	options: UseMutationOptions<TData, TError, TVariables>
): UseMutationResult<TData, TError, TVariables> => {
	const mutation = useMutation<TData, TError, TVariables>(options);
	const signOut = useSignOut();
	const navigate = useNavigate();

	// Manejo de error 401
	if ((mutation?.error as AxiosError)?.response?.status === 401) {
		signOut();
		navigate("/login");
	}

	return mutation;
};

export default useAuthMutation;
