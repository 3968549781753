import { useEffect } from 'react'
import { Flex, Select as ChakraSelect, Text, Checkbox, Textarea } from '@chakra-ui/react'
// icons
import { BsSticky } from 'react-icons/bs'
// form
import Select from 'react-select'
// api
import { getInspectionFromOrder } from '../../../../api/inspectionsApi'
import useAuthQuery from '../../../../myHooks/useAuthQuery'
import { getAllOrders } from '../../../../api/ordersApi'
import useAuthFetch from '../../../../myHooks/useAuthFetch'
// auth
import { useAuthHeader } from 'react-auth-kit'

export default function InspectionForm ({
    order,
    setOrder,
    zone,
    setZone,
    isBefore,
    setIsBefore,
    setPhoto,
    setSavedInspection
}) {
    const authHeader = useAuthHeader()

    const { data: orders } = useAuthFetch(['orders'], getAllOrders)
    const { data: inspection, isLoading } = useAuthQuery({
        queryKey: ['inspection', order, isBefore, zone],
        queryFn: () =>
            getInspectionFromOrder({
                token: authHeader(),
                zone,
                isBefore,
                order
            }),
        enabled: order !== undefined
    })
    useEffect(() => {
        if (inspection?.results) {
            const inspectionImages = inspection?.results[0]
            const newPhotoState = {
                right: inspectionImages?.image_right_side,
                left: inspectionImages?.image_left_side,
                back: inspectionImages?.image_back,
                front: inspectionImages?.image_front
            }
            setPhoto(newPhotoState)
            setSavedInspection(inspectionImages)
        }
    }, [inspection])

    const icon = <BsSticky size="20px" />
    const orderChoices = orders?.results.map((order) => ({
        label: '#' + order.id + ' ' + order.car?.brand + ' ' + order.car?.model,
        value: order.id
    }))

    return (
        <Flex direction="column" gap="2em" w="100%" p="1em">
            <Text fontWeight="bold" fontSize="16px">
                {!inspection ? 'Crear Inspección' : `Editar Inspección #${inspection.results[0]?.id}`}
            </Text>
            <Flex direction="column" gap="0.5em">
                <Flex align="center" gap="0.5em" color="black">
                    {icon}
                    Órden
                </Flex>
                <Select
                    placeholder="Escribe para buscar"
                    noOptionsMessage={'No hay órdenes disponibles'}
                    options={orderChoices}
                    value={order ? orderChoices?.find((order) => order.value === order) : ''}
                    onChange={(option) => setOrder(option.value)}
                />
            </Flex>
            <Flex direction="column" gap="0.5em">
                <Flex align="center" gap="0.5em" color="black">
                    {icon}
                    Zona de inspección
                </Flex>
                <ChakraSelect value={zone} onChange={(e) => setZone(e.target.value)}>
                    <option value="exterior">Exterior</option>
                    <option value="interior">Interior</option>
                </ChakraSelect>
            </Flex>
            <Flex align="center" justify="space-between">
                <Flex align="center" gap="0.5em">
                    <Checkbox size="sm" colorScheme="blue" isChecked={isBefore} onChange={() => setIsBefore(true)} />
                    <Text>Antes</Text>
                </Flex>
                <Flex align="center" gap="0.5em">
                    <Checkbox size="sm" colorScheme="blue" isChecked={!isBefore} onChange={() => setIsBefore(false)} />
                    <Text>Después</Text>
                </Flex>
            </Flex>
            <Flex direction="column" gap="0.5em">
                <Flex align="center" gap="0.5em" color="black">
                    {icon}
                    Descripción
                </Flex>
                <Textarea />
            </Flex>
        </Flex>
    )
}
