import React, { useState, useEffect, useCallback } from "react";
import {
	Flex,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper
} from "@chakra-ui/react";
import type { FormikProps } from "formik";
import moment from "moment";

interface ServiceDurationFieldProps {
	formik: FormikProps<any>;
	index: number;
}

const ServiceDurationField: React.FC<ServiceDurationFieldProps> = ({ formik, index }) => {
	const [hours, setHours] = useState<number>(0);
	const [mins, setMins] = useState<number>(0);

	const handleChangeHours = (value: string | number) => setHours(Number(value));
	const handleChangeMins = (value: string | number) => setMins(Number(value));

	const parseDuration = useCallback(() => {
		const duration = formik?.values?.prices[index]?.duration;
		if (duration) {
			const formattedMinutes = moment.duration(duration, "minutes");
			const hours = formattedMinutes.hours();
			const days = formattedMinutes.days();
			setMins(formattedMinutes.minutes());
			if (days > 0) {
				setHours(hours + days * 24);
			} else {
				setHours(hours);
			}
		}
	}, [formik?.values, index]);

	useEffect(() => {
		parseDuration();
	}, [parseDuration]);

	useEffect(() => {
		const totalDuration = Number(hours * 60) + Number(mins);
		formik.setFieldValue(`prices.${index}.duration`, totalDuration);
	}, [hours, mins]);

	return (
		<Flex gap='1rem' align='center' pt='0.5rem' justify='center'>
			<NumberInput maxW='75' bg='white' value={hours} onChange={handleChangeHours}>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>

			<NumberInput bg='white' maxW='75' value={mins} onChange={handleChangeMins}>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>
		</Flex>
	);
};

export default ServiceDurationField;
