import axios from "axios";
import { createApi, getAll, getOptions, getAllPag, createUpdate, deleteItem } from "./baseApi";

const businessApi = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}business`
});

export const syncLandingData = async (payload) => {
	const response = await businessApi.post("sync-data", payload.data, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const getliveLandingViewData = async (payload) => {
	const slug = `/live-view?workshopId=${payload.slug}`;

	const response = await businessApi.get(slug);
	return response.data;
};
export const getServicesLiveView = async (payload) => {
	let slug = "/services-live-view";
	if (payload.slug) {
		slug = `${slug}?workshopId=${payload.slug}`;
	}
	if (payload.page) {
		slug = `${slug}&page=${payload.page}`;
	}
	if (payload.filter) {
		slug = `${slug}&category=${payload.filter}`;
	}
	if (payload.searchQuery) {
		slug = `${slug}&search=${payload.searchQuery}`;
	}
	const response = await businessApi.get(slug);
	return response.data;
};

export const getAvailableServiceCategories = async (payload) => {
	let slug = "/available-categories";
	if (payload.slug) {
		slug = `${slug}?workshopId=${payload.slug}`;
	}
	const response = await businessApi.get(slug);
	return response.data;
};

export const getAllBusinesses = async (payload) => {
	let slug = "/detailing-hub";
	if (payload.page) {
		slug = `${slug}?page=${payload.page}`;
	}
	if (payload.pageSize) {
		slug = `${slug}&page_size=${payload.pageSize}`;
	}
	if (payload.searchQuery) {
		slug = `${slug}&search=${payload.searchQuery}`;
	}
	if (payload.autonomousCommunity) {
		slug = `${slug}&autonomous_community=${payload.autonomousCommunity}`;
	}
	const response = await businessApi.get(slug);
	return response.data;
};
export const getAllBusinessesMap = async () => {
	const response = await businessApi.get("map-view");
	return response.data;
};
export const getAvailableHours = async (payload) => {
	const response = await businessApi.get(`available-hours${payload.filter}`);
	return response.data;
};
export const getAvailableDays = async (payload) => {
	const response = await businessApi.get(`available-days${payload.filter}`);
	return response.data;
};

const businessProfilesApi = createApi("business/profiles");

export const getBusinessProfile = async (payload) => {
	const response = await businessProfilesApi.get("", {
		headers: { Authorization: payload.token }
	});
	return response.data[0];
};
export const getBusinessOptions = async (payload) => {
	return getOptions(businessProfilesApi, payload);
};
export const createUpdateBusinessProfile = async (payload) => {
	let response = {};

	if (payload.slug) {
		response = await businessProfilesApi.patch(`/${payload.slug}/`, payload.data, {
			headers: {
				Authorization: payload.token,
				"Content-Type": "multipart/form-data"
			}
		});
	} else {
		response = await businessProfilesApi.post("/", payload.data, {
			headers: {
				Authorization: payload.token,
				"Content-Type": "multipart/form-data"
			}
		});
	}
	return response.data;
};
export const deleteLandingWorkshop = async (payload) => {
	return deleteItem(businessProfilesApi, payload);
};

// BUSINESS ADDRESSES

const businessAddressApi = createApi("business/address");

export const getBusinessAddress = async (payload) => {
	const response = await businessAddressApi.get("", {
		headers: { Authorization: payload.token }
	});
	return response.data[0];
};
export const getBusinessAddressOptions = async (payload) => {
	return getOptions(businessAddressApi, payload);
};

export const createUpdateBusinessAddress = async (payload) => {
	return createUpdate(businessAddressApi, payload);
};

const businessServicesApi = createApi("business/services");

export const getAllBusinessServices = async (payload) => {
	return getAll(businessServicesApi, payload);
};
export const getAllBusinessServicesPag = async (payload) => {
	return getAllPag(businessServicesApi, payload);
};
export const createUpdateBusinessService = async (payload) => {
	return createUpdate(businessServicesApi, payload);
};
export const deleteBusinessService = async (payload) => {
	return deleteItem(businessServicesApi, payload);
};
export const getBusinessServiceOptions = async (payload) => {
	return getOptions(businessServicesApi, payload);
};

const businessExtrasApi = createApi("business/extras");

export const getBusinessExtras = async (payload) => {
	const response = await businessExtrasApi.get("", {
		headers: { Authorization: payload.token }
	});
	return response.data[0];
};
export const createUpdateBusinessExtra = async (payload) => {
	return createUpdate(businessExtrasApi, payload);
};
export const deleteBusinessExtra = async (payload) => {
	return deleteItem(businessExtrasApi, payload);
};

const businessScheduleApi = createApi("business/schedule");

export const getAllBusinessHours = async (payload) => {
	return getAll(businessScheduleApi, payload);
};
export const createUpdateBussinessHours = async (payload) => {
	return createUpdate(businessScheduleApi, payload);
};
export const deleteBusinessHours = async (payload) => {
	return deleteItem(businessScheduleApi, payload);
};

const businessScheduleSettingsApi = createApi("business/schedule-settings");

export const getBusinessHoursSettings = async (payload) => {
	const response = await businessScheduleSettingsApi.get("", {
		headers: { Authorization: payload.token }
	});
	return response.data[0];
};
export const createUpdateBussinessHoursSettings = async (payload) => {
	return createUpdate(businessScheduleSettingsApi, payload);
};

export const getBusinessHoursOptions = async (payload) => {
	return getOptions(businessScheduleApi, payload);
};

// SERVICE SETTINGS
const businessServiceSettingsApi = createApi("business/service-settings");

export const getBusinessServiceSettings = async (payload) => {
	const response = await businessServiceSettingsApi.get("", {
		headers: { Authorization: payload.token }
	});
	return response.data[0];
};
export const createUpdateBussinessServiceSettings = async (payload) => {
	return createUpdate(businessServiceSettingsApi, payload);
};

export const getBusinessServiceSettingsOptions = async (payload) => {
	return getOptions(businessServiceSettingsApi, payload);
};

const orderRequestApi = createApi("business/order-request");

export const getAllOrderRequestsPag = async (payload) => {
	return getAllPag(orderRequestApi, payload);
};
export const getActiveOrderRequests = async (payload) => {
	const response = await orderRequestApi.get("/?active=True", {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const manageOrderRequest = async (payload) => {
	const response = await orderRequestApi.patch(`/${payload.slug}/`, payload.data, {
		headers: { Authorization: payload.token }
	});

	return response.data;
};

export const createUpdateOrderRequest = async (payload) => {
	return createUpdate(orderRequestApi, payload);
};
export const deleteOrderRequest = async (payload) => {
	return deleteItem(orderRequestApi, payload);
};

const workersApi = createApi("business/workers");

export const getWorkers = async (payload) => {
	return getAll(workersApi, payload);
};
export const createUpdateWorker = async (payload) => {
	return createUpdate(workersApi, payload);
};
export const deleteWorker = async (payload) => {
	return deleteItem(workersApi, payload);
};

const imagesApi = createApi("business/images");

export const getWorkImages = async (payload) => {
	const response = await imagesApi.get("", {
		headers: { Authorization: payload.token }
	});
	return response.data;
};
export const createWorkImage = async (payload) => {
	const response = await imagesApi.post("/", payload.data, {
		headers: {
			Authorization: payload.token,
			"Content-Type": "multipart/form-data"
		}
	});
	return response.data;
};
export const deleteWorkImage = async (payload) => {
	return deleteItem(imagesApi, payload);
};
