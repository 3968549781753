import { useState, useMemo } from "react";
import { useDisclosure, Flex, IconButton, Tr, Td } from "@chakra-ui/react";
import moment from "moment";
// comps
import ResponsiveTable from "../../../common/Tables/ResponsiveTable";
import PopoverDelete from "../../../common/PopoverDelete";
import PurchaseForm from "../forms/SingleExpenseForm";
//fns
import { formatDecimals } from "../../../common/functions";
// icons
import { AiOutlineEdit } from "react-icons/ai";
// img
import emptyStock from "../../../../img/stock/emptyStock.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getAllPurchasesPag, deletePurchase } from "../../../../api/stockApi";

export default function PurchasesTable() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [purchase, setPurchase] = useState();

	const { data, isLoading } = useAuthFetch(["purchases", page], getAllPurchasesPag, page);

	const tableData = useMemo(
		() =>
			data?.results?.map((purchase) => (
				<Tr key={purchase.id}>
					<Td whiteSpace='pre-wrap' maxWidth='350px'>
						{purchase?.item?.name || purchase.concept}
					</Td>
					<Td maxW={["200px", "250px"]} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
						{purchase?.supplier?.name || "-- --"}
					</Td>
					<Td>{moment(purchase.purchased_at).format("L")}</Td>
					<Td>{formatDecimals(purchase.price)}</Td>
					<Td>
						{formatDecimals(purchase.quantity)} {purchase.unit}
					</Td>
					<Td>{formatDecimals(purchase.taxes)}</Td>
					<Td>{formatDecimals(purchase.total)}</Td>
					<Td>
						<Flex gap='1em'>
							<IconButton
								icon={<AiOutlineEdit size='20px' color='#0258FF' />}
								bg='transparent'
								onClick={() => {
									setPurchase(purchase);
									onOpen();
								}}
							/>
							<PopoverDelete
								deleteKey={["deletePurchase"]}
								deleteFn={deletePurchase}
								slug={purchase?.id}
								refetchKey={["purchases"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data, onOpen]
	);
	const isEmpty = data?.count < 1;
	return (
		<ResponsiveTable
			paginated
			data={data}
			tableData={tableData}
			isEmpty={isEmpty}
			onOpen={() => {
				setPurchase();
				onOpen();
			}}
			onClose={onClose}
			page={page}
			setPage={setPage}
			isLoading={isLoading}
			item={{
				label: "Compras",
				headers: [
					{ key: "item", label: "Item", roundedLeft: "lg" },
					{ key: "supplier", label: "Proveedor" },
					{ key: "purchased_at", label: "Fecha" },
					{ key: "price", label: "Precio(€)" },
					{ key: "qty", label: "Uds" },
					{ key: "taxes", label: "IVA(€)" },
					{ key: "total", label: "Total(€)" }
				],
				formItem: purchase,
				isOpen
			}}
			FormComponent={PurchaseForm}
			emptyImage={emptyStock}
		/>
	);
}
