import { useState } from "react";
import { Flex, Text, Switch, IconButton, useToast } from "@chakra-ui/react";
import moment from "moment";
// icons
import { AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai";
// comps
import { Spinner } from "@chakra-ui/react";
// api
import useAuthMutation from "../../../myHooks/useAuthMutation";
import { updateUserPlan, updateUser } from "../../../api/adminApi";
import { useQueryClient } from "@tanstack/react-query";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function UserCardMobile({ user }) {
	const toast = useToast();
	const QueryClient = useQueryClient();
	const authHeader = useAuthHeader();

	const [show, setShow] = useState(false);

	return (
		<Flex p='1em' rounded='lg' bg='white' direction='column' gap='1em'>
			<Flex justify='space-between' w='100%' align='center'>
				<Text>{user.email}</Text>
				<IconButton
					bg='transparent'
					icon={show ? <AiOutlineUpCircle size='25px' color='blue' /> : <AiOutlineDownCircle size='25px' />}
					onClick={() => setShow(!show)}
				/>
			</Flex>

			{show && (
				<>
					<hr />
					<Flex justify='space-between'>
						<Flex gap='1em'>
							<Text>isActive</Text>
							<Switch colorScheme='brand' isChecked={user.is_active} />
						</Flex>
						<Flex gap='1em'>
							<Text>isAdmin</Text>
							<Switch colorScheme='brand' isChecked={user.is_admin} />
						</Flex>
					</Flex>
					<Flex justify='space-between'>
						<Text fontWeight='bold'>Creado</Text>
						<Text>{moment(user.created_at).format("lll")} </Text>
					</Flex>
					<Flex justify='space-between'>
						<Text fontWeight='bold'>Last seen</Text>
						<Text>{moment(user.last_login).format("lll")} </Text>
					</Flex>
					<Flex justify='space-between'>
						<Text fontWeight='bold'>Orders</Text>
						<Text fontWeight='bold'>Invoice</Text>
						<Text fontWeight='bold'>Budgets</Text>
						<Text fontWeight='bold'>isStaff</Text>
					</Flex>
					<Flex justify='space-between'>
						<Text> {user.total_orders} </Text>
						<Text>{user.total_invoices}</Text>
						<Text>{user.total_budgets}</Text>
					</Flex>
				</>
			)}
		</Flex>
	);
}
