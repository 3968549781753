import { useEffect } from 'react'
import { Button, Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
// Components
import NavLoginRegister from '../../components/navbar/NavLoginRegister'
// api
import { activate } from '../../api/authApi'
import { useMutation } from '@tanstack/react-query'

export default function Activate () {
    const navigate = useNavigate()
    const toast = useToast()

    const { user_id, token } = useParams()

    const { data, isLoading, mutate, error } = useMutation(
        ['activate'],
        () =>
            activate({
                user_id,
                token
            }),
        {
            onSuccess: () => {
                toast({ title: 'Has activado tu cuenta!', status: 'success' })
            },
            onError: (error) => {
                toast({
                    title: error.message,
                    description: error.response.data.email,
                    status: 'error'
                })
            }
        }
    )
    // calling activate user just when user lands
    useEffect(() => {
        mutate()
    }, [])
    return (
        <Flex w="100%" height="100vh" direction="column" bg="lightgrey">
            <NavLoginRegister />
            <Flex justify="center" align="center" w="100%">
                <Flex py="5%" w={['80%', '75%', '400px', '400px']} direction="column" align="center" gap="5">
                    <Flex
                        bg="white"
                        w="100%"
                        rounded="xl"
                        direction="column"
                        align="center"
                        gap="6"
                        py="12%"
                        boxShadow="lg"
                    >
                        {data && (
                            <>
                                <Heading size="md"> ¡Enhorabuena! </Heading>
                                <Flex direction="column" gap="2em" align="center" justify="center" px="2em">
                                    <Text align='center' >{data}</Text>
                                    <Button
                                        variant="primary"
                                        w="150px"
                                        alignSelf="center"
                                        onClick={() => navigate('/login')}
                                    >
                                        Iniciar sesión
                                    </Button>
                                </Flex>
                            </>
                        )}

                        {error && (
                            <Flex direction="column">
                                <Text color="red">{error.response?.data}</Text>
                                <Button
                                    variant="primary"
                                    w="150px"
                                    mt="2em"
                                    alignSelf="center"
                                    onClick={() => navigate('/login')}
                                >
                                    Iniciar sesión
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
