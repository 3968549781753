import { useMemo } from "react";
import { Text, Flex, useMediaQuery } from "@chakra-ui/react";
import Select from "react-select";
//icons
import { TbCar } from "react-icons/tb";
import { PiCarProfile } from "react-icons/pi";
import { PiVan } from "react-icons/pi";
import { PiMotorcycleFill } from "react-icons/pi";
//types
import type { PriceDuration } from "../../../../../types/service";
//fns
import { formatDecimals, parseDuration } from "../../../../common/functions";
import type { SingleValue, ActionMeta } from "react-select";

type SizeSelectProps = {
	choices: PriceDuration[];
	setSelected: (item: PriceDuration | null) => void;
};

interface FormattedOption {
	label: React.ReactNode;
	value: number;
}

export default function SizeSelect({ choices, setSelected }: SizeSelectProps) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const customStyles = {
		control: (provided: any) => ({
			...provided,
			width: "100%"
		}),
		placeholder: (provided: any) => ({
			...provided,
			color: "#0258FF",
			fontWeight: "500",
			fontSize: isMobile ? "12px" : "16px"
		})
	};

	const handleSelect = (newValue: SingleValue<FormattedOption>) => {
		const selectedId = newValue ? newValue.value : null;
		const selected = choices.find((priceDuration) => priceDuration.id === selectedId) || null;
		setSelected(selected);
	};

	const carIcons = {
		UNIQUE: <TbCar color='#0258FF' size='20px' />,
		S: <TbCar color='#0258FF' size='20px' />,
		M: <PiCarProfile color='#0258FF' size='20px' />,
		L: <PiVan color='#0258FF' size='20px' />,
		XL: <PiVan color='#0258FF' size='20px' />,
		MOTO: <PiMotorcycleFill color='#0258FF' size='20px' />
	};

	const formatPrice = (priceDuration: PriceDuration) => {
		if (priceDuration.price_type === "discount" && priceDuration.discounted_price) {
			return (
				<Flex align='center' ml='1rem'>
					<Text as='s' color='gray' fontWeight='semibold'>
						{formatDecimals(priceDuration.price)}€
					</Text>
					<Text ml='0.5rem' color='black' fontWeight='semibold'>
						{formatDecimals(priceDuration.discounted_price)}€
					</Text>
				</Flex>
			);
		}
		return (
			<Text ml='1rem' color='black' fontWeight='semibold'>
				{formatDecimals(priceDuration.price)}€
			</Text>
		);
	};

	const formatData: FormattedOption[] = useMemo(() => {
		return choices.map((priceDuration: PriceDuration) => ({
			label: (
				<Flex align='center' fontSize={isMobile ? "12px" : "16px"}>
					{carIcons[priceDuration.size]}
					<Text ml='0.5rem' color='blue.500' fontWeight='semibold'>
						{priceDuration?.size}
					</Text>
					{formatPrice(priceDuration)}
					<Text ml='0.5rem'>{parseDuration(priceDuration?.duration)}</Text>
				</Flex>
			),
			value: priceDuration?.id
		}));
	}, [choices]);

	return (
		<Select
			styles={customStyles}
			onChange={handleSelect}
			placeholder={isMobile ? "Tamaño" : "Selecciona tamaño"}
			noOptionsMessage={() => "No hay opciones"}
			options={formatData}
		/>
	);
}
