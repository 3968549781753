import { useState } from "react";
import { Flex, useMediaQuery, Image, useToast, Text, Input, IconButton, Button } from "@chakra-ui/react";
//icons
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
//comps
import WorkImageGallery from "./WorkImageGallery";
//api
import { useQueryClient } from "@tanstack/react-query";
import { useAuthHeader } from "react-auth-kit";
import { createWorkImage } from "../../../../../api/businessesApi";
import useAuthMutation from "../../../../../myHooks/useAuthMutation";

const IMAGE_MAX_SIZE_MB = 9; //5MBytes

export default function WorkImageForm() {
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const toast = useToast();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const [imageFile, setImageFile] = useState(null);
	const [preview, setPreview] = useState(null);

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createWorkImage,
		onSuccess: () => {
			toast({ title: "Imagen subida!", status: "success" });
			setImageFile(null);
			setPreview(null);
			QueryClient.invalidateQueries(["workImages"]);
			QueryClient.refetchQueries("workImages", { force: true });
		},
		onError: () => {
			toast({
				title: "Ups! Ha habido un error",
				status: "error"
			});
		}
	});

	const handleSelectImage = (e) => {
		const file = e?.target?.files[0];
		if (file) {
			if (file.size > 1024 * 1024 * IMAGE_MAX_SIZE_MB) {
				alert(
					`El tamaño del archivo es demasiado grande. El tamaño máximo permitido es de ${IMAGE_MAX_SIZE_MB} MB.`
				);
				e.target.value = null; // Limpiar la selección del archivo
				return;
			}

			setImageFile(file);
			const photoUrl = URL?.createObjectURL(file);
			setPreview(photoUrl);
		}
	};
	const handleCancelImage = () => {
		setImageFile(null);
		setPreview();
	};
	const handleSubmit = () => {
		const payload = {
			data: {
				work_image: imageFile
			},
			token: authHeader()
		};
		mutate(payload);
	};
	if (!isMobile) {
		return (
			<Flex direction='column'>
				<Flex w='100%' gap='1rem' align='end'>
					{preview && (
						<>
							<Image rounded='lg' src={preview} objectFit={"cover"} w='300px' />
							<IconButton
								cursor={"pointer"}
								alignSelf='start'
								onClick={handleCancelImage}
								ml='-50px'
								mt='10px'
								size='xs'
								icon={<MdOutlineCancel size='15px' />}
								variant='danger'
							/>
						</>
					)}
					<Flex direction='column' gap='2rem'>
						{!imageFile && (
							<>
								<Text>
									Recomendamos imágenes en formato horizontal, que no ocupen más de{" "}
									{IMAGE_MAX_SIZE_MB}MB
								</Text>
								<Input type='file' accept='image/png, image/jpeg' onChange={handleSelectImage} />
							</>
						)}
						{error && <Text color='red'>{error.response?.data?.work_image[0]}</Text>}
						{imageFile && (
							<Button
								justifySelf='end'
								alignSelf='end'
								onClick={handleSubmit}
								isLoading={isLoading}
								isDisabled={!imageFile}
								variant='primary'
								w='150px'
							>
								Subir
							</Button>
						)}
					</Flex>
				</Flex>
				<WorkImageGallery />
			</Flex>
		);
	}
	return (
		<Flex direction='column' mt='2rem'>
			{preview && (
				<>
					<Image rounded='lg' src={preview} objectFit={"cover"} w='300px' />
				</>
			)}
			<Flex direction='column'>
				{!imageFile && (
					<Input type='file' bg='white' accept='image/png, image/jpeg' onChange={handleSelectImage} />
				)}
				{error && <Text color='red'>{error.response?.data?.work_image[0]}</Text>}
				{imageFile && (
					<Flex align='center' gap='1rem' mt='1rem' justify='end'>
						{preview && (
							<IconButton
								cursor={"pointer"}
								alignSelf='start'
								onClick={handleCancelImage}
								icon={<MdOutlineCancel size='18px' />}
								variant='danger'
							/>
						)}
						<Button
							justifySelf='end'
							alignSelf='end'
							onClick={handleSubmit}
							isLoading={isLoading}
							isDisabled={!imageFile}
							variant='primary'
							w='150px'
						>
							Subir
						</Button>
					</Flex>
				)}
			</Flex>
			<WorkImageGallery />
		</Flex>
	);
}
