import axios from 'axios'

const paymentsApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}payments`
})

export const getCheckoutSession = async (payload) => {
    const response = await paymentsApi.get(`checkout?session_id=${payload.filter[0]}&env_settings=${payload.filter[1]}`, {
        headers: { Authorization: payload.token }
    })
    return response.data
}

export const getWhatsappPackage = async (payload) => {
    const response = await paymentsApi.get('whatsapp-package', {
        headers: { Authorization: payload.token }
    })
    return response.data
}

export const createCheckoutSession = async (payload) => {
    const response = await paymentsApi.post('checkout', payload.data, {
        headers: { Authorization: payload.token }
    })
    return response.data
}
export const createCustomerPortal = async (payload) => {
    const response = await paymentsApi.post('customer-portal', payload.data, {
        headers: { Authorization: payload.token }
    })
    return response.data
}

export const createPayment = async (payload) => {
    const response = await paymentsApi.post('payment', '', {
        headers: { Authorization: payload.token }
    })
    return response.data
}

// Only for admin users
export const cancelPlan = async (payload) => {
    const response = await paymentsApi.post('cancel', payload.data, {
        headers: { Authorization: payload.token }
    })
    return response.data
}
