import { useToast, Flex } from "@chakra-ui/react";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../common/forms/InputField";
// comps
import NextStepButton from "./NextStepButton";
// icons
import { MdOutlinePhone } from "react-icons/md";
import { BsShop } from "react-icons/bs";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthFetch from "../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateBusinessProfile, getBusinessProfile } from "../../../api/businessesApi";

export default function BusinessInformationForm({ nextStep, currentStep, prevStep }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data: businessProfile, isLoading: lf } = useAuthFetch(["businessProfile"], getBusinessProfile);

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateBusinessProfile,
		onSuccess: () => {
			toast({ title: "Negocio actualizado!", status: "success" });
			QueryClient.invalidateQueries(["businessProfile"]);
			QueryClient.refetchQueries("businessProfile", { force: true });
			nextStep();
		},
		onError: (error) => {
			toast({
				title: "Ups! Ha habido un error",
				description: error.code,
				status: "error"
			});
		}
	});
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const validationSchema = Yup.object({
		name: Yup.string().required("El nombre es obligatorio"),
		phone: Yup.string().matches(phoneRegExp, "Teléfono no válido").min(9, "Debe ser de al menos 9 dígitos")
	});
	const initialValues = {
		name: businessProfile?.name || "",
		phone: businessProfile?.phone || ""
	};
	const submit = (values) => {
		const payload = {
			data: values,
			slug: businessProfile.id,
			token: authHeader()
		};
		mutate(payload);
	};
	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			validationSchema={validationSchema}
			onSubmit={(values) => submit(values)}
		>
			{(formik) => (
				<>
					<Flex direction='column' gap='1em' maxW='320px' justify='center'>
						<InputField
							icon={<BsShop color='black' size='15px' />}
							label='Nombre de tu negocio'
							required
							name='name'
							placeholder='Shniy Detail Center'
							error={error?.response?.data?.name}
						/>
						<InputField
							icon={<MdOutlinePhone color='black' size='20px' />}
							label='Telefono de contacto'
							name='phone'
							placeholder='622455654'
							required
							error={error?.response?.data?.phone}
						/>
						<NextStepButton
							prevStep={prevStep}
							currentStep={currentStep}
							isLoading={isLoading || lf}
							formik={formik}
						/>
					</Flex>
				</>
			)}
		</Formik>
	);
}
