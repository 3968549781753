import { Flex, Text, Switch } from "@chakra-ui/react";
import { Field, useField } from "formik";

interface SwitchFieldProps {
	name: string;
	label: string;
	direction?: "row" | "column";
}

export default function SwitchField({ name, label, direction = "row" }: SwitchFieldProps) {
	const [field, meta, helpers] = useField(name);

	return (
		<Flex gap='0.5rem' align='center' direction={direction}>
			<Switch isChecked={field.value} name={name} onChange={() => helpers.setValue(!field.value)} />
			<Text color='darkblue' fontSize='16px' fontWeight='regular'>
				{label}
			</Text>
		</Flex>
	);
}
