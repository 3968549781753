import { Flex, Text, Image, Button, useMediaQuery } from '@chakra-ui/react'
// img
import notFoundImage from '../img/404/404.png'
import { useNavigate } from 'react-router-dom'

export default function NotFound () {
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const navigate = useNavigate()
    if (!isMobile) {
        return (
            <Flex w='100%' minH='100dvh' justify='center' align='center' bg='#F4F4F9' >
                <Flex align='center'>
                    <Flex direction='column' >
                        <Text color='blue.500' fontSize='60px' fontWeight='bold' >
                            Error 404
                        </Text>
                        <Text mt='1rem'fontSize='32px' fontWeight='bold' >
                            ¡Oh vaya!
                        </Text>
                        <Text mt='0.5rem' >
                            La página que buscas no se ha podido encontrar
                        </Text>
                        <Button variant='primary' w='150px' mt='1.5rem'
                            onClick={(() => navigate('dashboard'))}
                        >
                            Volver al panel
                        </Button>
                    </Flex>
                    <Image
                        src={notFoundImage}
                    />
                </Flex>
            </Flex>
        )
    }
    if (isMobile) {
        return (
            <Flex w='100%' minH='100dvh' justify='center' align='center' bg='#F4F4F9' >
                <Flex direction='column' p='2em'>
                    <Text color='blue.500' fontSize='60px' fontWeight='bold' >
                            Error 404
                    </Text>
                    <Text mt='1rem'fontSize='32px' fontWeight='bold' >
                            ¡Oh vaya!
                    </Text>
                    <Text mt='0.5rem' >
                            La página que buscas no se ha podido encontrar
                    </Text>
                    <Button variant='primary' w='150px' mt='1.5rem'
                        onClick={(() => navigate('dashboard'))}
                    >
                            Volver al panel
                    </Button>
                    <Image
                        mt='2rem'
                        alignSelf='center'
                        maxW='80%'
                        src={notFoundImage}
                    />
                </Flex>
            </Flex>
        )
    }
}
