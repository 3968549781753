import { Button, useToast } from "@chakra-ui/react";
// api
import useAuthMutation from "../../myHooks/useAuthMutation";
import { createCheckoutSession } from "../../api/paymentsApi";
// icons
import { BsArrowUpCircle } from "react-icons/bs";
// auth
import { useAuthHeader, useAuthUser } from "react-auth-kit";
//type

interface CheckoutButtonProps {
	label?: string;
	type?: string;
	variant?: "primary" | "secondary";
	plan: "LITE" | "PRO" | "LITE_WHATSAPP" | "PRO_WHATSAPP";
	period: "monthly" | "biannual" | "annual";
}

export default function CheckoutButton({
	label,
	type,
	plan,
	period,
	variant = "primary",
	...props
}: CheckoutButtonProps) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const auth = useAuthUser();

	const currentPlan = auth()?.plan?.name;

	const env_settings = process.env.REACT_APP_ENV_SETTINGS;
	const { isLoading, mutate } = useAuthMutation({
		mutationFn: createCheckoutSession,
		onSuccess: (response) => {
			window.location.href = response.redirect_url;
		},
		onError: () => {
			toast({
				title: "Ups! Ha habido un error",
				status: "error"
			});
		}
	});

	const handleCheckout = () => {
		const payload = {
			token: authHeader(),
			data: {
				subscribe_to: plan,
				period: period,
				env_settings
			}
		};
		mutate(payload);
	};

	if (type === "green") {
		return (
			<Button
				my='1em'
				w='100%'
				size='md'
				colorScheme='green'
				{...props}
				isLoading={isLoading}
				leftIcon={<BsArrowUpCircle color='white' size='20px' />}
				alignSelf='center'
				onClick={handleCheckout}
			>
				{label || "Actualizar"}
			</Button>
		);
	}
	if (currentPlan === plan) {
		return (
			<Button colorScheme='green' isDisabled isLoading={isLoading}>
				Actual
			</Button>
		);
	}
	return (
		<Button
			variant={variant}
			{...props}
			onClick={handleCheckout}
			isLoading={isLoading}
			title='Necesitas una suscripción PRO para acceder a este plan'
		>
			{label || "Comprar"}
		</Button>
	);
}
