import { useRef } from 'react'
import { Image, Flex, IconButton, useMediaQuery, Text } from '@chakra-ui/react'
// icons
import { TbDiamonds } from 'react-icons/tb'
import { MdDeleteOutline } from 'react-icons/md'
import { IoClose } from 'react-icons/io5'

export default function PhotoViewer ({
    photo,
    savedInspection,
    setPhoto,
    view,
    circleCoords,
    setCircleCoords,
    setSelectedDetail
}) {
    const containerRef = useRef(null)
    const { x, y } = circleCoords
    const { details } = savedInspection || {}
    const [isMobile] = useMediaQuery('(max-width: 768px)')

    const handleImageClick = (event) => {
        setSelectedDetail()
        const containerRect = containerRef.current.getBoundingClientRect()
        const x = event.clientX - containerRect.left
        const y = event.clientY - containerRect.top
        setCircleCoords({ x, y })
    }

    const handleSelectDetail = (detail) => {
        setSelectedDetail(detail)
        setCircleCoords({ x: detail.x_coord, y: detail.y_coord })
    }

    const handleDeSelectDetail = () => {
        setSelectedDetail()
        setCircleCoords({ x: 0, y: 0 })
    }

    const deleteImage = () => {
        setPhoto((prevPhoto) => ({
            ...prevPhoto,
            [view]: null
        }))
    }
    const circleStyle = {
        position: 'absolute',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        background: 'blue',
        opacity: 0.7
    }
    const detailMarker = (x, y) => (
        <>
            <div style={{ ...circleStyle, left: x - 10, top: y - 10 }} />
            <TbDiamonds
                color="white"
                fill="white"
                size="20px"
                style={{ position: 'absolute', left: x - 5, top: y - 5 }}
            />
        </>
    )
    const handlePhotoDisplay = () => {
        if (view === 'back' && savedInspection?.image_back) return savedInspection.image_back
        else if (view === 'left' && savedInspection?.image_left_side) return savedInspection.image_left_side
        else if (view === 'right_side' && savedInspection?.imge_rigght_side) return savedInspection.imge_rigght_side
        else if (view === 'front' && savedInspection?.image_front) return savedInspection.image_front
        else return photo
    }
    const detailSelected = x !== 0 && y !== 0
    return (
        <Flex ref={containerRef} style={{ position: 'relative' }} justify="center" direction="column">
            <Flex w="100%" gap="1em" pt="20px" pl="15px">
                <IconButton
                    onClick={deleteImage}
                    variant="danger"
                    size="sm"
                    icon={<MdDeleteOutline size="20px" />}
                    zIndex={10}
                />

                {!(circleCoords.x === 0 && circleCoords.y === 0) && (
                    <IconButton
                        onClick={handleDeSelectDetail}
                        bg="white"
                        size="sm"
                        icon={<IoClose size="25px" />}
                        zIndex={10}
                    />
                )}
            </Flex>
            {!isMobile
                ? (
                    <Image
                        src={handlePhotoDisplay()}
                        opacity={'80%'}
                        objectFit="contain"
                        w="auto"
                        maxH="65vh"
                        rounded="xl"
                        onClick={handleImageClick}
                    />
                )
                : (
                    <Image
                        mt="-40px"
                        src={handlePhotoDisplay()}
                        opacity={'80%'}
                        objectFit="contain"
                        h="100%"
                        rounded="xl"
                        onClick={handleImageClick}
                    />
                )}
            {detailSelected && detailMarker(x, y)}
            {details?.map((detail) => (
                <Flex cursor="pointer" key={detail.id} onClick={() => handleSelectDetail(detail)}>
                    {detailMarker(detail.x_coord, detail.y_coord)}
                </Flex>
            ))}
            {!detailSelected && (
                <Text py="2em" fontSize="16px">
                    Selecciona en cualquier punto del coche para añadir un detalle
                </Text>
            )}
            {savedInspection?.details && <Text>tenemos details</Text>}
        </Flex>
    )
}
