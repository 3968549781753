import { Flex, Text, useMediaQuery } from "@chakra-ui/react";
// comps
import MessageCountBox from "./MessageCountBox";

export default function WhatsappPreviewBox() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<Flex gap='2em' align='start'>
				<Flex direction='column' gap='0.5em' maxW='300px'>
					<Flex direction='column' bg='white' p='1em' gap='0.2em' rounded='xl' fontSize='14px'>
						<Text>¡Hola Jose Manuel 👋!</Text>
						<Text>
							Te recordamos que tienes una cita en Centro Detailing SuperShine el jueves 21 de Junio de
							2024 a las 11:30.
						</Text>
						<Text>¿Nos confirmas tu asistencia?</Text>
						<Text fontSize='12px' color='blue.500' align='end' mt='-15px'>
							11:45
						</Text>
					</Flex>
					<Flex justify='space-between'>
						<Flex bg='white' px='1em' py='0.5em' rounded='xl'>
							👌🏻 ASISTIRÉ
						</Flex>
						<Flex bg='white' px='1em' py='0.5em' rounded='xl'>
							⛔ NO ASISTIRÉ
						</Flex>
					</Flex>
				</Flex>
				<MessageCountBox />
			</Flex>
		);
	}
	return <MessageCountBox />;
}
