import {
	Flex,
	Text,
	Button,
	Avatar,
	Badge,
	Progress,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useMediaQuery
} from "@chakra-ui/react";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

// comps
import UserPlanDisplay from "../plans/UserPlanDisplay";
import Navbar from "./Navbar";
import PlanLimitsProgressFree from "../common/PlanLimitsProgressFree";
import PlanLimitsProgressPro from "../common/PlanLimitsProgressPro";
import CheckoutButton from "../payments/CheckoutButton";
// icons
import { FiLogOut } from "react-icons/fi";
import { IoMdArrowDropdown, IoMdSettings } from "react-icons/io";
// auth
import { useSignOut, useAuthUser } from "react-auth-kit";
//types
import type { UserPlan } from "../../types/plan";

interface TopbarProps {
	userplan: UserPlan;
}

export default function Topbar({ userplan }: TopbarProps) {
	const auth = useAuthUser();
	const signOut = useSignOut();
	const navigate = useNavigate();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const name = auth()?.name || auth()?.email?.split("@")[0];
	const plan = userplan?.plan?.name;
	const plan_expiration = userplan?.expires_at;
	const profile_image = auth()?.profile_image;

	const calcExirationInDays = () => {
		const startDate = moment();
		const endDate = moment(plan_expiration);
		let daysDiff = endDate.diff(startDate, "days");
		if (daysDiff < 0) {
			daysDiff = 0;
		}
		return daysDiff;
	};
	if (!isMobile) {
		return (
			<Flex w='100%' h='8vh' bg='white' py='1em' px='2em' gap='2em' align='center' justify='space-between'>
				<Flex align='center' gap='0.5em'>
					<UserPlanDisplay plan={plan} size='md' />
					{plan === "TRIAL" && (
						<>
							{plan_expiration && (
								<Text color='#737A87' fontSize='16px'>
									Te quedan {calcExirationInDays()} días de prueba.
								</Text>
							)}
							<Button variant='white' border='none' fontWeight='bold' onClick={() => navigate("/planes")}>
								Actualiza tu plan ahora
							</Button>
							{/*
                        <CheckoutButton
                            label='Actualiza tu plan ahora'
                            border='none'
                            variant='white'
                            fontWeight='bold'
                            subscription='pro'
                        />
                        */}
						</>
					)}
				</Flex>
				<Flex align='center' gap='2em'>
					<Progress value={80} />
					<Text> ¡Hola {name}! ¿Cómo va el negocio?</Text>
					{!(plan === "LITE" || plan === "TRIAL") && <PlanLimitsProgressPro />}
					<Menu>
						<Flex align='end'>
							<MenuButton>
								<Avatar name={name} src={profile_image} size='md' justifySelf='flex-end' />
							</MenuButton>
							<IoMdArrowDropdown />
						</Flex>

						<MenuList>
							<MenuItem
								gap='0.5em'
								onClick={() => {
									navigate("/ajustes");
								}}
							>
								<IoMdSettings />
								<span>Ajustes</span>
							</MenuItem>
							<MenuItem
								gap='0.5em'
								onClick={() => {
									signOut();
									navigate("/login");
								}}
							>
								<FiLogOut />
								<span>Cerrar sesión</span>
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</Flex>
		);
	}
	return (
		<Flex
			w='100%'
			h='8dvh'
			bg='white'
			py='1em'
			px='2em'
			gap='2em'
			align='center'
			justify='space-between'
			position='sticky'
			top={0}
			zIndex='sticky'
			boxShadow='sm'
		>
			<Navbar userplan={userplan} />
			{plan === "TRIAL" ? (
				<Flex direction='column' align='center'>
					<Flex align='center'>
						<Badge rounded='2xl' fontSize='10px' bg='#FF7C02' color='white' py='0.5em' px='1.5em'>
							Prueba gratis
						</Badge>
						<Button
							variant='white'
							border='none'
							fontWeight='bold'
							fontSize='14px'
							onClick={() => navigate("/planes")}
						>
							Actualizar
						</Button>
					</Flex>
					{plan_expiration && (
						<Text color='#737A87' fontSize='10px'>
							Te quedan {calcExirationInDays()} días de prueba.
						</Text>
					)}
				</Flex>
			) : (
				<Text fontSize='14px' align='center'>
					!Hola {name?.split(" ")[0]}!
				</Text>
			)}
			<Menu>
				<Flex align='end'>
					<MenuButton>
						<Avatar name={name} src={profile_image} size='sm' justifySelf='flex-end' />
					</MenuButton>
					<IoMdArrowDropdown />
				</Flex>

				<MenuList>
					<MenuItem
						gap='0.5em'
						onClick={() => {
							signOut();
							navigate("/login");
						}}
					>
						<FiLogOut />
						<span>Cerrar sesión</span>
					</MenuItem>
				</MenuList>
			</Menu>
		</Flex>
	);
}
