import { useContext, useEffect } from "react";
import { Flex, useMediaQuery, useDisclosure } from "@chakra-ui/react";

import { useNavigate, useLocation } from "react-router-dom";
// comps
import Navbar from "../components/navbar/Navbar";
import Topbar from "../components/navbar/Topbar";
import { PlanContext } from "../components/common/context/PlanContext";
import DirectPaymentModal from "../pages/Payments/DirectPaymentModal";
// api
import useAuthFetch from "../myHooks/useAuthFetch";
import { getPlanLimitations, getUserPlan } from "../api/plansApi";
// auth
import { useAuthUser, useAuthHeader } from "react-auth-kit";
//types
import type { ReactNode } from "react";
import type { UserPlan, UserPlanLimitations } from "../types/plan";

interface AppLayoutProps {
	children: ReactNode;
	bgMobile?: string;
}

export default function AppLayout({ children, ...props }: AppLayoutProps) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const { isOpen: isOpenPayment, onOpen: onOpenPayment, onClose: onClosePayment } = useDisclosure();
	const navigate = useNavigate();
	const authUser = useAuthUser();
	const location = useLocation(); // Obtener la instancia de useLocation
	const auth = useAuthUser();
	const isStaff = auth()?.is_staff;

	const { data } = useAuthFetch(["planlimits"], getPlanLimitations) as {
		data: UserPlanLimitations;
	};
	const { setLimits } = useContext(PlanContext);
	const { data: userplan } = useAuthFetch(["userplan"], getUserPlan) as {
		data: UserPlan;
	};
	useEffect(() => {
		if (data) {
			setLimits(data);
		}
	}, [data, setLimits]);

	useEffect(() => {
		if (
			location.pathname !== "/planexpired" &&
			location.pathname !== "/planes" &&
			userplan?.has_expired &&
			!isStaff &&
			userplan?.plan.name !== "BETA"
		) {
			navigate("/planexpired");
		}
	}, [userplan, location, navigate, isStaff]);

	useEffect(() => {
		if (
			//authUser()?.direct_payment &&
			authUser()?.plan?.name === "TRIAL" &&
			location.pathname !== "/checkoutstatus/"
		) {
			console.log("enterin here");
			onOpenPayment();
		}
		//onOpenPayment();
	}, []);

	if (!isMobile) {
		return (
			<>
				<Flex w='100%' bg='lightgrey'>
					<Navbar userplan={userplan} />
					<Flex
						direction='column'
						w='100%'
						align='center'
						maxH='100dvh'
						overflowY='scroll'
						sx={{
							"&::-webkit-scrollbar": { width: "10px", height: "8px" },
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#f5f5f5"
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#cfcfcf"
							}
						}}
					>
						<Topbar userplan={userplan} />
						{children}
					</Flex>
				</Flex>
				<DirectPaymentModal isOpen={isOpenPayment} onClose={onClosePayment} />
			</>
		);
	}
	return (
		<>
			<Flex w='100%' direction='column' minH='100dvh' bg={props?.bgMobile ? props.bgMobile : "lightgrey"}>
				<Topbar userplan={userplan} />
				{children}
			</Flex>
			<DirectPaymentModal isOpen={isOpenPayment} onClose={onClosePayment} />
		</>
	);
}
