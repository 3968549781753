import { useState, useMemo, useCallback } from "react";
import {
	useToast,
	useDisclosure,
	Flex,
	Text,
	Box,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from "@chakra-ui/react";
import moment from "moment";
// comps
import TableMobile from "../../../../common/Tables/Mobile/TableMobile";
import ExpenseForm from "../../forms/RecurringExpenseForm";
import PopoverDelete from "../../../../common/PopoverDelete";
import EditItemButton from "../../../../common/Tables/EditItemButton";
import RecurrenceTagDisplay from "../RecurrenceTagDisplay";
// fns
// icons
import { AiOutlineShop } from "react-icons/ai";
// img
import emptyStock from "../../../../../img/stock/emptyStock.png";
// api
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { useQueryClient } from "@tanstack/react-query";
import { getAllExpenses, deleteExpense } from "../../../../../api/stockApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function ExpensesTableMobile() {
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [expense, setExpense] = useState();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data, isLoading } = useAuthFetch(["expenses", page], getAllExpenses, page);

	const { mutate, isLoading: ld } = useAuthMutation({
		mutationFn: deleteExpense,
		onSuccess: () => {
			toast({ title: "Borrado con exito!", status: "success" });
			QueryClient.invalidateQueries(["expenses"]);
			QueryClient.refetchQueries("expenses", { force: true });
			onClose();
		},
		onError: () => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const deleteItem = useCallback(
		(id) => {
			mutate({ slug: id, token: authHeader() });
		},
		[authHeader, mutate]
	);
	const openEdit = (item) => {
		setExpense(item);
		onOpen();
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((expense) => (
				<AccordionItem key={expense.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Box as='span' flex='1' textAlign='left' fontSize='14px' color='darkblue'>
							{expense.name}
							<RecurrenceTagDisplay ml='1.5rem' recurrence={expense.recurrence} />
						</Box>
						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex
							w='100%'
							h='100%'
							direction='column'
							gap='1em'
							justify='space-between'
							fontSize='14px'
							p='0.5em'
						>
							<Text>Descripción {expense.description || "-- --"}</Text>
							<Text>Beneficiario {expense.beneficiary || "-- --"}</Text>
							<Text>{moment(expense.purchased_at).format("lll")}</Text>
							<Text fontWeight='bold'>Total: {expense.price}€</Text>
							<Flex w='100%' justify='end' mt='-3em'>
								<EditItemButton editFn={openEdit} item={expense} />
								<PopoverDelete deleteFn={deleteItem} slug={expense.id} isLoading={ld} />
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data, deleteItem, ld, onOpen]
	);
	const openForm = () => {
		setExpense();
		onOpen();
	};
	return (
		<>
			<TableMobile
				data={data}
				tableData={tableData}
				openFormFn={openForm}
				isLoading={isLoading}
				emptyImg={emptyStock}
				label='Compra'
				page={page}
				setPage={setPage}
			/>
			<ExpenseForm item={expense} onClose={onClose} isOpen={isOpen} />
		</>
	);
}
