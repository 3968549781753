import { useState } from 'react'
import { Text, Flex, Image, Button } from '@chakra-ui/react'
// comps
import TutorialPagination from '../TutorialPagination'
// img
import step1 from '../../../img/tutorial/orders/Tutorial_Ordenes_1.png'
import step2 from '../../../img/tutorial/orders/Tutorial_Ordenes_2.png'
import step3 from '../../../img/tutorial/orders/Tutorial_Ordenes_3.png'
import step4 from '../../../img/tutorial/orders/Tutorial_Ordenes_4.png'
import step5 from '../../../img/tutorial/orders/Tutorial_Ordenes_5.png'
import step6 from '../../../img/tutorial/orders/Tutorial_Ordenes_6.png'
import step7 from '../../../img/tutorial/orders/Tutorial_Ordenes_7.png'
import step8 from '../../../img/tutorial/orders/Tutorial_Ordenes_8.png'
import step9 from '../../../img/tutorial/orders/Tutorial_Ordenes_9.png'
import andres from '../../../img/tutorial/Andres.png'

export default function OrderTutorial ({ onClose }) {
    const [step, setStep] = useState(0)

    const slides = [
        {
            title: (
                <Flex flexWrap="wrap" justify="center">
                    <Text>Haz clic en</Text>
                    <Text color="blue" fontWeight="bold" mx="0.2em">
                        + Orden
                    </Text>
                    <Text>para crear tu primera orden</Text>
                </Flex>
            ),
            image: step1
        },
        {
            title: (
                <Text align="center">
                    Rellena los campos y haz clic en
                    <span style={{ color: 'blue' }}> Guardar</span>
                </Text>
            ),
            image: step2
        },
        {
            title: (
                <Text align="center">
                    Puedes crear un
                    <span style={{ color: 'blue' }}> nuevo coche</span> que se guardará en tu base de datos
                </Text>
            ),
            image: step3
        },
        {
            title: (
                <Text align="center">
                    También crear un <span style={{ color: 'blue' }}> nuevo cliente</span> que se asociará a un coche
                </Text>
            ),
            image: step4
        },
        {
            title: (
                <Text align="center">
                    Crea un <span style={{ color: 'blue' }}> presupuesto</span> de tu orden
                </Text>
            ),
            image: step5
        },
        {
            title: (
                <Text align="center">
                    Cuando hagas clic en <span style={{ color: 'blue' }}> Empezar</span> tu órden pasará a "En Curso"
                    Una vez hayas terminado tu orden, haz clic en <span style={{ color: 'green' }}> Finalizar</span> y
                    se pasará a "Completadas"
                </Text>
            ),
            image: step6
        },
        {
            title: <Text align="center">Una vez finalizada crea una factura para enviársela a tu cliente</Text>,
            image: step7
        },
        {
            title: (
                <Text align="center">
                    Echa un vistazo a la base de datos de tus clientes y coches en la sección de
                    <span style={{ color: 'blue' }}> Datos </span>
                    que encontrarás en el menú de navegación
                </Text>
            ),
            image: step8
        },
        {
            title: (
                <Text align="center">
                    Echa un vistazo a la base de datos de tus facturas y presupuestos en la sección de
                    <span style={{ color: 'blue' }}> Facturas </span>
                    que encontrarás en el menú de navegación
                </Text>
            ),
            image: step9
        },
        {
            title: (
                <Text align="center">
                    ¡Enhorabuena, ya tienes las nociones básicas para empezar a disfrutar de todas las ventajas de
                    Anothertool!
                </Text>
            ),
            image: andres
        }
    ]
    const slide = slides[step]
    return (
        <>
            <Flex direction="column" gap="1em" justify="center" align="center" p="2em" h="550px">
                {(step === 0 || step === slides.length - 1) && <Image src={andres} />}
                {slide.title}
                {step !== slides.length - 1 && <Image maxH="350px" src={slide.image} />}
                {step === slides.length - 1 && (
                    <Button onClick={onClose} variant="primary" mt="2em">
                        Terminar Guía
                    </Button>
                )}
            </Flex>
            <TutorialPagination step={step} slides={slides} setStep={setStep} />
        </>
    )
}
