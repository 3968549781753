import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// big calendar
import "react-big-calendar/lib/css/react-big-calendar.css";
// chakra ui
import { ChakraProvider } from "@chakra-ui/react";
import { myTheme } from "./styles/myTheme";
// query client
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// auth
import { AuthProvider } from "react-auth-kit";
import refreshApi from "./api/refreshApi";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<AuthProvider
		authType={"cookie"}
		authName={"_auth"}
		refresh={refreshApi}
		cookieDomain={window.location.hostname}
		cookieSecure={false}
	>
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<ChakraProvider theme={myTheme}>
					<App />
				</ChakraProvider>
			</BrowserRouter>
		</QueryClientProvider>
	</AuthProvider>
);
