import { useState, useEffect } from 'react'
import { Flex, Text, Switch } from '@chakra-ui/react'
// comps
import InputField from '../../../common/forms/InputField'

export default function DiscountField ({ formik, calculateTotals, order }) {
    const [display, setDisplay] = useState(Number(formik.values.discount) > 0)
    useEffect(() => {
        calculateTotals(formik.values, order)
        if (!display) {
            formik.setFieldValue('discount', 0)
        }
    }, [display])

    return (
        <Flex direction="column">
            <Flex align="center" gap="2em" mt="1em" h="50px">
                <Switch onChange={() => setDisplay(!display)} isChecked={display} />
                <Text minW="200px">Añadir descuento (%)</Text>
            </Flex>
            {display && <InputField name="discount" w="150px" placeholder="x%" />}
        </Flex>
    )
}
