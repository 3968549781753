import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work

export default function OrdersChart({ data }) {
	const transformData = () => {
		if (data) {
			const chartData = data.chart;
			return chartData.map((item) => ({
				date: moment(item.date).format("D MMM"),
				Ordenes: item.orders,
				Ausencias: item.absences
			}));
		}
		return [];
	};

	const calculateMaxDataValue = () => {
		const flattenedData = transformData().flatMap((entry) => [entry.Ordenes, entry.Ausencias]);
		const max = Math.max(...flattenedData);
		if (max > 0) {
			return max * 1.5;
		}
		return 5;
	};

	const parsedData = transformData();
	const maxDataValue = calculateMaxDataValue();
	const isEmpty = data?.orders_graph?.length < 1;

	return (
		<Flex direction='column' align='center' w='100%' h='100%' bg='white' p='2em' rounded='xl'>
			<Flex w='100%' justify='space-between' align='center' gap='0.5em' mb='1em' px='2em'>
				<Text fontSize='20px' fontWeight='bold'>
					Resumen órdenes
				</Text>
				<Flex align='center' gap='1em'>
					<Text color='gray'>TOTAL:</Text>
					<Text fontSize='28px' fontWeight='600'>
						{data?.now}
					</Text>
				</Flex>
			</Flex>
			{isEmpty && (
				<Text noOfLines={2} align='center' mb='-25px' fontWeight='bold' zIndex={1000}>
					¡Empieza a generar órdenes de trabajo para ver un resumen!
				</Text>
			)}
			<Flex filter={isEmpty && "grayscale(100%) blur(3px) opacity(0.90)"} w='100%' h='100%'>
				<ResponsiveContainer width='100%' minHeight={200} maxHeight={"100%"}>
					<AreaChart data={parsedData}>
						<defs>
							<linearGradient id='blueGradient' x1='0' y1='0' x2='0' y2='1'>
								<stop offset='5%' stopColor={!isEmpty ? "#CCDEFF" : "#737A87"} stopOpacity={0.8} />
								<stop offset='90%' stopColor={!isEmpty ? "#0258FF" : "#737A87"} stopOpacity={0} />
							</linearGradient>
						</defs>

						<XAxis dataKey='date' />
						<YAxis domain={[0, maxDataValue]} />
						<Tooltip />
						<Area
							type='monotone'
							strokeWidth={2}
							dataKey='Ordenes'
							stroke={!isEmpty ? "#0258FF" : "#737A87"}
							fillOpacity={1}
							fill='url(#blueGradient)'
						/>
						<Area
							strokeWidth={2}
							type='linear'
							dataKey='Ausencias'
							stroke='orange'
							strokeDasharray='10 10'
							fillOpacity={0}
						/>
					</AreaChart>
				</ResponsiveContainer>
			</Flex>
		</Flex>
	);
}
