import { useState } from 'react'
import { Text, Flex, Image, Button } from '@chakra-ui/react'
// comps
import TutorialPagination from '../TutorialPagination'
// img
import step1 from '../../../img/tutorial/dashboard/Tutorial_Ordenes_1.png'
import step2 from '../../../img/tutorial/dashboard/Tutorial_Ordenes_2.png'
import step3 from '../../../img/tutorial/dashboard/Tutorial_Ordenes_3.png'
import step4 from '../../../img/tutorial/dashboard/Tutorial_Ordenes_4.png'
import step5 from '../../../img/tutorial/dashboard/Tutorial_Ordenes_5.png'
import step6 from '../../../img/tutorial/dashboard/Tutorial_Ordenes_6.png'
import step7 from '../../../img/tutorial/dashboard/Tutorial_Ordenes_7.png'
import step8 from '../../../img/tutorial/dashboard/Tutorial_Ordenes_8.png'
import step9 from '../../../img/tutorial/dashboard/Tutorial_Ordenes_9.png'
import andres from '../../../img/tutorial/Andres.png'

export default function DashboardTutorial ({ onClose }) {
    const [step, setStep] = useState(0)

    const slides = [
        {
            title: (
                <Text align="center" maxW="250px">
                    Haz clic en <span style={{ fontWeight: 'bold', color: 'blue' }}> + Orden </span> para crear tu
                    primera orden
                </Text>
            ),
            image: step1
        },
        {
            title: (
                <Text align="center" maxW="250px">
                    Rellena los campos y haz clic en <span style={{ fontWeight: 'bold', color: 'blue' }}>Guardar</span>
                </Text>
            ),
            image: step2
        },
        {
            title: (
                <Text align="center" maxW="250px">
                    Puedes crear un <span style={{ fontWeight: 'bold', color: 'blue' }}>nuevo coche</span> que se
                    guardará en tu base de
                </Text>
            ),
            image: step3
        },
        {
            title: (
                <Text align="center" maxW="250px">
                    Puedes crear un <span style={{ fontWeight: 'bold', color: 'blue' }}>nuevo cliente</span> que se
                    asociará a un coche
                </Text>
            ),
            image: step4
        },
        {
            title: (
                <Text align="center" maxW="250px">
                    Crea un presupuesto a partir de tu orden
                </Text>
            ),
            image: step5
        },
        {
            title: (
                <Text align="center">
                    Cuando hagas clic en <span style={{ fontWeight: 'bold', color: 'blue' }}>Empezar</span> tu órden
                    pasara a "En Curso". Una vez hayas terminado tu órden, haz clic en{' '}
                    <span style={{ fontWeight: 'bold', color: 'green' }}>Finalizar</span>y se pasará a "Completadas"
                </Text>
            ),
            image: step6
        },
        {
            title: <Text align="center">Una vez finalizada crea una factura para enviársela a tu cliente</Text>,
            image: step7
        },
        {
            title: (
                <Text align="center">
                    Echa un vistazo a tu base de datos de clientes y coches en la sección de{' '}
                    <span style={{ fontWeight: 'bold', color: 'blue' }}>Datos </span>
                    que encontrarás en la barra de navegación en la parte izquierda.
                </Text>
            ),
            image: step8
        },
        {
            title: (
                <Text align="center">
                    Echa un vistazo a tu base de datos de facturas y presuppuestos en la sección de{' '}
                    <span style={{ fontWeight: 'bold', color: 'blue' }}>Facturas </span>
                    que encontrarás en la barra de navegación en la parte izquierda.
                </Text>
            ),
            image: step9
        },
        {
            title: (
                <Text align="center">
                    ¡Enhorabuena, ya tienes nociones básicas para empezar a disfrutar de ttodas las ventajas de
                    Anothertool!
                </Text>
            ),
            image: andres
        }
    ]
    const slide = slides[step]
    return (
        <>
            <Flex direction="column" gap="1em" justify="center" align="center" p="1em" h="600px">
                {(step === 0 || step === slides.length - 1) && <Image src={andres} />}
                {slide.title}
                {step !== slides.length - 1 && <Image src={slide.image} />}
                {step === slides.length - 1 && (
                    <Button onClick={onClose} variant="primary" mt="2em">
                        Terminar Guía
                    </Button>
                )}
            </Flex>
            <TutorialPagination step={step} slides={slides} setStep={setStep} />
        </>
    )
}
