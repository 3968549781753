import { useState } from "react";
import { Flex, Tabs, TabList, TabPanels, Tab, TabPanel, Select, useMediaQuery } from "@chakra-ui/react";
// comps
import OrdersTableMobile from "../../components/pages/orders/tables/OrdersTableMobile";
import CarsTableMobile from "../../components/pages/data/cars/CarsTableMobile";
import ClientsTableMobile from "../../components/pages/data/clients/ClientsTableMobile";
import OrdersTablePag from "../../components/pages/orders/tables/OrdersTablePag";
import ClientsTablePag from "../../components/pages/data/clients/ClientsTablePag";
import CarsTablePag from "../../components/pages/data/cars/CarsTablePag";
import HelperModal from "../../components/tutorial/HelperModal";

export default function Data() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [tableSelect, setTableSelect] = useState("orders");

	if (!isMobile) {
		return (
			<>
				<Flex justify='center' align='top' py='30px' w='100%'>
					<Tabs
						variant='brand'
						rounded='xl'
						bg='white'
						px='20px'
						pt='30px'
						isLazy={true}
						minH='35vh'
						width={{
							base: "100%",
							md: "700px",
							lg: "800px",
							xl: "1080px",
							"2xl": "1300px"
						}}
					>
						<TabList ml='15px'>
							<Tab>Ordenes</Tab>
							<Tab>Coches</Tab>
							<Tab>Clientes</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<OrdersTablePag />
							</TabPanel>
							<TabPanel>
								<CarsTablePag />
							</TabPanel>
							<TabPanel>
								<ClientsTablePag />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Flex>
				<HelperModal />
			</>
		);
	}
	return (
		<Flex w='100%' direction='column' px='1em' pt='0.5em'>
			<Select
				value={tableSelect}
				onChange={(e) => setTableSelect(e.target.value)}
				color='black'
				fontWeight='bold'
				maxW='150px'
				fontSize='20px'
				border='none'
				h='5dvh'
			>
				<option value='orders' style={{ fontSize: "16px" }}>
					Órdenes
				</option>

				<option value='cars' style={{ fontSize: "16px" }}>
					Coches
				</option>
				<option value='clients' style={{ fontSize: "16px" }}>
					Clientes
				</option>
			</Select>
			{tableSelect === "orders" && <OrdersTableMobile />}
			{tableSelect === "cars" && <CarsTableMobile />}
			{tableSelect === "clients" && <ClientsTableMobile />}
		</Flex>
	);
}
