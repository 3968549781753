import { Flex, Text, Skeleton, useMediaQuery } from '@chakra-ui/react'
// icons
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi'

export default function ComparisonStatBox ({ icon, title, data, period, isReverse, isFinancial, isLoading }) {
    const [isLittle] = useMediaQuery('(max-width: 1080px)')
    const RED = '#F44E4E'
    const GREEN = '#42C74F'

    const periodMappings = {
        month: 'el último mes',
        year: 'el último año',
        week: 'la última semana'
    }
    const { last_period: lastPeriod, now } = data || {}
    let changeText, changeColor, changeIcon
    if (lastPeriod > 0) {
        changeText = `+${Math.floor(lastPeriod)} ` + (isFinancial ? '€' : '')
        changeColor = isReverse ? RED : GREEN
        changeIcon = <BiTrendingUp color={isReverse ? RED : GREEN} />
    } else if (lastPeriod < 0) {
        changeText = `${Math.floor(lastPeriod)}` + (isFinancial ? '€' : '')
        changeColor = isReverse ? GREEN : RED
        changeIcon = <BiTrendingDown color={isReverse ? GREEN : RED} />
    } else {
        changeText = 'Igual'
        changeColor = 'grey'
    }
    const periodDisplay = periodMappings[period] || ''
    return (
        <Flex bg="white" rounded="lg" p="1.5em" justify="space-between" direction="column" align="center" h="100%">
            <Flex align="center" gap="0.5em">
                {icon}
                <Text fontWeight="bold" fontSize="20px">
                    {title}
                </Text>
            </Flex>
            {isLoading
                ? <Skeleton height='25px' w='60%' />
                : <Text fontSize="40px" fontWeight="bold">
                    {Math.floor(now)}
                    {isFinancial && '€'}
                </Text>
            }
            {!isLittle
                ? (
                    <Flex align="center" gap="0.5em">
                        {changeIcon}
                        <Text color={changeColor}>{changeText}</Text>
                        <Text fontSize="10px" color="gray">
                        vs {periodDisplay}
                        </Text>
                    </Flex>
                )
                : (
                    <Flex direction="column">
                        <Flex align="center" gap="0.5em">
                            {changeIcon}
                            <Text color={changeColor}>{changeText}</Text>
                        </Flex>
                        <Text fontSize="10px" color="gray">
                        vs {periodDisplay}
                        </Text>
                    </Flex>
                )}
        </Flex>
    )
}
