import { Grid, GridItem, useMediaQuery, Flex } from "@chakra-ui/react";
// comps
import RevenueChart from "./incomeExpenses/RevenueChart";
import ComparisonStatBox from "./ComparisonStatBox";
import PendingInvoicesStatBox from "./incomeExpenses/PendingInvoicesStatBox";
import PayMethodPieChart from "./incomeExpenses/PayMethodPieChart";
import ExpensesBox from "./incomeExpenses/ExpensesBox";
import IncomeBox from "./incomeExpenses/IncomeBox";
import LoadingSpinner from "../../common/loading/LoadingSpinner";
// icons
import { TbFileInvoice } from "react-icons/tb";
import { BsBag } from "react-icons/bs";
import { FaEuroSign } from "react-icons/fa";

export default function FinancialStats({ data, period, isLoading }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<Grid
				pt='2em'
				pb='4em'
				px='4em'
				w='100%'
				h='86vh'
				templateRows='repeat(6, 1fr)'
				templateColumns='repeat(4, 1fr)'
				gap={6}
			>
				<GridItem rowSpan={4} colSpan={2}>
					{isLoading ? (
						<Flex w='100%' h='100%' bg='white' py='1em' rounded='xl' justify='center' align='center'>
							<LoadingSpinner />
						</Flex>
					) : (
						<RevenueChart data={data?.revenue} />
					)}
				</GridItem>
				<GridItem rowSpan={3} colSpan={2}>
					{isLoading ? (
						<Flex w='100%' h='100%' bg='white' py='1em' rounded='xl' justify='center' align='center'>
							<LoadingSpinner />
						</Flex>
					) : (
						<IncomeBox income={data?.income} period={period} isLoading={isLoading} />
					)}
				</GridItem>
				<GridItem colSpan={2} rowSpan={3}>
					{isLoading ? (
						<Flex w='100%' h='100%' bg='white' py='1em' rounded='xl' justify='center' align='center'>
							<LoadingSpinner />
						</Flex>
					) : (
						<ExpensesBox expenses={data?.expenses} period={period} isLoading={isLoading} />
					)}
				</GridItem>
				<GridItem colSpan={1} rowSpan={2}>
					<ComparisonStatBox
						isLoading={isLoading}
						title='Ticket medio'
						icon={<TbFileInvoice size='25px' />}
						data={data?.avg_ticket}
						period={period}
						isFinancial
					/>
				</GridItem>
				<GridItem colSpan={1} rowSpan={2}>
					<PendingInvoicesStatBox
						title='Pendiente de pago'
						icon={<TbFileInvoice size='25px' />}
						data={data?.pending}
						period={period}
					/>
				</GridItem>
			</Grid>
		);
	}
	return (
		<Flex direction='column' gap='1em' p='2em'>
			<RevenueChart data={data?.revenue} />
			<PayMethodPieChart data={data?.pay_method} />
			<ComparisonStatBox
				isLoading={isLoading}
				title='Ticket medio'
				icon={<TbFileInvoice size='25px' />}
				data={data?.avg_ticket}
				period={period}
				isFinancial
			/>
			<ComparisonStatBox
				isLoading={isLoading}
				title='Ingresos'
				icon={<FaEuroSign size='25px' />}
				data={data?.income}
				period={period}
				isFinancial
			/>
			<ComparisonStatBox
				isLoading={isLoading}
				title='Gastos'
				isReverse={true}
				icon={<BsBag size='25px' />}
				data={data?.expenses}
				period={period}
				isFinancial
			/>
			<PendingInvoicesStatBox
				title='Pendiente de pago'
				icon={<TbFileInvoice size='25px' />}
				data={data?.pending}
				period={period}
			/>
		</Flex>
	);
}
