import { Flex, Image, Text, Highlight, Button, useMediaQuery } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
//img
import logo from "../../../../img/brand/at_white.png";

interface FooterProps {
	contentWidth: object;
}

const Footer = ({ contentWidth }: FooterProps) => {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const navigate = useNavigate();
	if (!isMobile) {
		return (
			<Flex h='150px' bg='darkblue' justify='center' w='100%' mt='2rem'>
				<Flex justify='space-between' align='center' width={contentWidth}>
					<Flex
						direction='column'
						cursor='pointer'
						onClick={() => window.open("https://another-tool.com/", "_blank")}
					>
						<Flex align='center' gap='0.2em'>
							<Image src={logo} boxSize='18px' alignSelf='center' />
							<Text fontWeight={"bold"} fontSize='20px'>
								<Highlight query={"another"} styles={{ color: "white" }}>
									another
								</Highlight>
								<Highlight query={"tool"} styles={{ color: "blue.500" }}>
									tool
								</Highlight>
							</Text>
						</Flex>
						<Text color='#FFFFFF' alignSelf='end' fontWeight='400' fontSize='12px' maxW='350px'>
							La herramienta definitiva para gestionar tu centro de detailing y llevar tu negocio al
							siguiente nivel
						</Text>
					</Flex>

					<Flex gap='1rem'>
						<Button variant='primary' onClick={() => navigate("/register")}>
							Regístrate
						</Button>
						<Button variant='transparent' color='white' onClick={() => navigate("/login")}>
							Inicia sesión
						</Button>
					</Flex>
				</Flex>
			</Flex>
		);
	}
	return (
		<Flex h='150px' bg='darkblue' justify='center' w='100%' mt='2rem' px='1rem'>
			<Flex justify='space-between' align='center' width={contentWidth}>
				<Flex
					maxW='65%'
					direction='column'
					cursor='pointer'
					onClick={() => window.open("https://another-tool.com/", "_blank")}
				>
					<Flex align='center' gap='0.2em'>
						<Image src={logo} boxSize='18px' alignSelf='center' />
						<Text fontWeight={"bold"} fontSize='20px'>
							<Highlight query={"another"} styles={{ color: "white" }}>
								another
							</Highlight>
							<Highlight query={"tool"} styles={{ color: "blue.500" }}>
								tool
							</Highlight>
						</Text>
					</Flex>
					<Text color='#FFFFFF' alignSelf='end' fontWeight='400' fontSize='12px' maxW='350px'>
						La herramienta definitiva para gestionar tu centro de detailing y llevar tu negocio al siguiente
						nivel
					</Text>
				</Flex>

				<Flex direction='column' gap='1rem'>
					<Button variant='primary' size='sm' onClick={() => navigate("/register")}>
						Regístrate
					</Button>
					<Button variant='transparent' size='sm' color='white' onClick={() => navigate("/login")}>
						Inicia sesión
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Footer;
