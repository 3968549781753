import { useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
//comps
import AddServiceButton from "./AddServiceButton";
import PriceDurationRangeDisplay from "./PriceDurationRangeDisplay";
import SizeSelect from "./SizeSelect";
//types
import type { PriceDuration } from "../../../../../types/service";

interface PriceDurationSelectProps {
	priceDurations: PriceDuration[];
	selectedServices: PriceDuration[];
	handleToggleService: (service: PriceDuration | null) => void;
}

export default function PriceDurationSelect({
	priceDurations,
	selectedServices,
	handleToggleService
}: PriceDurationSelectProps) {
	const [selectedPriceDuration, setSelectedPriceDuration] = useState<PriceDuration | null>(null);

	const isUniqueSize = priceDurations && priceDurations[0]?.size === "UNIQUE";

	useEffect(() => {
		if (isUniqueSize) {
			setSelectedPriceDuration(priceDurations[0]);
		}
	}, [isUniqueSize]);

	return (
		<Flex direction='column' align='end' gap='1rem'>
			<AddServiceButton
				service={selectedPriceDuration}
				selectedServices={selectedServices}
				toggleService={handleToggleService}
			/>
			<PriceDurationRangeDisplay prices={priceDurations} />
			{!isUniqueSize && <SizeSelect choices={priceDurations} setSelected={setSelectedPriceDuration} />}
		</Flex>
	);
}
