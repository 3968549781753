import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSignOut } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
// auth
import { useAuthHeader } from 'react-auth-kit'

const useAuthDelete = (mutationKey, deleteFn, slug, refetchKey) => {
    const QueryClient = useQueryClient()
    const authHeader = useAuthHeader()
    const toast = useToast()
    const signOut = useSignOut()
    const navigate = useNavigate()

    return useMutation(mutationKey, () => deleteFn({ token: authHeader(), slug }), {
        onSuccess: () => {
            toast({ title: 'Borrado con exito!', status: 'success' })
            QueryClient.invalidateQueries(refetchKey)
            QueryClient.refetchQueries(refetchKey, { force: true })
        },
        onError: (error) => {
            if (error.response.status === 401) {
                signOut()
                navigate('/login')
            }
            toast({
                title: error.message,
                description: error.code,
                status: 'error'
            })
        }
    })
}

export default useAuthDelete
