import { Flex, Image, Text, Highlight, Link, useMediaQuery } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
//img
import logo from "../../../../img/brand/at_white.png";

interface TopBarProps {
	contentWidth: object;
}

const TopBar: React.FC<TopBarProps> = ({ contentWidth }) => {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const navigate = useNavigate();

	if (!isMobile) {
		return (
			<Flex h='75px' bg='darkblue' justify='center' w='100%' position='sticky' top='0' zIndex={1000}>
				<Flex justify='space-between' align='center' width={contentWidth}>
					<Flex
						direction='column'
						cursor='pointer'
						onClick={() => window.open("https://another-tool.com/", "_blank")}
					>
						<Flex align='center' gap='0.2em'>
							<Image src={logo} boxSize='18px' alignSelf='center' />
							<Text fontWeight={"bold"} fontSize='20px'>
								<Highlight query={"another"} styles={{ color: "white" }}>
									another
								</Highlight>
								<Highlight query={"tool"} styles={{ color: "blue.500" }}>
									tool
								</Highlight>
							</Text>
						</Flex>
						<Text color='white' alignSelf='end' fontSize='12px'>
							Reservas online
						</Text>
					</Flex>

					<Flex direction='column'>
						<Text color='white' fontSize='12px' alignSelf='end'>
							¿Tienes un negocio?
						</Text>
						<Link color='white' onClick={() => navigate("/register")}>
							Gestiona tus reservas ahora
						</Link>
					</Flex>
				</Flex>
			</Flex>
		);
	}
	return (
		<Flex h='75px' bg='darkblue' justify='center' w='100%' position='sticky' px='1rem' top='0' zIndex={1000}>
			<Flex justify='space-between' align='center' width={contentWidth}>
				<Flex
					direction='column'
					cursor='pointer'
					onClick={() => window.open("https://another-tool.com/", "_blank")}
				>
					<Flex align='center' gap='0.2em'>
						<Image src={logo} boxSize='18px' alignSelf='center' />
						<Text fontWeight={"bold"} fontSize='16px'>
							<Highlight query={"another"} styles={{ color: "white" }}>
								another
							</Highlight>
							<Highlight query={"tool"} styles={{ color: "blue.500" }}>
								tool
							</Highlight>
						</Text>
					</Flex>
					<Text color='white' alignSelf='end' fontSize='12px'>
						Reservas online
					</Text>
				</Flex>

				<Flex direction='column'>
					<Text color='white' fontSize='12px' alignSelf='end'>
						¿Tienes un negocio?
					</Text>
					<Link color='white' fontSize='14px' onClick={() => navigate("/register")}>
						Gestiona tus reservas ahora
					</Link>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default TopBar;
