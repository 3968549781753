import { useContext } from "react";
import { Flex, Grid, GridItem } from "@chakra-ui/react";
import { Tabs, TabList, Tab, TabPanels, TabPanel, useMediaQuery, useClipboard } from "@chakra-ui/react";
import { PlanContext } from "../../components/common/context/PlanContext";
// comps
import ProgrammedWhatsappTable from "../../components/pages/communication/ProgrammedWhatsappTable";
import ProgrammedWhatsappTableMobile from "../../components/pages/communication/ProgrammedWhatsappTableMobile";
import CommunicationSettingsForm from "../../components/pages/communication/CommunicationSettingsForm";
import PanelDescriptionBox from "../../components/pages/communication/PanelDescriptionBox";
import WhatsappPreviewBox from "../../components/pages/communication/WhatsappPreviewBox";
import NoPackageView from "../../components/pages/communication/NoPackageView";

export default function Communication() {
	const { limits } = useContext(PlanContext);
	const whatsappEnabled = limits?.whatsapp_enabled;
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<>
				{whatsappEnabled ? (
					<Grid
						// p={["1em", "1em", "1em", "1em", "4em", "4em"]}
						// w="100%"
						width={{ base: "100%", md: "700px", lg: "800px", xl: "1080px", "2xl": "1300px" }}
						h='85dvh'
						templateRows='repeat(3, 1fr)'
						templateColumns='repeat(2, 1fr)'
						gap={6}
						mt='1.5em'
					>
						<GridItem colSpan={1}>
							<PanelDescriptionBox />
						</GridItem>
						<GridItem colSpan={1}>
							<WhatsappPreviewBox />
						</GridItem>
						<GridItem rowSpan={2} colSpan={2} p='1rem' bg='white' rounded='lg'>
							<Tabs variant='brand'>
								<TabList>
									<Tab>Historial</Tab>
									<Tab>Configuración</Tab>
								</TabList>
								<TabPanels>
									<TabPanel>
										<ProgrammedWhatsappTable />
									</TabPanel>
									<TabPanel>
										<CommunicationSettingsForm />
									</TabPanel>
								</TabPanels>
							</Tabs>
						</GridItem>
					</Grid>
				) : (
					<NoPackageView />
				)}
			</>
		);
	}
	return (
		<Flex direction='column' px='2rem' pt='1rem' pb='60px' gap='1rem' fontSize='14px'>
			<PanelDescriptionBox />
			<WhatsappPreviewBox />
			<ProgrammedWhatsappTableMobile />
		</Flex>
	);
}
