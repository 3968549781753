import { useState } from "react";
import {
	AspectRatio,
	Modal,
	ModalOverlay,
	ModalContent,
	useMediaQuery,
	Flex,
	Text,
	Button,
	Image
} from "@chakra-ui/react";

// img
import logo from "../../../img/brand/at_white.png";
// comps
import StepsDisplay from "./StepsDisplay";
import BusinessInformationForm from "./BusinessInformationForm";
import AnothertoolColored from "../../common/AnothertoolColored";
// icons
// api
import useAuthMutation from "../../../myHooks/useAuthMutation";
import { updateTutorialStatus } from "../../../api/authApi";
// auth
import { useAuthHeader, useAuthUser } from "react-auth-kit";

const Onboarding = ({ isOpen, onClose }) => {
	const authHeader = useAuthHeader();
	const authUser = useAuthUser();
	const auth = useAuthUser();

	const [currentStep, setCurrentStep] = useState(0);
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const name = auth()?.name || auth()?.email?.split("@")[0];

	const { mutate } = useAuthMutation({
		mutationFn: updateTutorialStatus,
		onSuccess: () => {
			const user = authUser() || {};
			user.has_seen_tutorial = true;
			onClose();
		},
		onError: (error) => {
			console.log(error);
		}
	});
	const handleNextStep = () => {
		if (currentStep < steps.length) {
			setCurrentStep(currentStep + 1);
		}
	};
	const handlePrevStep = () => {
		if (currentStep > 0) {
			setCurrentStep(currentStep - 1);
		}
	};
	const finishTutorial = () => {
		const payload = {
			data: {
				step: currentStep
			},
			token: authHeader()
		};
		mutate(payload);
	};

	const miniTitleFontSize = isMobile ? "14px" : "26px";
	const titleFontSize = isMobile ? "24px" : "50px";
	const descFontSize = isMobile ? "16px" : "22px";

	const steps = [
		{
			id: 1,
			miniTitle: <Text fontSize={miniTitleFontSize}>Hola {name}, </Text>,
			title: (
				<Text fontSize={titleFontSize} my='0.5em' fontWeight='bold' align='center' color='darkblue'>
					Te damos la bienvenida a <span style={{ color: "#001234" }}> another</span>
					<span style={{ color: "#0258FF" }}>tool</span>
				</Text>
			),
			desc: (
				<Flex direction='column'>
					<Text fontSize={descFontSize} mb='2em' align='center' maxW='600px'>
						La herramienta definitiva para llevar tu taller al siguiente nivel 🚀
					</Text>
					<AspectRatio maxW='100%' ratio={16 / 9}>
						<iframe
							title='¿Que es Anothertool?'
							src='https://www.youtube.com/embed/4ytEQuA1gm0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						/>
					</AspectRatio>
				</Flex>
			),
			action: (
				<Button variant='primary' w='250px</Flex>' mt={!isMobile ? "3em" : "2em"} onClick={handleNextStep}>
					¡Empezar!
				</Button>
			)
		},
		{
			id: 2,
			title: (
				<Text fontSize={titleFontSize} mb='1em' fontWeight='bold' align='center' color='darkblue'>
					Cuéntanos un poco sobre ti y tu negocio
				</Text>
			),
			form: (
				<BusinessInformationForm
					nextStep={handleNextStep}
					prevStep={handlePrevStep}
					currentStep={currentStep}
				/>
			)
		},
		{
			id: 3,
			title: (
				<Text fontSize={titleFontSize} my='0.5em' fontWeight='bold' align='center' color='darkblue'>
					¡Genial! Estás ready para empezar a gestionar tu negocio
				</Text>
			),
			desc: (
				<Text fontSize={descFontSize} mb='2em' align='center' maxW='600px'>
					<span style={{ color: "#001234", fontWeight: "bold" }}> Te acabamos de mandar un email </span>
					con un tutorial general de anothertool por que sabemos que a veces empezar con un nuevo método de
					gestión puede ser abrumador y aburrido <br /> <br /> A lo largo de la semana te enviaremos más, para
					que puedas descubrir todo el potencial del nuestro software. <br /> <br />
					¡Dale caña, nos vemos dentro!
				</Text>
			),
			action: !isMobile ? (
				<Button variant='primary' w='250px' mt='2em' onClick={finishTutorial}>
					¡Empezar!
				</Button>
			) : (
				<Button variant='primary' alignSelf='center' w='250px' mb='1em' mt='2em' onClick={finishTutorial}>
					¡Empezar!
				</Button>
			)
		}
	];
	return (
		<Modal isOpen={isOpen} onClose={onClose} size='full' closeOnOverlayClick={false} closeOnEsc={false}>
			<ModalOverlay bg='blackAlpha.800' />
			<ModalContent>
				{!isMobile ? (
					<Flex w='100%'>
						<Flex bg='darkblue' h='100vh' w='100px' align='start' justify='center'>
							<Image src={logo} mt='2em' />
						</Flex>
						<Flex w='100%' justify='center' align='center' direction='column'>
							<StepsDisplay currentStep={currentStep} maxSteps={steps.length} />
							{steps[currentStep].miniTitle}
							<Flex maxW='620px'>{steps[currentStep].title}</Flex>
							<Flex>{steps[currentStep].desc}</Flex>
							{steps[currentStep].action}
							{steps[currentStep].form}
						</Flex>
						{/*
                        <Button onClick={handleNextStep} w='100px' position='fixed' bottom='2em' right='300px' >
                            next</Button>
                        */}
					</Flex>
				) : (
					<Flex w='100%' direction='column' minH='100svh'>
						<Flex bg='darkblue' h='50px' w='100%' align='center' justify='start'>
							<Image src={logo} ml='1em' />
							<StepsDisplay currentStep={currentStep} maxSteps={steps.length} />
						</Flex>
						<Flex direction='column' px='2em' py='2em' align='center' alignSelf='center'>
							{steps[currentStep].miniTitle}
							{steps[currentStep].title}
							{steps[currentStep].desc}
							{steps[currentStep].form}
							{steps[currentStep].action}
						</Flex>
					</Flex>
				)}
			</ModalContent>
		</Modal>
	);
};

export default Onboarding;
