import { useToast, useMediaQuery, Flex, Button, Checkbox, Text, Grid, GridItem } from "@chakra-ui/react";
// forms validation
import { Formik } from "formik";
// api
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import { getBusinessExtras, createUpdateBusinessExtra } from "../../../../../api/businessesApi";
import { useQueryClient } from "@tanstack/react-query";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function ExtrasForm() {
	const authHeader = useAuthHeader();
	const toast = useToast();
	const QueryClient = useQueryClient();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const { data: businessExtras } = useAuthFetch(["businessExtras"], getBusinessExtras);
	const { isLoading, mutate } = useAuthMutation({
		mutationFn: createUpdateBusinessExtra,
		onSuccess: () => {
			toast({ title: "Extras actualizados!", status: "success" });
			QueryClient.invalidateQueries(["businessExtras"]);
			QueryClient.refetchQueries("businessExtras", { force: true });
		},
		onError: (error) => {
			console.log(error);
			toast({
				title: "¡Ups!, ha habido un error",
				status: "error"
			});
		}
	});

	const initialValues = {
		accepts_card: businessExtras?.accepts_card || false,
		accessible: businessExtras?.accessible || false,
		eco_friendly: businessExtras?.eco_friendly || false,
		has_parking: businessExtras?.has_parking || false,
		home_service: businessExtras?.home_service || false,
		member_discounts: businessExtras?.member_discounts || false,
		product_selling: businessExtras?.product_selling || false,
		service_warranty: businessExtras?.service_warranty || false,
		vehicle_insurance: businessExtras?.vehicle_insurance || false,
		waiting_room: businessExtras?.waiting_room || false
	};

	const handleSubmit = (values) => {
		const payload = { data: values, slug: businessExtras.id, token: authHeader() };
		mutate(payload);
	};
	return (
		<Flex bg={isMobile && "white"} rounded='lg' p={isMobile && "1rem"} mt={isMobile && "1.5rem"}>
			<Formik initialValues={initialValues} onSubmit={(values) => handleSubmit(values)} enableReinitialize>
				{(formik) => (
					<Flex direction='column' w='100%'>
						<Grid w='100%' templateColumns='repeat(3, 1fr)' gap={6}>
							<GridItem>
								<Flex align='center' gap='5px'>
									<Checkbox
										name='accepts_card'
										isChecked={formik.values.accepts_card}
										onChange={() =>
											formik.setFieldValue("accepts_card", !formik.values.accepts_card)
										}
									/>
									<Text>Acepta tarjeta</Text>
								</Flex>
							</GridItem>
							<GridItem>
								<Flex align='center' gap='5px'>
									<Checkbox
										name='accessible'
										isChecked={formik.values.accessible}
										onChange={() => formik.setFieldValue("accessible", !formik.values.accessible)}
									/>
									<Text>Accesible</Text>
								</Flex>
							</GridItem>
							<GridItem>
								<Flex align='center' gap='5px'>
									<Checkbox
										name='eco_friendly'
										isChecked={formik.values.eco_friendly}
										onChange={() =>
											formik.setFieldValue("eco_friendly", !formik.values.eco_friendly)
										}
									/>
									<Text>ECO Friendly</Text>
								</Flex>
							</GridItem>
							<GridItem>
								<Flex align='center' gap='5px'>
									<Checkbox
										name='has_parking'
										isChecked={formik.values.has_parking}
										onChange={() => formik.setFieldValue("has_parking", !formik.values.has_parking)}
									/>
									<Text>Parking</Text>
								</Flex>
							</GridItem>
							<GridItem>
								<Flex align='center' gap='5px'>
									<Checkbox
										name='home_service'
										isChecked={formik.values.home_service}
										onChange={() =>
											formik.setFieldValue("home_service", !formik.values.home_service)
										}
									/>
									<Text>Servicio domicilio</Text>
								</Flex>
							</GridItem>
							<GridItem>
								<Flex align='center' gap='5px'>
									<Checkbox
										name='member_discounts'
										isChecked={formik.values.member_discounts}
										onChange={() =>
											formik.setFieldValue("member_discounts", !formik.values.member_discounts)
										}
									/>
									<Text>Descuentos a socios</Text>
								</Flex>
							</GridItem>
							<GridItem>
								<Flex align='center' gap='5px'>
									<Checkbox
										name='product_selling'
										isChecked={formik.values.product_selling}
										onChange={() =>
											formik.setFieldValue("product_selling", !formik.values.product_selling)
										}
									/>
									<Text>Venta de productos</Text>
								</Flex>
							</GridItem>
							<GridItem>
								<Flex align='center' gap='5px'>
									<Checkbox
										name='product_warranty'
										isChecked={formik.values.product_warranty}
										onChange={() =>
											formik.setFieldValue("product_warranty", !formik.values.product_warranty)
										}
									/>
									<Text>Garantía de servicio</Text>
								</Flex>
							</GridItem>
						</Grid>
						<Button
							mt='1em'
							alignSelf='end'
							variant='primary'
							isDisabled={!formik.dirty}
							onClick={formik.handleSubmit}
							isLoading={isLoading}
						>
							Guardar
						</Button>
					</Flex>
				)}
			</Formik>
		</Flex>
	);
}
