import { Flex, Avatar } from '@chakra-ui/react'

export default function OrderWorkersView ({ workers }) {
    return (
        <Flex align='center' gap='0.5em' >
            {workers?.map((worker) => (
                <Avatar size='xs' key={worker.id} name={worker.name} bg={worker.color} />
            ))}
        </Flex>
    )
}
