import { useState, useMemo } from "react";
import { useDisclosure, Flex, IconButton, Tr, Td } from "@chakra-ui/react";
// comps
import CarForm from "./CarForm";
import ResponsiveTable from "../../../common/Tables/ResponsiveTable";
import PopoverDelete from "../../../common/PopoverDelete";
import CarHistoryModal from "./CarHistoryModal";
import SearchInput from "../../../common/Tables/SearchInput";
// icons
import { AiOutlineEdit, AiOutlineHistory } from "react-icons/ai";
// img
import emptyCars from "../../../../img/data/emptyCars.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getAllCarsPag, deleteCar } from "../../../../api/carsApi";

export default function CarsTablePag() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenCarHistory, onOpen: onOpenCarHistory, onClose: onCloseCarHistory } = useDisclosure();
	const [page, setPage] = useState(1);
	const [car, setCar] = useState();
	const [searchQuery, setSearchQuery] = useState("");

	const { data, isLoading } = useAuthFetch(["cars", page, searchQuery], getAllCarsPag, page, null, true, searchQuery);
	const tableData = useMemo(
		() =>
			data?.results?.map((car) => (
				<Tr key={car.id}>
					<Td>{car.plate || " -- --"}</Td>
					<Td> {car.brand_display} </Td>
					<Td> {car.model ? car.model : "-- --"} </Td>
					<Td> {car.client ? car.client.name : "-- --"} </Td>
					<Td>
						<Flex gap='1em' key={car.id}>
							<IconButton
								icon={<AiOutlineEdit size='20px' color='#0258FF' />}
								bg='transparent'
								onClick={() => {
									setCar(car);
									onOpen();
								}}
							/>

							<IconButton
								icon={<AiOutlineHistory size='20px' color='#0258FF' />}
								bg='transparent'
								onClick={() => {
									setCar(car);
									onOpenCarHistory();
								}}
							/>

							<PopoverDelete
								deleteKey={["deleteCar"]}
								deleteFn={deleteCar}
								slug={car?.id}
								refetchKey={["cars"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data, onOpen, onOpenCarHistory]
	);

	const isEmpty = data?.count < 1;
	return (
		<>
			<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
			<ResponsiveTable
				paginated
				searchQuery={searchQuery}
				data={data}
				tableData={tableData}
				isEmpty={isEmpty}
				onOpen={() => {
					setCar();
					onOpen();
				}}
				onClose={onClose}
				page={page}
				setPage={setPage}
				isLoading={isLoading}
				item={{
					label: "Coche",
					headers: [
						{ key: "plate", label: "Matrícula/Bastidor", roundedLeft: "lg" },
						{ key: "brand", label: "Marca" },
						{ key: "model", label: "Modelo" },
						{ key: "client", label: "Cliente" }
					],
					formItem: car,
					isOpen
				}}
				FormComponent={CarForm}
				emptyImage={emptyCars}
			/>
			<CarHistoryModal car={car} isOpen={isOpenCarHistory} onClose={onCloseCarHistory} />
		</>
	);
}
