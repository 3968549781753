import { Flex, Image, Text } from "@chakra-ui/react";
//comps
import PopoverDelete from "../../../../common/PopoverDelete";
//api
import { getWorkImages, deleteWorkImage } from "../../../../../api/businessesApi";
import useAuthFetch from "../../../../../myHooks/useAuthFetch";

export default function WorkImageGallery() {
	const { data: workImages } = useAuthFetch(["workImages"], getWorkImages);

	return (
		<Flex direction='column' mt='2rem' gap='1rem'>
			<Text fontSize='20px' fontWeight='bold'>
				Las imágenes de tu negocio
			</Text>
			<Flex maxW='100%' wrap='wrap' justify='space-between'>
				{workImages?.map((image) => (
					<Flex key={image.id}>
						<Image rounded='lg' w='300px' src={image.work_image} mb='2rem' mr='-40px' />
						<PopoverDelete
							deleteKey={["deleteWorkImage"]}
							deleteFn={deleteWorkImage}
							slug={image.id}
							refetchKey={["workImages"]}
						/>
					</Flex>
				))}
			</Flex>
		</Flex>
	);
}
