import { Modal, ModalOverlay, ModalContent, ModalBody, Button, Text, Image, useDisclosure } from '@chakra-ui/react'
// img
import andres from '../../img/tutorial/Andres.png'

export default function SupportContactEmail ({ onClose }) {
    const { isOpen, onOpen, onClose: closeThisModal } = useDisclosure()

    const closeAllModals = () => {
        closeThisModal()
        onClose()
    }
    return (
        <>
            <Button onClick={onOpen} colorScheme="orange">
                Hablar con soporte
            </Button>

            <Modal isOpen={isOpen} onClose={closeAllModals}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody align="center" p="4em">
                        <Image src={andres} my="1em" />
                        <Text fontWeight="bold">¿Necesitas ayuda?</Text>
                        <Text fontWeight="bold">¿Algo no funciona bien?</Text>
                        <Text color="grey" my="1em">
                            Mándame un mensaje con lo que sucede y me pondré en contacto contigo lo antes posible
                        </Text>
                        <Text fontWeight="bold">hola@another-tool.com</Text>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
