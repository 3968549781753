import { Flex, Text, Skeleton } from "@chakra-ui/react";
//icons
import { FiCalendar } from "react-icons/fi";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { IoReceiptOutline } from "react-icons/io5";
import { BiCar } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { TbFileInvoice } from "react-icons/tb";

export default function OrderCardLoading() {
	return (
		<>
			<Flex
				direction='column'
				maxW='100%'
				my='0.5em'
				boxShadow='2px 2px 2px 2px #F4F4F9'
				gap='1em'
				rounded='xl'
				justify='center'
				p='1em'
				bg='white'
			>
				<Flex maxW='100%' overflowWrap='break-word' justify='space-between' align='center'>
					<Flex align='center' gap='0.5em' w='100%'>
						<Text fontWeight='bold'> Orden </Text>
						<Skeleton h='12px' w='60%' rounded='lg' />
					</Flex>
				</Flex>
				<Flex gap='0.5em' align='center' flexWrap='wrap' w='100%'>
					<BiCar size='20px' color='grey' />
					<Skeleton h='12px' w='40%' rounded='lg' />
					<Skeleton h='12px' w='25%' rounded='lg' />
				</Flex>
				<Flex gap='0.5em' align='center' w='100%'>
					<FiUser size='20px' color='grey' />
					<Skeleton h='12px' w='35%' rounded='lg' />
					<Skeleton h='12px' w='35%' rounded='lg' />
				</Flex>
				<Flex justify='space-between'>
					<Flex align='center' gap='0.5em'>
						<IoReceiptOutline size='20px' color='grey' />
						<FiCalendar size='20px' color='grey' />
						<MdOutlineNotificationsActive size='20px' color='grey' />
					</Flex>
					<TbFileInvoice size='20px' color='grey' />
				</Flex>
			</Flex>
		</>
	);
}
