import { useState, useMemo } from "react";
import { useDisclosure, Flex, IconButton, Tr, Td } from "@chakra-ui/react";
// comps
import PopoverDelete from "../../../common/PopoverDelete";
import ResponsiveTable from "../../../common/Tables/ResponsiveTable";
import SupplierForm from "../forms/SupplierForm";
import SupplierPurchasesModal from "../supplierPurchasesHistory/SupplierPurchasesModal";
// icons
import { AiOutlineEdit } from "react-icons/ai";
// img
import emptyStock from "../../../../img/stock/emptyStock.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getAllSuppliersPag, deleteSupplier } from "../../../../api/stockApi";

export default function SuppliersTable() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [supplier, setSupplier] = useState();

	const { data, isLoading } = useAuthFetch(["suppliers", page], getAllSuppliersPag, page);

	const tableData = useMemo(
		() =>
			data?.results?.map((supplier) => (
				<Tr key={supplier.id}>
					<Td>{supplier.name}</Td>
					<Td maxW='250px' overflow={"hidden"} textOverflow={"ellipsis"}>
						{supplier.address || "-- --"}
					</Td>
					<Td> {supplier.phone || "-- --"} </Td>
					<Td> {supplier.email || "-- --"} </Td>
					<Td isNumeric> {supplier.total_spent || 0} </Td>
					<Td>
						<Flex gap='1em'>
							<IconButton
								icon={<AiOutlineEdit size='20px' color='#0258FF' />}
								bg='transparent'
								onClick={() => {
									setSupplier(supplier);
									onOpen();
								}}
							/>
							<SupplierPurchasesModal supplier={supplier} />
							<PopoverDelete
								deleteKey={["deleteSupplier"]}
								deleteFn={deleteSupplier}
								slug={supplier?.id}
								refetchKey={["suppliers"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data, onOpen]
	);
	const isEmpty = data?.count < 1;
	return (
		<ResponsiveTable
			paginated
			data={data}
			tableData={tableData}
			isEmpty={isEmpty}
			onOpen={() => {
				setSupplier();
				onOpen();
			}}
			onClose={onClose}
			page={page}
			setPage={setPage}
			isLoading={isLoading}
			item={{
				label: "Proveedor",
				headers: [
					{ key: "name", label: "Nombre", roundedLeft: "lg" },
					{ key: "address", label: "Dirección" },
					{ key: "phone", label: "Teléfono" },
					{ key: "mail", label: "Email" },
					{ key: "total_spent", label: "Gasto(€)" }
				],
				formItem: supplier,
				isOpen
			}}
			FormComponent={SupplierForm}
			emptyImage={emptyStock}
		/>
	);
}
