import { Flex, Text, Skeleton, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";
import { Stat, StatLabel, StatNumber, StatHelpText } from "@chakra-ui/react";
import moment from "moment";
//api
import useAuthFetch from "../../myHooks/useAuthFetch";
import { getStripeData } from "../../api/adminApi";

export default function StripeData() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const { data, isLoading } = useAuthFetch(["stripeData"], getStripeData);

	if (!isMobile) {
		return (
			<Grid templateColumns='repeat(3, 1fr)' gap={4} mt='1rem'>
				<GridItem rowSpan={2} colSpan={1} bg='white' rounded='lg' p='1rem'>
					{isLoading ? (
						<Skeleton height='100%' h='5px' />
					) : (
						<Stat color='darkblue'>
							<StatLabel>Ticket medio</StatLabel>
							<StatNumber>{data?.average_ticket}€</StatNumber>
							<StatHelpText>{(data?.average_ticket / 1.21).toFixed(2)}€ Neto</StatHelpText>
						</Stat>
					)}
				</GridItem>
				<GridItem rowSpan={2} colSpan={1} bg='white' rounded='lg' p='1rem'>
					{isLoading ? (
						<Skeleton height='100%' h='5px' />
					) : (
						<Stat color='darkblue'>
							<StatLabel>LTV</StatLabel>
							<StatNumber>{data?.ltv}€</StatNumber>
							<StatHelpText>{(data?.ltv / 1.21).toFixed(2)}€ Neto</StatHelpText>
						</Stat>
					)}
				</GridItem>
				<GridItem rowSpan={2} colSpan={1} bg='white' rounded='lg' p='1rem'>
					{isLoading ? (
						<Skeleton height='100%' h='5px' />
					) : (
						<Stat color='darkblue'>
							<StatLabel>MRR</StatLabel>
							<StatNumber>{data?.mrr}€</StatNumber>
							<StatHelpText>{(data?.mrr / 1.21).toFixed(2)}€ Neto</StatHelpText>
						</Stat>
					)}
				</GridItem>
				<GridItem rowSpan={2} colSpan={1} bg='white' rounded='lg' p='1rem'>
					{isLoading ? (
						<Skeleton height='100%' h='5px' />
					) : (
						<Stat color='darkblue'>
							<StatLabel>Duración media</StatLabel>
							<StatNumber>
								{moment.duration(data?.average_duration, "days").months()} meses y{" "}
								{moment.duration(data?.average_duration, "days").days().toFixed(2)} días
							</StatNumber>
						</Stat>
					)}
				</GridItem>
			</Grid>
		);
	}
	return (
		<Flex direction='column' p='2em'>
			StripeDataMobile
		</Flex>
	);
}
