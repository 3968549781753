import { useState, useEffect } from "react";
import { Button, useMediaQuery } from "@chakra-ui/react";
//types
import type { PriceDuration } from "../../../../../types/service";
//icons
import { FaCheck } from "react-icons/fa";

interface AddServiceButtonProps {
	service: PriceDuration | null;
	selectedServices: PriceDuration[];
	toggleService: (service: PriceDuration | null) => void;
}

export default function AddServiceButton({ service, selectedServices, toggleService }: AddServiceButtonProps) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const [selected, setSelected] = useState<boolean>(selectedServices.some((s) => s.id === service?.id));
	useEffect(() => {
		if (!service) return;
		setSelected(selectedServices.some((s) => s.id === service.id));
	}, [selectedServices, service]);

	const serviceNotSelected = service === null;

	if (serviceNotSelected) {
		return (
			<Button
				maxW='150px'
				w='100%'
				fontSize={!isMobile ? "14px" : "12px"}
				fontWeight='regular'
				variant='primary'
				//color='white'
				cursor='not-allowed'
				isDisabled
			>
				Reservar
			</Button>
		);
	}
	return (
		<Button
			maxW='150px'
			w='100%'
			fontSize={!isMobile ? "14px" : "12px"}
			variant={selected ? "lightGreen" : "primary"}
			//variant='primary'
			onClick={() => toggleService(service)}
		>
			{selected ? <FaCheck color='#42C74F' /> : "Reservar"}
		</Button>
	);
}
