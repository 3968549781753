import { Text } from "@chakra-ui/react";
//types
import type { PriceDuration } from "../../../../types/service";
//fns
import { formatDecimals } from "../../../common/functions";

interface PriceDurationDisplayProps {
	prices: PriceDuration[];
}

export default function PriceDisplay({ prices }: PriceDurationDisplayProps) {
	let priceDisplay = "-- --";

	if (prices.length === 1) {
		const price = prices[0].price;
		priceDisplay = `${formatDecimals(price)}€`;
	} else if (prices.length > 1) {
		const minPrice = Math.min(...prices.map((priceDuration) => priceDuration.price));
		const maxPrice = Math.max(...prices.map((priceDuration) => priceDuration.price));
		priceDisplay = `${formatDecimals(minPrice)}€ - ${formatDecimals(maxPrice)}€`;
	}

	return <Text>{priceDisplay}</Text>;
}
