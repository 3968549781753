import { useState, useMemo } from "react";
import {
	useDisclosure,
	Flex,
	Text,
	Badge,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from "@chakra-ui/react";
// comps
import PopoverDelete from "../../../../common/PopoverDelete";
import EditItemButton from "../../../../common/Tables/EditItemButton";
import TableMobile from "../../../../common/Tables/Mobile/TableMobile";
// img
import emptyCars from "../../../../../img/data/emptyCars.png";
// icons
import { LuUser } from "react-icons/lu";
// api
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { getAllOrderRequestsPag, deleteOrderRequest } from "../../../../../api/businessesApi";

export default function OrderRequestsTableMobile() {
	const [request, setRequest] = useState();
	const [page, setPage] = useState(1);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data, isLoading } = useAuthFetch(["orderRequests", page], getAllOrderRequestsPag, page);
	const openEdit = (item) => {
		setRequest(item);
		onOpen();
	};
	const openForm = () => {
		setRequest();
		onOpen();
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((request) => (
				<AccordionItem key={request.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Flex align='center' w='100%' gap='1em' justify='space-between'>
							<Text> {request.client_name}</Text>
							{request.approved === true ? (
								<Badge colorScheme='green' rounded='xl'>
									Aprobada
								</Badge>
							) : request.approved === false ? (
								<Badge colorScheme='red' rounded='xl'>
									Rechazada
								</Badge>
							) : (
								<Badge colorScheme='orange' rounded='xl'>
									Pendiente
								</Badge>
							)}
						</Flex>
						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex direction='column' gap='1em' justify='space-between' fontSize='14px'>
							<Flex gap='0.5em' align='center'>
								<LuUser size='15px' color='black' />
								<Text>
									{request.client_email} - {request.client_phone}
								</Text>
							</Flex>
							<Flex gap='0.5em' align='center'>
								<LuUser size='15px' color='black' />
								<Text>
									{request.brand_display} {request.model || "-- --"}
								</Text>
							</Flex>
							<Flex w='100%' justify='end' mt='-2em'>
								<PopoverDelete
									deleteKey={["deleteOrderRequest"]}
									deleteFn={deleteOrderRequest}
									slug={request?.id}
									refetchKey={["orderRequests"]}
								/>
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data]
	);

	return (
		<Flex mt='1.5rem' direction='column'>
			<TableMobile
				withoutButton
				data={data}
				tableData={tableData}
				openFormFn={openForm}
				isLoading={isLoading}
				emptyImg={emptyCars}
				label='Solicitud'
				page={page}
				setPage={setPage}
			/>
		</Flex>
	);
}
