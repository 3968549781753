import { Flex, Text, useMediaQuery } from "@chakra-ui/react";

export default function PanelDescriptionBox() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<Flex w='100%' h='100%' direction='column' gap='1em'>
				<Text fontWeight='bold' color='darkblue' fontSize={isMobile ? "20px" : "24px"}>
					Panel de recordatorios por WhatAapp
				</Text>
				<Text fontSize={isMobile ? "14px" : "16px"}>
					Para enviar recordatorios por whatsapp es necesario que los clientes lo consientan explícitamente a
					través del formulario que se encuentra desde la misma orden, o en la ficha del cliente
				</Text>
				<Text fontWeight='bold' fontSize={isMobile ? "14px" : "16px"}>
					A la derecha puedes previsualizar los mensajes que se van a enviar al cliente
				</Text>
			</Flex>
		);
	}
	return (
		<Text fontWeight='bold' color='darkblue' fontSize='20px' mb='1rem'>
			Panel de recordatorios por WhatAapp
		</Text>
	);
}
