import { Tooltip } from "@chakra-ui/react";
// icons
import { HiOutlineDocumentCheck } from "react-icons/hi2";

export default function OrderQuoteTag({ order }) {
	const quote = order?.quote;

	const getQuoteLabel = () => {
		if (!quote) {
			return "Sin Presupuesto";
		}
		const status = quote.status;
		switch (status) {
			case "pending":
				return "Pendiente";
			case "approved":
				return "Aprobado";
			case "expired":
				return "Caducado";
			case "declined":
				return "Rechazado";
			default:
				return "Desconocido";
		}
	};

	const getQuoteColor = () => {
		if (!quote) {
			return "grey";
		}
		const status = quote.status;
		switch (status) {
			case "pending":
				return "orange";
			case "approved":
				return "#42C74F";
			case "expired":
				return "#C74242";
			case "declined":
				return "#C74242";
			default:
				return "grey.400";
		}
	};
	return (
		<Tooltip label={getQuoteLabel()} rounded='lg' bg='gray.100' color={getQuoteColor()} shouldWrapChildren>
			<HiOutlineDocumentCheck size='20px' color={getQuoteColor()} />
		</Tooltip>
	);
}
