import axios from 'axios'

const authApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}user`
})

export const login = async (credentials) => {
    const response = await authApi.post('/token', credentials)
    return response.data
}

export const googleLogin = async (email) => {
    const response = await authApi.post('/google-login', email)
    return response.data
}

export const updateTutorialStatus = async (payload) => {
    const response = await authApi.post('/update-tutorial-status', payload.data, {
        headers: { Authorization: payload.token }
    })
    return response.data
}

export const register = async (credentials) => {
    const response = await authApi.post('/register', credentials)
    return response.data
}

export const getResetLink = async (email) => {
    const response = await authApi.post('/get-reset-token', email)
    return response.data
}

export const resetPassword = async (payload) => {
    const link = `reset?user_id=${payload.user_id}&reset_token=${payload.token}`
    const response = await authApi.put(link, {
        password: payload.password
    })
    return response.data
}

export const activate = async (payload) => {
    const link = `activate?user_id=${payload.user_id}&confirmation_token=${payload.token}`
    const response = await authApi.get(link)
    return response.data
}
