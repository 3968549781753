import { createApi, getAll, getAllPag, createUpdate, deleteItem } from "./baseApi";
//types
import type { GetAllPayload, CreateUpdatePayload, DeletePayload } from "../types/api";
import type { Car } from "../types/car";
import type { Order } from "../types/order";

const carsApi = createApi("cars");

interface GetCarHistoryPayload {
	token: string;
	carId: number;
}

interface GetCarChoicesResponse {
	brands: Array<{ value: string; display_name: string }>;
	fuel: Array<{ value: string; display_name: string }>;
}

export const getAllCars = async (payload: GetAllPayload): Promise<Car[]> => {
	return getAll(carsApi, payload);
};

export const getAllCarsPag = async (payload: GetAllPayload): Promise<{ results: Car[]; count: number }> => {
	return getAllPag(carsApi, payload);
};

export const createUpdateCar = async (payload: CreateUpdatePayload): Promise<Car> => {
	return createUpdate(carsApi, payload);
};

export const deleteCar = async (payload: DeletePayload): Promise<void> => {
	return deleteItem(carsApi, payload);
};

export const getCarHistory = async (payload: GetCarHistoryPayload): Promise<Order[]> => {
	if (!payload.carId) {
		return [];
	}
	const response = await carsApi.get(`${payload.carId}/orders`, {
		headers: { Authorization: payload.token }
	});

	return response.data || [];
};

export const getCarChoices = async (payload: GetAllPayload): Promise<GetCarChoicesResponse> => {
	const response = await carsApi.options("/", {
		headers: { Authorization: payload.token }
	});

	return {
		brands: response.data.actions.POST.brand.choices,
		fuel: response.data.actions.POST.fuel.choices
	};
};

export const getCarBrandChoices = async (): Promise<{ value: string; display_name: string }[]> => {
	const response = await carsApi.get("/brand-choices");
	return response.data;
};
