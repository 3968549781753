import { useState } from "react";
import { Flex, Text, Button, useToast } from "@chakra-ui/react";
// comps
import BannerImageUploader from "./BannerImageUploader";
import AddressModal from "./address/AddressModal";
// forms
import InputField from "../../../../common/forms/InputField";
import { Formik } from "formik";
import * as Yup from "yup";
import { PHONE_REG_EXP } from "../../../../common/consts";
// icons
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { MdOutlineWebAsset } from "react-icons/md";
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateBusinessProfile } from "../../../../../api/businessesApi";

export default function BusinessInformationFormMobile({ businessProfile }) {
	const [preview, setPreview] = useState();
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateBusinessProfile,
		onSuccess: () => {
			toast({ title: "Taller actualizado!", status: "success" });
			QueryClient.invalidateQueries(["businessProfile"]);
			QueryClient.refetchQueries("businessProfile", { force: true });
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});

	const initialValues = {
		name: businessProfile?.name || "",
		email: businessProfile?.email || "",
		banner_image: businessProfile?.banner_image || "",
		phone: businessProfile?.phone || "",
		slug: businessProfile?.slug || "",
		instagram_url: businessProfile?.instagram_url || "",
		tiktok_url: businessProfile?.tiktok_url || "",
		youtube_url: businessProfile?.youtube_url || "",
		web_url: businessProfile?.web_url || ""
	};
	const SLUG_REG_EXP = /^[a-zA-Z0-9-]+$/;

	const validationSchema = Yup.object({
		name: Yup.string(),
		email: Yup.string().email("No tiene un formato válido"),
		phone: Yup.string().matches(PHONE_REG_EXP, "Teléfono no válido").min(9).nullable(),
		slug: Yup.string().matches(SLUG_REG_EXP, "La url no puede contener caracteres especiales"),
		instagram_url: Yup.string().url("El formato de la URL no es válido"),
		tiktok_url: Yup.string().url("El formato de la URL no es válido"),
		youtube_url: Yup.string().url("El formato de la URL no es válido"),
		web_url: Yup.string().url("El formato de la URL no es válido")
	});

	const handleSubmit = (values) => {
		const isUrl = /^(ftp|http|https):\/\/[^ "]+$/.test(values.banner_image);
		const payload = businessProfile
			? { data: values, slug: businessProfile.id, token: authHeader() }
			: { data: values, token: authHeader() };
		if (isUrl) {
			const { banner_image, ...newData } = payload.data;
			payload.data = newData;
		}
		payload.data.email = payload.data.email?.toLowerCase();
		payload.data.slug = payload.data.slug?.toLowerCase();
		mutate(payload);
	};

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			validationSchema={validationSchema}
			onSubmit={(values) => handleSubmit(values)}
		>
			{(formik) => (
				<Flex direction='column' gap='1em' px='1em' py='1em'>
					<BannerImageUploader
						preview={preview}
						setPreview={setPreview}
						formik={formik}
						bannerImage={businessProfile?.banner_image}
						error={error?.response?.data?.banner_image || formik.errors.banner_image}
					/>
					<InputField bg='white' name='name' label='Nombre del negocio' />
					<InputField bg='white' name='email' label='Email del negocio' />
					<InputField bg='white' name='phone' label='Teléfono' />
					<AddressModal currentAddress={businessProfile?.address} />
					<InputField
						bg='white'
						placeholder='https://'
						name='tiktok_url'
						label='Tiktok'
						icon={<FaTiktok size='20px' color='black' />}
					/>
					<InputField
						bg='white'
						placeholder='https://'
						name='instagram_url'
						label='Instagram'
						icon={<FaInstagram size='20px' color='black' />}
					/>
					<InputField
						bg='white'
						placeholder='https://'
						name='youtube_url'
						label='Youtube'
						icon={<FaYoutube size='20px' color='black' />}
					/>
					<InputField
						bg='white'
						placeholder='https://'
						name='web_url'
						label='Web'
						icon={<MdOutlineWebAsset size='20px' color='black' />}
					/>
					<Button
						variant='primary'
						size='md'
						py='0.5em'
						onClick={formik.handleSubmit}
						isDisabled={!formik.dirty}
						isLoading={isLoading}
					>
						Guardar
					</Button>
				</Flex>
			)}
		</Formik>
	);
}
