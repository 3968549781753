import { useParams } from "react-router-dom";
//comps
import QuotePDF from "./pdf/quote/QuotePDF";
//api
import { getQuoteDetail } from "../../../api/quotesApi";
import { useQuery } from "@tanstack/react-query";
import { Flex } from "@chakra-ui/react";

export default function QuotePDFPage() {
	const { uuid } = useParams();

	const { data, isLoading } = useQuery({
		queryKey: ["quoteDetail", uuid],
		queryFn: () => getQuoteDetail(uuid)
	});
	const quote = data?.quote;
	const invoiceSettings = data?.invoice_settings;
	const businessProfile = data?.business_profile;
	return (
		<Flex w='100%' h='100vh' minH='100dvh'>
			{isLoading ? (
				"Cargando..."
			) : (
				<QuotePDF quote={quote} invoiceSettings={invoiceSettings} businessProfile={businessProfile} />
			)}
		</Flex>
	);
}
