import { useContext } from "react";
import { Menu, MenuButton, MenuList, IconButton, MenuItem } from "@chakra-ui/react";
import { PlanContext } from "../../../common/context/PlanContext";
// icon
import { FiMoreHorizontal } from "react-icons/fi";
import { AiOutlineEdit } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { TbFileInvoice } from "react-icons/tb";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { IoReceiptOutline } from "react-icons/io5";

export default function OrderCardDropDown({
	onOpenOrderForm,
	onOpenInvoiceModal,
	onOpenQuoteModal,
	onOpenDepositModal,
	onOpenWhatsAppModal,
	remove,
	hasInvoice,
	hasQuote,
	depositReceiptId
}) {
	const hasDepositReceipt = !!depositReceiptId;
	const { limits } = useContext(PlanContext);
	return (
		<Menu>
			<MenuButton bg='white' as={IconButton} icon={<FiMoreHorizontal />} />
			<MenuList>
				<MenuItem onClick={() => onOpenOrderForm()} gap='0.5em'>
					<AiOutlineEdit size='20px' color='black' />
					Editar
				</MenuItem>
				{hasDepositReceipt ? (
					<MenuItem
						onClick={() => window.open(`/resguardo-deposito/${depositReceiptId}`, "_blank")}
						gap='0.5em'
					>
						<IoReceiptOutline size='18px' color='black' />
						Ver Resguardo depósito
					</MenuItem>
				) : (
					<MenuItem onClick={() => onOpenDepositModal()} gap='0.5em'>
						<IoReceiptOutline size='18px' color='black' />+ Resguardo depósito
					</MenuItem>
				)}
				<MenuItem onClick={() => onOpenQuoteModal()} gap='0.5em'>
					<TbFileInvoice size='20px' color='black' /> {hasQuote ? "Ver" : "+"} Presupuesto
				</MenuItem>
				<MenuItem onClick={() => onOpenInvoiceModal()} gap='0.5em'>
					<TbFileInvoice size='20px' color='black' /> {hasInvoice ? "Ver" : "+"} Factura
				</MenuItem>
				{limits?.whatsapp_enabled && (
					<MenuItem onClick={() => onOpenWhatsAppModal()} gap='0.5em'>
						<MdOutlineNotificationsActive size='20px' color='black' />
						Mensaje
					</MenuItem>
				)}
				<MenuItem onClick={() => remove()} gap='0.5em'>
					<BsTrash size='20px' color='red' /> Eliminar
				</MenuItem>
			</MenuList>
		</Menu>
	);
}
