import { useState } from "react";
import { Text, Flex, Skeleton, useMediaQuery, Grid, GridItem } from "@chakra-ui/react";

export default function ReservationHourPicker({ day, hours, setHour, isloading }) {
	const [selectedHourIndex, setSelectedHourIndex] = useState();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const handleSelectHour = (hourItem, index) => {
		if (hourItem !== "Cerrado") {
			setHour(hourItem);
			setSelectedHourIndex(index);
		}
	};
	const isEmpty = hours?.length < 1;
	if (isloading) {
		return (
			<Grid gap='1rem' templateColumns='repeat(3, 1fr)'>
				{Array.from({ length: 9 }).map((_, j) => (
					<Skeleton h='35px' w='100px' rounded='md' key={j} />
				))}
			</Grid>
		);
	}
	if (!day) {
		return (
			<Flex direction='column' h={isMobile && "40vh"} justify='center' align='center'>
				<Text w='170px'>Selecciona un día para ver los horarios</Text>
			</Flex>
		);
	}
	if (!isEmpty && typeof hours !== "string" && !isloading) {
		return (
			<Grid templateColumns='repeat(3, 1fr)' gap='1rem'>
				{hours?.map((hourItem, index) => (
					<GridItem
						key={hourItem}
						colSpan={1}
						bg={index === selectedHourIndex && "blue.500"}
						border={index === selectedHourIndex ? "1px solid blue" : "1px solid black"}
						cursor={hourItem === "Cerrado" ? "not-allowed" : "pointer"}
						onClick={() => handleSelectHour(hourItem, index)}
						rounded='md'
						justify='center'
						align='center'
						px='0.6em'
						py='0.4em'
						w='100px'
					>
						<Text color={index === selectedHourIndex && "white"} fontSize='14px'>
							{hourItem}
						</Text>
					</GridItem>
				))}
			</Grid>
			/*
            <Flex direction='column' h={isMobile?'40vh':'420px'} pr='1em'  overflowY='scroll' gap='0.5em'
            sx={{
                "&::-webkit-scrollbar": {width: "5px"},
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f5f5f5",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#cfcfcf",
                },}}
            >
                {hours?.map((hourItem, index)=>(
                    <Flex key={index}
                        bg={index===selectedHourIndex&&'blue.500'}
                        border={index===selectedHourIndex?'1px solid blue':'1px solid black'}
                        cursor ='pointer'
                        onClick={()=>handleSelectHour(hourItem, index)}
                        rounded='md' justify='center' align='center'
                        px='0.6em' py='0.4em'
                        w='150px'
                    >
                        <Text  color={index===selectedHourIndex&&'white'} fontSize='16px'>
                            {hourItem}
                        </Text>
                    </Flex>
                ))}
            </Flex>
            */
		);
	}
	return (
		<Flex direction='column' h={isMobile && "40vh"} justify='center' align='center'>
			<Text w='170px'>No hay horas libres para este día</Text>
		</Flex>
	);
}
