import { useState, useEffect } from "react";
import { Flex, Text, Button, Box, IconButton, Select, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { Tabs, TabList, Tab, TabPanels, TabPanel, useMediaQuery, useClipboard } from "@chakra-ui/react";
//icons
import { IoDesktopSharp } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
//comps
import BusinessInformationForm from "../../components/pages/businesses/businessSettings/profile/BusinessInformationForm";
import ServicesTable from "../../components/pages/services/tables/ServicesTable";
import BusinessHoursTable from "../../components/pages/businesses/businessSettings/schedule/BusinessHoursTable";
import BusinessHoursSettings from "../../components/pages/businesses/businessSettings/schedule/BusinessHoursSettings";
import WorkersTable from "../../components/pages/businesses/businessSettings/workers/WorkersTable";
import WorkImageForm from "../../components/pages/businesses/businessSettings/workImages/WorkImageForm";
import ExtrasForm from "../../components/pages/businesses/businessSettings/extras/ExtrasForm";
import OrderRequestsTable from "../../components/pages/businesses/businessSettings/orderRequests/OrderRequestsTable";
//mobile
import ServicesTableMobile from "../../components/pages/services/tables/ServicesTableMobile";
import BusinessInformationFormMobile from "../../components/pages/businesses/businessSettings/profile/BusinessInformationFormMobile";
import BusinessHoursTableMobile from "../../components/pages/businesses/businessSettings/schedule/BusinessHoursTableMobile";
import WorkersTableMobile from "../../components/pages/businesses/businessSettings/workers/WorkersTableMobile";
import ProfileCompletedProgressBar from "../../components/pages/businesses/businessSettings/ProfileCompletedProgressBar";
import OrderRequestsTableMobile from "../../components/pages/businesses/businessSettings/orderRequests/OrderRequestsTableMobile";
//api
import useAuthFetch from "../../myHooks/useAuthFetch";
import { getBusinessProfile, getAllBusinessHours } from "../../api/businessesApi";

export default function BusinessSetings() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [tableSelect, setTableSelect] = useState("profile");

	const [error, setError] = useState("");

	const { data: businessProfile } = useAuthFetch(["businessProfile"], getBusinessProfile);
	const { data: businessHours } = useAuthFetch(["businessHours"], getAllBusinessHours);

	const profileURL = `https://anothertool.es/reservas/${businessProfile?.slug}`;

	const { onCopy, hasCopied } = useClipboard(profileURL);
	useEffect(() => {
		setError("");
	}, [businessHours]);

	const handlePreview = () => {
		if (businessHours.length < 7) {
			setError("Debes completar tu horario para todos los días de la semana");
			return;
		}
		const url = `/reservas/${businessProfile?.slug}`;
		window.open(url, "_blank");
	};
	if (!isMobile) {
		return (
			<Flex
				direction='column'
				maxH='88dvh'
				width={{ base: "100%", md: "700px", lg: "800px", xl: "1080px", "2xl": "1300px" }}
			>
				<Flex w='100%' align='center' justify='space-between' pt='1em'>
					<Flex gap='0.8rem' align='center' flexWrap='wrap'>
						<Text color='black' fontSize='32px' fontWeight='bold'>
							Tu negocio
						</Text>
						<ProfileCompletedProgressBar progress={businessProfile?.profile_completed} />
					</Flex>
					<Flex align='center' gap='0.5em'>
						{error && <Text color='red'>{error}</Text>}
						<InputGroup bg='white' rounded='xl' minW='350px'>
							<Input placeholder={profileURL.split("https://")[1]} />
							<InputRightElement>
								<IconButton
									onClick={() => onCopy(profileURL)}
									size='sm'
									bg='white'
									icon={hasCopied ? <FaCheck size='20px' /> : <FaRegCopy size='20px' />}
								/>
							</InputRightElement>
						</InputGroup>
						<Button
							variant='white'
							w='200px'
							isDisabled={error}
							onClick={handlePreview}
							sx={{
								"&:hover .icon": {
									color: "white"
								}
							}}
							leftIcon={<Box className='icon' as={IoDesktopSharp} size='20px' color='#0258FF' />}
						>
							Ver Perfil
						</Button>
					</Flex>
				</Flex>
				<Flex
					p='1em'
					bg='white'
					rounded='lg'
					direction='column'
					mt='2rem'
					maxH='100%'
					sx={{
						paddingRight: "0.2rem",
						"&::-webkit-scrollbar": { width: "5px", height: "8px" },
						"&::-webkit-scrollbar-track": {
							backgroundColor: "#f5f5f5"
						},
						"&::-webkit-scrollbar-thumb": {
							backgroundColor: "#cfcfcf"
						}
					}}
					overflowY='scroll'
				>
					<Tabs variant='brand'>
						<TabList pb='1rem'>
							<Tab>Tu negocio</Tab>
							<Tab>Servicios</Tab>
							<Tab>Horarios</Tab>
							<Tab>Extras</Tab>
							<Tab>Imágenes</Tab>
							<Tab>Equipo</Tab>
							<Tab>Solicitudes</Tab>
						</TabList>
						<hr />
						<TabPanels>
							<TabPanel>
								<BusinessInformationForm businessProfile={businessProfile} />
							</TabPanel>
							<TabPanel>
								<ServicesTable />
							</TabPanel>
							<TabPanel>
								<BusinessHoursSettings />
								<BusinessHoursTable businessHours={businessHours} />
							</TabPanel>
							<TabPanel>
								<ExtrasForm />
							</TabPanel>
							<TabPanel pt='2em'>
								<WorkImageForm />
							</TabPanel>
							<TabPanel pt='2em'>
								<WorkersTable />
							</TabPanel>
							<TabPanel>
								<OrderRequestsTable />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Flex>
			</Flex>
		);
	}
	return (
		<Flex w='100%' direction='column' px='1em' pt='0.5em'>
			<Flex
				w='100%'
				justify='space-between'
				align='center'
				gap='2em'
				h='5dvh'
				pt='1em'
				pr={tableSelect === "workers" && "3em"}
			>
				<Select
					value={tableSelect}
					onChange={(e) => setTableSelect(e.target.value)}
					color='black'
					fontWeight='bold'
					fontSize='20px'
					border='none'
					maxW='50%'
				>
					<option value='profile' style={{ fontSize: "16px" }}>
						Información
					</option>
					<option value='services' style={{ fontSize: "16px" }}>
						Servicios
					</option>
					<option value='schedule' style={{ fontSize: "16px" }}>
						Horario
					</option>
					<option value='extras' style={{ fontSize: "16px" }}>
						Extras
					</option>
					<option value='images' style={{ fontSize: "16px" }}>
						Imagenes
					</option>
					<option value='workers' style={{ fontSize: "16px" }}>
						Equipo
					</option>
					<option value='requests' style={{ fontSize: "16px" }}>
						Solicitudes
					</option>
				</Select>
				<Flex align='center' gap='1em' mr='50px'>
					{error && <Text color='red'>{error}</Text>}
					<IconButton
						variant='white'
						isDisabled={error}
						onClick={handlePreview}
						icon={<IoDesktopSharp size='20px' color='#0258FF' />}
					/>
				</Flex>
			</Flex>
			{tableSelect === "profile" && <BusinessInformationFormMobile businessProfile={businessProfile} />}
			{tableSelect === "services" && <ServicesTableMobile />}
			{tableSelect === "schedule" && <BusinessHoursTableMobile businessHours={businessHours} />}
			{tableSelect === "extras" && <ExtrasForm />}
			{tableSelect === "images" && <WorkImageForm />}
			{tableSelect === "workers" && <WorkersTableMobile />}
			{tableSelect === "requests" && <OrderRequestsTableMobile />}
		</Flex>
	);
}
