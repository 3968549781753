import { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function BooleanFilter({ availableFilters, setFilter }) {
	const [appliedFilters, setAppliedFilters] = useState([]);

	const handleChange = (selectedOptions) => {
		const newAppliedFilters = selectedOptions || [];
		setAppliedFilters(newAppliedFilters);
		updateParentFilter(newAppliedFilters);
	};

	const updateParentFilter = (filters) => {
		const filtersString = filters.map((filter) => `${filter.value}=true`).join("&");
		setFilter(filtersString ? `&${filtersString}` : "");
	};

	return (
		<div style={{ minWidth: "250px" }}>
			<Select
				isMulti
				components={animatedComponents}
				value={appliedFilters}
				onChange={handleChange}
				options={availableFilters}
				placeholder='Filtra por ...'
				noOptionsMessage={() => "No hay opciones"}
				styles={{
					control: (provided, state) => ({
						...provided,
						width: "100%",
						borderRadius: "5px",
						borderColor: state.isFocused ? "black" : provided.borderColor
					}),
					multiValue: (provided) => ({
						...provided,
						background: "#FAFAFA",
						borderRadius: "5px"
					}),
					multiValueLabel: (provided) => ({
						...provided,
						color: "black"
					}),
					multiValueRemove: (provided) => ({
						...provided,
						color: "#001234",
						":hover": {
							backgroundColor: "#FF6347",
							color: "white"
						}
					})
				}}
			/>
		</div>
	);
}
