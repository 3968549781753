import { Tag, TagLabel } from "@chakra-ui/react";
//types
import type { Order } from "../../../../types/order";

interface OrderReminderStatusViewProps {
	status: Order["reminder_status"];
}

export default function OrderReminderStatusView({ status }: OrderReminderStatusViewProps) {
	const statusMap: Record<string, { bg: string; display: string }> = {
		confirmed: { bg: "green.500", display: "Confirmada" },
		cancelled: { bg: "red.500", display: "Cancelada" }
	};

	const { bg, display } = status
		? statusMap[status] || { bg: "gray.500", display: "Desconocido" }
		: { bg: "gray.500", display: "No definido" };
	if (!status) return null;
	return (
		<Tag bg={bg} rounded='xl' p='0.3em' maxW='85px'>
			<TagLabel color='white' fontSize='12px'>
				{display}
			</TagLabel>
		</Tag>
	);
}
