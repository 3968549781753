import React from 'react'
import { FormControl, FormErrorMessage, FormLabel, Flex } from '@chakra-ui/react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { useField } from 'formik'

const animatedComponents = makeAnimated()

export default function MultiSelectFieldFilter ({ label, icon, options, ...props }) {
    const [field, meta, helpers] = useField(props)
    const formatData = options?.map((dato) => {
        const { id, name, display_name, value, label, color } = dato
        return { value: value || id, label: name || display_name || label, color }
    })
    const handleChange = (options) => {
        const selectedValues = options?.map((option) => option.value)
        helpers.setValue(selectedValues)
    }
    const formattedInitialValues = meta?.initialValue?.map((item) => ({
        label: item.name + (item.price ? ' ' + item.price + ' €' : ''),
        value: item.id,
        color: item.color
    }))
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: state.selectProps.value?.color || 'white',
            borderRadius: '5px',
            borderColor: state.isFocused ? 'black' : provided.borderColor
        }),
        multiValue: (provided, state) => ({
            ...provided,
            background: state.data.color || 'FAFAFA',
            borderRadius: '5px'
        }),
        borderColor: 'red'
    }
    return (
        <FormControl isInvalid={(meta.error && meta.touched) || props.error}>
            <FormLabel color="black">
                <Flex align="center" gap="0.5em">
                    {icon}
                    {label}
                </Flex>
            </FormLabel>
            <Select
                placeholder="Escribe para buscar"
                isMulti
                styles={customStyles}
                components={animatedComponents}
                noOptionsMessage={() => 'No hay opciones de ' + label}
                options={formatData}
                name={field.name}
                onBlur={field.onBlur}
                defaultValue={formattedInitialValues?.filter((option) => option !== null)}
                // value={formatData ? formatData.filter((option) => field.value.includes(option.value)) : ""}
                onChange={handleChange}
            />
            {props?.error && <FormErrorMessage>{props.error} </FormErrorMessage>}
            {meta.touched && meta.error && <FormErrorMessage>{meta.error} </FormErrorMessage>}
        </FormControl>
    )
}
