import { useState, useMemo } from "react";
import {
	IconButton,
	Flex,
	Text,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from "@chakra-ui/react";
//icons
import { AiOutlineEye } from "react-icons/ai";
// comps
import TableMobile from "../../../common/Tables/Mobile/TableMobile";
import PopoverDelete from "../../../common/PopoverDelete";
// img
import emptyCars from "../../../../img/data/emptyCars.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getAllDepositReceiptsPag, deleteDepositReceipt } from "../../../../api/depositReceiptApi";

export default function DepositReceiptsTableMobile() {
	const [page, setPage] = useState(1);

	const { data, isLoading } = useAuthFetch(["depositReceipts", page], getAllDepositReceiptsPag, page);
	const getHasSignature = (receipt) => {
		if (receipt.signature) {
			return `Firmado por ${receipt.signature.name}`;
		}
		return "No firmado";
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((receipt) => (
				<AccordionItem key={receipt.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Flex w='100%' align='center' justify='space-between' fontSize='14px' pr='1em'>
							<Text>RD{receipt.incremental_id}</Text>
							<Text>{getHasSignature(receipt)}</Text>
						</Flex>
						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex
							w='100%'
							h='100%'
							direction='column'
							gap='1em'
							justify='space-between'
							fontSize='14px'
							p='0.5em'
						>
							<Text>{receipt.observations.length} Observaciones</Text>
							<Text>Vehiculo con {receipt.kms} kms</Text>
							<Flex w='100%' justify='end' mt='-2em'>
								<IconButton
									bg='transparent'
									icon={<AiOutlineEye size='20px' color='blue' />}
									onClick={() => window.open(`/resguardo-deposito/${receipt.id}`, "_blank")}
								/>
								<PopoverDelete
									deleteKey={["deleteDepositReceipt"]}
									deleteFn={deleteDepositReceipt}
									slug={receipt?.id}
									refetchKey={["depositReceipts"]}
								/>
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data]
	);

	return (
		<TableMobile
			data={data}
			tableData={tableData}
			isLoading={isLoading}
			emptyImg={emptyCars}
			label='Resguardo Depçosito'
			page={page}
			setPage={setPage}
		/>
	);
}
