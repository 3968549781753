import { useState, useEffect, Fragment } from "react";
import { Flex, Text, IconButton, Badge, useToast } from "@chakra-ui/react";

import moment from "moment";
// comps
import OrderRequestCancelPopover from "./OrderRequestCancelPopover";
import OrderCardServiceTags from "./OrderCardServiceTags";
// icons
import { BiCar } from "react-icons/bi";
import { FiUser, FiCalendar } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import { MdCleaningServices, MdInfoOutline, MdOutlineLocalPhone } from "react-icons/md";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { manageOrderRequest, deleteOrderRequest } from "../../../../api/businessesApi";

export default function OrderRequestCard({ request }) {
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const toast = useToast();
	const [hasExpired, setHasExpired] = useState(false);
	const [expiration, setExpiration] = useState("");
	const [cancelReason, setCancelReason] = useState("");

	const { isLoading, mutate } = useAuthMutation({
		mutationFn: manageOrderRequest,
		onSuccess: () => {
			toast({ title: "Solicitud actualizada!", status: "success" });
			QueryClient.invalidateQueries(["activeOrderRequests"]);
			QueryClient.refetchQueries("activeOrderRequests", { force: true });
		},
		onError: () => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});

	const handleRequest = (approve, requestId, cancelReason) => {
		const payload = {
			data: {
				approved: approve,
				cancel_reason: cancelReason
			},
			slug: requestId,
			token: authHeader()
		};
		mutate(payload);
	};
	useEffect(() => calculateExpiration(), [request]);
	const calculateExpiration = () => {
		const expiresAt = moment(request?.expires_at);
		const currentTime = moment();
		const hoursDiff = expiresAt.diff(currentTime, "hours");
		const minutesDiff = expiresAt.diff(currentTime, "minutes") % 60;
		if (hoursDiff <= 0 && minutesDiff < 0) {
			setHasExpired(true);
		} else {
			setExpiration(`${hoursDiff}h ${minutesDiff}m`);
		}
	};
	return (
		<Flex
			key={request.id}
			direction='column'
			maxW='100%'
			minW='250px'
			my='0.5em'
			boxShadow='2px 2px 2px 2px #F4F4F9'
			gap='1rem'
			rounded='xl'
			justify='center'
			p='1em'
			bg='white'
		>
			{hasExpired ? (
				<Badge colorScheme='red' rounded='lg'>
					Caducada
				</Badge>
			) : (
				<Text>Expira en {expiration}</Text>
			)}
			<Flex maxW='100%' overflowWrap='break-word' justify='space-between' align='center'>
				<Text fontWeight='bold' color='darkblue'>
					Solicitud #{request.incremental_id.toString().padStart(5, "0")}{" "}
				</Text>
				<Flex gap='1rem'>
					<OrderRequestCancelPopover
						isLoading={isLoading}
						cancelReason={cancelReason}
						setCancelReason={setCancelReason}
						isDisabled={request.approved || hasExpired}
						cancelRequest={() => handleRequest(false, request.id, cancelReason)}
					/>
					<IconButton
						aria-label='approve-button'
						colorScheme='green'
						size='xs'
						onClick={() => handleRequest(true, request.id, null)}
						isLoading={isLoading}
						isDisabled={request.approved || hasExpired}
						icon={<FaCheck size='18px' color='white' />}
					/>
				</Flex>
			</Flex>
			<OrderCardServiceTags services={request.services} />
			<Flex gap='0.5rem' align='center'>
				<FiCalendar size='20px' color='grey' />
				<Text color='grey' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
					{moment(request.date_in).format("HH:mm dddd D MMMM")}
				</Text>
			</Flex>

			<Flex gap='0.5em' align='center' flexWrap='wrap'>
				<BiCar size='20px' color='grey' />
				<Text color='grey' fontWeight='semibold'>
					{request.brand_display}
				</Text>
				<Text color='grey'>{request.model}</Text>
			</Flex>

			<Flex gap='0.5em' flexWrap='wrap'>
				<FiUser size='20px' color='grey' />
				<Flex direction='column' gap='0.2rem'>
					<Text color='grey' fontWeight='semibold'>
						{request.client_name}
					</Text>
					<Text color='grey'>{request.client_email}</Text>
					<Text color='grey'>{request.client_phone}</Text>
				</Flex>
			</Flex>
			{request.observations && (
				<Flex gap='0.5em' align='center'>
					<MdInfoOutline size='20px' color='grey' />
					<Text color='grey' fontWeight='bold' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
						{request.observations}
					</Text>
				</Flex>
			)}
		</Flex>
	);
}
