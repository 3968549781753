import type React from "react";
import { Flex, Text, Divider, useMediaQuery, Grid, GridItem } from "@chakra-ui/react";
//icons
import { FaRegCreditCard, FaParking, FaHome, FaShoppingCart } from "react-icons/fa";
import { FaAccessibleIcon } from "react-icons/fa6";
import { MdEco, MdDiscount } from "react-icons/md";
import { LuAlertTriangle } from "react-icons/lu";
//types
import type { BusinessExtras } from "../../../../types/business";

interface ExtraSectionProps {
	extras: BusinessExtras;
}

const ExtraSection: React.FC<ExtraSectionProps> = ({ extras }) => {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<Flex direction='column' p='1rem' mt='1rem' rounded='xl' bg='white'>
				<Text fontWeight='bold' fontSize='24px'>
					Información extra
				</Text>
				<Divider borderWidth='1px' my='1em' color='#D9D9D9' />
				<Grid gap='2rem' templateColumns='repeat(2, 1fr)'>
					{extras.accepts_card && (
						<GridItem>
							<Flex gap='0.8em' align='center'>
								<FaRegCreditCard color='#0258FF' size='18px' />
								<Text fontWeight='bold'>Se puede pagar con tarjeta </Text>
							</Flex>
						</GridItem>
					)}
					{extras.accessible && (
						<GridItem>
							<Flex gap='0.8em' align='center'>
								<FaAccessibleIcon color='#0258FF' size='18px' />
								<Text fontWeight='bold'>Accesibilidad </Text>
							</Flex>
						</GridItem>
					)}
					{extras.eco_friendly && (
						<GridItem>
							<Flex gap='0.8em' align='center'>
								<MdEco color='#0258FF' size='18px' />
								<Text fontWeight='bold'>Ecológico </Text>
							</Flex>
						</GridItem>
					)}
					{extras.has_parking && (
						<GridItem>
							<Flex gap='0.8em' align='center'>
								<FaParking color='#0258FF' size='18px' />
								<Text fontWeight='bold'>Parking </Text>
							</Flex>
						</GridItem>
					)}
					{extras.home_service && (
						<GridItem>
							<Flex gap='0.8em' align='center'>
								<FaHome color='#0258FF' size='18px' />
								<Text fontWeight='bold'>A domicilio </Text>
							</Flex>
						</GridItem>
					)}
					{extras.product_selling && (
						<GridItem>
							<Flex gap='0.8em' align='center'>
								<FaShoppingCart color='#0258FF' size='18px' />
								<Text fontWeight='bold'>Venta de productos </Text>
							</Flex>
						</GridItem>
					)}
					{extras.member_discounts && (
						<GridItem>
							<Flex gap='0.8em' align='center'>
								<MdDiscount color='#0258FF' size='18px' />
								<Text fontWeight='bold'>Descuento de socios </Text>
							</Flex>
						</GridItem>
					)}
				</Grid>
				<Flex mt='1.5rem' color='darkblue' align='center' gap='0.5rem'>
					<LuAlertTriangle size='18px' />
					<Text fontWeight='semibold'>Nota importane</Text>
				</Flex>
				<Text mt='1rem'>
					Los precios indicados (*) para nuestros servicios de detailing están sujetos a cambios. En casos
					donde los vehículos lleguen en condiciones significativamente más sucias o dañadas de lo previsto,
					se aplicará un cargo adicional. Esto se debe a que el trabajo requerido excederá el tiempo y los
					recursos estimados inicialmente. Agradecemos tu comprensión y te garantizamos que cualquier ajuste
					en el precio se discutirá y acordará antes de comenzar el servicio.
				</Text>
			</Flex>
		);
	}
	return (
		<Flex direction='column'>
			<Divider borderWidth='1px' my='1em' color='#D9D9D9' />
			<Flex direction='column' gap='0.8em'>
				{extras.accepts_card && (
					<Flex gap='0.8em' align='center'>
						<FaRegCreditCard color='#0258FF' size='18px' />
						<Text fontWeight='bold'>Se puede pagar con tarjeta </Text>
					</Flex>
				)}
				{extras.accessible && (
					<Flex gap='0.8em' align='center'>
						<FaAccessibleIcon color='#0258FF' size='18px' />
						<Text fontWeight='bold'>Accesibilidad </Text>
					</Flex>
				)}
				{extras.eco_friendly && (
					<Flex gap='0.8em' align='center'>
						<MdEco color='#0258FF' size='18px' />
						<Text fontWeight='bold'>Ecológico </Text>
					</Flex>
				)}
				{extras.has_parking && (
					<Flex gap='0.8em' align='center'>
						<FaParking color='#0258FF' size='18px' />
						<Text fontWeight='bold'>Parking </Text>
					</Flex>
				)}
				{extras.home_service && (
					<Flex gap='0.8em' align='center'>
						<FaHome color='#0258FF' size='18px' />
						<Text fontWeight='bold'>A domicilio </Text>
					</Flex>
				)}
				{extras.product_selling && (
					<Flex gap='0.8em' align='center'>
						<FaShoppingCart color='#0258FF' size='18px' />
						<Text fontWeight='bold'>Venta de productos </Text>
					</Flex>
				)}
				{extras.member_discounts && (
					<Flex gap='0.8em' align='center'>
						<MdDiscount color='#0258FF' size='18px' />
						<Text fontWeight='bold'>Descuento de socios </Text>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

export default ExtraSection;
