import { extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./buttonTheme";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

export const myTheme = extendTheme({
	colors: {
		black: "#001234",
		brand_green: "#42C74F",
		hover_blue: "#749FF2",
		brand_red: "#C74242",
		hover_red: "#db8a8a",
		darkblue: "#001234", // darkblue,
		brandOrange: "#FF7C02", // orange
		lightgrey: "#F4F4F9",
		hover_green: "#88dd90",
		orange: {
			50: "#F2B174",
			800: "#FF7C02"
		},
		gray: {
			50: "#D9D9D9",
			500: "#737A87",
			800: "#737A87"
		},
		blue: {
			50: "#749FF2",
			500: "#0258FF",
			800: "#0258FF"
		},
		green: {
			50: "#BFE9C2",
			200: "#BFE9C2",
			500: "#42C74F",
			800: "#42C74F"
		},
		brand: {
			50: "#749FF2",
			500: "#0258FF",
			800: "#001234"
		}
	},
	fonts: {
		heading: `'Poppins', sans-serif`,
		body: `'Poppins', sans-serif`
	},
	components: {
		Heading: {
			variants: {
				h1: {
					fontFamily: "Poppins",
					fontWeight: "700",
					fontSize: "60px"
				},
				h2: {
					fontFamily: "Poppins",
					fontWeight: "600",
					fontSize: "35px"
				}
			}
		},
		Text: {},
		Button: buttonTheme,
		Tabs: {
			variants: {
				brand: {
					tab: {
						px: "10",
						rounded: "5px",
						_selected: {
							color: "white",
							bg: "blue.500",
							rounded: "5px"
						}
					}
				},
				brand_light: {
					tab: {
						px: "0.1",
						ml: "1rem",
						_selected: {
							color: "#0258FF",
							borderBottom: "2px solid #0258FF"
						}
					},
					tabPanel: {
						pt: "2em"
					}
				}
			}
		},
		Drawer: {
			parts: ["dialog", "header", "body"],
			variants: {
				mobile: {
					dialog: {
						maxW: "100px"
					}
				}
			}
		},
		Link: {
			variants: {
				filter: {
					bg: "#0258FF",
					color: "white",
					boxShadow: "4px 4px 4px rgba(2, 88, 255, 0.3)",
					fontFamily: "Poppins",
					fontWeight: "400",
					_hover: {
						// bg: "#aabcdb",
						// color: "#001234",
						bg: "#749FF2"
					}
				}
			}
		}
	}
});
