import { useState, useEffect } from "react";
import {
	Button,
	useToast,
	Switch,
	Divider,
	Flex,
	Text,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerBody,
	DrawerFooter
} from "@chakra-ui/react";
import moment from "moment";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../../../common/forms/InputField";
import SelectField from "../../../../common/forms/SelectField";
// api
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import { getBusinessHoursOptions, createUpdateBussinessHours } from "../../../../../api/businessesApi";
import { useQueryClient } from "@tanstack/react-query";
//types
import type { ApiResponse } from "../../../../../types/api";
import type {
	BusinessHour,
	BusinessHourInput,
	BusinessHourPayload,
	BusinessHourAxiosError
} from "../../../../../types/business";
// auth
import { useAuthHeader } from "react-auth-kit";

interface BusinessHoursFormProps {
	businessHour?: BusinessHour;
	isOpen: boolean;
	onClose: () => void;
}

export default function BusinessHoursForm({ onClose, isOpen, businessHour }: BusinessHoursFormProps) {
	const authHeader = useAuthHeader();
	const toast = useToast();
	const QueryClient = useQueryClient();
	const [isSplit, setIsSplit] = useState(false);
	const { data: businessHoursOptions } = useAuthFetch(["dayChoices"], getBusinessHoursOptions) as {
		data: ApiResponse;
	};
	const { isLoading, mutate, error } = useAuthMutation<BusinessHour, BusinessHourPayload, BusinessHourAxiosError>({
		mutationFn: createUpdateBussinessHours,
		onSuccess: () => {
			toast({ title: "Creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["businessHours"]);
			QueryClient.refetchQueries({ queryKey: ["businessHours"], exact: true });
			onClose();
		},
		onError: (error) => {
			console.log(error);
			toast({
				title: "¡Ups!, ha habido un error",
				status: "error"
			});
		}
	});

	const dayChoices = businessHoursOptions?.actions?.POST?.day?.choices;

	useEffect(() => {
		if (businessHour?.morning_closing_time || businessHour?.afternoon_opening_time) {
			setIsSplit(true);
		} else {
			setIsSplit(false);
		}
	}, [businessHour]);

	const initialValues: BusinessHourInput = {
		day: businessHour?.day || "",
		is_closed: businessHour?.is_closed || false,
		morning_opening_time: businessHour?.morning_opening_time || undefined,
		morning_closing_time: businessHour?.morning_closing_time || undefined,
		afternoon_opening_time: businessHour?.afternoon_opening_time || undefined,
		afternoon_closing_time: businessHour?.afternoon_closing_time || undefined
	};
	const validationSchema = Yup.object({
		day: Yup.string().required("El día es obligatorio"),
		isClosed: Yup.boolean(),

		// Validar morning_opening_time
		morning_opening_time: Yup.string().when("is_closed", {
			is: false,
			then: Yup.string()
				.required("Obligatorio")
				.matches(/^(?:[0-1]\d|2[0-3]):(?:[0-5]\d)(?::[0-5]\d)?$/, "Formato no válido"),
			otherwise: Yup.string().notRequired()
		}),
		morning_closing_time: Yup.string()
			.nullable()
			.notRequired()
			.test(
				"is-greater-than-morning-opening",
				"Debe ser mayor que la hora de apertura de la mañana",
				function (value) {
					const { morning_opening_time, afternoon_opening_time } = this.parent;
					if (morning_opening_time && value) {
						return value > morning_opening_time;
					}
					return true;
				}
			)
			.test(
				"is-less-than-afternoon-opening",
				"Debe ser menor que la hora de apertura de la tarde",
				function (value) {
					const { afternoon_opening_time } = this.parent;
					if (value && afternoon_opening_time) {
						return value < afternoon_opening_time;
					}
					return true;
				}
			),

		// Validar afternoon_opening_time
		afternoon_opening_time: Yup.string()
			.nullable()
			.notRequired()
			.test(
				"is-greater-than-morning-closing",
				"Debe ser mayor que la hora de cierre de la mañana",
				function (value) {
					const { morning_closing_time, morning_opening_time } = this.parent;
					if (morning_closing_time && value) {
						return value > morning_opening_time;
					}
					return true;
				}
			)
			.test(
				"is-less-than-afternoon-closing",
				"Debe ser menor que la hora de cierre de la tarde",
				function (value) {
					const { afternoon_closing_time } = this.parent;
					if (value && afternoon_closing_time) {
						return value < afternoon_closing_time;
					}
					return true;
				}
			),

		// Validar afternoon_closing_time
		afternoon_closing_time: Yup.string().when("isClosed", {
			is: false,
			then: Yup.string()
				.required("Obligatorio")
				.matches(/^(?:[0-1]\d|2[0-3]):(?:[0-5]\d)(?::[0-5]\d)?$/, "Formato no válido"),
			otherwise: Yup.string().notRequired()
		})
	});

	const handleSubmit = (values: BusinessHourInput) => {
		const payload = businessHour
			? { data: values, slug: businessHour.id, token: authHeader() }
			: { data: values, token: authHeader() };
		mutate(payload);
	};

	const handleChangeSplit = (formik: any) => {
		const newIsSplit = !isSplit;
		setIsSplit(newIsSplit);
		if (!newIsSplit) {
			formik.setFieldValue("morning_closing_time", null);
			formik.setFieldValue("afternoon_opening_time", null);
		}
	};

	return (
		<Drawer isOpen={isOpen} placement='right' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>{businessHour ? "Editar" : "Crear"} Servicio</DrawerHeader>
				<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
					{(formik) => (
						<>
							<DrawerBody>
								<Flex direction='column' gap='1em'>
									<SelectField label='Día' name='day' choices={dayChoices} icon={null} />
									<Flex align='center' gap='0.5em'>
										<Text>¿Cerrado?</Text>
										<Switch
											name='is_closed'
											colorScheme='blue'
											isChecked={formik.values.is_closed}
											onChange={() => formik.setFieldValue("is_closed", !formik.values.is_closed)}
										/>
									</Flex>
									<Flex align='center' gap='0.5em'>
										<Text>Horario partido?</Text>
										<Switch
											colorScheme='blue'
											isChecked={isSplit}
											onChange={() => handleChangeSplit(formik)}
										/>
									</Flex>
									{!formik.values.is_closed && (
										<>
											{isSplit && <Text>Turno Mañana</Text>}
											<InputField
												label={!isSplit ? "Hora de apertura" : "Apertura"}
												name='morning_opening_time'
												type='time'
												error={error?.response?.data?.morning_opening_time}
											/>
											{isSplit && (
												<>
													<InputField
														label='Cierre'
														name='morning_closing_time'
														type='time'
														error={error?.response?.data?.morning_closing_time}
													/>
													<Divider />
													<Text>Turno Tarde</Text>

													<InputField
														label='Apertura'
														name='afternoon_opening_time'
														type='time'
														error={error?.response?.data?.afternoon_opening_time}
													/>
												</>
											)}
											<InputField
												label={!isSplit ? "Hora de cierre" : "Cierre"}
												name='afternoon_closing_time'
												type='time'
												error={error?.response?.data?.afternoon_closing_time}
											/>
										</>
									)}
								</Flex>
								{error?.response?.data?.non_field_errors && (
									<Text color='red' mt='2em'>
										{error.response?.data?.non_field_errors[0]}
									</Text>
								)}
							</DrawerBody>
							<DrawerFooter>
								<Flex justify='right' columnGap='3' mt='3'>
									<Button variant='white' onClick={onClose}>
										Cancelar
									</Button>
									<Button
										variant='primary'
										isDisabled={!formik.dirty}
										onClick={() => formik.handleSubmit()}
										isLoading={isLoading}
									>
										Guardar
									</Button>
								</Flex>
							</DrawerFooter>
						</>
					)}
				</Formik>
			</DrawerContent>
		</Drawer>
	);
}
