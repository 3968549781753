import { Flex, Image, Text, useMediaQuery } from '@chakra-ui/react'
// comps
import CarViewSelect from './CarViewSelect'
import PhotoViewer from './PhotoViewer'
import SubmitInspection from './forms/SubmitInspectionButton'
import ImageUploader from './ImageUploader'
// img
import emptyPhoto from '../../../img/inspection/emptyPhoto.png'

export default function CarViewer ({
    photo,
    setPhoto,
    view,
    setView,
    circleCoords,
    setCircleCoords,
    order,
    state,
    savedInspection,
    isBefore,
    zone,
    setSelectedDetail
}) {
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    if (!isMobile) {
        return (
            <Flex direction="column" w="100%" h="100%" bg="white" p="2em" rounded="xl">
                {photo[view] && (
                    <PhotoViewer
                        photo={photo[view]?.url}
                        setPhoto={setPhoto}
                        view={view}
                        savedInspection={savedInspection}
                        circleCoords={circleCoords}
                        setCircleCoords={setCircleCoords}
                        setSelectedDetail={setSelectedDetail}
                    />
                )}
                {!photo[view] && (
                    <Flex direction="column" pt="4em" align="center" gap="3em">
                        <Image src={emptyPhoto} />
                        <Text align="center" maxW="350px">
                            Haz una fotografía del coche o añade una ya hecha desde tu galería. <br />
                            La fotografía debe corresponder con la vista que has seleccionado.
                        </Text>
                        <ImageUploader setPhoto={setPhoto} view={view} />
                    </Flex>
                )}
                <Flex gap="1em" w="100%" h="100%" align="end">
                    <Flex w="100%" justify="space-between">
                        <CarViewSelect view={view} setView={setView} photo={photo} />
                        <SubmitInspection
                            photo={photo}
                            setPhoto={setPhoto}
                            order={order}
                            state={state}
                            savedInspection={savedInspection}
                            isBefore={isBefore}
                            zone={zone}
                        />
                    </Flex>
                </Flex>
            </Flex>
        )
    } else {
        return (
            <Flex direction="column" p="0.5em" align="center" maxW="100%">
                <CarViewSelect view={view} setView={setView} photo={photo} />
                {photo[view]
                    ? (
                        <PhotoViewer
                            photo={photo[view]?.url}
                            setPhoto={setPhoto}
                            view={view}
                            savedInspection={savedInspection}
                            circleCoords={circleCoords}
                            setCircleCoords={setCircleCoords}
                            setSelectedDetail={setSelectedDetail}
                        />
                    )
                    : (
                        <Flex
                            direction="column"
                            align="center"
                            justify="center"
                            gap="2em"
                            bg="lightgrey"
                            mt="2em"
                            minH="50vh"
                            rounded="xl"
                            w="100%"
                        >
                            <Text align="center" maxW="150px">
                            Haz una fotografía del coche o añade una desde tu galería.
                            </Text>
                            <ImageUploader setPhoto={setPhoto} view={view} />
                        </Flex>
                    )}
            </Flex>
        )
    }
}
