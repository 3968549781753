import { Modal, ModalOverlay, ModalContent, Flex } from "@chakra-ui/react";
//comps
import PlanSelection from "./PlanSelection";

interface DirectPaymentModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export default function DirectPaymentModal({ isOpen, onClose }: DirectPaymentModalProps) {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size='full' closeOnOverlayClick={false}>
			<ModalOverlay bg='blackAlpha.800' />
			<ModalContent bg='lightgrey'>
				<Flex w='100%' justify='center'>
					<PlanSelection isDirectPayment />
				</Flex>
			</ModalContent>
		</Modal>
	);
}
