import { useState, useEffect } from "react";
import { Flex, IconButton, InputGroup, InputRightElement, Input } from "@chakra-ui/react";
//types
import type { KeyboardEvent } from "react";
//icons
import { CiSearch } from "react-icons/ci";
import { MdOutlineCancel } from "react-icons/md";

interface SearchInputProps {
	searchQuery: string;
	setSearchQuery: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ searchQuery, setSearchQuery }) => {
	const [searchInput, setSearchInput] = useState("");

	const handleSearch = () => {
		setSearchQuery(searchInput);
	};
	const handleCancelSearch = () => {
		setSearchInput("");
		setSearchQuery("");
	};
	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			handleSearch();
		}
		if (e.key === "Escape") {
			handleCancelSearch();
		}
	};
	useEffect(() => {
		if (searchInput === "" && searchQuery !== "") {
			setSearchQuery("");
		}
	}, [searchInput, searchQuery, setSearchQuery]);

	return (
		<Flex justify='end' onKeyDown={handleKeyDown}>
			<InputGroup minW='220px' maxW='350px' bg='white'>
				<Input
					placeholder='Escribe para buscar...'
					value={searchInput}
					onChange={(e) => {
						setSearchInput(e.target.value);
					}}
				/>
				<InputRightElement>
					{searchQuery ? (
						<IconButton
							aria-label='cancel_search'
							bg='transparent'
							onClick={handleCancelSearch}
							icon={<MdOutlineCancel size='25px' />}
						/>
					) : (
						<IconButton
							aria-label='search'
							bg='transparent'
							onClick={handleSearch}
							icon={<CiSearch size='30px' />}
						/>
					)}
				</InputRightElement>
			</InputGroup>
		</Flex>
	);
};

export default SearchInput;
