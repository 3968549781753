import { useState } from "react";
import { Flex, Text, IconButton, useMediaQuery } from "@chakra-ui/react";
import moment from "moment";
// icons
import { AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai";
import { CiLogout, CiLogin } from "react-icons/ci";
import { MdCleaningServices, MdOutlineNotificationsActive } from "react-icons/md";
import { PiChatTeardropTextBold } from "react-icons/pi";
import { TbFileInvoice } from "react-icons/tb";
import { BiWrench } from "react-icons/bi";

// comps
import OrderStatusView from "../../orders/OrderStatusView";
import InvoiceStatusBadge from "../../invoicing/InvoiceStatusBadge";

export default function CardHistoryCard({ order }) {
	const [display, setDisplay] = useState(false);
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	const service_duration = (dateIn, dateOut) => {
		if (!dateIn || !dateOut) {
			return "";
		}
		const diff = moment(dateOut).diff(moment(dateIn));
		const duration = moment.duration(diff);
		const days = Math.floor(duration.asDays());
		const hours = duration.hours();
		if (days < 1) {
			return `${hours} horas`;
		}
		return `${days} días ${hours} horas`;
	};
	const displayDate = (date) => {
		return date ? moment(date).format("L") : "-- --";
	};
	return (
		<Flex p='1em' direction='column' gap='1em' bg='lightgrey' rounded='xl'>
			<Flex justify='space-between' align={isMobile && "center"}>
				{!isMobile ? (
					<Flex gap='1em' align='center' alignSelf='start'>
						<Text fontSize='20px' fontWeight='bold'>
							Orden #{order.id}
						</Text>
						<OrderStatusView status={order.status} />
					</Flex>
				) : (
					<Flex direction='column' gap='0.5em'>
						<Text fontSize='20px' fontWeight='bold'>
							Orden #{order.id}
						</Text>
						<OrderStatusView status={order.status} />
					</Flex>
				)}
				<Flex direction='column' gap='0.5em'>
					<Flex gap='0.5em'>
						<CiLogin size='20px' />
						<Text>{displayDate(order.date_out)}</Text>
					</Flex>
					<Flex gap='0.5em'>
						<CiLogout size='20px' />
						<Text>{displayDate(order.date_in)}</Text>
					</Flex>
				</Flex>
			</Flex>
			<Flex gap='0.5em'>
				<MdCleaningServices size='20px' />
				<Text>
					{order?.services?.length > 0 ? (
						order.services.map((service, index) => (
							<span key={service.id}>
								{service.name}
								{index < order.services.length - 1 ? " + " : ""}
							</span>
						))
					) : (
						<span>-- --</span>
					)}
				</Text>
			</Flex>
			<Flex gap='0.5em' align='center'>
				<TbFileInvoice size='20px' />
				{order?.invoice ? (
					<>
						<Text>{order.invoice.total + " € "}</Text>
						<InvoiceStatusBadge status={order.invoice.status} />
					</>
				) : (
					"-- --"
				)}
			</Flex>

			{/* HIDDDEN */}
			{display && (
				<>
					<Flex gap='0.5em'>
						<PiChatTeardropTextBold size='20px' />
						<Text>{order.client_desc || "-- --"}</Text>
					</Flex>
					<Flex gap='0.5em'>
						<BiWrench size='20px' />
						{order?.stock_used?.length > 0 ? (
							<Text>
								{order?.stock_used?.map((item, index) => (
									<span key={item.id}>
										{item.total + "" + item.unit + " " + item.name}
										{index < order.stock_used.length - 1 ? " + " : ""}
									</span>
								))}
							</Text>
						) : (
							<Text>-- --</Text>
						)}
					</Flex>
					<Flex gap='0.5em'>
						<MdOutlineNotificationsActive size='20px' />
						<Text>{order.reminder || "-- --"}</Text>
					</Flex>
					<Flex gap='0.5em'>
						<Text fontSize='14px' fontWeight='bold'>
							Tiempo servicio
						</Text>
						<Text>{service_duration(order.date_in, order.date_out)}</Text>
					</Flex>
				</>
			)}
			<IconButton
				position='relative'
				w='20px'
				mt='-50px'
				alignSelf='end'
				bg='transparent'
				onClick={() => setDisplay(!display)}
				icon={display ? <AiOutlineUpCircle size='20px' /> : <AiOutlineDownCircle size='20px' />}
			/>
		</Flex>
	);
}
