import { useState, useEffect } from "react";
import {
	Flex,
	Text,
	Switch,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	useToast
} from "@chakra-ui/react";
//comps
import WarningTooltip from "../../../../common/WarningTooltip";
import LoadingSpinner from "../../../../common/loading/LoadingSpinner";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import { getBusinessHoursSettings, createUpdateBussinessHoursSettings } from "../../../../../api/businessesApi";
import { useQueryClient } from "@tanstack/react-query";
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../../myHooks/useAuthMutation";

export default function BusinessHoursSettings() {
	const [timeSlot, setTimeSlot] = useState();
	const [showUnavailable, setShowUnavailable] = useState(undefined);
	const [allowAutomaticBookings, setAllowAutomaticBookings] = useState(undefined);
	const toast = useToast();
	const QueryClient = useQueryClient();
	const authHeader = useAuthHeader();

	const { data, isLoading, isFetched } = useAuthFetch(["businessHoursSettings"], getBusinessHoursSettings);
	const { isLoading: lm, mutate } = useAuthMutation({
		mutationFn: createUpdateBussinessHoursSettings,
		onSuccess: () => {
			toast({ title: "Ajustes actualizados!", status: "success" });
			QueryClient.invalidateQueries(["businessHoursSettings"]);
			QueryClient.refetchQueries("businessHoursSettings", { force: true });
		},
		onError: () => {
			toast({
				title: "¡Ups, ha habido un error!",
				status: "error"
			});
		}
	});
	useEffect(() => {
		if (isFetched) {
			setTimeSlot(data?.time_slot);
			setShowUnavailable(data?.show_unavailable_hours);
			setAllowAutomaticBookings(data?.allow_automatic_bookings);
		}
	}, [isFetched]);
	const submit = () => {
		const payload = {
			data: {
				time_slot: timeSlot,
				show_unavailable_hours: showUnavailable,
				allow_automatic_bookings: allowAutomaticBookings
			},
			slug: data.id,
			token: authHeader()
		};
		mutate(payload);
	};
	useEffect(() => {
		if (data && timeSlot && timeSlot !== data?.time_slot) {
			submit();
		}
		if (data && showUnavailable !== undefined && showUnavailable !== data?.show_unavailable_hours) {
			submit();
		}
		if (data && allowAutomaticBookings !== undefined && allowAutomaticBookings !== data?.allow_automatic_bookings) {
			submit();
		}
	}, [timeSlot, showUnavailable, allowAutomaticBookings]);
	if (!isLoading || !lm) {
		return (
			<Flex direction='column' gap='1em'>
				<Flex>
					<Text>¿Mostrar a los clientes las horas ocupadas?</Text>
					<Switch
						ml='1em'
						isChecked={showUnavailable}
						onChange={() => setShowUnavailable(!showUnavailable)}
					/>
					<Text ml='0.5rem'>{showUnavailable ? "Sí" : "No"}</Text>
				</Flex>
				<Flex>
					<WarningTooltip
						label='¿Permitir reservas automáticas?'
						desc='Cuidado al cambiar este ajuste, los clientes podrán reservarte sin tu supervision. 
Si no estás de acuerdo con alguna reserva, tendrás que llamarles para anularla'
					/>
					<Switch
						ml='1rem'
						isChecked={allowAutomaticBookings}
						onChange={() => setAllowAutomaticBookings(!allowAutomaticBookings)}
					/>
					<Text ml='0.5rem'>{allowAutomaticBookings ? "Sí" : "No"}</Text>
				</Flex>
				<Flex align='end'>
					<Text>Mostrar horarios disponibles cada </Text>
					<NumberInput
						w='65px'
						mx='0.5em'
						size='sm'
						min={1}
						max={4}
						value={timeSlot}
						onChange={(e) => setTimeSlot(e)}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
					<Text>horas</Text>
				</Flex>
			</Flex>
		);
	}
	return <LoadingSpinner />;
}
