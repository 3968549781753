import { useState, useEffect } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	Flex,
	Text
} from "@chakra-ui/react";
//comps
import AddressGeoLocationInput from "./AddressGeoLocationInput";
import { CustomMarker } from "./CustomMarker";
import { Map } from "@vis.gl/react-google-maps";

export default function AddressModal({ currentAddress }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedPlace, setSelectedPlace] = useState(null);
	useEffect(() => {
		if (currentAddress?.latitude && currentAddress?.longitude) {
			setSelectedPlace({
				lat: Number(currentAddress.latitude),
				lng: Number(currentAddress.longitude)
			});
		}
	}, [currentAddress]);

	const hasLocation = selectedPlace !== null;

	return (
		<>
			<Flex direction='column' gap='0.5rem'>
				<Text>{currentAddress?.formatted_address}</Text>
				<Button onClick={onOpen} variant='primary'>
					{hasLocation ? "Modificar" : "Elegir dirección"}
				</Button>
			</Flex>
			<Modal isOpen={isOpen} onClose={onClose} size='2xl'>
				<ModalOverlay />
				<ModalContent pb='4rem'>
					<ModalHeader>Selecciona la ubicación de tu negocio</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<AddressGeoLocationInput
							currentAddress={currentAddress}
							selectedPlace={selectedPlace}
							setSelectedPlace={setSelectedPlace}
							handleClose={onClose}
						/>

						{hasLocation && (
							<Map
								style={{ width: "100%", height: "350px" }}
								zoom={17}
								defaultCenter={selectedPlace}
								libraries={["marker"]}
								mapId={"bf51a910020fa25a"}
								gestureHandling={"greedy"}
								disableDefaultUI={true}
							>
								<CustomMarker position={selectedPlace} />
							</Map>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
