import * as Yup from "yup";
import moment from "moment";
//fns
import { formatDecimals } from "../../../common/functions";

export const getInitialItems = (invoice, quote, order, taxChoices) => {
	if (invoice) {
		return invoice.items;
	}
	if (quote) {
		return quote.items;
	}
	if (order?.has_invoice) {
		return order.invoice[0].items;
	}
	if (order?.has_quote) {
		return order.quote[0].items;
	}
	if (order?.services) {
		return parseOrderServicesToInvoiceItems(order.services, taxChoices);
	}
	return [
		{
			concept: "",
			price: "",
			quantity: ""
		}
	];
};
export const parseOrderServicesToInvoiceItems = (services) => {
	return services.map((service) => ({
		concept: service.name,
		price: formatDecimals(service.price),
		quantity: 1
	}));
};

export const getInvoiceInitialValues = (invoice, order) => ({
	date: invoice ? moment(invoice.date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
	expiring_date: invoice?.expiring_date
		? moment(invoice.expiring_date).format("YYYY-MM-DD")
		: moment().add(5, "days").format("YYYY-MM-DD"),
	client: invoice?.client?.id || order?.client?.id,
	invoice_number: invoice ? invoice.invoice_number : "",
	status: invoice?.status || "pending",
	pay_method: invoice?.pay_method || "card",
	price_includes_tax: invoice ? invoice.price_includes_tax : true,
	total: invoice ? invoice.total : 0,
	tax: invoice ? invoice.tax : "twenty",
	taxes: invoice ? invoice.taxes : 0,
	series: invoice ? invoice.series : "fact",
	discount: invoice?.discount || 0,
	order: order?.id,
	items: getInitialItems(invoice, null, order)
});

export const getQuoteInitialValues = (quote, order, nextQuoteID) => ({
	date: quote ? moment(quote.date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
	expiring_date: quote?.expiring_date
		? moment(quote.expiring_date).format("YYYY-MM-DD")
		: moment().add(5, "days").format("YYYY-MM-DD"),
	client: quote?.client?.id || order?.client?.id,
	quote_number: quote ? quote.quote_number : nextQuoteID,
	status: quote?.status || "pending",
	total: quote ? quote.total : 0,
	tax: quote ? quote.tax : "twenty",
	taxes: quote ? quote.taxes : 0,
	discount: quote?.discount || 0,
	price_includes_tax: quote ? quote.price_includes_tax : true,
	order: order?.id,
	items: getInitialItems(null, quote, order)
});

export const getInvoiceValidationSchema = () => {
	return Yup.object({
		invoice_number: Yup.string().required("Debes añadir numero de factura"),
		date: Yup.date().required(),
		expiring_date: Yup.date().when(
			"date",
			(date, schema) => date && schema.min(date, "No puede ser anterior a la fecha de la factura")
		),
		client: Yup.number().required("Debes seleccionar un cliente"),
		status: Yup.string(),
		discount: Yup.number("Debe ser un número"),
		items: Yup.array()
			.required("La factura debe contener al menos un item")
			.of(
				Yup.object().shape({
					concept: Yup.string().required("Concepto obligatorio"),
					price: Yup.number().required("Introduce precio"),
					quantity: Yup.number().min(1, "Minima cantidad es 1").required("Debes introducir cantidad")
				})
			)
	});
};

export const getQuoteValidationSchema = () => {
	return Yup.object({
		quote_number: Yup.string().required("Debes añadir identificador de presupuesto"),
		date: Yup.date().required(),
		expiring_date: Yup.date().when(
			"date",
			(date, schema) => date && schema.min(date, "No puede ser anterior a la fecha de la factura")
		),
		client: Yup.number().required("Debes seleccionar un cliente"),
		discount: Yup.number("Debe ser un número"),
		status: Yup.string(),
		items: Yup.array()
			.required("El presupuesto debe contener al menos un item")
			.of(
				Yup.object().shape({
					concept: Yup.string().required("Concepto obligatorio"),
					price: Yup.number().required("Introduce precio"),
					quantity: Yup.number().min(1, "Minima cantidad es 1").required("Debes introducir cantidad")
				})
			)
	});
};
