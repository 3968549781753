import { useState } from "react";
import {
	Button,
	useToast,
	Flex,
	Text,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerBody,
	DrawerFooter
} from "@chakra-ui/react";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../../../common/forms/InputField";
import { FORM_COLOR_CHOICES, PHONE_REG_EXP } from "../../../../common/consts";
// icons
import { FaRegUser } from "react-icons/fa6";
import { MdOutlinePhone, MdOutlineEmail } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateWorker } from "../../../../../api/businessesApi";

export default function WorkerForm({ onClose, isOpen, item: worker }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const [color, setColor] = useState(worker ? worker.color : "");

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateWorker,
		onSuccess: () => {
			toast({ title: "Coche creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["cars"]);
			QueryClient.refetchQueries("cars", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const initialValues = {
		name: worker?.name || "",
		position: worker?.position || "",
		phone: worker?.phone || "",
		email: worker?.email || "",
		color: worker?.color || ""
	};

	const validationSchema = Yup.object({
		name: Yup.string("No tiene un formato válido").required("El nombre es obligatorio"),
		position: Yup.string("No tiene un formato válido"),
		email: Yup.string().email("No tiene un formato válido"),
		phone: Yup.string().matches(PHONE_REG_EXP, "Teléfono no válido").min(9)
	});

	const isDisabled = (formik) => {
		return (
			JSON.stringify(formik.errors) !== "{}" ||
			(JSON.stringify(formik.touched) === "{}" && JSON.stringify(formik.values) === "{}")
		);
	};

	const submit = (values) => {
		let payload = {};
		if (worker) {
			payload = {
				data: values,
				slug: worker.id,
				token: authHeader()
			};
		} else {
			payload = {
				data: values,
				token: authHeader()
			};
		}
		payload.data.color = color;
		mutate(payload);
	};
	return (
		<>
			<Drawer isOpen={isOpen} placement='right' onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{worker ? "Editar" : "Crear"} Trabajador</DrawerHeader>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => submit(values)}
					>
						{(formik) => (
							<>
								<DrawerBody>
									<Flex direction='column' as='form' gap='1.5em'>
										<InputField
											label='Nombre(*)'
											icon={<FaRegUser color='black' size='18px' />}
											name='name'
											error={error?.response?.data?.name}
										/>
										<InputField
											label='Cargo'
											icon={<GrUserWorker color='black' size='20px' />}
											name='position'
											GrUserWorker
											error={error?.response?.data?.position}
										/>
										<InputField
											label='Email'
											icon={<MdOutlineEmail color='black' size='20px' />}
											type='email'
											name='email'
											error={error?.response?.data?.email}
										/>
										<InputField
											label='Teléfono'
											icon={<MdOutlinePhone color='black' size='20px' />}
											name='phone'
											error={error?.response?.data?.email}
										/>
										<Flex align='center' gap='1em'>
											<Text>Color</Text>
											<Flex bg={color} boxSize='25px' rounded='md' />
										</Flex>
										<Flex align='center' justify='space-between' h='30px'>
											{FORM_COLOR_CHOICES.map((color) => (
												<Flex
													bg={color}
													boxSize='25px'
													rounded='md'
													cursor='pointer'
													key={color}
													_hover={{ boxSize: "30px" }}
													onClick={() => setColor(color)}
												/>
											))}
										</Flex>
									</Flex>
								</DrawerBody>
								<DrawerFooter>
									<Flex justify='right' columnGap='3' mt='3'>
										<Button variant='white' onClick={onClose}>
											Cancelar
										</Button>
										<Button
											variant='primary'
											isDisabled={isDisabled(formik)}
											onClick={formik.handleSubmit}
											isLoading={isLoading}
										>
											Guardar
										</Button>
									</Flex>
								</DrawerFooter>
							</>
						)}
					</Formik>
				</DrawerContent>
			</Drawer>
		</>
	);
}
