import { Flex, Select, IconButton } from '@chakra-ui/react'
// icons
import { GrDescend, GrAscend } from 'react-icons/gr'

export default function OrderSortBySelect ({ sortBy, setSortBy, ordering, setOrdering }) {
    const CHOICES = [
        {
            label: 'Creada',
            value: 'created_at'
        },
        {
            label: 'Editada',
            value: 'updated_at'
        },
        {
            label: 'Fecha entrada',
            value: 'date_in'
        },
        {
            label: 'Fecha salida',
            value: 'date_out'
        }
    ]
    const handleOrdering = () => {
        ordering === 'desc' ? setOrdering('asc') : setOrdering('desc')
    }

    return (
        <Flex align='center' gap='0.2em' >
            <Select bg='white'
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
            >
                {
                    CHOICES.map((orderChoice) => (
                        <option value={orderChoice.value} key={orderChoice.value} >
                            {orderChoice.label}
                        </option>
                    ))
                }
            </Select>
            <IconButton
                onClick={handleOrdering}
                icon={ordering === 'desc'
                    ? <GrDescend color='#001234' size='20px' />
                    : <GrAscend color='#001234' size='20px'/>
                }
            />
        </Flex>
    )
}
