import { IconButton } from '@chakra-ui/react'
// icon
import { AiOutlineEdit } from 'react-icons/ai'

export default function EditItemButton ({ editFn, item }) {
    return (
        <IconButton
            icon={<AiOutlineEdit size="22px" color="blue" />}
            bg="transparent"
            onClick={() => editFn(item)}
        />
    )
}
