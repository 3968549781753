import {
    useDisclosure,
    Button,
    IconButton,
    Image,
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton
    , Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow
} from '@chakra-ui/react'

import { useLocation } from 'react-router-dom'
// img
import andres from '../../img/tutorial/Andres.png'
// icons
import { AiOutlineClose } from 'react-icons/ai'
// comps
import DashboardTutorial from './dashboard/DashboardTutorial'
import OrderTutorial from './orders/OrderTutorial'
import SupportContactEmail from './SupportContactEmail'
import InvoiceTutorial from './invoices/InvoiceTutorial'
import StockTutorial from './stock/StockTutorial'
import DataTutorial from './data/DataTutorial'

export default function HelperModal () {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const location = useLocation()

    const closeAllModals = () => {
        onClose()
    }

    return (
        <>
            <Popover>
                <PopoverTrigger>
                    <Button
                        position="fixed"
                        right={{ sm: '1em' }}
                        bottom={{ sm: '1em' }}
                        zIndex={1000}
                        p="2em"
                        bg="white"
                        rounded="full"
                        sx={{
                            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        <Image src={andres} />
                    </Button>
                </PopoverTrigger>
                <PopoverContent rounded="full" p="40px" w="500px" shadow="md">
                    <PopoverArrow />
                    <PopoverBody>
                        <Flex align="center" gap="2em">
                            <Image src={andres} />
                            <Flex direction="column" gap="1em">
                                <Flex align="center" justify="space-between">
                                    <Text>¿En que puedo ayudarte?</Text>
                                    <IconButton icon={<AiOutlineClose color="black" />} />
                                </Flex>
                                <Flex align="center" gap="1em">
                                    <SupportContactEmail
                                        onClose={onClose}
                                    />
                                    <Button variant="primary" onClick={onOpen}>
                                        Ver tutorial
                                    </Button>
                                </Flex>
                            </Flex>
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            <Modal isOpen={isOpen} onClose={closeAllModals}>
                <ModalOverlay onClick={closeAllModals} />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        {location.pathname === '/dashboard' && <DashboardTutorial onClose={closeAllModals} />}
                        {location.pathname === '/ordenes' && <OrderTutorial onClose={closeAllModals} />}
                        {location.pathname === '/facturas' && <InvoiceTutorial onClose={closeAllModals} />}
                        {location.pathname === '/stock' && <StockTutorial onClose={closeAllModals} />}
                        {location.pathname === '/datos' && <DataTutorial onClose={closeAllModals} />}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
