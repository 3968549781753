import { Flex, Text } from "@chakra-ui/react";
import moment from "moment";
//fns
import { formatDecimals } from "../../../common/functions";

export default function PurchaseCard({ purchase }) {
	if (purchase) {
		return (
			<Flex w='100%' bg='lightgrey' direction='column' rounded='xl' p='1rem' gap='1rem'>
				<Flex justify='space-between'>
					<Text maxW='70%' color='darkblue' fontWeight='semibold'>
						{purchase.concept || purchase.item.name}{" "}
					</Text>
					<Text fontSize='14px'>{moment(purchase.purchased_at).format("L")}</Text>
				</Flex>
				<Flex justify='space-between' w='100%'>
					<Flex gap='0.5rem'>
						<Text>Qty:</Text>
						<Text color='darkblue' fontWeight='semibold'>
							{formatDecimals(purchase.quantity)}
						</Text>
					</Flex>
					<Flex gap='0.5rem'>
						<Text>Precio:</Text>
						<Text color='darkblue' fontWeight='semibold'>
							{purchase.price}€
						</Text>
					</Flex>
					<Flex gap='0.5rem'>
						<Text>IVA:</Text>
						<Text color='darkblue' fontWeight='semibold'>
							{purchase.taxes}€
						</Text>
					</Flex>
					<Flex gap='0.5rem'>
						<Text>Total:</Text>
						<Text color='darkblue' fontWeight='semibold'>
							{purchase.total}€
						</Text>
					</Flex>
				</Flex>
			</Flex>
		);
	}
}
