import { createApi, getAll, getAllPag, createUpdate, deleteItem } from './baseApi'

const inspectionsApi = createApi('inspections')

export const getAllInspections = async (payload) => {
    return getAll(inspectionsApi, payload)
}

export const getAllInspectionsPag = async (payload) => {
    return getAllPag(inspectionsApi, payload)
}

export const getInspectionFromOrder = async (payload) => {
    const before = payload.isBefore ? 'True' : 'False'
    const slug = `/?zone=${payload.zone}&before=${before}&order=${payload.order}`
    const response = await inspectionsApi.get(slug, {
        headers: { Authorization: payload.token }
    })
    return response.data
}

export const createUpdateInspection = async (payload) => {
    let response = {}
    const headers = {
        Authorization: payload.token,
        'Content-Type': 'multipart/form-data'
    }
    if (payload.slug) {
        response = await inspectionsApi.put(`/${payload.slug}/`, payload.data, {
            headers
        })
    } else {
        response = await inspectionsApi.post('/', payload.data, {
            headers
        })
    }
    return response.data
}

export const deleteInspection = async (payload) => {
    return deleteItem(inspectionsApi, payload)
}
