import { Flex, useMediaQuery } from "@chakra-ui/react";
// comps
import CalendarPicker from "./CalendarPicker";
import ReservationHourPicker from "./ReservationHourPicker";

export default function TimeStep({
	lDays,
	daysError,
	days,
	day,
	setDay,
	month,
	setMonth,
	hour,
	hours,
	setHour,
	lHours
}) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	// const lHours = true
	return (
		<Flex direction={"column"} gap='1em' w='100%' justify='space-evenly' align='center'>
			<CalendarPicker
				unavailableDays={days}
				day={day}
				setDay={setDay}
				month={month}
				setMonth={setMonth}
				error={daysError}
				isloading={lDays}
			/>
			<ReservationHourPicker isloading={lHours} hours={hours} hour={hour} day={day} setHour={setHour} />
		</Flex>
	);
}
