import { Flex, useMediaQuery } from "@chakra-ui/react";
// comps
import UserActionsTable from "../../components/admin/actions/UserActionsTable";
import LoginRegisterLogTable from "../../components/admin/actions/LoginRegisterLogTable";

export default function UserActions() {
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<Flex
				bg='white'
				rounded='xl'
				gap='1em'
				p='2em'
				mt='20px'
				justifySelf='center'
				alignSelf='center'
				direction='column'
			>
				<UserActionsTable />
				<LoginRegisterLogTable />
			</Flex>
		);
	}
	return (
		<Flex rounded='xl' p='1em' mt='20px' justifySelf='center' alignSelf='center' direction='column'>
			<UserActionsTable />
		</Flex>
	);
}
