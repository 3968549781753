import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Flex, Text, Box } from "@chakra-ui/react";

// fns
import { formatDecimals } from "../../../common/functions";

export default function ExpensesBreakdown({ title, data, color }) {
	const isEmpty = data?.list?.length < 1;
	return (
		<AccordionItem isDisabled={isEmpty}>
			<AccordionButton>
				<Box ml='-20px' mr='1em' boxSize='15px' rounded='full' bg={color || "lightgray"} />
				<Text as='span' flex='1' textAlign='left' fontWeight='bold'>
					{title}
				</Text>
				<Text fontWeight='bold' ml='2em'>
					{formatDecimals(data?.total)}€
				</Text>
				<AccordionIcon />
			</AccordionButton>
			{!isEmpty && (
				<AccordionPanel pb={4}>
					{data?.list?.map((expense) => (
						<Flex key={expense.id} w='100%' justify='space-between' gap='1em'>
							<Text> {expense.item?.name || expense.name || expense.concept} </Text>
							<Text>{formatDecimals(expense.item?.total || expense.total)}€</Text>
						</Flex>
					))}
				</AccordionPanel>
			)}
		</AccordionItem>
	);
}
