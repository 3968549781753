import { StyleSheet, Font } from "@react-pdf/renderer";
// fnts
import poppinsRegular from "./fonts/Poppins-Regular.ttf";
import poppinsMedium from "./fonts/Poppins-Medium.ttf";
import poppinsSemiBold from "./fonts/Poppins-SemiBold.ttf";

Font.register({
	family: "Poppins",
	fonts: [
		{ src: poppinsRegular },
		{ src: poppinsMedium, fontWeight: "medium" },
		{ src: poppinsSemiBold, fontWeight: "semibold" }
	]
});

export const styles = StyleSheet.create({
	page: {
		flexDirection: "column",
		backgroundColor: "white",
		fontSize: "12px",
		paddingHorizontal: "35px",
		fontFamily: "Poppins"
	},
	ticket: {
		paddingHorizontal: "0",
		paddingVertical: "10px",
		fontFamily: "Poppins",
		fontSize: "10px"
	},
	documentHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		paddingVertical: "10",
		marginTop: "25px",
		alignItems: "center",
		color: "black",
		fontSize: "14px"
	},
	invoiceHeader: {
		paddingHorizontal: "10",
		paddingVertical: "10",
		marginTop: "30px",
		backgroundColor: "#F7F7F7",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		color: "black",
		fontSize: "11px",
		borderRadius: "5px"
	},
	invoiceRow: {
		paddingHorizontal: "10",
		fontSize: "10px",
		marginTop: "5px",
		paddingVertical: "6px",
		flexDirection: "row",
		justifyContent: "space-between",
		fontWeight: "medium"
	},
	evenRow: {
		backgroundColor: "#F4F4F9",
		borderRadius: "5px"
	},
	car: {
		backgroundColor: "lightgrey",
		flexDirection: "row",
		padding: 20
	},
	row: {
		flexDirection: "row"
	},
	column: {
		flexDirection: "column",
		gap: "10px"
	},
	bold: {
		fontWeight: 900
	},
	heading: {
		fontSize: "18px"
	},
	resumeRow: {
		flexDirection: "row",
		fontSize: "12px",
		justifyContent: "space-between",
		width: "250px"
	},
	resumeRowTicket: {
		flexDirection: "row",
		alignSelf: "flex-end",
		fontSize: "8px",
		justifyContent: "space-between",
		width: "75%"
	}
});
