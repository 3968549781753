import { Flex, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";
// comps
import OrdersChart from "./orders/OrdersChart";
import ComparisonStatBox from "./ComparisonStatBox";
import ServicesPieChart from "./orders/ServicesPieChart";
// icons
import { LuGhost } from "react-icons/lu";
import { FiUser } from "react-icons/fi";
import { BiCar } from "react-icons/bi";
import { BsSticky } from "react-icons/bs";

export default function OrderStats({ data, period }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	if (!isMobile) {
		return (
			<Grid
				pt='2em'
				pb='4em'
				px='4em'
				w='100%'
				h='86vh'
				templateRows='repeat(4, 1fr)'
				templateColumns='repeat(4, 1fr)'
				gap={6}
			>
				<GridItem rowSpan={3} colSpan={3}>
					<OrdersChart data={data?.orders} />
				</GridItem>
				<GridItem rowSpan={3} colSpan={1}>
					<ServicesPieChart data={data?.services_presence} />
				</GridItem>
				<GridItem colSpan={1}>
					<ComparisonStatBox
						title='Clientes nuevos'
						icon={<BsSticky size='25px' />}
						data={data?.clients}
						period={period}
					/>
				</GridItem>
				<GridItem colSpan={1}>
					<ComparisonStatBox
						title='Ausencias'
						icon={<LuGhost size='25px' />}
						data={data?.absences}
						period={period}
						isReverse
					/>
				</GridItem>
				<GridItem colSpan={1}>
					<ComparisonStatBox title='Coches' icon={<BiCar size='25px' />} data={data?.cars} period={period} />
				</GridItem>
				<GridItem colSpan={1}>
					<ComparisonStatBox
						title='Clientes recurrentes'
						icon={<FiUser size='25px' />}
						data={data?.recurrent_clients}
						period={period}
					/>
				</GridItem>
			</Grid>
		);
	}
	return (
		<Flex direction='column' gap='1em' p='2em'>
			<OrdersChart data={data?.orders} />
			<ServicesPieChart data={data?.services_presence} />
			<ComparisonStatBox
				title='Citas totales'
				icon={<BsSticky size='25px' />}
				data={data?.clients}
				period={period}
			/>
			<ComparisonStatBox
				title='Ausencias'
				icon={<LuGhost size='25px' />}
				data={data?.absences}
				period={period}
				isReverse
			/>
			<ComparisonStatBox title='Coches' icon={<BiCar size='25px' />} data={data?.cars} period={period} />
			<ComparisonStatBox
				title='Clientes recurrentes'
				icon={<FiUser size='25px' />}
				data={data?.recurrent_clients}
				period={period}
			/>
		</Flex>
	);
}
