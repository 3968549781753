import { useState } from "react";
import {
	Flex,
	Text,
	Button,
	useDisclosure,
	Input,
	Checkbox,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton
} from "@chakra-ui/react";
import moment from "moment";
// icons
import { FaWhatsapp } from "react-icons/fa";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import { useQueryClient } from "@tanstack/react-query";
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { createUpdateClient } from "../../../../api/clientsApi";
import { getBusinessProfile } from "../../../../api/businessesApi";

export default function WhatsappConsentModal({ client, isFromTable, formSignedAt, setFormSignedAt, size }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const [name, setName] = useState();
	const [readTerms, setReadTerms] = useState(false);
	const [signedAt, setSignedAt] = useState();

	const disableSubmit = () => !(name && signedAt && readTerms);
	const { data: businessProfile } = useAuthFetch(["businessProfile"], getBusinessProfile);
	const { isLoading, mutate } = useAuthMutation({
		mutationFn: createUpdateClient,
		onSuccess: () => {
			toast({ title: "Consentimiento firmado!", status: "success" });
			QueryClient.invalidateQueries(["clients"]);
			QueryClient.refetchQueries("clients", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const closeModal = () => {
		setName();
		setReadTerms(false);
		setSignedAt();
		onClose();
	};
	const signTerms = () => {
		if (isFromTable) {
			const formattedDate = new Date(signedAt).toISOString(); // Formatear la fecha a ISO 8601

			const payload = {
				data: {
					name: client.name,
					consent_signed_at: formattedDate // Usar la fecha formateada
				},
				slug: client.id,
				token: authHeader()
			};

			mutate(payload);
		} else {
			setFormSignedAt(signedAt);
			onClose();
		}
	};

	return (
		<>
			{client && client?.consent_signed_at ? (
				<Text color='green.500'>
					Firmado {!isFromTable && moment(client?.consent_signed_at).format("l HH:MM")}{" "}
				</Text>
			) : (
				<Button
					variant='white'
					size={size || "md"}
					isDisabled={signedAt !== undefined}
					onClick={onOpen}
					maxW='100px'
					h='30px'
				>
					{formSignedAt ? "Firmado" : "Firmar"}
				</Button>
			)}
			<Modal isOpen={isOpen} onClose={closeModal} size='4xl'>
				<ModalOverlay />
				<ModalContent bg='white' maxH='85dvh' overflowY='scroll'>
					<ModalHeader>
						<Flex align='center' gap='0.5em'>
							<FaWhatsapp color='black' size='20px' />
							<Text fontSize='20px'>
								Consentimiento para el Uso de WhatsApp como Medio de Comunicación
							</Text>
						</Flex>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody py='1em'>
						<Flex direction='column'>
							<Text>
								Estimado/a cliente, <br /> <br />
								Entiendo y consiento que{" "}
								<span style={{ fontWeight: "bold" }}> {businessProfile?.name}</span> utilice WhatsApp
								como medio de comunicación adicional para enviarme mensajes relacionados con mis citas y
								servicios. <br /> <br />
								Al otorgar este consentimiento: <br />
								1. Acepto recibir mensajes a través de WhatsApp de{" "}
								<span style={{ fontWeight: "bold" }}> {businessProfile?.name}.</span> <br />
								2. Reconozco que estos mensajes serán enviados exclusivamente por{" "}
								<span style={{ fontWeight: "bold" }}> {businessProfile?.name}</span>
								<br />
								3. Comprendo que <span style={{ fontWeight: "bold" }}> {businessProfile?.name}</span> se
								compromete a cumplir con todas las leyes y regulaciones locales relacionadas con la
								gestión de datos personales. <br />
								<br />
								Entiendo que este consentimiento es voluntario y que tengo el derecho de retirarlo en
								cualquier momento, notificando a{" "}
								<span style={{ fontWeight: "bold" }}> {businessProfile?.name}</span> por escrito o a
								través de WhatsApp con la palabra "STOP".
							</Text>
							<Flex direction='column' mt='2em' gap='0.5em'>
								<Text fontWeight='bold'>Nombre Completo</Text>
								<Input onChange={(e) => setName(e.target.value)} />
							</Flex>
							<Flex direction='column' mt='1em' gap='0.5em'>
								<Text fontWeight='bold'>Fecha y Hora</Text>
								<Input type='datetime-local' onChange={(e) => setSignedAt(e.target.value)} />
							</Flex>
							<Checkbox
								mt='2em'
								color='black'
								value={readTerms}
								onChange={() => setReadTerms(!readTerms)}
							>
								Confirmo que he leído los términos y condiciones
							</Checkbox>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<Button
							variant='primary'
							size='md'
							onClick={signTerms}
							isDisabled={disableSubmit()}
							isLoading={isLoading}
						>
							Firmar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
