import { useState, useMemo, useCallback } from "react";
import {
	useToast,
	useDisclosure,
	Flex,
	Text,
	Box,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from "@chakra-ui/react";
// comps
import TableMobile from "../../../../common/Tables/Mobile/TableMobile";
import StockForm from "../../forms/StockForm";
import PopoverDelete from "../../../../common/PopoverDelete";
import EditItemButton from "../../../../common/Tables/EditItemButton";
// fns
import { formatDecimals } from "../../../../common/functions";
// icons
import { AiOutlineShop } from "react-icons/ai";
import { PiChatTeardropTextBold } from "react-icons/pi";
import { MdOutlineInventory2 } from "react-icons/md";
// img
import emptyStock from "../../../../../img/stock/emptyStock.png";
// api
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { useQueryClient } from "@tanstack/react-query";
import { getAllStockPag, deleteStock } from "../../../../../api/stockApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function StockTableMobile() {
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [stock, setStock] = useState();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data, isLoading } = useAuthFetch(["stock", page], getAllStockPag, page);

	const { mutate, isLoading: ld } = useAuthMutation({
		mutationFn: deleteStock,
		onSuccess: () => {
			toast({ title: "Borrado con exito!", status: "success" });
			QueryClient.invalidateQueries(["stock"]);
			QueryClient.refetchQueries("stock", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const deleteItem = useCallback(
		(id) => {
			mutate({ slug: id, token: authHeader() });
		},
		[authHeader, mutate]
	);
	const openEdit = (item) => {
		setStock(item);
		onOpen();
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((stock) => (
				<AccordionItem key={stock.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Box as='span' flex='1' textAlign='left' fontSize='14px' color='darkblue'>
							{stock.name}
						</Box>
						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex
							w='100%'
							h='100%'
							direction='column'
							gap='1em'
							justify='space-between'
							fontSize='14px'
							p='0.5em'
						>
							<Flex gap='0.5em' align='center'>
								<PiChatTeardropTextBold size='15px' color='black' />
								<Text>{stock.description || "-- --"}</Text>
							</Flex>
							{stock.reference && <Text>Ref {stock.reference}</Text>}
							<Flex gap='0.5em' align='center'>
								<MdOutlineInventory2 size='18px' color='black' />
								<Text color={stock.available_quantity > 0 ? "green.500" : "red"}>
									{formatDecimals(stock.available_quantity)} {stock.unit_of_measurement} disponibles
								</Text>
							</Flex>
							<Flex gap='0.5em' align='center'>
								<AiOutlineShop size='18px' color='black' />
								<Text>{stock.supplier?.name || "-- --"}</Text>
							</Flex>
							<Flex w='100%' justify='end' mt='-3em'>
								<EditItemButton editFn={openEdit} item={stock} />
								<PopoverDelete deleteFn={deleteItem} slug={stock.id} isLoading={ld} />
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data, deleteItem, ld, onOpen]
	);
	const openForm = () => {
		setStock();
		onOpen();
	};
	return (
		<>
			<TableMobile
				data={data}
				tableData={tableData}
				openFormFn={openForm}
				isLoading={isLoading}
				emptyImg={emptyStock}
				label='Órden'
				page={page}
				setPage={setPage}
			/>
			<StockForm item={stock} onClose={onClose} isOpen={isOpen} />
		</>
	);
}
