import { useState, useMemo, useCallback } from "react";
import {
	useToast,
	useDisclosure,
	Flex,
	Text,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from "@chakra-ui/react";
import moment from "moment";
// comps
import TableMobile from "../../../common/Tables/Mobile/TableMobile";
import QuoteStatusBadge from "../QuoteStatusBadge";
import QuoteModal from "../forms/QuoteModal";
import PopoverDelete from "../../../common/PopoverDelete";
import EditItemButton from "../../../common/Tables/EditItemButton";
// icons
import { LuUser } from "react-icons/lu";
// img
import emptyInvoices from "../../../../img/invoicing/emptyInvoices.png";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { getAllQuotesPag, deleteQuote } from "../../../../api/quotesApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function QuotesTableMobile() {
	const toast = useToast();
	const [quote, setQuote] = useState();
	const { isOpen: isOpenForm, onOpen: onOpenForm, onClose: onCloseForm } = useDisclosure();

	const [page, setPage] = useState(1);
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data, isLoading } = useAuthFetch(["quotes", page], getAllQuotesPag, page);

	const { mutate, isLoading: ld } = useAuthMutation({
		mutationFn: deleteQuote,
		onSuccess: () => {
			toast({ title: "Borrado con exito!", status: "success" });
			QueryClient.invalidateQueries(["quotes"]);
			QueryClient.refetchQueries("quotes", { force: true });
			onCloseForm();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const deleteItem = useCallback(
		(id) => {
			mutate({ slug: id, token: authHeader() });
		},
		[authHeader, mutate]
	);
	const openEdit = (item) => {
		setQuote(item);
		onOpenForm();
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((quote) => (
				<AccordionItem key={quote.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Flex w='100%' align='center' justify='space-between' fontSize='14px' pr='1em'>
							<Text>{quote.quote_number}</Text>
							<Text>{quote.total}€</Text>
							<QuoteStatusBadge status={quote.status} />
						</Flex>
						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex
							w='100%'
							h='100%'
							direction='column'
							gap='1em'
							justify='space-between'
							fontSize='14px'
							p='0.5em'
						>
							<Flex gap='0.5em' align='center'>
								<LuUser size='15px' color='black' />
								<Text>{quote.client.name}</Text>
							</Flex>
							<Text>Fecha {quote.date ? moment(quote.date).format("l") : " -- --"}</Text>
							<Text>IVA: {quote.taxes}</Text>
							<Text>
								<span style={{ fontWeight: "bold" }}>Total:</span> {quote.total}€
							</Text>
							<Flex w='100%' justify='end' mt='-2em'>
								<EditItemButton editFn={openEdit} item={quote} />
								<PopoverDelete deleteFn={deleteItem} slug={quote.id} isLoading={ld} />
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data, onOpenForm, deleteItem, ld]
	);
	const openForm = () => {
		setQuote();
		onOpenForm();
	};

	return (
		<>
			<QuoteModal item={quote} onClose={onCloseForm} isOpen={isOpenForm} />
			<TableMobile
				data={data}
				tableData={tableData}
				openFormFn={openForm}
				isLoading={isLoading}
				emptyImg={emptyInvoices}
				label='Presupuesto'
				page={page}
				setPage={setPage}
			/>
		</>
	);
}
