import { Fragment, useState } from 'react'
import { Flex, Button, Text, IconButton, useDisclosure } from '@chakra-ui/react'
// comps
import BusinessHoursForm from './BusinessHoursForm'
import PopoverDelete from '../../../../common/PopoverDelete'
// icons
import { AiOutlineEdit } from 'react-icons/ai'
// api
import { deleteBusinessHours } from '../../../../../api/businessesApi'

export default function BusinessHoursTableMobile ({ businessHours }) {
    const [businessHour, setBusinessHour] = useState()
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Flex w='100%' h='100%' p='1em' direction='column' >
                <Button variant='primary' size='sm' onClick={onOpen} >
                + Horario
                </Button>
                {businessHours &&
            <Flex direction='column' mt='1em' gap='0.5em' maxH='90%' overflowY='scroll' pr='1em'
                sx={{
                    '&::-webkit-scrollbar': { width: '5px' },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f5f5f5'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#cfcfcf'
                    }
                }}
            >
                {businessHours.map((businessHour) => (
                    <Fragment key={businessHour.id}>
                        <Flex bg='white' w='100%' p='0.5em' rounded='lg' align='center' justify='space-between' >
                            <Text>
                                {businessHour.day_display}
                            </Text>
                            <Flex align='center' gap='0.5em' >
                                {businessHour.is_closed
                                    ? <Text>Cerrado</Text>
                                    : <Text>
                                        {businessHour.opening_time?.replace(/:\d{2}$/, '')} - {businessHour.closing_time?.replace(/:\d{2}$/, '')}
                                    </Text>
                                }
                                <IconButton
                                    icon={<AiOutlineEdit size="20px" color="#0258FF" />}
                                    bg="transparent"
                                    onClick={() => {
                                        setBusinessHour(businessHour)
                                        onOpen()
                                    }}
                                />
                                <PopoverDelete
                                    deleteFn={deleteBusinessHours}
                                    deleteKey={['deleteBusinessHours']}
                                    slug={businessHour?.id}
                                    refetchKey={['landingBusinessHours']}
                                />
                            </Flex>
                        </Flex>
                    </Fragment>
                ))}
            </Flex>
                }
            </Flex>
            <BusinessHoursForm
                businessHour={businessHour}
                isOpen={isOpen}
                onClose={onClose}
            />

        </>
    )
}
