import axios from "axios";
import { createApi, getAll, getAllPag, createUpdate, deleteItem } from "./baseApi";

const quotesApi = createApi("estimations/quotes");

export const getQuoteDetail = async (uuid) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}estimations/quote/${uuid}`);
	return response.data;
};

export const getNextQuoteID = async (payload) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}estimations/next-quote-id`, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};
export const getQuoteOptions = async (payload) => {
	const response = await quotesApi.options("/", {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const getAllQuotes = async (payload) => {
	return getAll(quotesApi, payload);
};
export const getAllQuotesPag = async (payload) => {
	return getAllPag(quotesApi, payload);
};
export const createUpdateQuote = async (payload) => {
	return createUpdate(quotesApi, payload);
};
export const deleteQuote = async (payload) => {
	return deleteItem(quotesApi, payload);
};
