import { FormControl, FormErrorMessage, Textarea, FormLabel, Flex } from '@chakra-ui/react'
import { Field, useField } from 'formik'

// Factorizacion del input de texto en los formularios
export default function TextareaField ({ label, icon, ...props }) {
    // hook de formik para obtener el field data y los errors y touched de cada field
    const [field, meta] = useField(props)
    return (
        <FormControl isInvalid={(meta.error && meta.touched) || props.error?.length > 0}>
            <FormLabel color="black">
                <Flex align="center" gap="0.5em">
                    {icon}
                    {label}
                </Flex>
            </FormLabel>
            <Field
                as={Textarea}
                {...field} // aqui van los props de onblur, on change
                {...props} // props que pasamos como placeholder etc
            />
            <FormErrorMessage> {meta.error} </FormErrorMessage>
            <FormErrorMessage> {props.error} </FormErrorMessage>
        </FormControl>
    )
}
