import { Flex, Button, Accordion, Image, Text } from "@chakra-ui/react";
// comps
import PaginationMenu from "../PaginationMenu";
import LoadingOverlay from "../../loading/LoadingOverlay";

export default function TableMobile({
	data,
	tableData,
	openFormFn,
	isLoading,
	emptyImg,
	label,
	page,
	setPage,
	withoutButton
}) {
	const isEmpty = data?.count < 1;

	return (
		<>
			<Flex direction='column' gap='1em' h='100%'>
				{!withoutButton && (
					<Flex align='center' w='100%' justify='end' mt='-2.6rem'>
						<Button variant='primary' size='sm' onClick={openFormFn}>
							+
						</Button>
					</Flex>
				)}
				<Flex
					direction='column'
					gap='0.5em'
					w='100%'
					pr='5px'
					sx={{
						flexGrow: 1,
						overflowY: "auto",
						maxH: "72dvh",
						"&::-webkit-scrollbar": { width: "5px" },
						"&::-webkit-scrollbar-track": {
							backgroundColor: "#f5f5f5"
						},
						"&::-webkit-scrollbar-thumb": {
							backgroundColor: "#cfcfcf"
						}
					}}
				>
					<Accordion allowMultiple>{tableData}</Accordion>
				</Flex>
				{isEmpty && (
					<Flex align='center' py='4em' direction='column'>
						<Image src={emptyImg} />
						<Text mt='2em'>Aún no hay ningúna orden creada</Text>
						<Flex flexWrap='wrap' justify='center'>
							<Text>Haz click en</Text>
							<Text color='blue' fontWeight='bold' mx='0.2em'>
								+ {label}
							</Text>
							<Text>para crear una nueva</Text>
						</Flex>
					</Flex>
				)}
			</Flex>
			<PaginationMenu total={data?.count} next={data?.next} prev={data?.previous} page={page} setPage={setPage} />
			<LoadingOverlay isLoading={isLoading} />
		</>
	);
}
