import { useState } from 'react'
import { Text, Flex, Image, Button } from '@chakra-ui/react'
// comps
import TutorialPagination from '../TutorialPagination'
// img
import step1 from '../../../img/tutorial/invoices/Tutorial_Facturas_1.png'
import step2 from '../../../img/tutorial/invoices/Tutorial_Facturas_2.png'
import step3 from '../../../img/tutorial/invoices/Tutorial_Facturas_3.png'
import step4 from '../../../img/tutorial/invoices/Tutorial_Facturas_4.png'
import step5 from '../../../img/tutorial/invoices/Tutorial_Facturas_5.png'
import andres from '../../../img/tutorial/Andres.png'

export default function InvoiceTutorial ({ onClose }) {
    const [step, setStep] = useState(0)

    const slides = [
        {
            title: (
                <Flex flexWrap="wrap" justify="center">
                    <Text>¡Te acompaño a crear tu primera</Text>
                    <Text color="blue" fontWeight="bold" mx="0.2em">
                        factura
                    </Text>
                    <Text>!</Text>
                </Flex>
            ),
            image: step1
        },
        {
            title: (
                <Text align="center">
                    Busca al <span style={{ fontWeight: 'bold' }}> cliente </span> que quieras facturar y añade las{' '}
                    <span style={{ fontWeight: 'bold' }}> fechas</span>
                </Text>
            ),
            image: step2
        },
        {
            title: (
                <Text align="center">
                    El <span style={{ fontWeight: 'bold' }}> Nº Factura </span> se pondrá por defecto, puedes
                    configurarlo desde el panel de <span style={{ fontWeight: 'bold' }}> Ajustes</span>
                </Text>
            ),
            image: step3
        },
        {
            title: (
                <Text align="center">
                    Añade <span style={{ fontWeight: 'bold' }}> items </span> a tu factura. Define el concepto, precio,
                    cantidad y selecciona un impuesto. Añade, edita y elimina tantos items como quieras
                </Text>
            ),
            image: step4
        },
        {
            title: (
                <Text align="center">
                    Edita tu factura y modifica su estado. Elimínala o visualiza y expórtala en PDF
                </Text>
            ),
            image: step5
        },
        {
            title: (
                <Text align="center">
                    Los <span style={{ fontWeight: 'bold' }}> Presupuestos </span> siguen un mecanismo muy similar.
                    ¡Empieza a crear tus primeras facturas y presupuestos ahora!
                </Text>
            ),
            image: andres
        }
    ]
    const slide = slides[step]
    return (
        <>
            <Flex direction="column" gap="1em" justify="center" align="center" p="1em" h="550px">
                {(step === 0 || step === slides.length - 1) && <Image src={andres} />}
                {slide.title}
                {step !== slides.length - 1 && <Image src={slide.image} />}
                {step === slides.length - 1 && (
                    <Button onClick={onClose} variant="primary" mt="2em">
                        Terminar Guía
                    </Button>
                )}
            </Flex>
            <TutorialPagination step={step} slides={slides} setStep={setStep} />
        </>
    )
}
