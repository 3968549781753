import { Tag, TagLabel } from '@chakra-ui/react'

export default function InvoiceStatusBadge ({ status }) {
    let color = ''
    let bg = ''
    let label = ''
    if (status === 'pending' || status === 'Pendiente') {
        color = 'orange'
        bg = 'orange.100'
        label = 'Pendiente'
    }
    if (status === 'payed' || status === 'Pagada') {
        color = '#42C74F'
        bg = '#42C74F1A'
        label = 'Pagada'
    }
    if (status === 'expired' || status === 'Caducada') {
        color = '#F44E4E'
        bg = '#F44E4E1A'
        label = 'Caducada'
    }
    return (
        <Tag bg={bg} rounded="xl" p="0.5em">
            <TagLabel color={color}>&#x2022; {label}</TagLabel>
        </Tag>
    )
}
