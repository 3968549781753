export const FORM_COLOR_CHOICES = [
	"#749FF2", // hover_blue
	"#F39F76", // melocoton
	"#FFF8B8", // yellow
	"#EFEFF1", // grey
	"#88dd90", // green
	"#E2F6D3", // menta
	"#AECCDC", // blue
	"#D3BFDB" // purple
];

export const PHONE_REG_EXP =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const NIF_CIF_REGEX = /^(\d{8}[A-Z])|([ABCDEFGHJKLMNPQRSUVWXYZ]{1}\d{7}[A-Z]{1})$/;
