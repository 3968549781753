import { Flex, Tooltip, Text } from "@chakra-ui/react";
// icons
import { TbFileInvoice } from "react-icons/tb";
// fs
import { formatDecimals } from "../../../common/functions";

export default function OrderInvoiceTag({ order }) {
	const invoice = order?.invoice || order?.multi_invoice;
	const order_total = invoice?.total || order.order_total;

	const getInvoiceLabel = () => {
		if (!invoice) {
			return "Sin factura";
		}
		const status = invoice.status;
		switch (status) {
			case "pending":
				return "Pendiente";
			case "payed":
				return "Pagada";
			case "expired":
				return "Caducada";
			default:
				return "Desconocido"; // Handle unexpected invoice statuses gracefully
		}
	};

	const getInvoiceColor = () => {
		if (!invoice) {
			return "grey";
		}
		const status = invoice.status;
		switch (status) {
			case "pending":
				return "orange";
			case "payed":
				return "#42C74F";
			case "expired":
				return "#C74242";
			default:
				return "grey.400"; // Use a neutral color for unknown statuses
		}
	};
	return (
		<Flex align='center' gap='0.2rem'>
			<Tooltip label={getInvoiceLabel()} rounded='lg' bg='gray.100' color={getInvoiceColor()} shouldWrapChildren>
				<TbFileInvoice size='20px' color={getInvoiceColor()} />
			</Tooltip>
			<Text color={getInvoiceColor()}>{formatDecimals(order_total)}€</Text>
		</Flex>
	);
}
