import moment from "moment";

export function formatDecimals(number) {
	const formattedNumber = Number(number).toFixed(2); // Formatea el número con 2 decimales
	const integerPart = Number.parseInt(formattedNumber); // Parte entera del número
	const decimalPart = Number.parseFloat(formattedNumber) - integerPart; // Parte decimal del número

	// Si no hay parte decimal, retorna la parte entera como número
	// Si hay parte decimal, retorna el número formateado a dos decimales como número
	return decimalPart === 0 ? integerPart : Number(formattedNumber);
}

export function parseDuration(duration, format) {
	const formattedMinutes = moment.duration(duration, "minutes");
	const days = formattedMinutes.days();
	const hours = formattedMinutes.hours();
	const minutes = formattedMinutes.minutes();
	const output = [];
	if (days > 0) {
		format === "long" ? output.push(`${days} días`) : output.push(`${days}d`);
	}
	if (hours > 0) {
		format === "long" ? output.push(`${hours} horas`) : output.push(`${hours}h`);
	}
	if (minutes > 0) {
		format === "long" ? output.push(`${minutes} minutos`) : output.push(`${minutes}m`);
	}
	return output.join(" ");
}
