import { useState } from "react";
import { Button, GridItem, Flex, IconButton, Text, useMediaQuery } from "@chakra-ui/react";
//forms
import ServiceDurationField from "./ServiceDurationField";
import InputField from "../../../common/forms/InputField";
import SelectField from "../../../common/forms/SelectField";
//api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getServiceOptions } from "../../../../api/servicesApi";
// icons
import { AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai";

export default function PriceDurationRow({ formik, index, arrayHelpers }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const [display, setDisplay] = useState(true);

	const { data: serviceOptions } = useAuthFetch(["serviceOptions"], getServiceOptions);
	let sizeOptions = serviceOptions?.actions?.POST?.prices?.child?.children?.size?.choices;
	const priceTypeOptions = serviceOptions?.actions?.POST?.prices?.child?.children?.price_type?.choices;

	if (formik.values.prices.length > 1) {
		sizeOptions = sizeOptions?.filter((option) => option.value !== "UNIQUE");
	}
	const handleDisplay = () => {
		setDisplay((prevDisplay) => !prevDisplay);
	};

	if (!isMobile) {
		return (
			<>
				<GridItem rowSpan={1} colSpan={1}>
					<SelectField name={`prices.${index}.size`} bg='white' choices={sizeOptions} />
				</GridItem>
				<GridItem rowSpan={1} colSpan={2}>
					<ServiceDurationField formik={formik} index={index} />
				</GridItem>
				<GridItem rowSpan={1} colSpan={1}>
					<SelectField name={`prices.${index}.price_type`} bg='white' choices={priceTypeOptions} />
				</GridItem>
				<GridItem rowSpan={1} colSpan={1}>
					<InputField name={`prices.${index}.price`} placeholder='€' bg='white' />
				</GridItem>
				<GridItem rowSpan={1} colSpan={1}>
					<InputField
						name={`prices.${index}.discounted_price`}
						isDisabled={formik.values.prices[index].price_type !== "discount"}
						placeholder='€'
						bg='white'
					/>
				</GridItem>
				<GridItem rowSpan={1} colSpan={1}>
					<Button mt='10px' variant='danger' size='xs' onClick={() => arrayHelpers.remove(index)}>
						-
					</Button>
				</GridItem>
			</>
		);
	}
	return (
		<Flex
			bg='#F7F7F7'
			fontSize='14px'
			direction='column'
			px='1em'
			py='2em'
			rounded='xl'
			border='1px solid #D9D9D9'
			gap='1em'
		>
			<Flex gap='1rem'>
				<ServiceDurationField formik={formik} index={index} />

				<IconButton
					bg='transparent'
					icon={display ? <AiOutlineUpCircle size='25px' /> : <AiOutlineDownCircle size='25px' />}
					onClick={handleDisplay}
				/>
			</Flex>
			{display && (
				<>
					<Flex align='center' w='100%' justify='space-between' gap='1em'>
						<SelectField
							label='Tipo'
							name={`prices.${index}.price_type`}
							bg='white'
							choices={priceTypeOptions}
						/>
						<InputField label='Precio' name={`prices.${index}.price`} placeholder='€' bg='white' />
						<InputField
							label='Precio rebajado'
							name={`prices.${index}.discounted_price`}
							isDisabled={formik.values.prices[index].price_type !== "discount"}
							placeholder='€'
							bg='white'
						/>
					</Flex>
					<Flex align='center' w='100%' justify='space-between' gap='2em'>
						<SelectField label='Tamaño' name={`prices.${index}.size`} bg='white' choices={sizeOptions} />

						<Button
							mt='10px'
							variant='danger'
							size='xs'
							onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
						>
							-
						</Button>
					</Flex>
				</>
			)}
		</Flex>
	);
}
