import React from "react";
import { Button, Flex, useMediaQuery } from "@chakra-ui/react";
import { FormikProps } from "formik";
//icons
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";

interface NextStepButtonProps {
    formik: FormikProps<any>; 
    isLoading: boolean;
    disableSubmit: boolean;
    prevStep: () => void;
    currentStep: number;
}

const NextStepButton: React.FC<NextStepButtonProps> = ({ formik, isLoading, disableSubmit, prevStep, currentStep }) => {
    const [isMobile] = useMediaQuery("(max-width: 768px)");

    if (!isMobile) {
        return (
            <Button
                w="250px"
                variant="primary"
                mt='1em'
                isDisabled={disableSubmit}
                onClick={()=>formik.handleSubmit()}
                isLoading={isLoading}
            >
                Siguiente {">"}
            </Button>
        );
    } else {
        return (
            <Flex w="100%" justify="space-evenly" align="center" mt="2em">
                {currentStep > 0 && (
                    <Button
                        onClick={prevStep}
                        leftIcon={<IoIosArrowDropleftCircle color="grey" size="30px" />}
                        color="grey"
                        bg="white"
                    >
                        Anterior
                    </Button>
                )}
                <Button
                    isDisabled={disableSubmit}
                    onClick={()=>formik.handleSubmit()}
                    isLoading={isLoading}
                    rightIcon={<IoIosArrowDroprightCircle size="30px" />}
                    bg="white"
                    color="blue.500"
                >
                    Siguiente
                </Button>
            </Flex>
        );
    }
}

export default NextStepButton;

