import { Text, Input, Select, Button, GridItem } from "@chakra-ui/react";
// forms
import InputField from "../../../common/forms/InputField";
import SelectField from "../../../common/forms/SelectField";

export default function DepositObservationRow({ formik, index, arrayHelpers }) {
	const observationZones = [
		{ label: "1 Techo", value: "roof" },
		{ label: "3 Capó", value: "hood" },
		{ label: "4 Defensa delantera", value: "front_bumper" },
		{ label: "22 Puerta delantera izquierda", value: "left_front_door" },
		{ label: "12 Puerta delantera derecha", value: "right_front_door" },
		{ label: "7 Defensa trasera", value: "rear_bumper" },
		{ label: "19 Puerta trasera izquierda", value: "left_rear_door" },
		{ label: "11 Puerta trasera derecha", value: "right_rear_door" },
		{ label: "6 Maletero", value: "trunk" }
	];

	return (
		<>
			<GridItem rowSpan={1} colSpan={2}>
				<SelectField placeholder='Selecciona' choices={observationZones} name={`observations.${index}.zone`} />
			</GridItem>
			<GridItem rowSpan={1} colSpan={4}>
				<InputField name={`observations.${index}.description`} />
			</GridItem>
			<GridItem rowSpan={1} colSpan={1} align='end'>
				<Button
					justifySelf='end'
					mt='10px'
					variant='danger'
					size='xs'
					onClick={() => arrayHelpers.remove(index)}
				>
					-
				</Button>
			</GridItem>
		</>
	);
}
