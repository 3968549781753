import { useState } from 'react'
import { Button } from '@chakra-ui/react'
// icons
import { FaSortAmountUp, FaSortAmountDown, FaSort } from 'react-icons/fa'

const icons = [<FaSort />, <FaSortAmountDown />, <FaSortAmountUp />]

export default function OrderByButton ({ filter, onToggleButton }) {
    const [index, setIndex] = useState(0)
    const filters = ['', `-${filter}`, filter]

    const handleClick = () => {
        setIndex((prevIndex) => (prevIndex + 1) % filters.length)
        const newOrdering = filters[index + 1]
        onToggleButton(newOrdering)
    }
    return <Button onClick={handleClick} leftIcon={icons[index]}></Button>
}
