import { useState, useMemo, useCallback } from "react";
import {
	useToast,
	useDisclosure,
	Flex,
	Text,
	Box,
	Button,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from "@chakra-ui/react";
// comps
import TableMobile from "../../../common/Tables/Mobile/TableMobile";
import CarForm from "./CarForm";
import PopoverDelete from "../../../common/PopoverDelete";
import EditItemButton from "../../../common/Tables/EditItemButton";
import CarHistoryModal from "./CarHistoryModal";
// icons
import { LuUser } from "react-icons/lu";
import { AiOutlineHistory } from "react-icons/ai";
// img
import emptyCars from "../../../../img/data/emptyCars.png";
// api
import useAuthMutation from "../../../../myHooks/useAuthMutation";
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { useQueryClient } from "@tanstack/react-query";
import { getAllCarsPag, deleteCar } from "../../../../api/carsApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function CarsTableMobile() {
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenCarHistory, onOpen: onOpenCarHistory, onClose: onCloseCarHistory } = useDisclosure();
	const [page, setPage] = useState(1);
	const [car, setCar] = useState();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data, isLoading } = useAuthFetch(["cars", page], getAllCarsPag, page);

	const { mutate, isLoading: ld } = useAuthMutation({
		mutationFn: deleteCar,
		onSuccess: () => {
			toast({ title: "Borrado con exito!", status: "success" });
			QueryClient.invalidateQueries(["cars"]);
			QueryClient.refetchQueries("cars", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const openEdit = (item) => {
		setCar(item);
		onOpen();
	};
	const openCarHistoryModal = (car) => {
		setCar(car);
		onOpenCarHistory();
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((car) => (
				<AccordionItem key={car.id} bg='white' rounded='xl' border='none' mb='0.5em'>
					<AccordionButton bg='white' rounded='xl' w='100%' _hover={{ bg: "white" }}>
						<Box as='span' flex='1' textAlign='left' fontSize='14px' color='darkblue'>
							{car.brand_display} {car.model}
						</Box>
						<AccordionIcon boxSize='25px' />
					</AccordionButton>
					<AccordionPanel pb='0em'>
						<Flex w='100%' h='100%' direction='column' gap='1em' justify='space-between' fontSize='14px'>
							<Flex gap='0.5em' align='center'>
								<LuUser size='15px' color='black' />
								<Text>{car.client?.name}</Text>
							</Flex>
							<Button
								onClick={() => openCarHistoryModal(car)}
								p='0'
								alignSelf='start'
								variant='white'
								border='none'
								size='sm'
								leftIcon={<AiOutlineHistory size='20px' color='#0258FF' />}
							>
								Ver historial
							</Button>
							<Flex w='100%' justify='end' mt='-2em'>
								<EditItemButton editFn={openEdit} item={car} />
								<PopoverDelete
									deleteKey={["deleteCar"]}
									deleteFn={deleteCar}
									slug={car?.id}
									refetchKey={["cars"]}
								/>
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			)),
		[data, ld]
	);
	const openForm = () => {
		setCar();
		onOpen();
	};
	return (
		<>
			<CarForm item={car} isOpen={isOpen} onClose={onClose} />
			<CarHistoryModal car={car} isOpen={isOpenCarHistory} onClose={onCloseCarHistory} />
			<TableMobile
				data={data}
				tableData={tableData}
				openFormFn={openForm}
				isLoading={isLoading}
				emptyImg={emptyCars}
				label='Órden'
				page={page}
				setPage={setPage}
			/>
		</>
	);
}
