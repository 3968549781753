import { useMemo, useState } from "react";
import { TableContainer, Text, Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react";
import moment from "moment";
// comps
import PaginationMenu from "../../../common/Tables/PaginationMenu";
import MessageStatusView from "../MessageStatusView";
// api
import { useAuthHeader } from "react-auth-kit";
import useAuthQuery from "../../../../myHooks/useAuthQuery";
import { getOrderProgrammedWhatsapps } from "../../../../api/communicationApi";

export default function OrderWhatsappsTable({ order, isOpen }) {
	const [page, setPage] = useState(1);
	const authHeader = useAuthHeader();

	const { data } = useAuthQuery({
		queryKey: ["orderWhatsapps", order?.id, page],
		queryFn: () => getOrderProgrammedWhatsapps({ token: authHeader(), orderId: order?.id, page }),
		enabled: !!order && isOpen
	});

	const tableData = useMemo(
		() =>
			data?.results?.map((whatsapp) => (
				<Tr key={whatsapp.id} fontSize='14px'>
					<Td>{whatsapp.type_display || "-- --"}</Td>
					<Td> {moment(whatsapp.send_at).format("HH:MM L") || "-- --"} </Td>
					<Td>
						<MessageStatusView status={whatsapp?.status} />
					</Td>
				</Tr>
			)),
		[data]
	);
	const shouldPaginate = data?.count > 5;
	if (data?.count > 0) {
		return (
			<TableContainer
				mt='1em'
				pb='1em'
				sx={{
					marginLeft: "5px",
					"&::-webkit-scrollbar": { width: "5px", height: "8px" },
					"&::-webkit-scrollbar-track": {
						backgroundColor: "#f5f5f5"
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: "#cfcfcf"
					}
				}}
			>
				<Table variant='unstyled' size='md'>
					<Thead>
						<Tr bg='lightgrey' color='darkblue'>
							<Th roundedLeft='lg'>Tipo</Th>
							<Th>Fecha</Th>
							<Th roundedRight='lg'>Status</Th>
						</Tr>
					</Thead>
					<Tbody>{tableData}</Tbody>
				</Table>
				{shouldPaginate && (
					<PaginationMenu
						total={data?.count}
						next={data?.next}
						prev={data?.previous}
						page={page}
						setPage={setPage}
					/>
				)}
			</TableContainer>
		);
	}
	return <Text>No hay mensajes enviados para esta órden de trabajo</Text>;
}
