import axios from 'axios'

const statsApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}stats`
})

export const getAllStats = async (payload) => {
    let url = `/${payload.slug}`
    if (payload.period) {
        url = `${url}?period=${payload.period}`
    }
    if (payload.year) {
        url = `${url}&year=${payload.year}`
    }
    if (payload.month) {
        url = `${url}&month=${payload.month}`
    }
    if (payload.week) {
        url = `${url}&week=${payload.week}`
    }
    const response = await statsApi.get(url, {
        headers: { Authorization: payload.token }
    })
    return response.data
}

export const getDashboardStats = async (payload) => {
    const response = await statsApi.get('/dashboard', {
        headers: { Authorization: payload.token }
    })
    return response.data
}

export const getOrderStatsOld = async (payload) => {
    let url = '/orders'
    if (payload.filter) {
        url = `${url}?period=${payload.filter}`
    }
    const response = await statsApi.get(url, {
        headers: { Authorization: payload.auth }
    })
    return response.data
}

export const getCarStats = async (payload) => {
    const response = await statsApi.get('/cars', {
        headers: { Authorization: payload.auth }
    })
    return response.data
}

export const getInvoiceStats = async (payload) => {
    const response = await statsApi.get('/invoices', {
        headers: { Authorization: payload.auth }
    })
    return response.data
}

export const getClientStats = async (payload) => {
    let url = '/clients'
    if (payload.filter) {
        url = `${url}?period=${payload.filter}`
    }
    const response = await statsApi.get(url, {
        headers: { Authorization: payload.auth }
    })
    return response.data
}

export const getAverageServiceTime = async (payload) => {
    // formato url --> /api/stats/average-times?type=waiting&period=week
    let url = `/average-times?type=${payload.type}`
    if (payload.filter) {
        url = `${url}&period=${payload.filter}`
    }
    const response = await statsApi.get(url, {
        headers: { Authorization: payload.auth }
    })
    return response.data
}
