import { useContext } from 'react'
import { Flex, Text, Progress } from '@chakra-ui/react'
import { PlanContext } from '../../common/context/PlanContext'
// comps
import CheckoutButton from '../../payments/CheckoutButton'

export default function MessageCountBox () {
    const { limits } = useContext(PlanContext)
    const sent = limits?.whatsapp_used || 0
    const limit = limits?.whatsapp_limit || 60
    const percentage = (sent / limit) * 100
    return (
        <Flex direction="column" align="start" justify="space-evenly"
            bg="white" rounded="xl" gap="0.7em" w="100%" p='1em'
        >
            <Flex align="center" gap="0.5em">
                <Text fontWeight="bold">Has enviado:</Text>
                <Text>
                    {sent} de {limit}
                </Text>
            </Flex>
            <Progress value={percentage} w="90%" h="10px" rounded="lg" />
            <Text fontWeight="bold" fontSize='14px'>¡Quiero enviar más!</Text>
            {/*
            <CheckoutButton/>
            */}
        </Flex>
    )
}
