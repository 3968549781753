import { Flex, Text, IconButton, Button, Badge } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
// comps
import StripeModalButton from "../payments/StripeModalButton";
// auth
import { useAuthUser } from "react-auth-kit";
//types
import type { IconType } from "react-icons";

interface NavItemProps {
	icon: IconType;
	title: string;
	slash: string;
	isDisabled?: boolean;
	comingSoon?: boolean;
	isPro?: boolean;
	onClose?: () => void; // Es una función opcional
}

export default function NavItem({
	icon,
	title,
	slash,
	isDisabled,
	comingSoon,
	isPro,
	onClose,
	...props
}: NavItemProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const auth = useAuthUser();
	const plan = auth()?.plan?.name;

	const disableButton = isDisabled || (isPro && plan === "FREE") || comingSoon;

	const goTo = () => {
		navigate(slash);
		if (onClose) {
			onClose();
		}
	};

	return (
		<Flex direction='column' align='center'>
			<Button
				{...props}
				variant='navitem'
				py='2em'
				onClick={goTo}
				isActive={location.pathname === slash}
				isDisabled={disableButton}
			>
				<Flex direction='column' align='center' gap='0.5em' w='60px'>
					<IconButton
						aria-label={title}
						as={icon}
						bg='transparent'
						size='xs'
						color='whiteAlpha.800'
						_hover={{ bgColor: "transparent" }}
					/>
					<Text fontSize='14px' color='whiteAlpha.800'>
						{title}
					</Text>
				</Flex>
			</Button>
			{comingSoon && plan === "BETA" && (
				<Badge
					bg='blue.500'
					color='white'
					rounded='xl'
					fontWeight='400'
					fontSize='10px'
					// w="50px"
					zIndex='1'
				>
					SOON
				</Badge>
			)}
			{isPro && plan === "FREE" && <StripeModalButton type='blue' />}
		</Flex>
	);
}
