import { useMemo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Flex } from "@chakra-ui/react";
import Select from "react-select";
import type { ActionMeta, SingleValue } from "react-select";
import { useField } from "formik";

type Option = {
	id?: string | number;
	name?: string;
	display_name?: string;
	value?: string | number;
	label?: string;
};

type SelectFieldFilterProps = {
	label: string;
	icon?: React.ReactNode;
	required?: boolean;
	options: Option[];
	isloading?: boolean;
	placeholder?: string;
	error?: string;
	name: string; // This is required for useField hook
};

type FormattedOption = {
	value: string | number | undefined;
	label: string | undefined;
};

export default function SelectFieldFilter({
	label,
	icon,
	required,
	options,
	isloading,
	placeholder,
	error,
	...props
}: SelectFieldFilterProps) {
	const [field, meta, helpers] = useField(props);

	const formatData = useMemo(() => {
		return options?.map(({ id, name, display_name, value, label }) => ({
			value: value || id,
			label: name || display_name || label
		}));
	}, [options]);

	const handleChange = (option: SingleValue<FormattedOption>, action: ActionMeta<FormattedOption>) => {
		if (action.action === "clear" || !option) {
			helpers.setValue("");
		} else {
			helpers.setValue(option.value);
		}
	};

	return (
		<FormControl isInvalid={(meta.error && meta.touched) || !!error} isRequired={required} w='100%'>
			<Flex gap='0.5em' align='start'>
				{icon}
				<FormLabel color='black'>{label}</FormLabel>
			</Flex>
			<Select
				isLoading={isloading || false}
				placeholder={placeholder || "Escribe para buscar"}
				noOptionsMessage={() => `No hay opciones de ${label}`}
				options={formatData}
				name={field.name}
				onBlur={field.onBlur}
				value={formatData?.find((option) => option.value === field.value) || null}
				onChange={handleChange}
				isClearable={true}
			/>
			{error && <FormErrorMessage>{error} </FormErrorMessage>}
			{meta.touched && meta.error && <FormErrorMessage>{meta.error} </FormErrorMessage>}
		</FormControl>
	);
}
