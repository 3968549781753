import { Flex, Text, IconButton, useMediaQuery } from "@chakra-ui/react";
//icons
import { BsChevronLeft, BsChevronRight, BsChevronBarLeft, BsChevronBarRight } from "react-icons/bs";

interface PaginationMenuProps {
    total: number;
    next?: string | null;
    prev?: string | null;
    pageSize?: number;
    page: number;
    setPage: (value: number)=>void;
}

export default function PaginationMenu({ total, next, prev, page, setPage, pageSize }:PaginationMenuProps) {
    const total_pages = Math.ceil(total / (pageSize || 10));
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    if(!isMobile){
    return (
        <Flex gap="0.5em"  align="center" mt="2em" mb="1em" justify="end">
            <Text fontSize="16px" mr="2em">
                Total: {total}
            </Text> 
            <IconButton
                aria-label="first_page"
                bg="darkblue"
                color="white"
                size="sm"
                isDisabled={prev === null}
                onClick={() => setPage(1)}
                icon={<BsChevronBarLeft />}
            />

            <IconButton
                aria-label="prev_page"
                bg="darkblue"
                color="white"
                size="sm"
                isDisabled={prev === null}
                onClick={() => setPage(page - 1)}
                icon={<BsChevronLeft />}
            />

            <Text fontSize="16px">
                {page}/{total_pages}
            </Text>

            <IconButton
                aria-label="next_page"
                bg="darkblue"
                color="white"
                size="sm"
                isDisabled={next === null}
                onClick={() => setPage(page + 1)}
                icon={<BsChevronRight />}
            />

            <IconButton
                aria-label="last_page"
                bg="darkblue"
                color="white"
                size="sm"
                isDisabled={next === null}
                onClick={() => setPage(total_pages)}
                icon={<BsChevronBarRight />}
            />
        </Flex>
    );
    }else{
    return (
        <Flex gap="0.5em" w='100%' align="center" px='1em' justify='end'  >
            <IconButton
                aria-label="first_page"
                bg="darkblue"
                color="white"
                size="sm"
                isDisabled={prev === null}
                onClick={() => setPage(1)}
                icon={<BsChevronBarLeft />}
            />

            <IconButton
                aria-label="prev_page"
                bg="darkblue"
                color="white"
                size="sm"
                isDisabled={prev === null}
                onClick={() => setPage(page - 1)}
                icon={<BsChevronLeft />}
            />

            <Text fontSize="16px">
                {page}/{total_pages}
            </Text>

            <IconButton
                aria-label="next_page"
                bg="darkblue"
                color="white"
                size="sm"
                isDisabled={next === null}
                onClick={() => setPage(page + 1)}
                icon={<BsChevronRight />}
            />

            <IconButton
                aria-label="last_page"
                bg="darkblue"
                color="white"
                size="sm"
                isDisabled={next === null}
                onClick={() => setPage(total_pages)}
                icon={<BsChevronBarRight />}
            />
        </Flex>
    ); 
    }
}
