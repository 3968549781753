import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    IconButton,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    Button
} from '@chakra-ui/react'
//
import useAuthDelete from '../../myHooks/useAuthDelete'
// icons
import { BsTrash } from 'react-icons/bs'

export default function PopoverDelete ({ deleteKey, refetchKey, deleteFn, slug }) {
    const { mutate, isLoading } = useAuthDelete(deleteKey, deleteFn, slug, refetchKey)

    return (
        <Popover >
            <PopoverTrigger >
                <IconButton   isLoading={isLoading} 
                icon={<BsTrash size={"20px"}  color="red" />} bg="transparent" />
            </PopoverTrigger>

            <PopoverContent p="0.5em">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>¿Seguro que quieres borrarlo?</PopoverBody>

                <Button
                    variant="danger"
                    isLoading={isLoading}
                    onClick={() => mutate()}
                    size="sm"
                    mt="0.5em"
                    mx="1em"
                    w="75px"
                    alignSelf="end"
                >
                    Borrar
                </Button>
            </PopoverContent>
        </Popover>
    )
}
