import { Flex, Button } from '@chakra-ui/react'

export default function OrderListFilter ({ period, setPeriod, ...props }) {
    return (
        <Flex {...props} border="2px solid #D9D9D9" rounded="md">
            <Button variant="filter" isActive={period === 'day'} onClick={() => setPeriod('day')}>
                Hoy
            </Button>
            <Button variant="filter" isActive={period === 'week'} onClick={() => setPeriod('week')}>
                Semana
            </Button>
            <Button variant="filter" isActive={period === 'month'} onClick={() => setPeriod('month')}>
                Mes
            </Button>
            <Button variant="filter" isActive={period === 'all'} onClick={() => setPeriod('all')}>
                Todo
            </Button>
        </Flex>
    )
}
