import { FormControl, FormErrorMessage, FormHelperText, Input, FormLabel, Flex, Skeleton } from "@chakra-ui/react";
import { Field, useField } from "formik";

interface InputFieldProps {
	name: string;
	label: string;
	icon?: React.ReactNode;
	hint?: string;
	required?: boolean;
	error?: string;
	isLoading?: boolean;
	type?: string;
}

export default function InputField({
	name,
	label,
	icon,
	hint,
	required,
	error,
	isLoading,
	type,
	...props
}: InputFieldProps) {
	// hook de formik para obtener el field data y los errors y touched de cada field
	const [field, meta] = useField(name);
	return (
		<FormControl isInvalid={(meta.error && meta.touched) || !!error} isRequired={required}>
			<Flex gap='0.5em' align='start'>
				{icon}
				<FormLabel color='black'>{label}</FormLabel>
			</Flex>
			{!isLoading ? (
				<Field
					h='40px'
					as={Input}
					type={type}
					{...field} // aqui van los props de onblur, on change
					{...props} // props que pasamos como placeholder etc
				/>
			) : (
				<Skeleton h='40px' maxW='75%' rounded='lg' />
			)}
			<FormHelperText>{hint}</FormHelperText>
			<FormErrorMessage> {meta.error} </FormErrorMessage>
			<FormErrorMessage> {error} </FormErrorMessage>
		</FormControl>
	);
}
