import { useEffect, Fragment } from "react";
import { Flex, Grid, GridItem, Button, Text, useMediaQuery } from "@chakra-ui/react";
// icons
import { MdCleaningServices } from "react-icons/md";
// comps
import InvoiceItemRow from "./InvoiceItemRow";
// forms
import { FieldArray, useFormikContext } from "formik";

export default function InvoiceItems({ formik, calculateTotals, order }) {
	const { values } = useFormikContext();
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	useEffect(() => {
		calculateTotals(values, order);
	}, [values]);

	return (
		<FieldArray
			name='items'
			render={(arrayHelpers) => (
				<>
					<Flex justify='space-between' align='center' mt='1em'>
						<Flex align='center' gap='10px'>
							<MdCleaningServices size='20px' color='black' />
							<Text fontSize='20px' fontWeight='bold'>
								Servicios
							</Text>
						</Flex>
						<Flex align='center' gap='10px'>
							<Button
								variant='primary'
								size='xs'
								onClick={() =>
									arrayHelpers.push({
										concept: "",
										quantity: "",
										price: ""
									})
								}
								isDisabled={formik.errors.items | (JSON.stringify(formik.touched.items) === "{}")}
							>
								+
							</Button>
							<Text color='blue.500'>Servicio</Text>
						</Flex>
					</Flex>
					{typeof formik.errors.items === "string" && (
						<Text color='red' fontSize='14px'>
							{formik.errors.items}
						</Text>
					)}
					{!isMobile ? (
						<Grid
							templateColumns='repeat(15, 1fr)'
							gap={4}
							mt='0.5em'
							bg='#F7F7F7'
							px='1em'
							py='2em'
							rounded='xl'
							border='1px solid #D9D9D9'
						>
							<GridItem rowSpan={1} colSpan={6}>
								<Text>Concepto</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={2}>
								<Text align='center'>Uds.</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={2}>
								<Text align='center'>Precio</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={2}>
								<Text align='end'>IVA</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={2}>
								<Text align='end'>Total</Text>
							</GridItem>
							{formik.values.items &&
								formik.values.items.length > 0 &&
								formik.values.items.map((item, index) => (
									<Fragment key={item.id}>
										<InvoiceItemRow formik={formik} index={index} arrayHelpers={arrayHelpers} />
									</Fragment>
								))}
						</Grid>
					) : (
						<>
							{formik.values.items &&
								formik.values.items.length > 0 &&
								formik.values.items.map((item, index) => (
									<Fragment key={item.id}>
										<InvoiceItemRow formik={formik} index={index} arrayHelpers={arrayHelpers} />
									</Fragment>
								))}
						</>
					)}
				</>
			)}
		/>
	);
}
