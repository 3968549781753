import { useState, useEffect } from 'react'
import { Flex, useMediaQuery, Grid, GridItem, Link, Button } from '@chakra-ui/react'

// comps
import SubmitInspection from '../../components/pages/inspection/forms/SubmitInspectionButton'
import CarViewer from '../../components/pages/inspection/CarViewer'
import InspectionForm from '../../components/pages/inspection/forms/InspectionForm'
import Detail from '../../components/pages/inspection/details/Detail'

export default function Inspection () {
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const [unsavedData, setUnsavedData] = useState(false)
    const [savedInspection, setSavedInspection] = useState()
    const [selectedDetail, setSelectedDetail] = useState()
    const [view, setView] = useState('right')
    const [zone, setZone] = useState('exterior')
    const [order, setOrder] = useState()
    const [isBefore, setIsBefore] = useState(true)
    const [circleCoords, setCircleCoords] = useState({ x: 0, y: 0 })
    const [photo, setPhoto] = useState({
        right: null,
        left: null,
        back: null,
        front: null
    })
    const [step, setStep] = useState(0)

    // Cada vez que cambio vista o foto, quito el selector
    useEffect(() => {
        setCircleCoords({ x: 0, y: 0 })
    }, [view, order])

    const handleNextStep = () => {
        if (step < 2) {
            setStep(step + 1)
        }
    }
    const handlePrevStep = () => {
        if (step > 0) {
            setStep(step - 1)
        }
    }
    const checkUnsavedData = () => {
        const photos = Object.values(photo)
        setUnsavedData(false)
        for (let i = 0; i < photos.length; i++) {
            if (photos[i]) {
                console.log(typeof photos[i])
                if (typeof photos[i] === 'object') {
                    setUnsavedData(true)
                }
            }
        }
    }
    useEffect(() => {
        checkUnsavedData()
    }, [photo])
    if (!isMobile) {
        return (
            <Grid p="1em" w="100%" h="92dvh" templateRows="repeat(1, 1fr)" templateColumns="repeat(11, 1fr)" gap={6}>
                <GridItem rowSpan={1} colSpan={3}>
                    <InspectionForm
                        order={order}
                        setOrder={setOrder}
                        zone={zone}
                        setZone={setZone}
                        isBefore={isBefore}
                        setIsBefore={setIsBefore}
                        setSavedInspection={setSavedInspection}
                        setPhoto={setPhoto}
                    />
                </GridItem>
                <GridItem rowSpan={1} colSpan={5}>
                    <CarViewer
                        photo={photo}
                        setPhoto={setPhoto}
                        view={view}
                        setView={setView}
                        circleCoords={circleCoords}
                        setCircleCoords={setCircleCoords}
                        order={order}
                        zone={zone}
                        setZone={setZone}
                        savedInspection={savedInspection}
                        isBefore={isBefore}
                        setSelectedDetail={setSelectedDetail}
                    />
                </GridItem>
                <GridItem rowSpan={1} colSpan={3}>
                    <Detail
                        photo={photo}
                        view={view}
                        savedInspection={savedInspection}
                        order={order}
                        zone={zone}
                        isBefore={isBefore}
                        circleCoords={circleCoords}
                        setCircleCoords={setCircleCoords}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                    />
                </GridItem>
            </Grid>
        )
    } else {
        return (
            <Flex p="1em" h="92vh" direction="column">
                {step === 0 && (
                    <>
                        <InspectionForm
                            order={order}
                            setOrder={setOrder}
                            zone={zone}
                            setZone={setZone}
                            isBefore={isBefore}
                            setIsBefore={setIsBefore}
                            setSavedInspection={setSavedInspection}
                            setPhoto={setPhoto}
                        />
                        <Button
                            bg="transparent"
                            color="blue.500"
                            fontSize="18px"
                            mt="2em"
                            alignSelf="end"
                            onClick={handleNextStep}
                            isDisabled={!order}
                        >
                            Seleccionar la vista del coche &gt;
                        </Button>
                    </>
                )}
                {step === 1 && (
                    <>
                        <CarViewer
                            photo={photo}
                            setPhoto={setPhoto}
                            view={view}
                            setView={setView}
                            circleCoords={circleCoords}
                            setCircleCoords={setCircleCoords}
                            order={order}
                            zone={zone}
                            setZone={setZone}
                            savedInspection={savedInspection}
                            isBefore={isBefore}
                            setSelectedDetail={setSelectedDetail}
                        />
                        <Flex
                            align="center"
                            w="100%"
                            justify="space-between"
                            pr="1.5em"
                            position="absolute"
                            bottom="2em"
                        >
                            <Button bg="transparent" color="blue.500" fontSize="18px" onClick={handlePrevStep}>
                                &lt; Atrás
                            </Button>
                            {unsavedData
                                ? (
                                    <SubmitInspection
                                        photo={photo}
                                        setPhoto={setPhoto}
                                        order={order}
                                        savedInspection={savedInspection}
                                        isBefore={isBefore}
                                        zone={zone}
                                    />
                                )
                                : (
                                    <Button
                                        bg="transparent"
                                        color="blue.500"
                                        fontSize="18px"
                                        onClick={handleNextStep}
                                        isDisabled={!photo[view] || (circleCoords.x === 0 && circleCoords.y === 0)}
                                    >
                                    Personalizar detalle &gt;
                                    </Button>
                                )}
                        </Flex>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Detail
                            photo={photo}
                            view={view}
                            savedInspection={savedInspection}
                            order={order}
                            zone={zone}
                            isBefore={isBefore}
                            circleCoords={circleCoords}
                            setCircleCoords={setCircleCoords}
                            setSelectedDetail={setSelectedDetail}
                        />
                        <Link
                            color="blue.500"
                            fontWeight="bold"
                            fontSize="18px"
                            position="absolute"
                            bottom="2em"
                            left="2em"
                            onClick={handlePrevStep}
                        >
                            &lt; Atrás
                        </Link>
                    </>
                )}
            </Flex>
        )
    }
}
