import { Fragment } from "react";
import { Flex, Grid, GridItem, Button, Text, useMediaQuery } from "@chakra-ui/react";
// icons
import { MdCleaningServices } from "react-icons/md";
// comps
import PriceDurationRow from "./PriceDurationRow";
// forms
import { FieldArray } from "formik";

export default function PriceDurationItems({ formik }) {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const isUniqueSize = formik.values.prices && formik.values.prices[0]?.size === "UNIQUE";
	return (
		<FieldArray
			name='prices'
			render={(arrayHelpers) => (
				<>
					<Flex justify='space-between' align='center' mt='1em'>
						<Flex align='center' gap='10px'>
							<MdCleaningServices size='20px' color='black' />
							<Text fontSize='20px' fontWeight='bold'>
								Precios
							</Text>
						</Flex>
						<Flex align='center' gap='10px'>
							<Button
								variant='primary'
								isDisabled={isUniqueSize}
								size='xs'
								onClick={() =>
									arrayHelpers.push({
										price: 0,
										discounted_price: 0,
										duration: 0,
										size: "M",
										price_type: "fixed"
									})
								}
							>
								+
							</Button>
							<Text color={isUniqueSize ? "grey" : "blue.500"}>Precio</Text>
						</Flex>
					</Flex>
					{!isMobile ? (
						<Grid
							templateColumns='repeat(7, 1fr)'
							gap={4}
							mt='0.5em'
							bg='#F7F7F7'
							px='1em'
							py='2em'
							rounded='xl'
							border='1px solid #D9D9D9'
						>
							<GridItem rowSpan={1} colSpan={1}>
								<Text>Tamaño</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={2}>
								<Text textAlign='center'>Duración</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={1}>
								<Text>Tipo de precio</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={1}>
								<Text>Precio(€)</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={1}>
								<Text>Precio rebajado(€)</Text>
							</GridItem>
							<GridItem rowSpan={1} colSpan={1} />

							{formik.values.prices &&
								formik.values.prices.length > 0 &&
								formik.values.prices.map((item, index) => (
									<Fragment key={item.id}>
										<PriceDurationRow formik={formik} index={index} arrayHelpers={arrayHelpers} />
									</Fragment>
								))}
						</Grid>
					) : (
						<>
							{formik.values.prices &&
								formik.values.prices.length > 0 &&
								formik.values.prices.map((item, index) => (
									<Fragment key={item.id}>
										<PriceDurationRow formik={formik} index={index} arrayHelpers={arrayHelpers} />
									</Fragment>
								))}
						</>
					)}
				</>
			)}
		/>
	);
}
