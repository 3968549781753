import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton } from "@chakra-ui/react";
import { PDFViewer, Page, Text, View, Document, Image } from "@react-pdf/renderer";
// fs
import { formatDecimals } from "../../../common/functions";
import { styles } from "./PDFStyle";
// api
import useAuthFetch from "../../../../myHooks/useAuthFetch";
import { getInvoiceSettings } from "../../../../api/invoicesApi";
import { getBusinessProfile } from "../../../../api/businessesApi";

export default function InvoiceTicketPDFModal({ invoice, isOpen, onClose }) {
	const { data: invoiceSettingsArray } = useAuthFetch(["invoiceSettings"], getInvoiceSettings, null, null, !!isOpen);
	const { data: businessProfile } = useAuthFetch(["businessProfile"], getBusinessProfile, null, null, !!isOpen);
	const discount = invoice?.discount > 0 ? (invoice.discount / 100) * invoice.subtotal : 0;

	const invoiceSettings = invoiceSettingsArray?.length > 0 ? invoiceSettingsArray[0] : [];
	return (
		<Modal isOpen={isOpen} onClose={onClose} size='5xl'>
			<ModalOverlay />
			<ModalContent h='90vh'>
				<ModalHeader>Visor de Factura Ticket {invoice?.invoice_number}</ModalHeader>
				<ModalCloseButton />
				<PDFViewer width='100%' height='100%'>
					<Document title={`Factura ${invoice?.invoice_number}`}>
						<Page size='A4' style={styles.ticket}>
							<View style={{ width: "55mm", flexDirection: "column", alignItems: "center" }}>
								{businessProfile?.banner_image && (
									<Image
										style={{
											maxWidth: "50px",
											maxHeight: "50px",
											objectFit: "cover",
											borderRadius: "5px"
										}}
										cache={false}
										crossorigin='anonymous'
										src={businessProfile.banner_image}
									/>
								)}
								<Text style={{ marginTop: "10px", fontWeight: "medium" }}>
									{businessProfile?.name || "Nombre de tu centro"}
								</Text>
								<Text style={{ marginTop: "5px", fontSize: "8px", textAlign: "center" }}>
									{invoiceSettings?.address || "Dirección de facturación"}
								</Text>
								<Text style={{ fontSize: "8px", marginTop: "3px" }}>
									CIF: {invoiceSettings?.CIF || "CIF de tu centro"}
								</Text>
								<Text style={{ marginBottom: "10px", fontSize: "8px" }}>
									Tel: {businessProfile?.phone || "Teléfono del centro"}
								</Text>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "space-between",
										fontSize: "8px",
										width: "100%",
										paddingHorizontal: "5px",
										marginBottom: "3px",
										textAlign: "center"
									}}
								>
									<View style={{ width: "15%", textAlign: "left" }}>
										<Text>UDS</Text>
									</View>
									<View style={{ width: "30%" }}>
										<Text>ITEM</Text>
									</View>
									<View style={{ width: "20%", textAlign: "right" }}>
										<Text>PRECIO</Text>
									</View>
									<View style={{ width: "15%", textAlign: "right" }}>
										<Text>IVA</Text>
									</View>
									<View style={{ width: "20%", textAlign: "right" }}>
										<Text>TOTAL</Text>
									</View>
								</View>
								{invoice?.items?.map((item) => (
									<View
										key={item.id}
										style={{
											flexDirection: "row",
											justifyContent: "space-between",
											fontSize: "6px",
											width: "100%",
											paddingHorizontal: "5px",
											textAlign: "center",
											marginBottom: "4px"
										}}
									>
										<View style={{ width: "15%", textAlign: "left" }}>
											<Text>{formatDecimals(item.quantity)}</Text>
										</View>
										<View style={{ width: "25%" }}>
											<Text>{item?.concept}</Text>
										</View>
										<View style={{ width: "20%", textAlign: "right" }}>
											<Text>{formatDecimals(item.price)}</Text>
										</View>
										<View style={{ width: "15%", textAlign: "right" }}>
											<Text>
												{formatDecimals(item.taxes)}{" "}
												{invoice.taxes > 0 && `(${invoice.tax_display})`}
											</Text>
										</View>
										<View style={{ width: "20%", textAlign: "right" }}>
											<Text>{formatDecimals(item.total)}</Text>
										</View>
									</View>
								))}
								<View style={{ marginVertical: "6px" }} />
								{invoice?.discount > 0 && (
									<>
										<View style={styles.resumeRowTicket}>
											<Text>Subtotal</Text>
											<Text>{formatDecimals(invoice?.subtotal)}€</Text>
										</View>

										<View style={styles.resumeRowTicket}>
											<Text>Descuento {invoice?.discount}%</Text>
											<Text>{discount.toFixed(2)}€</Text>
										</View>
									</>
								)}
								<View style={styles.resumeRowTicket}>
									<Text>Base Imponible</Text>
									<Text>{formatDecimals(invoice?.subtotal - discount)}€</Text>
								</View>
								<View style={styles.resumeRowTicket}>
									<Text>IVA</Text>
									<Text>{invoice?.taxes}€</Text>
								</View>

								<View style={styles.resumeRowTicket}>
									<Text>Total</Text>
									<Text style={{ fontWeight: "semibold" }}>{invoice?.total}€</Text>
								</View>
								<View
									style={{
										paddingVertical: "5px",
										marginTop: "10px",
										width: "100%",
										borderTop: "1px solid black"
									}}
								>
									<Text style={{ fontSize: "8px", textAlign: "center" }}>GRACIAS POR SU COMPRA</Text>
								</View>
							</View>
						</Page>
					</Document>
				</PDFViewer>
			</ModalContent>
		</Modal>
	);
}
