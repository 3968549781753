import {
	Flex,
	Text,
	Button,
	Switch,
	Select,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton
} from "@chakra-ui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// comps
import { Spinner } from "@chakra-ui/react";
import LoadingOverlay from "../../common/loading/LoadingOverlay";
import AddressModal from "../../pages/businesses/businessSettings/profile/address/AddressModal";
// api
import { loginAsUser, updateUserPlan, updateUser, renewUserTrialPlan, manageExtraPlan } from "../../../api/adminApi";
import { getAllPlans } from "../../../api/adminApi";
import useAuthFetch from "../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../myHooks/useAuthMutation";
import { useSignIn } from "react-auth-kit";
import { userSignIn } from "../../../utils";
import { useQueryClient } from "@tanstack/react-query";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function UserDetail({ user, isOpen, onClose }) {
	const authHeader = useAuthHeader();
	const signIn = useSignIn();
	const navigate = useNavigate();
	const QueryClient = useQueryClient();
	const toast = useToast();

	const { data: plans } = useAuthFetch(["plans"], getAllPlans);
	const { isLoading: loadingAs, mutate } = useAuthMutation({
		mutationFn: loginAsUser,
		onSuccess: (token) => {
			toast({
				title: "Token obtenido para ese usuario!",
				status: "success"
			});
			userSignIn(token, toast, signIn, navigate);
		},
		onError: (error) => {
			console.log(error);
			toast({ title: "Ha habido un error!", status: "error" });
		}
	});
	const { mutate: editUserPlan, isLoading: loadingPlan } = useAuthMutation({
		mutationFn: updateUserPlan,
		onSuccess: () => {
			toast({ title: "Actualizado con exito!", status: "success" });
			QueryClient.invalidateQueries(["users"]);
			QueryClient.refetchQueries("users", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const { mutate: manageUserExtraPlan, isLoading: loadingExtraPlan } = useAuthMutation({
		mutationFn: manageExtraPlan,
		onSuccess: () => {
			toast({ title: "Plan extra añadido!", status: "success" });
			QueryClient.invalidateQueries(["users"]);
			QueryClient.refetchQueries("users", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const { isLoading: lr, mutate: renewUserPlan } = useAuthMutation({
		mutationFn: renewUserTrialPlan,
		onSuccess: () => {
			toast({ title: "Actualizado con exito!", status: "success" });
			QueryClient.invalidateQueries(["users"]);
			QueryClient.refetchQueries("users", { force: true });
			onClose();
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const { isLoading: lu, mutate: editUser } = useAuthMutation({
		mutationFn: updateUser,
		onSuccess: () => {
			toast({ title: "Actualizado con exito!", status: "success" });
			QueryClient.invalidateQueries(["users"]);
			QueryClient.refetchQueries("users", { force: true });
		},
		onError: (error) => {
			toast({
				title: error.message,
				description: error.code,
				status: "error"
			});
		}
	});
	const changePlan = (e, user) => {
		const data = {
			plan: e.target.value
		};
		editUserPlan({
			filter: "?user=" + user,
			data,
			token: authHeader()
		});
	};

	const replaceUser = () => {
		const payload = {
			data: {
				email: user?.email
			},
			token: authHeader()
		};
		mutate(payload);
	};
	const handleRenewPlan = () => {
		const payload = {
			data: {
				userId: user.id
			},
			token: authHeader()
		};
		renewUserPlan(payload);
	};
	const handleUpdateUser = () => {
		const payload = {
			slug: user.id,
			data: { is_staff: !user.is_staff },
			token: authHeader()
		};
		editUser(payload);
	};
	const handleAddWhatsapp = () => {
		const payload = {
			data: {
				extra_plan_name: "whatsapp",
				user_id: user.id,
				action: "ADD"
			},
			token: authHeader()
		};
		manageUserExtraPlan(payload);
	};
	const handleRemoveWhatsapp = () => {
		const payload = {
			data: {
				extra_plan_name: "whatsapp",
				user_id: user.id,
				action: "REMOVE"
			},
			token: authHeader()
		};
		manageUserExtraPlan(payload);
	};
	const hasExtraPlans = user?.extra_plan?.length > 0;
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size='4xl'>
				<ModalOverlay />
				<ModalContent bg='white'>
					<ModalHeader>Tarjeta de usuario</ModalHeader>
					<ModalCloseButton />
					{user && (
						<ModalBody py='1em'>
							<Flex direction='column' gap='1em'>
								<Flex gap='1em' align='center'>
									<Text fontWeight='bold'>Plan:</Text>
									<Select
										value={user.plan[0]?.plan?.name}
										w='150px'
										onChange={(e) => changePlan(e, user.id)}
									>
										{plans?.map((plan) => (
											<option key={plan.id} value={plan.name}>
												{plan.name}
											</option>
										))}
									</Select>
									{user.plan[0]?.has_expired && <Text color='red'>Caducado</Text>}
									<Text>Expires: {moment(user.plan[0]?.expires_at).format("LLL")}</Text>
									<Button sizee='sm' variant='primary' onClick={handleRenewPlan} isLoading={lr}>
										Renew
									</Button>
								</Flex>
								<Flex gap='1rem'>
									<Text fontWeight='bold'>Subscription Id:</Text>
									<Text>{user.plan[0]?.subscription_id || "-- --"}</Text>
								</Flex>

								<Flex gap='1em' align='center'>
									<Text fontWeight='bold' w='190px'>
										Extra Plans:
									</Text>
									{hasExtraPlans ? (
										<>
											{user?.extra_plan.map((plan) => (
												<Text key={plan.id} value={plan.name}>
													{plan.name}
												</Text>
											))}
											<Text>Expires: {moment(user.extra_plan[0]?.expires_at).format("LLL")}</Text>
											<Button
												variant='danger'
												isLoading={loadingExtraPlan}
												onClick={handleRemoveWhatsapp}
											>
												- Whattsapp Package
											</Button>
										</>
									) : (
										<>
											<Button
												variant='primary'
												isLoading={loadingExtraPlan}
												onClick={handleAddWhatsapp}
											>
												+ Whattsapp Package
											</Button>
										</>
									)}
								</Flex>

								<hr />
								<Flex w='100%' justify='space-between' gap='2em'>
									<Flex direction='column' w='50%' gap='1em'>
										<Flex gap='1em'>
											<Text fontWeight='bold'>Email:</Text>
											<Text>{user.email} </Text>
										</Flex>
										<Flex gap='1em'>
											<Text fontWeight='bold'>Nombre:</Text>
											<Text>{user.name || " -- --"} </Text>
										</Flex>
										<Flex gap='1em'>
											<Text fontWeight='bold'>Negocio:</Text>
											<Text>{user.business.name || " -- --"} </Text>
										</Flex>
										<Flex gap='1em'>
											<Text fontWeight='bold'>Teléfono:</Text>
											<Text>{user.business.phone || " -- --"} </Text>
										</Flex>
										<Flex gap='1em'>
											<Text fontWeight='bold'>Dirección:</Text>
											<Text>{user.business?.address?.formatted_address || " -- --"} </Text>
										</Flex>
									</Flex>

									<Flex direction='column' w='50%' gap='1em'>
										<Flex gap='1em'>
											<Text fontWeight='bold'>Creado:</Text>
											<Text>{moment(user.created_at).format("LLL")} </Text>
										</Flex>
										<Flex gap='1em'>
											<Text fontWeight='bold'>Last login:</Text>
											<Text>{moment(user.last_login).format("LLL")} </Text>
										</Flex>
									</Flex>
								</Flex>
								<hr />
								<Flex gap='1em' mt='1rem'>
									<Text fontWeight='bold'>is Staff:</Text>
									{lu ? (
										<Spinner />
									) : (
										<Switch
											colorScheme='brand'
											isChecked={user.is_staff}
											onChange={handleUpdateUser}
										/>
									)}
								</Flex>
								<Flex gap='1em'>
									<Text fontWeight='bold'>is Active:</Text>
									{lu ? (
										<Spinner />
									) : (
										<Switch
											colorScheme='brand'
											isChecked={user.is_active}
											onChange={() =>
												editUser({
													slug: user.id,
													data: { is_active: !user.is_active },
													token: authHeader()
												})
											}
										/>
									)}
								</Flex>
								{user.social_account && "Google Account"}
							</Flex>
						</ModalBody>
					)}
					<ModalFooter>
						<Button variant='primary' size='lg' onClick={replaceUser} isLoading={loadingAs}>
							Login as
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<LoadingOverlay isLoading={loadingPlan} />
		</>
	);
}
