import { Text } from "@chakra-ui/react";
//types
import type { PriceDuration } from "../../../../types/service";
//fns
import { parseDuration } from "../../../common/functions";

interface PriceDurationDisplayProps {
	prices: PriceDuration[];
}

export default function DurationDisplay({ prices }: PriceDurationDisplayProps) {
	let durationDisplay = "-- --";

	if (prices.length === 1) {
		const duration = prices[0].duration;
		durationDisplay = parseDuration(duration);
	} else if (prices.length > 1) {
		const minDuration = Math.min(...prices.map((priceDuration) => priceDuration.duration));
		const maxDuration = Math.max(...prices.map((priceDuration) => priceDuration.duration));
		durationDisplay = `${parseDuration(minDuration)} - ${parseDuration(maxDuration)}`;
	}

	return <Text>{durationDisplay}</Text>;
}
