import { Text, View } from "@react-pdf/renderer";
import { styles } from "../PDFStyle";
import moment from "moment/moment";
//fns
import { formatDecimals } from "../../../../common/functions";

export default function InvoicePDFResume({ invoice, invoiceSettings }) {
	const discount = invoice?.discount > 0 ? (invoice.discount / 100) * invoice.subtotal : 0;
	const expirationDays = moment(invoice?.expiring_date).diff(moment(invoice?.date), "days");
	return (
		<>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "flex-end",
					marginTop: "50px"
				}}
			>
				<View
					style={{
						flexDirection: "column",
						gap: "10px",
						alignItems: "flex-end"
					}}
				>
					{invoice?.discount > 0 && (
						<>
							<View style={styles.resumeRow}>
								<Text>Subtotal</Text>
								<Text>{formatDecimals(invoice?.subtotal)}€</Text>
							</View>

							<View style={styles.resumeRow}>
								<Text>Descuento {invoice?.discount}%</Text>
								<Text>{discount.toFixed(2)}€</Text>
							</View>
						</>
					)}
					<View style={styles.resumeRow}>
						<Text>Base Imponible</Text>
						<Text>{formatDecimals(invoice?.subtotal - discount)}€</Text>
					</View>
					<View style={styles.resumeRow}>
						<Text>IVA</Text>
						<Text>{formatDecimals(invoice?.taxes)}€</Text>
					</View>
					<View style={styles.resumeRow}>
						<Text>Total</Text>
						<Text style={{ fontWeight: "semibold" }}>{formatDecimals(invoice?.total)}€</Text>
					</View>
				</View>
			</View>

			{/* Forma de pago etc */}
			<View
				style={{
					flexDirection: "column",
					position: "absolute",
					bottom: "35px",
					left: "35px",
					gap: "10px"
				}}
			>
				<Text style={{ color: "#0258FF", fontWeight: "medium" }}>Detalles Pago</Text>
				{invoiceSettings?.invoice_expiration && (
					<Text>
						El pago se realizará en un plazo de {expirationDays} días (
						{moment(invoice?.expiring_date).format("L")})
					</Text>
				)}
				{invoiceSettings?.IBAN && <Text>IBAN {invoiceSettings?.IBAN}</Text>}
			</View>
		</>
	);
}
