import { createApi, getAll, getAllPag, createUpdate, deleteItem } from './baseApi'

const clientsApi = createApi('clients')

export const getAllClients = async (payload) => {
    return getAll(clientsApi, payload)
}

export const getAllClientsPag = async (payload) => {
    return getAllPag(clientsApi, payload)
}

export const createUpdateClient = async (payload) => {
    return createUpdate(clientsApi, payload)
}

export const deleteClient = async (payload) => {
    return deleteItem(clientsApi, payload)
}
