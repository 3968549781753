import { Flex, Heading, Text, useMediaQuery, Highlight } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
// Components
import NavLoginRegister from "../../components/navbar/NavLoginRegister";
import RegisterForm from "../../components/auth/RegisterForm";

export default function RegisterBeta() {
	const pathname = useLocation().pathname;
	const [isMobile] = useMediaQuery("(max-width: 768px)");

	if (!isMobile) {
		return (
			<Flex w='100%' minH='100vh' direction='column' bg='lightgrey'>
				<NavLoginRegister path={pathname} />

				<Flex
					justify='center'
					align='start'
					w={!isMobile ? "100%" : "90%"}
					p={!isMobile ? "3em" : "0.5em"}
					gap='2em'
				>
					<Flex direction='column' gap='1em' maxW='600px'>
						<Heading>Eres justo lo que estábamos buscando</Heading>
						<Text>
							¡Únete a nosotros como beta tester y lleva tu taller de detailing a otro nivel antes que tu
							competencia!
						</Text>
						<Text fontWeight='bold' fontSize='18px'>
							Prepárate para estar a la última
						</Text>
						<Text>
							Es posible que a veces te encuentres con algún error 404 xd pero por otro lado tendrás
							acceso antes que nadie a las nuevas caracteristícas y actualizaciones. Además te convertiras
							en una parte muy importante del desarrollo y junto al equipo podrás diseñar y proponer
							nuevas funciones.
						</Text>
						<Text fontWeight='bold' fontSize='18px'>
							¿Cómo formar parte?
						</Text>
						<Text>
							<span style={{ color: "#0258FF", fontWeight: "bold" }}>1. </span>
							<span style={{ fontWeight: "bold" }}> Regístrate</span>a través del formulario de la derecha
						</Text>
						<Text>
							<span style={{ color: "#0258FF", fontWeight: "bold" }}>2. </span>
							<span style={{ fontWeight: "bold" }}> Utiliza el software. </span>
							Prueba, experimenta, intenta romperlo.
						</Text>
						<Text>
							<span style={{ color: "#0258FF", fontWeight: "bold" }}>3. </span>
							<span style={{ fontWeight: "bold" }}> Danos tu feedback. </span>
							Cuéntanos lo que te sirve y lo que no, lo que podría funcionar mejor o la caracteristica
							estrella con la que soñaste ayer.
						</Text>
						<Text fontWeight='bold'>¡Repite el paso 2 y 3 sin parar!</Text>
					</Flex>

					<Flex
						bg='white'
						maxW='550px'
						mt={isMobile && "1.5em"}
						rounded='xl'
						direction='column'
						align='center'
						gap='3'
						p={!isMobile ? "4em" : "2em"}
						boxShadow='lg'
					>
						<RegisterForm isBeta />
					</Flex>
				</Flex>
			</Flex>
		);
	} else {
		return (
			<Flex w='100%' minH='100vh' direction='column' bg='lightgrey'>
				<NavLoginRegister path={pathname} />
				<Flex direction='column' align='center' gap='1em' p='1em'>
					<Flex
						bg='white'
						rounded='xl'
						direction='column'
						align='center'
						gap='3'
						px='3em'
						py='1.5em'
						boxShadow='lg'
					>
						<RegisterForm isBeta />
					</Flex>
					<Flex direction='column' gap='1em' mt='1em'>
						<Heading fontSize='18px'>Eres justo lo que estábamos buscando</Heading>
						<Text>
							¡Únete a nosotros como beta tester y lleva tu taller de detailing a otro nivel antes que tu
							competencia!
						</Text>
						<Text fontWeight='bold' fontSize='18px'>
							Prepárate para estar a la última
						</Text>
						<Text>
							Es posible que a veces te encuentres con algún error 404 xd pero por otro lado tendrás
							acceso antes que nadie a las nuevas caracteristícas y actualizaciones. Además te convertiras
							en una parte muy importante del desarrollo y junto al equipo podrás diseñar y proponer
							nuevas funciones.
						</Text>
						<Text fontWeight='bold' fontSize='18px'>
							¿Cómo formar parte?
						</Text>
						<Text>
							<span style={{ color: "#0258FF", fontWeight: "bold" }}>1. </span>
							<Highlight query='Regístrate' styles={{ fontWeight: "bold" }}>
								Regístrate a través del formulario de la derecha
							</Highlight>
						</Text>
						<Text>
							<span style={{ color: "#0258FF", fontWeight: "bold" }}>2. </span>
							<span style={{ fontWeight: "bold" }}> Utiliza el software. </span>
							Prueba, experimenta, intenta romperlo.
						</Text>
						<Text>
							<span style={{ color: "#0258FF", fontWeight: "bold" }}>3. </span>
							<span style={{ fontWeight: "bold" }}> Danos tu feedback. </span>
							Cuéntanos lo que te sirve y lo que no, lo que podría funcionar mejor o la caracteristica
							estrella con la que soñaste ayer.
						</Text>
						<Text fontWeight='bold'>¡Repite el paso 2 y 3 sin parar!</Text>
					</Flex>
				</Flex>
			</Flex>
		);
	}
}
